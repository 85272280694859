import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "bp-input__description" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_wysiwyg_editor_actions = _resolveComponent("bp-wysiwyg-editor-actions")!
  const _component_editor_content = _resolveComponent("editor-content")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!

  return (_openBlock(), _createBlock(_component_bp_label, _mergeProps({ class: "bp-wysiwyg-editor" }, _ctx.labelProps, {
    key: _ctx.unique + '_' + _ctx.language
  }), _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['bp-wysiwyg-editor__wrapper', {
      'bp-wysiwyg-editor__wrapper--disabled': _ctx.disabled,
      'bp-wysiwyg-editor__wrapper--seamless': _ctx.seamless,
      'bp-wysiwyg-editor__wrapper--invalid': _ctx.hasError(),
      'bp-wysiwyg-editor__wrapper--valid': _ctx.isDirty && _ctx.valid !== undefined && _ctx.valid,
    }])
      }, [
        (_ctx.editor)
          ? (_openBlock(), _createBlock(_component_bp_wysiwyg_editor_actions, {
              key: 0,
              editor: _ctx.editor,
              "allow-alerts": _ctx.allowAlerts
            }, null, 8, ["editor", "allow-alerts"]))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_component_editor_content, {
          class: "bp-wysiwyg-editor__editor-wrapper",
          onDblclick: _ctx.extendLinkSelection,
          editor: _ctx.editor,
          key: _ctx.unique + '_' + _ctx.language,
          ref: "editorContent"
        }, null, 8, ["onDblclick", "editor"]))
      ], 2)
    ]),
    _: 2
  }, [
    (_ctx.isTranslated(_ctx.modelValue) || (_ctx.maxlength && _ctx.maxlength > 0) || (_ctx.desiredlength && _ctx.desiredlength > 0))
      ? {
          name: "text",
          fn: _withCtx(() => [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.editor && _ctx.editor.storage && _ctx.editor.storage.characterCount ? _ctx.editor.storage.characterCount.characters() : 0], (length) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: length }, [
                  ((_ctx.maxlength && _ctx.maxlength > 0) || (_ctx.desiredlength && _ctx.desiredlength > 0))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "flex gap-sm",
                        style: _normalizeStyle({
            ..._ctx.maxlength && _ctx.maxlength > 0 ? (
              length === _ctx.maxlength ? { color: 'var(--color-red-600)'} :
              length / _ctx.maxlength >= 0.9 || length + 1 === _ctx.maxlength ? { color: 'var(--color-yellow-600)' } :
              {}
            ) : {},
            ..._ctx.desiredlength && _ctx.desiredlength > 0 ? (
              length === _ctx.desiredlength ? { color: 'var(--color-green-600)' } :
              {}
            ) : {},
            'float': 'right',
            'justify-content': 'flex-end',
          })
                      }, [
                        (_ctx.maxlength && _ctx.maxlength > 0)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (length === _ctx.maxlength)
                                ? (_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 0,
                                    icon: "circle-xmark"
                                  }))
                                : (length / _ctx.maxlength >= 0.9 || length + 1 === _ctx.maxlength)
                                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                                      key: 1,
                                      icon: "triangle-exclamation"
                                    }))
                                  : _createCommentVNode("", true)
                            ], 64))
                          : (_ctx.desiredlength && _ctx.desiredlength > 0 && length === _ctx.desiredlength)
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 1,
                                icon: "check"
                              }))
                            : _createCommentVNode("", true),
                        (_openBlock(), _createBlock(_resolveDynamicComponent((_ctx.maxlength && _ctx.maxlength > 0 && (length === _ctx.maxlength || length / _ctx.maxlength >= 0.9 || length + 1 === _ctx.maxlength)) || (_ctx.desiredlength && _ctx.desiredlength > 0 && length === _ctx.desiredlength) ? 'strong' : 'span'), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(length) + "/" + _toDisplayString(_ctx.desiredlength || _ctx.maxlength), 1)
                          ]),
                          _: 2
                        }, 1024))
                      ], 4))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              _createElementVNode("div", _hoisted_1, [
                (_ctx.text || _ctx.$slots.text)
                  ? _renderSlot(_ctx.$slots, "text", { key: 0 }, () => [
                      _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_2)
                    ], true)
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isTranslated(_ctx.modelValue))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['bp-wysiwyg-editor__language-selector', { 'mt-sm': _ctx.text || _ctx.$slots.text }])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableLanguages).sort((a, b) => a < b ? -1 : 1), (lang, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: lang.toLowerCase()
                      }, [
                        (index !== 0)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              icon: "slash-forward",
                              "fixed-width": false
                            }))
                          : _createCommentVNode("", true),
                        (_openBlock(), _createBlock(_resolveDynamicComponent('bp-input'), {
                          "model-value": _ctx.language,
                          name: 'language-selector-' + _ctx.unique,
                          text: _ctx.getTranslatedName(lang),
                          value: _ctx.getTranslatedKey(lang),
                          "onUpdate:modelValue": _ctx.setLanguage,
                          seamless: "",
                          type: "radio"
                        }, null, 8, ["model-value", "name", "text", "value", "onUpdate:modelValue"]))
                      ], 64))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.hasError())
      ? {
          name: "error",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "error", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.error || _ctx.errorMessage), 1)
            ], true)
          ]),
          key: "1"
        }
      : undefined
  ]), 1040))
}