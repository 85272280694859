
import { computed, defineComponent, ref, PropType, watchEffect, onActivated, watch } from 'vue';
import { isSetEqual } from '@/utils/array';
import { isTree } from '@/utils/object';
import type { BaseDoc } from './BpVirtualScroller';
import useResize from '@/utils/resize-observer';

/**
 * A virtual scroller component.
 * https://dev.to/adamklein/build-your-own-virtual-scroll-part-i-11ib
 */
export default defineComponent({
  name: 'bp-virtual-scroller',
  props: {
    data: {
      type: Array as PropType<BaseDoc[]>,
      required: true
    },
    expanded: Object as PropType<Set<string>>,
    rowHeight: {
      type: Number,
      default: 50.4, // 1rem font-size (16px) + 1rem padding top & bottom (16px each) + factor 1.15 line-height (~2.4px)
    },
    bufferSize: {
      type: Number,
      default: 2,
    },
    nodeTag: {
      type: String,
      default: 'div',
    },
    nodesTag: {
      type: String,
      default: 'div',
    },
  },
  emits: [
    'dom-update'
  ],
  setup(props, ctx) {
    const scrollTop = ref(0);
    const viewport = ref<HTMLDivElement>();
    const viewportHeight = ref(0);
    const nodes = ref<HTMLDivElement>();

    const docs = ref<BaseDoc[]>([]);
    watchEffect(() => {
      if (isTree(props.data)) {
        docs.value = props.data.filter(record => record._depth === 0 || isSubTreeExpanded(record));
        return;
      }
      docs.value = props.data;
    })

    function isSubTreeExpanded(doc: BaseDoc) {
      let parentId = doc.parent_id;
      while (parentId && parentId !== 'root') {
        if (!props.expanded?.has(parentId)) {
          return false;
        }
        const parent = props.data.find(record => record._id === parentId);
        if (!parent) {
          return false;
        }
        parentId = parent.parent_id;
      }
      return true;
    }

    const itemCount = computed(() => docs.value.length);
    const totalContentHeight = computed(() => itemCount.value * props.rowHeight);

    const visibleNodeCount = computed(() => Math.ceil(viewportHeight.value / props.rowHeight) + 2 * props.bufferSize);

    const startNode = computed(() => {
      const firstNode = Math.floor(scrollTop.value / props.rowHeight);
      return Math.max(firstNode - props.bufferSize, 0);
    });

    const endNode = computed(() => startNode.value + Math.max(0, visibleNodeCount.value - 1));

    function adjustViewport() {
      const height = viewport.value ? viewport.value.offsetHeight : 0;
      viewportHeight.value = height;
    }
    useResize(() => viewport.value, adjustViewport);

    const offsetY = computed(() => startNode.value * props.rowHeight);

    let animationFrame = NaN;
    function onScroll(event: UIEvent) {
      const target = event.target as HTMLDivElement;
      if (!isNaN(animationFrame)) {
        cancelAnimationFrame(animationFrame);
      }
      animationFrame = requestAnimationFrame(() => {
        const scrollValue = target.scrollTop;
        scrollTop.value = scrollValue;
        animationFrame = NaN;
        if (nodes.value) {
          nodes.value.style.transform = `translateY(${offsetY.value}px)`;
        }
      });
    }
    // Keep alive handling when going back to page - either set the scrollTop position or remember it, based on scroll position
    // useLifecycle(() => {
    //   if (viewport.value && scrollTop.value > 0) {
    //     viewport.value.scrollTop = scrollTop.value;
    //   } else if (viewport.value) {
    //     scrollTop.value = viewport.value.scrollTop
    //   }
    // });

    const oldVisibleIds = ref(new Set());
    const visibleItems = computed(() => docs.value.slice(startNode.value, endNode.value + 1));
    watch(visibleItems, () => {
      const newVisibleIds = new Set(visibleItems.value.map(item => item._id));
      if (isSetEqual(oldVisibleIds.value, newVisibleIds)) {
        oldVisibleIds.value = newVisibleIds; 
        ctx.emit('dom-update');
      }
    });

    onActivated(() => {
      if (scrollTop.value > 0) {
        viewport.value?.scrollTo(0, scrollTop.value);
      } else {
        scrollTop.value = viewport.value?.scrollTop || 0;
      }
    })

    return {
      onScroll,
      totalContentHeight,
      viewport,
      offsetY,
      visibleItems,
      startNode,
      nodes,
      docs,
    };
  },
});
