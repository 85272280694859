
import { $gettext } from 'vue-gettext';
import { availableLanguages, currentLanguage, currentLanguageISOString, getTranslatedKey, getTranslatedName, isTranslated, Translated, TranslatedKey } from '@/translation';
import { byKey, Order } from '@/utils/array';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';
import { cssColor, incrementShade, Modifier } from '@/utils/color';
import { deepValue } from '@/utils/object';
import { escapeRegExp, humanizedJoin, removeHTML, uuid } from '@/utils/string';
import { IconProp } from './icon/BpIcon';
import { PopoverElement, seekOption } from './popover/BpPopoverMenu';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-select',
  props: {
    modelValue: [String, Array, Object] as PropType<string | string[] | Translated<string> | Translated<string[]>>,
    clearable: Boolean,
    closeOnClick: Boolean,
    createNewOnEnter: Boolean,
    data: Array as PropType<PopoverElement[]>,
    hideSelected: Boolean,
    large: Boolean,
    loading: Boolean,
    multiple: Boolean,
    placeholder: String,
    readonly: Boolean,
    searchable: {
      type: Boolean,
      default: true,
    },
    searchProperty: [String, Function] as PropType<string | (<T>(item: T) => string)>,
    sortable: {
      type: Boolean,
      default: true,
    },
    sortProperty: [String, Array, Function] as PropType<string | (<T>(item: T) => string)>,
    sortOrder: {
      type: String as PropType<Order>,
      default: 'asc'
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform: Function as PropType<(item: any) => string>,
    valid: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },
    // LABEL
    disabled: Boolean,
    error: String,
    errorIcon: IconProp,
    label: String,
    labelPosition: String,
    labelWidth: String,
    indent: Number,
    required: Boolean,
    seamless: Boolean,
    text: String,
    unwrap: Boolean,
    // POPOVER
    flip: Boolean,
    offset: [Number, Array] as PropType<number | [number, number]>,
    placement: String as PropType<'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end'>,
  },
  emits: [
    'update:model-value',
    'change-dirty',
    'change-valid',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// LABEL
    ///-------------------------------------------------------------------

    const labelProps = computed(() => Object.fromEntries(Object.entries(props)
      .filter(([key]) => [
        'disabled', 'error', 'errorIcon', 'innerGap', 'indent', 'label', 'labelPosition', 'labelWidth', 'required', 'seamless', 'text'
      ].includes(key))));
    const labelSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => ![
        'default',
        'label',
        hasError() && 'error',
      ].includes(slot));
    });

    const unique = uuid();

    ///-------------------------------------------------------------------
    /// COMPACT MODE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const language = ref<TranslatedKey>(currentLanguageISOString());

    function setLanguage(lang: string) {
      language.value = getTranslatedKey(lang);
    }

    ///-------------------------------------------------------------------
    /// SEARCH
    ///-------------------------------------------------------------------

    const searchKey = (item?: unknown) => item
      ? (typeof props.searchProperty === 'function' ? props.searchProperty(item) : props.searchProperty) || '_id'
      : typeof props.searchProperty === 'string' ? props.searchProperty : '_id';

    const search = ref<string>((() => {
      if (props.multiple || !props.modelValue) {
        return '';
      }
      if (isTranslated(props.modelValue)) {
        const value = props.modelValue[language.value];
        if (!value) {
          return '';
        }
        return typeof value === 'string' ? value : value[0];
      }
      return typeof props.modelValue === 'string' ? props.modelValue : props.modelValue[0];
    })());

    const createdData = computed(() => {
      const data = props.data;
      if (!props.multiple || !Array.isArray(props.modelValue) || !data) {
        return [];
      }
      const ids = data.map(el => el._id);
      return props.modelValue.filter(selected => !ids.includes(selected)).map(el => ({
        _id: el,
        [searchKey()]: el,
      }));
    })

    const menuData = computed(() => {
      const regex = new RegExp(escapeRegExp(search.value).replace(/\s/, '.*?'), 'i');
      if (!props.data) {
        return [];
      }
      let data: PopoverElement[] = [...props.data, ...createdData.value]
      const count = data.length;

      // Transform
      const transform = props.transform;
      if (transform !== undefined) {
        data = data
          .map(item => ({ ...item, _transform: transform(item) }))
          .filter(item => (regex.test(removeHTML(item._transform).replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&'))) || '_subheading' in item);
        data = data
          .filter(item => !item._subheading || data.filter(i => i.doc_type === item.doc_type).length > 1)
        if (props.sortable) {
          data = data.sort(byKey<PopoverElement>(props.sortProperty || '_transform', props.sortOrder));
        }
      }
      // Search property
      else {
        data = data
          .filter(item => (regex.test(props.searchProperty
            ? deepValue(item, searchKey(item))
            : item._id) && (props.hideSelected ? !selected.value.has(item._id) : true)) || '_subheading' in item);
        data = data
          .filter(item => !item._subheading || data.filter(i => i.doc_type === item.doc_type).length > 1)
        if (props.sortable) {
          data = data.sort(byKey<PopoverElement>(props.sortProperty || props.searchProperty, props.sortOrder));
        }
      }

      return data.length === 0 && !!search.value
        ? [{ _id: 'not_found', [transform !== undefined ? '_transform' : searchKey()]: $gettext('Nothing found…'), icon: 'ban', _disabled: true }]
        : data.length < count && !!search.value
          ? [...data, {_id: 'more', [transform !== undefined ? '_transform' : searchKey()]: $gettext('%{count} more entries not matching the search term…', { count: count - data.length }), icon: 'ellipsis-vertical', _disabled: true }]
          : data;
    })

    ///-------------------------------------------------------------------
    /// DIRTY
    ///-------------------------------------------------------------------

    const isDirty = ref(false);

    ///-------------------------------------------------------------------
    /// ITEMS
    ///-------------------------------------------------------------------

    function getItem(id: string) {
      const data = props.data;
      if (!data) {
        return;
      }
      const item = props.data.find(item => item._id === id);
      return item;
    }

    function getName(itemOrId: PopoverElement | string) {
      if (itemOrId === undefined) {
        return '';
      }
      let item;
      if (typeof itemOrId === 'string') {
        item = getItem(itemOrId);
      } else {
        item = itemOrId;
      }
      if (!item) {
        return itemOrId;
      } else if (Object.keys(item).length === 1 && '_id' in item) {
        return item._id;
      }

      // Transform
      const transform = props.transform;
      if (transform !== undefined) {
        return removeHTML(transform(item)).replaceAll('&nbsp;', ' ').replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&');
      }

      // Search property
      const key = searchKey(item);
      const name = key ? deepValue(item, key) : item._id;
      return name;
    }

    ///-------------------------------------------------------------------
    /// SELECTED
    ///-------------------------------------------------------------------

    const selected = ref(new Set<string>());

    watch(() => [props.modelValue, currentLanguage.value, language.value], () => {
      let value = isTranslated(props.modelValue) ? props.modelValue[language.value] : props.modelValue;
      // UNDEFINED HANDLING
      if (value === undefined) {
        selected.value = new Set<string>();
        if (!props.multiple) {
          search.value = '';
        }
        return;
      }

      // MULTIPLE SELECT
      if (props.multiple) {
        if (!Array.isArray(value)) {
          value = [value];
        }
        selected.value = new Set<string>(value.filter(el => el));
      }

      // SINGLE SELECT
      else {
        if (Array.isArray(value)) {
          value = value[0];
        }
        selected.value = new Set<string>([value].filter(el => el));
        search.value = getName(value)
      }
    }, { immediate: true });

    function emitValue() {
      const selectedArray = Array.from(selected.value);
      if (isTranslated(props.modelValue)) {
        ctx.emit('update:model-value', { ...props.modelValue, [language.value]: props.multiple ? selectedArray : selectedArray[0] || '' });
      } else {
        ctx.emit('update:model-value', props.multiple ? selectedArray : selectedArray[0] || '');
      }
    }

    function clearSelected() {
      if (selected.value.size === 0) {
        return;
      }
      selected.value.clear();
      emitValue()
    }

    function removeSelected(value: string) {
      if (!props.clearable && selected.value.size === 1) {
        return;
      }
      selected.value.delete(value);
      emitValue()
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function toggleSelected(option: PopoverElement, event?: PointerEvent) {
      // If the option is selected, delete it from the selected set if allowed.
      if (selected.value.has(option._id)) {
        if (props.clearable || selected.value.size > 1) {
          selected.value.delete(option._id);
        }
      }

      // Otherwise add the option to the selected set. If the select is a single select field, clear the selected set beforehand.
      else {
        if (!props.multiple) {
          selected.value.clear();
        }
        selected.value.add(option._id)
      }

      // If the select is a single select field, set the name of the option as the current search string, so it is shown as selected.
      // Close the open menu afterwards.
      if (!props.multiple) {
        search.value = getName(option);
        inputEl.value?.focus();
        menu.value.state = false;
      }

      // EMIT VALUE
      emitValue();

      // Conditionally focus next or previous sibling if the toggled option is hidden via "hide-selected" property.
      if (props.hideSelected) {
        const prevSibling = document.activeElement?.parentElement?.previousElementSibling?.children[0] as HTMLButtonElement | undefined;
        const nextSibling = document.activeElement?.parentElement?.nextElementSibling?.children[0] as HTMLButtonElement | undefined;
        if (nextSibling) {
          nextSibling.focus();
        } else if (prevSibling) {
          prevSibling.focus();
        }
      }

      // DIRTY STATE
      if (!isDirty.value) {
        isDirty.value = true;
        ctx.emit('change-dirty', true);
      }

      // VALIDITY
      checkValidity();
    }

    ///-------------------------------------------------------------------
    /// FOCUS / BLUR INPUT
    ///-------------------------------------------------------------------

    const inputEl = ref<HTMLInputElement>();

    function blurInput(event: FocusEvent) {
      // Find the element which was focussed (e.g. by clicking on it) when the input is blurred
      const relatedTarget = event.relatedTarget as HTMLDivElement | null;

      // If there is no such element we reset the search value.
      // In case of a single select field we reset the value to the last selected one.
      // A multiselect field will clear its search value alltogether.
      if (!relatedTarget || !relatedTarget.classList.contains('bp-popover-menu__option')) {
        if (!props.multiple) {
          search.value = getName(selected.value.values().next().value || '');
        } else {
          search.value = '';
        }
        menu.value.state = false;
      }

      if (!inputEl.value) {
        return;
      }
      keyboardNavigation.value = false;
    }

    function focusInput() {
      if (!inputEl.value || props.disabled) {
        return;
      }
      nextTick(() => inputEl.value && inputEl.value.focus())
      if (!menu.value.state) {
        openMenu();
      }
    }

    ///-------------------------------------------------------------------
    /// DROPDOWN MENU
    ///-------------------------------------------------------------------

    const menu = ref({
      state: false,
      nextState: true,
    })

    function openMenu() {
      if (!props.data || props.data.length === 0 || props.disabled) {
        return;
      }
      menu.value.state = true;
      dispatchCustomEvent();
    }

    function updateMenuState(newState: boolean) {
      if (!props.data || props.data.length === 0 || props.disabled) {
        return;
      }
      menu.value.state = newState;

      if (!newState) {
        if (!props.multiple) {
          search.value = getName(selected.value.values().next().value || '');
        } else {
          search.value = '';
        }
      }

      // If an option is clicked an therefore the menu would be closed,
      // focus the single select field in order to be able to continue filling out the form without additional mouse interaction
      if (!open && !props.multiple) {
        inputEl.value?.focus();
      }
    }

    function setMenuNextState() {
      menu.value.nextState = !menu.value.state;
    }

    function setMenuState() {
      if (!props.data || props.data.length === 0 || props.disabled) {
        return;
      }
      menu.value.state = menu.value.nextState;

      // In case of a single select field we reset the value to the last selected one.
      // A multiselect field will clear its search value alltogether.
      if (!props.multiple) {
        search.value = getName(selected.value.values().next().value || '');
      } else {
        search.value = '';
      }

      if (menu.value.state && !props.multiple) {
        search.value = '';
        inputEl.value?.focus();
      }
    }

    function resetMenuNextState() {
      window.setTimeout(() => menu.value.nextState = !menu.value.state, 0)
    }

    ///-------------------------------------------------------------------
    /// KEYBOARD NAVIGATION
    ///-------------------------------------------------------------------

    const keyboardNavigation = ref(false);

    /**
     * Focuses the first or last element of the popover menu.
     * MAY BE VERY FRAGILE - keep this in mind!
     * @param firstLast Whether to focus the `first` or the `last` element of the popover menu.
     */
    function focusElement(firstLast: 'first' | 'last') {
      if (props.disabled) {
        return;
      }
      const popoverMenuOptions = document.querySelector('.bp-popover-menu__options');
      if (!popoverMenuOptions) {
        return;
      }
      popoverMenuOptions.scrollTo(0, firstLast === 'first' ? 0 : popoverMenuOptions.children[0].scrollHeight);
      setTimeout(() => {
        const elements = document.querySelectorAll('.bp-popover-menu__option');
        const element = seekOption(elements[firstLast === 'first' ? 0 : elements.length - 1] as HTMLButtonElement | null, firstLast === 'first' ? 'next' : 'prev');
        if (!element) {
          return;
        }
        element.focus();
      }, 50);
    }

    /**
     * Handles keyboard input within the select's input field.
     * @param event The event.
     */
    function handleKeyboardInput(event: KeyboardEvent) {
      if (props.disabled) {
        return;
      }
      keyboardNavigation.value = false;
      switch (event.code) {
        case 'ArrowDown': {
          keyboardNavigation.value = true;
          event.preventDefault();
          if (!menu.value.state) {
            openMenu();
          } else {
            focusElement('first');
          }
          break;
        }
        case 'ArrowUp': {
          keyboardNavigation.value = true;
          event.preventDefault();
          if (!menu.value.state) {
            openMenu();
          } else {
            focusElement('last');
          }
          break;
        }
        case 'Backspace': {
          if (props.multiple && inputEl.value && inputEl.value.selectionStart === 0 && inputEl.value.selectionEnd === 0) {
            keyboardNavigation.value = true;
            event.preventDefault();
            removeSelected(Array.from(selected.value)[selected.value.size - 1])
          }
          break;
        }
        case 'Enter': {
          keyboardNavigation.value = true;
          event.preventDefault();
          if (!menu.value.state) {
            openMenu();
          } else {
            nextTick(() => {
              if (props.createNewOnEnter) {
                toggleSelected({ _id: search.value });
                search.value = '';
              } else {
                const firstElementInMenu = seekOption(document.querySelector('.bp-popover-menu__option--first') as HTMLButtonElement | null);
                if (!firstElementInMenu) return;
                firstElementInMenu.click();
              }
              if (props.closeOnClick) {
                menu.value.state = false;
              }
            })
          }
          break;
        }
        default: {
          if (!menu.value.state) {
            openMenu();
          }
          break;
        }
      }
      // TODO: FIX FOR MOBILE INPUT
      // - would work in combination by replacing v-model="search" with :model-value="search"
      // - somehow breaks display of currently selected value
      // const target = event.target as HTMLInputElement;
      // search.value = target.value;
    }

    ///-------------------------------------------------------------------
    /// SLOTS
    ///-------------------------------------------------------------------

    function slotName(prefix: string, id: string) {
      return `${prefix}-${id.replace(/\s/g, '_')}`;
    }

    const tagSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => {
        return slot.startsWith('option') || slot.startsWith('tag');
      });
    });

    const optionSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => {
        return slot.startsWith('option');
      });
    });

    ///-------------------------------------------------------------------
    /// VALIDITY
    ///-------------------------------------------------------------------

    function hasError() {
      return isDirty.value && !isValid.value && (errorMessage.value || ctx.slots.error);
    }

    const isValid = ref<boolean>(true);
    const errorMessage = ref<string | null>('');

    function checkValidity() {
      const wasValid = isValid.value;
      const value = props.modelValue as string;

      ///-------------------------------------------------------------------
      /// DISABLED -- TODO: Check if this logic is correct!
      ///-------------------------------------------------------------------

      if (props.disabled) {
        ctx.emit('change-valid', '', value);
        return;
      }

      ///-------------------------------------------------------------------
      /// REQUIRED
      ///-------------------------------------------------------------------

      if (props.required) {
        // TRANSLATED OBJECT
        if (isTranslated(value) && Object.values(value).filter(val => props.multiple ? val.length === 0 : !val).length > 0) {
          isValid.value = false;
          const errorLanguages = Object.entries(value)
            .filter(([, val]) => props.multiple ? val.length === 0 : !val)
            .map(([lang]) => getTranslatedName(lang))
            .sort((a, b) => a < b ? -1 : 1);
          errorMessage.value = humanizedJoin(errorLanguages) + ' ' + (errorLanguages.length > 1 ? $gettext('are required.') : $gettext('is required.'));
          ctx.emit('change-valid', errorMessage.value, value);
          return;
        }

        // STRING
        if (selected.value.size === 0 || (selected.value.size === 1 && !selected.value.values().next().value)) {
          isValid.value = false;
          errorMessage.value = $gettext('Is required.');
          ctx.emit('change-valid', errorMessage.value, value);
          return;
        }
      }

      isValid.value = true;
      errorMessage.value = null;

      if (wasValid !== isValid.value) {
        ctx.emit('change-valid', errorMessage.value, value); // Array.from(selected.value));
      }
    }

    watch(
      () => [props.modelValue, currentLanguage.value, props.required, props.disabled],
      () => setTimeout(checkValidity, 0),
      { immediate: true, deep: true }
    );

    ///-------------------------------------------------------------------
    /// INNER WRAPPER
    ///-------------------------------------------------------------------

    const innerWrapperEl = ref<HTMLDivElement>();
    const innerWrapperOverflow = ref(false);

    watch(() => [innerWrapperEl.value, selected.value], calculateOverflow, { immediate: true, deep: true });
    function calculateOverflow() {
      nextTick(() => {
        if (!props.unwrap || !innerWrapperEl.value) {
          return;
        }
        const tags = innerWrapperEl.value.querySelectorAll('.bp-select__tag');
        let width = 0;
        for (const tag of tags) {
          width += tag.getBoundingClientRect().width;
        }
        const padding = parseFloat(window.getComputedStyle(innerWrapperEl.value).getPropertyValue('padding-left'));
        const containerWidth = innerWrapperEl.value.getBoundingClientRect().width - padding;
        innerWrapperOverflow.value = containerWidth < width;
      })
    }

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = computed(() => ({
      '--select-border-color': cssColor('light-blue'),
      '--select-focus-background-color': cssColor('light-blue'),
      '--select-focus-text-color': cssColor('light-blue', Modifier.TEXT),
      '--select-tag-background-color': cssColor('light-blue'),
      '--select-tag-border-color': cssColor('light-blue', incrementShade(600, 200)),
      '--select-tag-text-color': cssColor('light-blue', Modifier.TEXT),
    }));

    ///-------------------------------------------------------------------
    /// ENSURE ONLY ONE SELECT IS OPEN
    ///-------------------------------------------------------------------

    function dispatchCustomEvent() {
      const e = new CustomEvent('click-select', { detail: unique });
      document.body.dispatchEvent(e);
    }

    document.body.addEventListener('click-select', (e: Event) => {
      if (unique !== (e as CustomEvent).detail) {
        menu.value.state = false;
      }
    });

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      availableLanguages,
      blurInput,
      clearSelected,
      compactMode,
      errorMessage,
      focusInput,
      getItem,
      getName,
      getTranslatedKey,
      getTranslatedName,
      handleKeyboardInput,
      hasError,
      innerWrapperEl,
      innerWrapperOverflow,
      inputEl,
      isDirty,
      isTranslated,
      language,
      menu,
      menuData,
      openMenu,
      optionSlots,
      removeHTML,
      removeSelected,
      resetMenuNextState,
      search,
      selected,
      setLanguage,
      setMenuNextState,
      setMenuState,
      slotName,
      style,
      tagSlots,
      toggleSelected,
      unique,
      updateMenuState,
      // LABEL
      labelProps,
      labelSlots,
    }
  }
})
