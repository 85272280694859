import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core'

export interface AlertOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    alert: {
      /**
       * Set a alert node
       */
      setAlert: (type: string) => ReturnType,
      /**
       * Toggle a alert node
       */
      toggleAlert: (type: string) => ReturnType,
      /**
       * Unset a alert node
       */
      unsetAlert: () => ReturnType,
    }
  }
}

export const inputRegex = /^\s*>\s$/

export const Alert = Node.create<AlertOptions>({

  name: 'alert',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'bp-blockquote'
      },
    }
  },

  content: 'block+',

  group: 'block',

  defining: true,

  addAttributes() {
    return {
      class: {
        default: this.options.HTMLAttributes.class,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'blockquote',
        getAttrs: node => (node as HTMLElement).classList.contains('bp-blockquote') && null
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['blockquote', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setAlert: (type: string) => ({ commands }) => {
        return commands.wrapIn(this.name, { class: `bp-blockquote--${type}` })
      },
      toggleAlert: (type: string) => ({ commands }) => {
        return commands.toggleWrap(this.name, { class: `bp-blockquote--${type}` })
      },
      unsetAlert: () => ({ commands }) => {
        return commands.lift(this.name)
      },
    }
  },

  addKeyboardShortcuts() {
    return {}
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ]
  },
})

export default Alert;
