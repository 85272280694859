
import useCompactMode from '@/compositions/use-compact-mode';
import { ColorProp, cssColor, Modifier } from '@/utils/color';
import { computed, CSSProperties, defineComponent, PropType } from 'vue';
import { IconProp } from './icon/BpIcon';

export default defineComponent({
  name: 'bp-alert',
  props: {
    color: {
      type: ColorProp,
      default: 'light-blue',
    },
    borderRadius: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
    },
    icon: IconProp,
    opacity: {
      type: Number,
      default: 0.15,
    },
    opaque: Boolean,
    small: Boolean,
    text: String,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    const style = computed<CSSProperties>(() => {
      const color = Array.isArray(props.color)
        ? props.color.length > 0 ? props.color[0] : 'light-blue' // Fallback if somehow color is an empty array
        : props.color;

      function getInnerBorderRadius() {
        switch (props.borderRadius) {
          case 'xl': return '-lg';
          case 'lg': return '';
          case 'md': return '-sm';
          default: return '-xs';
        }
      }

      return {
        '--alert-background-color': props.opaque ? 'var(--theme-background)' : `rgba(${cssColor(color, Modifier.RGB)}, var(--alert-opacity))`,
        '--alert-border-color': cssColor(color),
        '--alert-border-radius': `var(--border-radius${props.borderRadius === 'md' ? '' : '-' + props.borderRadius})`,
        '--alert-heading-color': props.opaque ? 'var(--theme-text)' : cssColor(color),
        '--alert-inner-border-radius': `var(--border-radius${getInnerBorderRadius()})`,
        '--alert-opacity': props.opacity,
        '--alert-text-color': 'var(--theme-text)',
        '--alert-translucent-color': `rgba(${cssColor(color, Modifier.RGB)}, var(--alert-opacity))`,
      }
    });
    return {
      compactMode,
      style,
    }
  }
})
