import { $pgettext } from 'vue-gettext';
import { getTranslatedPlural } from '@/translation';
import deepMerge from './object';
import DOMPurify from 'dompurify';

/**
 * Escapes all relevant characters in the given string for usage in a regular expression.
 * @param regExp The string.
 */
export function escapeRegExp(regExp: string) {
  return regExp.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Unescapes all relevant characters in the given string for usage outside of a regular expression.
 * @param noRegExp The string.
 */
export function unescapeRegExp(noRegExp: string) {
  return noRegExp.replace(/\\([.*+?^${}()|[\]\\])/g, '$1');
}

export function removeHTML(html: unknown, opts?: DOMPurify.Config) {
  if (!opts) { opts = {} }
  return DOMPurify.sanitize(typeof html === 'boolean' ? JSON.stringify(html) : (html as string), { ALLOWED_TAGS: [], ...opts }) as string;
}

export function encodeURL(text: string) {
  return encodeURIComponent(removeHTML(text.replace(/\/([ph1-6]*)><([ph1-6].*?)>/g, '/$1>\n<$2>')))
}

/**
 * Checks if the given URL is absolute meaning it starts with the HTTP/HTTPS protocol followed by a colon and two slashes.
 * @param url The URL.
 * @returns Whether the URL is abolute or not.
 */
export function isAbsoluteUrl(url: string) {
  return /^https?:\/\//.test(url);
}

/**
 * Formats a number of bytes.
 * @param bytes The bytes.
 * @param decimals The amount of decimal places.
 * @returns The formatted byte string.
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Creates a random hexadecimal UUID.
 * @returns A random UUID.
 */
export function uuid() {
  return Math.random().toString(16).substring(2);
}

/**
 * Converts a digest from the CouchDB to a hexadecimal string representation.
 * @param digest The digest.
 * @returns The hexadecimal string
 */
export function base64ToHex(digest: string) {
  if (digest.startsWith('md5-')) {
    digest = digest.substring('md5-'.length);
  }
  return [...atob(digest)]
    .map(c=> c.charCodeAt(0).toString(16).padStart(2,'0'))
    .join('');
}

/**
 * Limits the length of a string.
 * @param str The string
 * @param length The length
 * @param ellipsis The ellipsis at the end
 * @param ellipsisLength The length of the ellipsis. Set to `0` to potentially remove a single character to add the ellipsis.
 * @returns The limited string
 */
export function limitLength(str: string, length: number, ellipsis = '…', ellipsisLength = ellipsis.length) {
  if (str.length > length + ellipsisLength) {
    return str.substring(0, length) + ellipsis;
  }
  return str;
}

/**
 * Joins strings used in a way usually preferred in natural languages.
 * @example humanizedJoin(['bread', 'butter', 'milk']) === 'bread, butter and milk'
 * @param array The array to join
 * @param separator The separator between words
 * @param lastSeparator The last separator to use between the last two words
 * @returns The joined string
 */
export function humanizedJoin(array: string[], separator = ',', lastSeparator = $pgettext('Conjuction word to list multiple words', 'and')) {
  if (array.length === 0) {
    return '';
  }
  if (array.length === 1) {
    return array[0];
  }
  if (array.length === 2) {
    return array[0] + ' ' + lastSeparator + ' ' + array[1];
  }
  return array.slice(0, array.length - 1).join(separator + ' ') + ' ' + lastSeparator + ' ' + array[array.length - 1]
}

export type TranslatedUnitEnumerationOpts = { difference: boolean, includeCount: boolean }
export function translatedUnitEnumeration(list: { [type: string]: number | [number, number]}, opts: Partial<TranslatedUnitEnumerationOpts> = {}) {
  const options = deepMerge({ difference: false, includeCount: true }, opts) as TranslatedUnitEnumerationOpts;

  return humanizedJoin(Object.entries(list).map(([type, count]) => {
    const actualCount = Array.isArray(count) ? (options.difference ? count[0] - count[1] : count[1]) : count;
    return actualCount > 0 ? (options.includeCount ? actualCount + ' ' : '') + getTranslatedPlural(type, actualCount) : '';
  }).filter(el => el));
}

/*

humanizedList([
  { type: 'customer', expected: customerIDs.length, count: customers.length },
  { type: 'contact', expected: contactIDs.length, count: contacts.length },
  { type: 'user', expected: userIDs.length, count: users.length },
])

humanizedList({
  customers: [customerIDs.length, customers.length],
  contacts: [contactIDs.length, contacts.length],
  users: [userIDs.length, users.length],
})

*/

/**
 * Changes the first letter of each word of a string to uppercase using an invariant culture.
 * @param string The string.
 * @returns The transformed string.
 */
export function toTitleCase(string: string) {
  return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

/**
 * Changes the first letter of each word of a string to uppercase using the browser local culture.
 * @param string The string.
 * @returns The transformed string.
 */
export function toLocaleTitleCase(string: string) {
  return string.split(' ').map(word => word.charAt(0).toLocaleUpperCase() + word.slice(1)).join(' ');
}

/**
 * Changes the first letter of a string to uppercase using an invariant culture.
 * @param string The string
 * @returns The transformed string
 */
export function firstToUpperCase(string: string) {
  if (string.length === 0) {
    return '';
  }
  return string[0].toUpperCase() + string.substring(1);
}

/**
 * Changes the first letter of a string to uppercase using the browser local culture.
 * @param string The string
 * @returns The transformed string
 */
export function firstToLocaleUpperCase(string: string) {
  if (string.length === 0) {
    return '';
  }
  return string[0].toLocaleUpperCase() + string.substring(1);
}

/**
 * Changes the first letter of a string to lowercase using an invariant culture.
 * @param string The string
 * @returns The transformed string
 */
export function firstToLowerCase(string: string) {
  if (string.length === 0) {
    return '';
  }
  return string[0].toLowerCase() + string.substring(1);
}

/**
 * Changes the first letter of a string to lowercase using the browser local culture.
 * @param string The string
 * @returns The transformed string
 */
export function firstToLocaleLowerCase(string: string) {
  if (string.length === 0) {
    return '';
  }
  return string[0].toLocaleLowerCase() + string.substring(1);
}

export function hashString(string: string) {
  let hash = 0, i, char;
  if (string.length === 0) {
    return hash;
  }
  for (i = 0; i < string.length; i++) {
    char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function stringify(value: unknown) {
  if (value === undefined || value === null) {
    return '';
  }
  switch (typeof value) {
    case 'string': return value;
    case 'object': return JSON.stringify(value);
    default: return String(value);
  }
}
