import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/home/Home.vue')
  },
  ///-------------------------------------------------------------------
  /// NOT LOGGED IN
  ///-------------------------------------------------------------------
  {
    path: '/login',
    name: 'login',
    meta: {
      useLayout: false,
    },
    props: (route) => ({
      action: route.query.action,
      email: route.query.email,
      token: route.query.token,
      success: route.query.success === 'true',
    }),
    component: () => import('../views/Login.vue')
  },
  ///-------------------------------------------------------------------
  /// SHARED
  ///-------------------------------------------------------------------
  {
    path: '/datamanagement',
    name: 'datamanagement',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/Datamanagement.vue')
  },
  ///-------------------------------------------------------------------
  /// ADMIN AREA
  ///-------------------------------------------------------------------
  {
    path: '/admin',
    name: 'admin.dashboard',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/dashboard/Dashboard.vue')
  },
  {
    path: '/admin/customer/overview',
    name: 'admin.customer.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/customer/CustomerOverview.vue')
  },
  {
    path: '/admin/customer/new',
    name: 'admin.customer.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/customer/CustomerEditor.vue')
  },
  {
    path: '/admin/customer/edit/:id',
    name: 'admin.customer.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/customer/CustomerEditor.vue')
  },
  {
    path: '/admin/contact/overview',
    name: 'admin.contact.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/contact/ContactOverview.vue')
  },
  {
    path: '/admin/contact/new',
    name: 'admin.contact.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/contact/ContactEditor.vue')
  },
  {
    path: '/admin/contact/edit/:id',
    name: 'admin.contact.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/contact/ContactEditor.vue')
  },
  {
    path: '/admin/download/overview',
    name: 'admin.download.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/download/DownloadOverview.vue')
  },
  {
    path: '/admin/download/folder/new',
    name: 'admin.download.folder.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/download/DownloadFolderEditor.vue')
  },
  {
    path: '/admin/download/folder/edit/:id',
    name: 'admin.download.folder.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/download/DownloadFolderEditor.vue')
  },
  {
    path: '/admin/download/file/new',
    name: 'admin.download.file.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/download/DownloadFileEditor.vue')
  },
  {
    path: '/admin/download/file/edit/:id',
    name: 'admin.download.file.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/download/DownloadFileEditor.vue')
  },
  {
    path: '/admin/operating-system/overview',
    name: 'admin.operating-system.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/operating-system/OperatingSystemOverview.vue')
  },
  {
    path: '/admin/operating-system/designation/new',
    name: 'admin.operating-system.designation.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/operating-system/OperatingSystemDesignationEditor.vue')
  },
  {
    path: '/admin/operating-system/designation/edit/:id',
    name: 'admin.operating-system.designation.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/operating-system/OperatingSystemDesignationEditor.vue')
  },
  {
    path: '/admin/operating-system/version/new',
    name: 'admin.operating-system.version.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/operating-system/OperatingSystemVersionEditor.vue')
  },
  {
    path: '/admin/operating-system/version/edit/:id',
    name: 'admin.operating-system.version.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/operating-system/OperatingSystemVersionEditor.vue')
  },
  {
    path: '/admin/user/overview',
    name: 'admin.user.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/user/UserOverview.vue')
  },
  {
    path: '/admin/user/new',
    name: 'admin.user.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/user/UserEditor.vue')
  },
  {
    path: '/admin/user/edit/:id',
    name: 'admin.user.edit',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/user/UserEditor.vue')
  },
  {
    path: '/admin/tools/mail-list-generator',
    name: 'admin.tools.mail-list-generator',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/mail-list-generator/MailListGenerator.vue')
  },
  {
    path: '/admin/tools/license-certificate-generator',
    name: 'admin.tools.license-certificate-generator',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/license-certificate-generator/LicenseCertificateGenerator.vue')
  },
  {
    path: '/admin/video/overview',
    name: 'admin.video.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/video/VideoOverview.vue')
  },
  {
    path: '/admin/video/category/new',
    name: 'admin.video.category.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/video/VideoCategoryEditor.vue')
  },
  {
    path: '/admin/video/category/edit/:id',
    name: 'admin.video.category.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/video/VideoCategoryEditor.vue')
  },
  {
    path: '/admin/video/recording/new',
    name: 'admin.video.recording.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/video/VideoRecordingEditor.vue')
  },
  {
    path: '/admin/video/recording/edit/:id',
    name: 'admin.video.recording.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/video/VideoRecordingEditor.vue')
  },
  {
    path: '/admin/video/webinar/new',
    name: 'admin.video.webinar.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/video/VideoWebinarEditor.vue')
  },
  {
    path: '/admin/video/webinar/edit/:id',
    name: 'admin.video.webinar.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/video/VideoWebinarEditor.vue')
  },
  {
    path: '/admin/version/overview',
    name: 'admin.version.overview',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/version/VersionOverview.vue')
  },
  {
    path: '/admin/version/product/new',
    name: 'admin.version.product.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/version/VersionProductEditor.vue')
  },
  {
    path: '/admin/version/product/edit/:id',
    name: 'admin.version.product.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/version/VersionProductEditor.vue')
  },
  {
    path: '/admin/version/component/new',
    name: 'admin.version.component.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/version/VersionComponentEditor.vue')
  },
  {
    path: '/admin/version/component/edit/:id',
    name: 'admin.version.component.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/version/VersionComponentEditor.vue')
  },
  {
    path: '/admin/version/number/new',
    name: 'admin.version.number.new',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/version/VersionNumberEditor.vue')
  },
  {
    path: '/admin/version/number/edit/:id',
    name: 'admin.version.number.edit',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/admin/version/VersionNumberEditor.vue')
  },
  {
    path: '/admin/settings',
    name: 'admin.settings',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/admin/settings/GlobalSettings.vue')
  },
  ///-------------------------------------------------------------------
  /// USER AREA
  ///-------------------------------------------------------------------
  {
    path: '/download',
    name: 'download.area',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/download/DownloadArea.vue')
  },
  {
    path: '/video',
    name: 'video.area',
    meta: {
      useLayout: true,
    },
    props: true,
    component: () => import('../views/user/video/VideoArea.vue')
  },
  {
    path: '/video/:slug',
    name: 'video.play',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/video/VideoPlay.vue')
  },
  {
    path: '/license',
    name: 'license',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/license/License.vue')
  },
  {
    path: '/license/extend',
    name: 'license.extend',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/license/LicenseExtend.vue')
  },
  {
    path: '/consultation',
    name: 'consultation',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/consultation/Consultation.vue')
  },
  {
    path: '/support',
    name: 'support',
    meta: {
      useLayout: true,
    },
    component: () => import('../views/user/support/Support.vue')
  },
  {
    path: '/changelog',
    name: 'changelog',
    meta: {
      useLayout: true,
    },
    props: (route) => ({
      version: route.query.version,
    }),
    component: () => import('../views/user/changelog/Changelog.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
