
import { ColorProp, cssColor, Modifier } from '@/utils/color';
import { computed, CSSProperties, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { children, Navigation, NavigationEntry } from './BpNavigation';

export default defineComponent({
  name: 'bp-navigation-entry',
  props: {
    color: {
      type: ColorProp,
      default: ['light-blue', 700],
    },
    depth: {
      type: Number,
      default: 0,
    },
    isActive: Function as PropType<(entry: NavigationEntry) => boolean>,
    navigation: {
      type: Array as PropType<Navigation>,
      default: () => [],
    },
  },
  emits: [
    'click-entry',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = computed(() => {
      return {
        '--navigation-entry-color': cssColor(props.color),
        '--navigation-entry-icon-color': cssColor(props.color, Modifier.TEXT_SECONDARY),
        '--navigation-entry-text-color': cssColor(props.color, Modifier.TEXT),
      } as CSSProperties;
    });

    const iconColor = computed(() => cssColor(props.color));

    ///-------------------------------------------------------------------
    /// ACTIVE
    ///-------------------------------------------------------------------

    function isEntryActive(entry: NavigationEntry) {
      if (props.isActive !== undefined) {
        return props.isActive(entry);
      }

      if (!router.currentRoute.value.name) {
        return false;
      }

      function *elements(el: NavigationEntry): Generator<NavigationEntry> {
        yield el;
        if (el.children) {
          for (const child of el.children) {
            yield *elements(child);
          }
        }
      }

      const routes = [];
      for (const element of elements(entry)) {
        routes.push((element.to && typeof element.to !== 'string' && 'name' in element.to) ? element.to.name : '');
      }
      return routes.includes(router.currentRoute.value.name);
    }

    ///-------------------------------------------------------------------
    /// ENTRIES
    ///-------------------------------------------------------------------

    const entries = computed<NavigationEntry[]>(() => props.navigation);

    ///-------------------------------------------------------------------
    /// SLOTS
    ///-------------------------------------------------------------------

    const slots = computed(() => Object.keys(ctx.slots).filter(slot => slot.startsWith('option-')));

    function slotName(entry: NavigationEntry) {
      return 'option-' + entry._id;
    }

    function inheritChildren(item: NavigationEntry) {
      return children(item).sort((a: NavigationEntry, b: NavigationEntry) => (((children(a)?.length ?? 0) <= 1 ? 0 : 1) < ((children(b)?.length ?? 0) <= 1 ? 0 : 1)) ? -1 : 1);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      children,
      entries,
      iconColor,
      inheritChildren,
      isEntryActive,
      slotName,
      slots,
      style,
    }
  }
});
