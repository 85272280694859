import { PopoverElement } from '../popover/BpPopoverMenu';

export type Navigation = NavigationEntry[];

export interface NavigationEntry extends PopoverElement {
  name?: string;
  hidden?: boolean;
  children?: NavigationEntry[];
}

///-------------------------------------------------------------------
/// CHILDREN
///-------------------------------------------------------------------

export function children(item: NavigationEntry) {
  if (item.children) {
    return item.children.filter((child: NavigationEntry) => !child.hidden)
  } else if (item._children) {
    return item._children.filter((child: NavigationEntry) => !child.hidden)
  }
  return [];
}