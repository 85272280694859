import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-table__toolbar"
}
const _hoisted_2 = { class: "bp-table__head" }
const _hoisted_3 = { class: "bp-table__row" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "bp-table__cell"
}
const _hoisted_6 = { class: "bp-table__option" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "bp-table__row"
}
const _hoisted_9 = {
  key: 0,
  class: "bp-table__cell bp-table__cell--square"
}
const _hoisted_10 = ["placeholder", "value", "onInput"]
const _hoisted_11 = { class: "bp-table__body" }
const _hoisted_12 = ["onClick", "draggable", "data-table-row-id"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["data-key"]
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_popover = _resolveComponent("bp-popover")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_virtual_scroller = _resolveComponent("bp-virtual-scroller")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$slots.prefix)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "bp-table__prefix",
          ref: "prefixEl",
          style: _normalizeStyle(_ctx.tableRowHeight)
        }, [
          _renderSlot(_ctx.$slots, "prefix", {
            selection: _ctx.selection,
            count: _ctx.count
          }, undefined, true)
        ], 4))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unwrap ? 'div' : 'bp-card'), _mergeProps({
      class: ['bp-table__wrapper', 'class' in _ctx.$attrs ? _ctx.$attrs.class : ''],
      "border-width": _ctx.unwrap ? undefined : _ctx.borderWidth,
      "fill-width": _ctx.unwrap ? undefined : true,
      "max-height": _ctx.unwrap ? undefined : _ctx.maxHeight,
      "max-width": _ctx.unwrap ? undefined : _ctx.maxWidth,
      "seamless-content": _ctx.unwrap ? undefined : true,
      translucent: _ctx.unwrap ? undefined : true,
      "data-uuid": _ctx.unique
    }, _ctx.unwrap ? {} : _ctx.$attrs), _createSlots({
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['bp-table', {
        'bp-table--has-data': _ctx.data.length > 0,
        'bp-table--has-scrollbar': _ctx.virtualScrollerHasScrollbar,
        'bp-table--is-firefox': _ctx.isFirefox(),
        'bp-table--is-safari': _ctx.isSafari(),
        'bp-table--max-height': !!_ctx.maxHeight,
        'bp-table--max-width': !!_ctx.maxWidth,
        'bp-table--selectable': _ctx.selectable,
        'bp-table--sortable': _ctx.sortable,
      }]),
          ref: "tableEl",
          style: _normalizeStyle(_ctx.style)
        }, [
          (_ctx.unwrap && _ctx.$slots.toolbar)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "toolbar", { selection: _ctx.selection }, undefined, true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.selectable)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "bp-table__cell bp-table__cell--square bp-table__cell--pointer",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAll && _ctx.toggleAll(...args)))
                  }, [
                    ((_ctx.selection.size + _ctx.data.filter(item => _ctx.disabledRows?.(item)).length) === _ctx.count || _ctx.selection.size === 0)
                      ? (_openBlock(), _createBlock(_component_bp_icon, {
                          key: 0,
                          icon: _ctx.selection.size === 0 ? ['far', 'square'] : 'square-check'
                        }, null, 8, ["icon"]))
                      : (_openBlock(), _createBlock(_component_bp_icon, {
                          key: 1,
                          icon: [
              { icon: ['fad', 'square-minus'] },
              { icon: ['far', 'square'] },
            ]
                        }))
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: (column._id + '_' + _ctx.actionsWidth),
                  class: _normalizeClass(['bp-table__cell', 'bp-table__cell--flex', {
              'bp-table__cell--new-group': columnIndex > 0 && column._group !== _ctx.columns[columnIndex - 1]._group
            }])
                }, [
                  _createElementVNode("span", {
                    class: "bp-table__cell-content",
                    innerHTML: column.name
                  }, null, 8, _hoisted_4),
                  (_ctx.sortable && (!column.notSortable))
                    ? (_openBlock(), _createBlock(_component_bp_icon, {
                        key: 0,
                        class: "bp-table__icon",
                        icon: {
                icon: ['fad', 'sort'],
                primaryOpacity: !_ctx.sorting.get(column._id) ? 0.4 : 1,
                swapOpacity: _ctx.sorting.get(column._id) === 'desc'
              },
                        onClick: ($event: any) => (_ctx.sortColumn(column._id)),
                        onMousedown: _ctx.preventSelection
                      }, null, 8, ["icon", "onClick", "onMousedown"]))
                    : _createCommentVNode("", true)
                ], 2)), [
                  [_directive_tooltip, _ctx.tooltip(column.name)]
                ])
              }), 128)),
              (_ctx.hasActionsColumn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.headers.length > 1)
                      ? (_openBlock(), _createBlock(_component_bp_popover, {
                          key: 0,
                          "model-value": _ctx.menu,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.menu = $event)),
                          data: _ctx.hideableColumns,
                          onClickOption: _ctx.toggleColumn
                        }, {
                          default: _withCtx(() => [
                            (_ctx.columnsHideable)
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  class: "bp-table__icon",
                                  icon: "gear",
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menu = !_ctx.menu))
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          option: _withCtx(({ option }) => [
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_bp_input, {
                                type: "checkbox",
                                "model-value": !_ctx.hiddenColumns.has(option._id),
                                "onUpdate:modelValue": ($event: any) => (_ctx.toggleColumn(option)),
                                seamless: ""
                              }, null, 8, ["model-value", "onUpdate:modelValue"]),
                              _createElementVNode("span", null, [
                                _createElementVNode("span", {
                                  innerHTML: option.name
                                }, null, 8, _hoisted_7),
                                _cache[11] || (_cache[11] = _createTextVNode(" ")),
                                (_ctx.searching.has(option._id))
                                  ? (_openBlock(), _createBlock(_component_translate, {
                                      key: 0,
                                      tag: "em"
                                    }, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode("(currently searched)")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["model-value", "data", "onClickOption"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.searchable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_ctx.selectable)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: column._id,
                      class: _normalizeClass(['bp-table__cell', 'bp-table__cell--search', {
              'bp-table__cell--searching': _ctx.searching.has(column._id),
              'bp-table__cell--new-group': columnIndex > 0 && column._group !== _ctx.columns[columnIndex - 1]._group
            }])
                    }, [
                      (column.filter)
                        ? (_openBlock(), _createBlock(_component_bp_select, {
                            data: column.filter,
                            key: _ctx.currentLanguage,
                            "model-value": _ctx.searching.get(column._id),
                            placeholder: _ctx.$gettext('Filter …'),
                            "onUpdate:modelValue": ($event: any) => (_ctx.filterColumn(column._id, $event)),
                            clearable: "",
                            multiple: "",
                            "search-property": "name",
                            "sort-property": "_id",
                            unwrap: ""
                          }, null, 8, ["data", "model-value", "placeholder", "onUpdate:modelValue"]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("input", {
                              class: "bp-table__input",
                              placeholder: _ctx.$gettext('Search …'),
                              value: _ctx.unescapeRegExp(_ctx.searchValues.get(column._id) || ''),
                              onInput: ($event: any) => (_ctx.searchColumn(column._id, $event))
                            }, null, 40, _hoisted_10),
                            (_ctx.searching.has(column._id))
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  class: "bp-table__icon",
                                  icon: "xmark",
                                  onClick: ($event: any) => (_ctx.clearSearchColumn(column._id))
                                }, null, 8, ["onClick"]))
                              : (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 1,
                                  class: "bp-table__icon cursor-default",
                                  icon: "magnifying-glass"
                                }))
                          ], 64))
                    ], 2))
                  }), 128)),
                  (_ctx.hasActionsColumn)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "bp-table__cell",
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearSearch && _ctx.clearSearch(...args)))
                      }, [
                        (_ctx.searching.size > 0)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              class: "bp-table__icon",
                              icon: "xmark"
                            }))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_bp_loading, {
              "model-value": _ctx.loading || _ctx.searchLoading
            }, null, 8, ["model-value"]),
            (_ctx.tableData.length > 0)
              ? (_openBlock(), _createBlock(_component_bp_virtual_scroller, {
                  data: _ctx.tableData,
                  expanded: _ctx.expandedRef,
                  key: JSON.stringify(_ctx.tableData),
                  "row-height": _ctx.compactMode ? _ctx.rowHeight - 16 : _ctx.rowHeight,
                  onDomUpdate: _ctx.calculateActionsWidth,
                  class: "bp-table__virtual-scroller",
                  ref: "virtualScrollerEl"
                }, {
                  default: _withCtx(({ item, index: rowIndex }) => [
                    (item._subheading)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(['bp-table__subheading', {
                'bp-table__subheading--first': rowIndex === 0,
                'bp-table__subheading--even': rowIndex % 2 === 0,
                'bp-table__subheading--odd': rowIndex % 2 === 1,
              }])
                        }, _toDisplayString(item.name), 3))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(['bp-table__row', {
                'bp-table__row--disabled': _ctx.disabledRows?.(item),
                'bp-table__row--even': rowIndex % 2 === 0,
                'bp-table__row--expanded': _ctx.expandedRef.has(item._id),
                'bp-table__row--odd': rowIndex % 2 === 1,
                'bp-table__row--selected': _ctx.selection.has(item._id),
              }, _ctx.rowClass?.(item)]),
                          onClick: ($event: any) => (_ctx.selectable ? _ctx.toggle(item._id) : undefined),
                          onDragenter: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.dragenter && _ctx.dragenter(...args))),
                          onDragover: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
                          onDragstart: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.dragstart && _ctx.dragstart(...args))),
                          onDragend: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.dragend && _ctx.dragend(...args))),
                          onDrop: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args))),
                          draggable: _ctx.draggableRows ? 'true' : undefined,
                          "data-table-row-id": item._id
                        }, [
                          (_ctx.selectable)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(['bp-table__cell', 'bp-table__cell--square', 'bp-table__cell--pointer', _ctx.cellClass?.(item)]),
                                onClick: _withModifiers(($event: any) => (_ctx.toggle(item._id, 'multi')), ["stop"])
                              }, [
                                _createVNode(_component_bp_icon, {
                                  icon: _ctx.selection.has(item._id) ? 'square-check' : ['far', 'square']
                                }, null, 8, ["icon"])
                              ], 10, _hoisted_13))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                            return _withDirectives((_openBlock(), _createElementBlock("div", {
                              class: _normalizeClass(['bp-table__cell', 'bp-table__cell--flex', {
                  'bp-table__cell--new-group': columnIndex > 0 && column._group !== _ctx.columns[columnIndex - 1]._group
                }, _ctx.cellClass?.(item)]),
                              key: `${item._id}_${column._id}_${_ctx.columns.length}_${_ctx.innerWidth}_${_ctx.actionsWidth}`,
                              "data-key": `${item._id}_${column._id}_${_ctx.columns.length}_${_ctx.innerWidth}_${_ctx.actionsWidth}`,
                              style: _normalizeStyle(columnIndex === 0 ? { '--table-depth': item._depth ? (item._children ? item._depth : item._depth + 1) : undefined } : {})
                            }, [
                              (columnIndex === 0 && '_children' in item)
                                ? (_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 0,
                                    class: "bp-table__cell-expand-icon",
                                    icon: "chevron-right",
                                    onClick: _withModifiers(($event: any) => (_ctx.toggleExpanded(item._id)), ["stop"])
                                  }, null, 8, ["onClick"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                class: _normalizeClass(['bp-table__cell-content', {
                  'bp-table__cell-content--monospace': column.monospace,
                  'bp-table__cell-content--text-align-center': typeof _ctx.value(item, column) === 'boolean' || column.textAlign === 'center',
                  ['bp-table__cell-content--text-align-' + column.textAlign]: column.textAlign,
                }])
                              }, [
                                _renderSlot(_ctx.$slots, 'cell-' + column._id, {
                                  item: item,
                                  value: _ctx.value(item, column)
                                }, () => [
                                  _renderSlot(_ctx.$slots, "cell", {
                                    item: item,
                                    value: _ctx.value(item, column)
                                  }, () => [
                                    (item['_icon_' + column._id])
                                      ? (_openBlock(), _createBlock(_component_bp_icon, {
                                          key: 0,
                                          class: "bp-table__cell-icon",
                                          icon: item['_icon_' + column._id]
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    (typeof _ctx.value(item, column) === 'boolean')
                                      ? (_openBlock(), _createBlock(_component_bp_icon, {
                                          key: 1,
                                          class: "bp-table__cell-icon",
                                          icon: _ctx.value(item, column) ? {
                        icon: 'check',
                        color: ['green', 600],
                      } : {
                        icon: 'xmark',
                        color: ['red', 600],
                      }
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    (!(typeof _ctx.value(item, column) === 'boolean'))
                                      ? (_openBlock(), _createElementBlock("span", {
                                          key: 2,
                                          class: "bp-table__cell-value",
                                          innerHTML: _ctx.value(item, column)
                                        }, null, 8, _hoisted_15))
                                      : _createCommentVNode("", true)
                                  ], true)
                                ], true)
                              ], 2),
                              (column.canCopy && _ctx.value(item, column))
                                ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 1,
                                    class: "bp-table__icon",
                                    icon: ['far', 'copy'],
                                    onClick: ($event: any) => (_ctx.copyToClipboard(_ctx.value(item, column)))
                                  }, null, 8, ["onClick"])), [
                                    [_directive_tooltip, { offset: [_ctx.compactMode ? -4 : -12, 0], text: _ctx.$gettext('Copy to clipboard') }]
                                  ])
                                : _createCommentVNode("", true)
                            ], 14, _hoisted_14)), [
                              [_directive_tooltip, _ctx.tooltip(item, column)]
                            ])
                          }), 128)),
                          (_ctx.hasActionsColumn)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(['bp-table__cell', 'bp-table__actions', _ctx.cellClass?.(item)]),
                                "data-actions": "",
                                onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                              }, [
                                _renderSlot(_ctx.$slots, "actions", {
                                  item: item,
                                  key: '' + _ctx.compactMode
                                }, undefined, true)
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 42, _hoisted_12))
                  ]),
                  _: 3
                }, 8, ["data", "expanded", "row-height", "onDomUpdate"]))
              : (_openBlock(), _createBlock(_component_translate, {
                  key: 1,
                  class: "bp-table__no-entries",
                  tag: "em"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("No entries found")
                  ])),
                  _: 1
                }))
          ])
        ], 6)
      ]),
      _: 2
    }, [
      (_ctx.$slots.toolbar)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "toolbar", { selection: _ctx.selection }, undefined, true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["class", "border-width", "fill-width", "max-height", "max-width", "seamless-content", "translucent", "data-uuid"]))
  ], 64))
}