
import { defineComponent } from 'vue';

/**
 * A button group component.
 */
export default defineComponent({
  name: 'bp-button-group',
  setup() {
    return {};
  },
});
