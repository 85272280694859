import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bp-layout__wrapper" }
const _hoisted_2 = { class: "flex-column gap-md" }
const _hoisted_3 = { class: "flex-column gap-md" }
const _hoisted_4 = {
  class: "bp-main bp-main--layout",
  id: "bp-main",
  ref: "mainEl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_download_preview = _resolveComponent("bp-download-preview")!
  const _component_bp_video_preview = _resolveComponent("bp-video-preview")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_header = _resolveComponent("bp-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bp_footer = _resolveComponent("bp-footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bp-layout",
    id: "bp-layout",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_bp_header, {
        key: [_ctx.showDownloadHeader, _ctx.showVideoHeader].join(),
        "main-nav": _ctx.mainNav,
        "side-nav": _ctx.sideNav,
        id: "bp-header",
        ref: "headerEl",
        "side-nav-icon": "circle-user"
      }, _createSlots({
        "side-nav-header": _withCtx(() => [
          _createElementVNode("strong", null, _toDisplayString(_ctx.user?.email), 1)
        ]),
        "side-nav-option-dark_mode": _withCtx(({ option }) => [
          _createVNode(_component_bp_icon, {
            icon: option.icon,
            style: {"color":"var(--theme-text-disabled)"}
          }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(option.name), 1),
          _createVNode(_component_bp_input, {
            type: "toggle",
            "model-value": _ctx.darkMode,
            style: {"margin-left":"auto","pointer-events":"none"}
          }, null, 8, ["model-value"])
        ]),
        "side-nav-option-compact_mode": _withCtx(({ option }) => [
          _createVNode(_component_bp_icon, {
            icon: option.icon,
            style: {"color":"var(--theme-text-disabled)"}
          }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(option.name), 1),
          _createVNode(_component_bp_input, {
            type: "toggle",
            "model-value": _ctx.compactMode,
            style: {"margin-left":"auto","pointer-events":"none"}
          }, null, 8, ["model-value"])
        ]),
        "side-nav-footer": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(['bp-layout__button-wrapper', { 'bp-layout__button-wrapper--desktop': _ctx.isDesktop }])
          }, [
            (_ctx.user?.roles.includes('impersonate_user'))
              ? (_openBlock(), _createBlock(_component_bp_button, {
                  key: 0,
                  style: {"width":"100%"},
                  color: ['blue', 500],
                  icon: ['far', 'user-tie'],
                  onClick: _ctx.backToAdmin
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Back to admin")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_bp_button, {
              color: "red",
              icon: "right-from-bracket",
              style: {"width":"100%"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout(_ctx.user)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Log out")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 2
      }, [
        (_ctx.showDownloadHeader)
          ? {
              name: "main-nav-header-download",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.downloadStore.getFiles('featured'), (file) => {
                    return (_openBlock(), _createBlock(_component_bp_download_preview, {
                      key: file._id,
                      "model-value": file,
                      "hide-changelog": ""
                    }, null, 8, ["model-value"]))
                  }), 128))
                ])
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.showVideoHeader)
          ? {
              name: "main-nav-header-video",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoStore.getVideos({ filter: ['featured-webinar'] }), (video) => {
                    return (_openBlock(), _createBlock(_component_bp_video_preview, {
                      key: video._id,
                      "model-value": video,
                      compact: "",
                      "hide-description": ""
                    }, null, 8, ["model-value"]))
                  }), 128))
                ])
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["main-nav", "side-nav"])),
      _createElementVNode("main", _hoisted_4, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, { include: /overview|dashboard/ }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 1
        })
      ], 512)
    ]),
    _createVNode(_component_bp_footer, { id: "bp-footer" })
  ], 4))
}