import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "bp-timeline",
  ref: "timelineEl"
}
const _hoisted_2 = ["data-timeline-entry-id"]
const _hoisted_3 = {
  key: 0,
  class: "bp-timeline__entry-date"
}
const _hoisted_4 = {
  key: 1,
  class: "bp-timeline__entry-name"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 4,
  class: "bp-timeline__entry-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timelineEntries, (entry, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "bp-timeline__entry",
        key: entry._id,
        "data-timeline-entry-id": entry._id,
        style: _normalizeStyle(_ctx.style(index))
      }, [
        (entry.date)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_component_bp_icon, {
                class: "mr-sm",
                icon: ['far', 'calendar']
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.localizeDate(entry.date)), 1)
            ]))
          : _createCommentVNode("", true),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bp-timeline__entry-dot" }, null, -1)),
        (entry.name)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getTranslated(entry.name)), 1))
          : _createCommentVNode("", true),
        (entry.link)
          ? (_openBlock(), _createBlock(_component_bp_icon, {
              key: 2,
              class: "bp-timeline__entry-link",
              icon: "hashtag",
              onClick: ($event: any) => (_ctx.copyToClipboard(entry.link))
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bp-timeline__entry-line" }, null, -1)),
        (entry.text && _ctx.removeHTML(_ctx.getTranslated(entry.text)).length > 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: "bp-timeline__entry-text",
              innerHTML: _ctx.getTranslated(entry.text)
            }, null, 8, _hoisted_5))
          : (_openBlock(), _createElementBlock("em", _hoisted_6, _toDisplayString(_ctx.emptyText), 1))
      ], 12, _hoisted_2))
    }), 128))
  ], 512))
}