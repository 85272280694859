import { Contact } from '@/stores/contact';
import { inject, ref, Ref } from 'vue'
import { RequiredKeys } from './object';
import { User } from '@/stores/user';
import { useSalutationStore } from '@/stores/salutation';

export interface CheckSession {
  status: 'logged_in' | 'forbidden',
  user: SessionUser
}

export interface SessionUser {
  _id: string,
  email: string,
  company: string,
  roles: ('admin' | 'impersonate_user' | 'user' | 'anonymous')[]
}

export type AnonymousUser = Omit<SessionUser, 'email' | 'company'> & { username: string };

export const defaultSessionUser: Ref<SessionUser> = ref({
  _id: '',
  email: '',
  company: '',
  roles: [],
})

export function useUser() {
  const user = inject<Ref<SessionUser>>('user');
  return user && !!user.value ? user : defaultSessionUser
}

export function getFullSalutation(person: RequiredKeys<Contact | User, 'salutation' | 'forename' | 'surname'>) {
  const salutationStore = useSalutationStore();
  const salutation = salutationStore.getSalutationOf(person.salutation);
  return [
    salutation,
    person.forename,
    person.surname
  ].filter(el => !!el).join(' ');
}