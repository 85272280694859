import { byKey, Order } from '@/utils/array';
import { cordawareIcon } from '@/components/icon/BpIcon';
import { defaultTranslated, getTranslated, Translated } from '@/translation';
import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import { removeHTML } from '@/utils/string';
import clone from '@sahnee/clone';
import deepMerge, { flatten, KeyOf, Tree, tree, subtree, Flat, RequiredKeys, path, OptionalKeys } from '@/utils/object';

export const BESTINFORMED_VERSIONS = ['5', '6', '6.5'] as const;
export type BestinformedVersion = typeof BESTINFORMED_VERSIONS[number] | '';

interface State {
  versions: Version[],
  loading: number;
  loaded: boolean;
}

interface GetParentsOpts {
  docTypes: ('version_product' | 'version_component' | 'version_number')[],
  excludeIds: string | string[],
  includeRoot: boolean,
}

const defaultOpts: GetParentsOpts = {
  docTypes: ['version_product', 'version_component'],
  excludeIds: [],
  includeRoot: false,
}

/**
 * A base.
 */
interface Base {
  _id: string;
  _rev: string;
  parent_id: string;
}

interface ProductOrComponentBase extends Base {
  icon: string;
  meta: Record<string, Translated<string>>;
  meta_keys: string[];
  name: Translated<string>;
  order: number;
  uuid: string;
}

export type Version = Product | Component | VersionNumber;

export interface Product extends ProductOrComponentBase {
  doc_type: 'version_product';
  registered: boolean;
}

export interface Component extends ProductOrComponentBase {
  doc_type: 'version_component';
}

export interface VersionNumber extends Base {
  doc_type: 'version_number';
  changelog: Translated<string>;
  date: string;
  meta: Record<string, string>;
  version: string;
}

type Type = 'product' | 'component' | 'number';

export const defaultProduct: Omit<Product, '_id' | '_rev'> = {
  doc_type: 'version_product',
  icon: '',
  meta: {},
  meta_keys: [],
  name: defaultTranslated(),
  order: 0,
  parent_id: 'root',
  registered: false,
  uuid: '',
}

export const defaultComponent: Omit<Component, '_id' | '_rev'> = {
  ...defaultProduct,
  doc_type: 'version_component',
  parent_id: '',
}

export const defaultVersionNumber: Omit<VersionNumber, '_id' | '_rev'> = {
  doc_type: 'version_number',
  changelog: defaultTranslated(),
  date: '',
  meta: {},
  parent_id: '',
  version: '',
}

function prepare(version: Partial<Version>) {
  let fullVersion: Partial<Version> = {};
  let defaultKeys: string[] = [];
  // Webinar
  if (version.doc_type === 'version_product') {
    fullVersion = deepMerge(defaultProduct, version as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultProduct)];
  }
  // Recording
  else if (version.doc_type === 'version_component') {
    fullVersion = deepMerge(defaultComponent, version as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultComponent)];
  }
  // Category
  else {
    fullVersion = deepMerge(defaultVersionNumber, version as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultVersionNumber)];
  }
  const additionalKeys = Object.keys(fullVersion).filter(key => !defaultKeys.includes(key)) as (keyof Version)[];
  for (const key of additionalKeys) {
    delete fullVersion[key];
  }
  return fullVersion;
}

/**
 * The version store.
 */
export const useVersionStore = () => {
  // Define a local store reference
  const useStore = defineStore('version', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        versions: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for versions by their ID.
       * @param ids The ID or an array of IDs of the versions.
       * @returns An array of versions or a single version from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const versions = state.versions.filter(version => ids.includes(version._id));
          return ids.length === 1 ? versions[0] : versions
        }) as ((ids: string | [string]) => Version) & ((ids: string[]) => Version[])
      },
      getByUUIDPath() {
        return (path: string) => {
          return this.versions.find(version => {
            const versionPath = [...this.getUUIDPathById(version._id), version.doc_type !== 'version_number' ? version.uuid : version.version].join('_');
            return versionPath === path;
          })
        }
      },
      getName() {
        return (version?: OptionalKeys<Version, '_id' | '_rev'>) => {
          if (!version) { return '' }
          let name = '';
          switch (version.doc_type) {
            case 'version_product': name = `${getTranslated(version.name)}${version.registered ? '<sup>®</sup>' : ''}`; break;
            case 'version_component': name = getTranslated(version.name); break;
            case 'version_number': name = version.version; break;
            default: name = ''; break;
          }
          return removeHTML(name, { ALLOWED_TAGS: ['sup'] })
        }
      },
      getNameById() {
        return (id: string) => {
          return this.getName(this.getById(id));
        }
      },
      getVersion() {
        return this.getName
      },
      /**
       * Get the version number
       */
      getVersionById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const versionNumbers = state.versions
            .filter(version => ids.includes(version._id) && version.doc_type === 'version_number')
            .map(version => (version as VersionNumber).version);
          return ids.length === 1 ? versionNumbers[0] : versionNumbers
        }) as ((ids: string | [string]) => string) & ((ids: string[]) => string[])
      },
      getIcon() {
        return (productOrComponent: Version | string | undefined) => {
          if (typeof productOrComponent === 'string') {
            productOrComponent = this.getByUUIDPath(productOrComponent);
          }
          if (!productOrComponent) {
            return cordawareIcon();
          }
          if (productOrComponent.doc_type === 'version_number') {
            return ['far', 'v'];
          }
          return (productOrComponent.icon && productOrComponent.icon !== 'default') ? productOrComponent.icon : (productOrComponent.doc_type === 'version_product' ? cordawareIcon() : ['far', 'gear']);
        }
      },
      isProductOrComponent: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const versions = state.versions.filter(version => ids.includes(version._id));
          return ids.length === 1 ? ['version_product', 'version_component'].includes(versions[0].doc_type) : versions.map(version => ['version_product', 'version_component'].includes(version.doc_type))
        }) as ((ids: string | [string]) => boolean) & ((ids: string[]) => boolean[])
      },
      /**
       * Search for the path to a given version by their ID.
       * @param ids The ID or an array of IDs of versions.
       * @returns The path, as an ordered array of the versions, or an array of paths.
       */
      getPathById: (state: State) => {
        return ((ids: string | string[]) => path(state.versions, ids)) as ((ids: string | [string]) => (Product | Component)[]) & ((ids: string[]) => (Product | Component)[][]);
      },
      getUUIDPathById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const paths = path(state.versions, ids) as ((Product | Component)[] | (Product | Component)[][]);
          if (ids.length === 1) {
            return Array.from(new Set((paths as (Product | Component)[]).map(productOrComponent => productOrComponent.uuid)))
          }
          return (paths as (Product | Component)[][]).map(path => Array.from(new Set(path.map(productOrComponent => productOrComponent.uuid))))
        }) as ((ids: string | [string]) => string[]) & ((ids: string[]) => string[][]);
      },
      /** */
      getMetaById() {
        return (id: string) => {
          const productOrComponent = this.getById(id) as Product | Component;
          const path = this.getPathById(id);
          const list = productOrComponent ? [...path, productOrComponent] : path;
          const meta = [];
          for (let i = list.length - 1; i >= 0; i--) {
            meta.push(...list[i].meta_keys.map(key => [key, list[i].meta[key]]));
          }
          return Object.fromEntries(meta) as Record<string, Translated<string>>;
        }
      },
      getTranslatedMetaKey: (state: State) => {
        return (key: string) => {
          const meta: Record<string, Translated<string>> = (state.versions
            .filter((version) => version.doc_type !== 'version_number') as (Product | Component)[])
            .reduce((acc, version) => ({ ...acc, ...version.meta }), {});
          return meta[key] ? getTranslated(meta[key]) : '';
        }
      },
      /**
       * Search for products within the versions.
       * @returns An array of products from the store.
       */
      getProducts: (state: State) => {
        return () => state.versions.filter(product => product.doc_type === 'version_product') as Product[];
      },
      /**
       * Search for all root products within the versions.
       * @returns An array of products from the store.
       */
      getRootProducts: (state: State) => {
        return () => state.versions.filter(product => product.doc_type === 'version_product' && product.parent_id === 'root') as Product[];
      },
      /**
       * Search for components within the versions.
       * @returns An array of components from the store.
       */
      getComponents: (state: State) => {
        return () => state.versions.filter(component => component.doc_type === 'version_component') as Component[];
      },
      /**
       * Search for version numbers within the versions.
       * @returns An array of version numbers from the store.
       */
      getVersionNumbers: (state: State) => {
        return () => state.versions.filter(component => component.doc_type === 'version_number') as VersionNumber[];
      },
      /**
       * Builds a tree hierarchy of all products, components & versions by connecting them via their `parent_id`.
       * @returns The tree hierarchy.
       */
      getTree() {
        // return () => tree(state.versions, 'parent_id');
        return (
          key: KeyOf<Version> | ((item: Version) => KeyOf<Version>) = 'date' as KeyOf<Version>,
          order: Order = 'desc'
        ) => {
        return [
          // All other versions will be displayed normally in a tree structure
            ...tree(clone(this.versions).sort(byKey(key, order)), 'parent_id')
          ];
        };
      },
      getFlatTree() {
        return () => flatten(this.getTree());
      },
      getSubtree() {
        const tree = this.getTree();
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const versions: Tree<Version>[] = [];
          for (const id of ids) {
            const found = subtree(tree, id) as Tree<Version>;
            if (found) {
              versions.push(found);
            }
          }
          return ids.length === 1 ? versions[0] : versions;
        }) as ((ids: string | [string]) => Tree<Version>) & ((ids: string[]) => Tree<Version>[])
      },
      getFlatSubtree() {
        return (ids: string | string[]) => {
          const versions: Flat<Tree<Version>>[] = [];
          for (const version of flatten(Array.isArray(ids) ? this.getSubtree(ids as string[]) : [this.getSubtree(ids as string)])) {
            if (versions.map(el => el._id).includes(version._id)) {
              continue;
            }
            versions.push(version);
          }
          return versions;
        };
      },
      /**
       * Search for categorys that are valid as the new parent of a given version.
       * @param id The ID of the version for which a valid parent category is beeing searched.
       * @returns An array of valid categorys as the new parent.
       */
      getValidParentProductsOrComponents() {
        return (id: string) => {
          const invalidIds = this.getFlatSubtree(id).map(el => el._id);
          return [
            ...this.getProducts(),
            ...this.getComponents()
          ].filter(productOrComponent => !invalidIds.includes(productOrComponent._id))
        };
      },
      getParents() {
        return (opts: Partial<GetParentsOpts> = {}) => {
          const actualOpts = { ...defaultOpts, ...opts };
          // Ensure we have a list of excluded IDs
          if (actualOpts.excludeIds === undefined) {
            actualOpts.excludeIds = [];
          } else if (typeof actualOpts.excludeIds === 'string') {
            actualOpts.excludeIds = [actualOpts.excludeIds];
          }
          // If we have excluded IDs also exclude any ID which is in the entire sub tree of that version
          actualOpts.excludeIds = actualOpts.excludeIds.filter(id => !!id).flatMap(id => this.getFlatSubtree(id).map(version => version._id));
          const root = actualOpts.includeRoot ? [{
            _id: 'root',
            name: {
              de: `Kein(e) Über${actualOpts.docTypes.map(docType => {
                switch (docType) {
                  case 'version_product': return 'produkt';
                  case 'version_component': return 'komponente';
                  case 'version_number': return 'versionsnummer';
                }
              }).join(' / -')}`,
              en: `No parent ${actualOpts.docTypes.map(docType => {
                switch (docType) {
                  case 'version_product': return 'product';
                  case 'version_component': return 'component';
                  case 'version_number': return 'version number';
                }
              }).join(' / ')}`,
            },
            doc_type: actualOpts.docTypes[0],
          } as Version] : [];
          const versions = this.versions.filter(version => {
            return actualOpts.docTypes.includes(version.doc_type) && (actualOpts.excludeIds.length > 0
              ? !actualOpts.excludeIds.includes(version._id) && !actualOpts.excludeIds.includes(version.parent_id)
              : true);
          });
          return [
            ...root,
            ...versions
          ]
        }
      },
      /**
       *
       */
      getProductsOrComponentsWithRoot: (state: State) => {
        return (id?: string) => {
          if (!id) {
            id = '';
          }
          return [
            {
              _id: 'root',
              name: {
                de: 'Kein Überordner',
                en: 'No parent category'
              },
            },
            ...state.versions
              .filter(productOrComponent => id !== ''
                ? ((productOrComponent.doc_type === 'version_product' || productOrComponent.doc_type === 'version_component') && productOrComponent.parent_id !== id && productOrComponent._id !== id)
                : productOrComponent.doc_type === 'version_product' || productOrComponent.doc_type === 'version_component')
          ] as RequiredKeys<Partial<Product | Component>, '_id' | 'name'>[]
        };
      },
      findVersionByName: (state: State) => {
        return (name: string, versions?: Version[]) => {
          if (!versions) {
            versions = state.versions;
          }
          const regex = new RegExp(name, 'i');
          return versions.find(version => version.doc_type !== 'version_number'
            ? Object.values((version as Product | Component).name).map(name => regex.test(name)).includes(true)
            : regex.test((version as VersionNumber).version));
        }
      },
      hasChildren() {
        return ((ids: string | string[], type?: Type) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const children: boolean[] = [];
          for (const id of ids) {
            switch (type) {
              case 'product': {
                children.push(!!this.versions.filter(el => el.doc_type === 'version_product').find(el => el.parent_id === id));
                break;
              }
              case 'component': {
                children.push(!!this.versions.filter(el => el.doc_type === 'version_component').find(el => el.parent_id === id));
                break;
              }
              case 'number': {
                children.push(!!this.versions.filter(el => el.doc_type === 'version_number').find(el => el.parent_id === id));
                break;
              }
              default: {
                children.push(!!this.versions.find(el => el.parent_id === id));
                break;
              }
            }
          }
          return ids.length === 1 ? children[0] : children;
        }) as ((ids: string | [string], type?: Type) => boolean) & ((ids: string[], type?: Type) => boolean[])
      },
      /**
       * Checks if a given version is already present in the same component.
       * @param version The version.
       * @returns Whether the given email already exists in another contact or not.
       */
      versionExists: (state: State) => {
        return (version: OptionalKeys<VersionNumber, '_id' | '_rev'>, excludedIds: string | string[] = []) => {
          if (excludedIds === undefined) {
            excludedIds = []
          } else if (!Array.isArray(excludedIds)) {
            excludedIds = [excludedIds];
          }
          const versions = state.versions
            .filter(v => v.parent_id === version.parent_id && !excludedIds.includes(v._id) && v.doc_type === 'version_number')
            .map(v => (v as VersionNumber).version.trim())
          return versions.includes(version.version.trim());
        }
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------

    actions: {
        /**
         * Initially loads the data by calling the erlang haneler.
         * If the store has already been loaded, no more requests will be sent.
         */
        async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
         * Reload the store completely.
         * Calls the erlang haneler and replaces the current state with the newly loaded one.
         */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const versions = await json<Version[]>('/api/store/versions', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          if (versions.length > 0) {
            this.versions = versions;
            this.loaded = true;
          }
        } finally {
          this.loading--;
        }
      },
      /**
         * Creates a new version.
         * The version will automatically be saved in the database and added to the store.
         * @param version The version.
         */
      async create(version: Partial<Version>) {
        this.loading++;
        try {
          const created = await json<Version>('/api/store/versions', {
            method: 'POST',
            json: {
              action: 'create',
              version: prepare(version)
            }
          });
          if (created) {
            this.versions.push(created);
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
         * Updates an exisiting version.
         * The version will automatically be saved in the database and updated in the store.
         * @param version The version.
         */
      async update(version: Partial<Version>) {
        this.loading++;
        try {
          const updated = await json<Version>('/api/store/versions', {
            method: 'POST',
            json: {
              action: 'update',
              version: prepare(version)
            }
          });
          if (updated) {
            const index = this.versions.findIndex((version: Version) => version._id === updated._id);
            this.versions[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
         * Delete an exisiting version.
         * The version will automatically be deleted from the database and the store.
         * @param ids The ID or an array of IDs of versions to delete.
         */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
        this.loading++;
        try {
          const deletedDocs = await json<Version[]>('/api/store/versions', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deleted of deletedDocs) {
            const index = this.versions.findIndex((version: Version) => version._id === deleted._id);
            this.versions.splice(index, 1);
          }
          return {
            success: true,
            data: deletedDocs,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
         * Updates an exisiting version.
         * The version will automatically be saved in the database and updated in the store.
         * @param version The version.
         */
      async move(id: string, parent: string) {
        this.loading++;
        try {
          const moved = await json<Version>('/api/store/versions', {
            method: 'POST',
            json: {
              action: 'move',
              id,
              parent,
            }
          });
          if (moved) {
            const index = this.versions.findIndex((version: Version) => version._id === moved._id);
            this.versions[index] = moved;
            return {
              success: true,
              data: moved,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  store.load();

  // Return the store reference
  return store;
};
