
import { actionTooltip, Header } from './BpTable';
import { byKey, Order } from '@/utils/array';
import { computed, CSSProperties, defineComponent, nextTick, onMounted, PropType, ref, watch, watchEffect } from 'vue';
import { copyToClipboard } from '@/utils/navigator';
import { currentLanguage } from '@/translation';
import { deepValue, flatten, isTree, KeyOf } from '@/utils/object';
import { escapeRegExp, removeHTML, unescapeRegExp, uuid } from '@/utils/string';
import { isFirefox, isSafari } from '@/utils/user-agent';
import { loadComponentCache, saveComponentCache } from '@/utils/component';
import { Tooltip } from '@/utils/tooltip';
import { useDebounceFn } from '@vueuse/core'
import BpVirtualScrollerVue from '../virtual-scroller/BpVirtualScroller.vue';
import clone from '@sahnee/clone';
import type { BaseDoc } from '../virtual-scroller/BpVirtualScroller';
import useCompactMode from '@/compositions/use-compact-mode';
import useResize from '@/utils/resize-observer';

/**
 * A virtual scroller component.
 * https://dev.to/adamklein/build-your-own-virtual-scroll-part-i-11ib
 */
export default defineComponent({
  name: 'bp-table',
  props: {
    data: {
      type: Array as PropType<BaseDoc[]>,
      required: true,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClass: Function as PropType<((item: any) => string | undefined)>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cellClass: Function as PropType<((item: any) => string | undefined)>,
    borderWidth: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
    },
    columnMinWidth: {
      type: Number,
      default: 8, // in rem
    },
    defaultSorting: [String, Array] as PropType<string | [string, Order]>,
    draggableRows: Boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disabledRows: Function as PropType<((item: any) => boolean)>,
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },
    rowHeight: {
      type: Number,
      default: 50.4, // 1rem font-size (16px) + 1rem padding top & bottom (16px each) + factor 1.15 line-height (~2.4px)
    },
    columnsHideable: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Object as PropType<Set<string>>,
      default: new Set(),
    },
    loading: Boolean,
    maxHeight: String,
    maxWidth: String,
    searchable: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    unwrap: Boolean,
  },
  emits: [
    'rows:dragenter',
    // 'rows:dragleave',
    'rows:drop',
    'rows:dragend',
    'update:expanded',
    'update:selection',
    'sort',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// UUID
    ///-------------------------------------------------------------------
    
    const unique = ref<string>(uuid());

    ///-------------------------------------------------------------------
    /// COMPACT MODE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// PREFIX
    ///-------------------------------------------------------------------

    const prefixEl = ref<HTMLDivElement>();

    ///-------------------------------------------------------------------
    /// TABLE
    ///-------------------------------------------------------------------

    const tableEl = ref<HTMLDivElement>();

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const columns = computed(() => props.headers.filter(header => !hiddenColumns.value.has(header._id)));
    const hiddenColumns = ref<Set<string>>(new Set(props.headers
      .filter(header => header.hidden)
      .map(header => header._id)
    ));
    const hideableColumns = computed(() => {
      let columns = props.headers;
      if (props.headers.length - hiddenColumns.value.size === 1) {
        columns = props.headers.map(header => hiddenColumns.value.has(header._id) ? header : { ...header, _disabled: true });
      }
      return columns.map(column => searching.value.has(column._id) ? { ...column, _disabled: true } : column)
    });

    function toggleColumn(column: Header) {
      if (hiddenColumns.value.has(column._id)) {
        hiddenColumns.value.delete(column._id);
      } else {
        hiddenColumns.value.add(column._id);
      }
      saveComponentCache('table', tableEl.value || null, Array.from(hiddenColumns.value));
    }

    watch(() => tableEl.value, () => {
      if (!tableEl.value) {
        return;
      }
      const data = loadComponentCache('table', tableEl.value);
      if (!data) {
        return;
      }
      hiddenColumns.value = new Set(data)
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// SEARCH
    ///-------------------------------------------------------------------

    const searching = ref<Map<string, string>>(new Map());
    const searchValues = ref<Map<string, string>>(new Map());
    const searchLoading = ref<boolean>(false);

    function filterColumn(headerId: string, filter: string) {
      if (filter.length === 0) {
        searching.value.delete(headerId);
        return;
      }
      searching.value.set(headerId, filter)
    }

    function searchColumn(headerId: string, event: Event) {
      const target = event.target as HTMLInputElement;
      if (!target) {
        return;
      }
      const searchValue = escapeRegExp(target.value);
      if (!searchValue) {
        searchValues.value.delete(headerId);
      } else {
        searchValues.value.set(headerId, searchValue);
      }

      searchLoading.value = true;
      const debouncedFn = useDebounceFn(() => {
        const value = escapeRegExp(target.value);
        if (!value) {
          searching.value.delete(headerId);
        } else {
          searching.value.set(headerId, value);
        }
        searchLoading.value = false;
      }, 1000, { maxWait: 5000 });
      debouncedFn();
    }

    function clearSearchColumn(headerId: string) {
      searching.value.delete(headerId);
      searchValues.value.delete(headerId);
    }

    function clearSearch() {
      searching.value.clear();
      searchValues.value.clear();
    }

    ///-------------------------------------------------------------------
    /// SORT
    ///-------------------------------------------------------------------

    const sorting = ref<Map<string, Order>>(new Map([
      props.defaultSorting
        ? Array.isArray(props.defaultSorting) ? props.defaultSorting : [props.defaultSorting, 'asc']
        : [props.headers.find(header => !header.notSortable)?._id || '', 'asc']
    ]));

    function sortColumn(headerId: string) {
      if (!sorting.value.has(headerId) || sorting.value.get(headerId) === 'desc') {
        sorting.value.clear();
        sorting.value.set(headerId, 'asc');
      } else {
        sorting.value.clear();
        sorting.value.set(headerId, 'desc');
      }
      ctx.emit('sort', sorting.value);
    }

    ///-------------------------------------------------------------------
    /// DATA
    ///-------------------------------------------------------------------

    const count = ref(0);
    const isTreeStructure = ref(isTree(props.data));

    watch(() => props.data, (data) => {
      // Calculate actions column width
      calculateActionsWidth();

      // Update selection
      for (const id of Array.from(selection.value)) {
        if (data.find(item => item._id === id) === undefined) {
          selection.value.delete(id);
        }
      }

      // Update isTreeStrucutre
      isTreeStructure.value = isTree(props.data);
    }, { deep: true });

    const tableData = computed(<T extends BaseDoc>() => {
      // console.time('[TABLE] tableData')
      let data: T[] = [];

      // 1. Transform columns so that every cell contains the value which will be displayed.
      const headers = columns.value.filter(header => !!header.filter || !!header.transform || !!header.icon);
      for (const item of isTreeStructure.value ? flatten(props.data) : props.data) {
        const newItem = clone(item);
        if (newItem._subheading) {
          data.push(newItem as T);
          continue;
        }
        for (const header of headers) {
          if (header.transform) {
            newItem['_transform_' + header._id] = header.transform(item, deepValue(item, header._id));
          }
          // if (header.icon) {
          //   newItem['_icon_' + header._id] = typeof header.icon === 'function' ? header.icon(item, deepValue(item, header._id)) : header.icon;
          // }
          // if (header.tooltip) {
          //   newItem['_tooltip_' + header._id] = header.tooltip(item, deepValue(item, header._id));
          // }
        }

        // 2. Filter columns if a filter is defined and matches the current item
        let results: boolean[] = [];
        for (const [key, search] of searching.value.entries()) {
          if (Array.isArray(search)) {
            const header = headers.find((header: Header) => header._id === key);
            if (header && header.filter) {
              let filterResults: boolean[] = [];
              for (const filter of header.filter) {
                if (search.includes(filter._id)) {
                  if (filter.detect !== undefined) {
                    filterResults.push(filter.detect(item, deepValue(item, header._id)))
                  } else {
                    const regex = new RegExp(filter._id.replace(/\s/, '.*?'), 'i');
                    filterResults.push(regex.test(removeHTML(item[key])))
                  }
                }
              }
              results.push(filterResults.includes(true))
            }
          }
        }

        // 3. Fill the data array with the filtered & transformed items
        if (results.length === 0 || !results.includes(false)) {
          data.push(newItem as T)
        }
      }

      // 4. Filter the already transformed data so the user can search for the values he sees. TODO: Implement searching for tree structures!
      if (!isTreeStructure.value) {
        data = data.filter(item => {
          const results: boolean[] = [];
          for (const [key, search] of searching.value.entries()) {
            if (!Array.isArray(search)) {
              const regex = new RegExp(search.replace(/\s/, '.*?'), 'i');
              results.push(regex.test(removeHTML(item[key])))
            }
          }
          return results.length === 0 || !results.includes(false)
        });
      }

      // 5. Sort the filtered data by the given key and order. TODO: Implement sorting for tree strucutres!
      if (!isTreeStructure.value) {
        const actualSorting = sorting.value.entries().next().value;
        if (!actualSorting) {
          // console.timeEnd('[TABLE] tableData')
          return data;
        }

        const [key, order] = actualSorting as [KeyOf<T>, Order];
        if (!data.find(d => '_subheading' in d)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.sort(byKey(key as unknown as any, order));
        }
      }

      // 6. Add icons and tooltips to the data
      data = data.map(item => {
        for (const header of headers) {
          if (header.icon) {
            (item as BaseDoc)['_icon_' + header._id] = typeof header.icon === 'function' ? header.icon(item, deepValue(item, header._id as KeyOf<T>)) : header.icon;
          }
          if (header.tooltip) {
            (item as BaseDoc)['_tooltip_' + header._id] = header.tooltip(item, deepValue(item, header._id as KeyOf<T>));
          }
        }
        return item;
      })

      // 7. Return the prepared data to the table.
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      count.value = data.length;
      // console.timeEnd('[TABLE] tableData')
      return data;
    })

    function getItem(id: string) {
      return (isTree(props.data) ? flatten(props.data) : props.data)
        .find(item => item._id === id) as BaseDoc;
    }

    const expandedRef = ref(props.expanded);

    function toggleExpanded(id: string) {
      if (expandedRef.value.has(id)) {
        expandedRef.value.delete(id);
      } else {
        expandedRef.value.add(id);
      }
      ctx.emit('update:expanded', expandedRef.value);
    }

    watch(() => props.expanded, () => expandedRef.value = props.expanded);

    function value(item: BaseDoc, column: Header) {
      const key = '_transform_' + column._id;
      return key in item ? item[key] : item[column._id];
    }

    ///-------------------------------------------------------------------
    /// TOOLTIP
    ///-------------------------------------------------------------------

    function tooltip(item: BaseDoc | string, column?: Header): Tooltip {
      const offset: [number, number] = [compactMode?.value ? -4 : -12, 0];

      // If a custom tooltip is provided in the header definition, use this as the tooltip
      if (typeof item !== 'string' && column && `_tooltip_${column._id}` in item && !!item['_tooltip_' + column._id]) {
        return typeof item['_tooltip_' + column._id] === 'object'
          ? { ...item['_tooltip_' + column._id], offset }
          : { offset, text: item['_tooltip_' + column._id] };
      }

      // Get text
      let text = '';
      if (typeof item === 'string') { text = removeHTML(item) }
      else if (column !== undefined) { text = removeHTML(value(item, column)) }

      // Return tooltip
      return (el: HTMLElement) => {
        const content = el.querySelector('.bp-table__cell-content') as HTMLElement;
        const value = el.querySelector('.bp-table__cell-value') as HTMLElement;

        // If we have a cell value, calculate whether a tooltip has to be shown based on the exacts widths of the value and its container element
        if (value) {
          const computedStyle = window.getComputedStyle(content);
          const paddingRight = parseFloat(computedStyle.getPropertyValue('padding-right'));
          const offsetLeft = (value.getBoundingClientRect().left - content.getBoundingClientRect().left);
          if (value.getBoundingClientRect().width > (content.getBoundingClientRect().width - offsetLeft - paddingRight)) {
            return { offset, text };
          }
        }
        // Otherwise use the built in scrollWidth & clientWidth instead (these numbers are rounded therefore possibly inaccurate!)
        else if (content.scrollWidth > content.clientWidth) {
          return { offset, text };
        }
      }
    }

    const innerWidth = ref(window.innerWidth);

    ///-------------------------------------------------------------------
    /// TOGGLE COLUMNS
    ///-------------------------------------------------------------------

    const menu = ref(false);

    ///-------------------------------------------------------------------
    /// ACTION COLUMN
    ///-------------------------------------------------------------------

    const actionsWidth = ref('auto');
    const hasActionsColumn = computed(() => ctx.slots.actions || props.columnsHideable || props.searchable);

    function calculateActionsWidth() {
      nextTick(() => {
        let width = 0;
        const elements = document.querySelectorAll('[data-uuid="' + unique.value + '"] [data-actions]');
        for (const element of elements) {
          let elementWidth = 0;
          for (const child of element.children) {
            const rect = child.getBoundingClientRect();
            elementWidth += rect.width;
          }
          // Set width to the total width of all actions
          width = Math.max(width, elementWidth);
        }
        // If columns are hidable set the width to a minimum of 52 px (= 1.25 em, the width of a fixed-width icon)
        // At the end, add 1 px for the left border of the action column
        actionsWidth.value = (Math.max(width, props.columnsHideable ? (compactMode?.value ? 36 : 52) : 0) + 1) + 'px';
      });
    }

    watch(() => expandedRef.value, calculateActionsWidth, { deep: true });
    watch(() => [props.loading, compactMode?.value], calculateActionsWidth, { immediate: true });

    useResize(() => tableEl.value, () => {
      innerWidth.value = window.innerWidth;
      calculateActionsWidth()
    });

    onMounted(() => {
      calculateActionsWidth();
    });

    ///-------------------------------------------------------------------
    /// SELECTION
    ///-------------------------------------------------------------------

    const selection = ref(new Set())

    function toggle(id: string, mode: 'single' | 'multi' = 'single') {
      if (mode === 'single') {
        selection.value.clear();
      }
      if (selection.value.has(id)) {
        selection.value.delete(id);
      } else {
        selection.value.add(id);
      }
      ctx.emit('update:selection', selection.value);
    }

    function toggleAll() {
      if ((selection.value.size + tableData.value.filter(item => props.disabledRows?.(item)).length) < tableData.value.length) {
        for (const item of tableData.value) {
          const { _id: id } = item;
          if (!selection.value.has(id) && !props.disabledRows?.(item)) {
            selection.value.add(id);
          }
        }
      } else {
        selection.value.clear();
      }
      ctx.emit('update:selection', selection.value);
    }

    ///-------------------------------------------------------------------
    /// PREVENT DOUBLE CLICK USER SELECTION
    ///-------------------------------------------------------------------

    function preventSelection(event: MouseEvent) {
      if (event.detail > 1) {
        event.preventDefault();
      }
    }

    ///-------------------------------------------------------------------
    /// TABLE STYLE
    ///-------------------------------------------------------------------

    const style = ref<CSSProperties>({});
    const tableRowHeight = ref<CSSProperties>({});

    function setStyle() {
      tableRowHeight.value = {
        '--table-row-height': (compactMode?.value ? props.rowHeight - 16 : props.rowHeight) + 'px',
      }
      style.value = {
        '--table-columns': (props.selectable ? `${compactMode?.value ? 36 : 52}px ` : '')
          + (columns.value.length === 1 ? '1fr' : columns.value.map(column => `minmax(${props.columnMinWidth * (column.width && /\d+fr/.test(column.width) ? parseFloat(column.width) : 1)}rem, ${column.width || '1fr'})`).join(' '))
          + (hasActionsColumn.value ? ` minmax(${actionsWidth.value}, ${compactMode?.value ? 37 : 53}px)` : ''),
        '--table-prefix-height': prefixEl.value ? prefixEl.value.getBoundingClientRect().height + 'px' : undefined,
        '--table-max-height': props.maxHeight,
        '--table-max-width': props.maxWidth,
        ...tableRowHeight.value
      };
    }

    watchEffect(setStyle);

    ///-------------------------------------------------------------------
    /// VIRTUAL SCROLLER
    ///-------------------------------------------------------------------
    
    const virtualScrollerEl = ref<typeof BpVirtualScrollerVue>();
    const virtualScrollerDom = computed<HTMLElement | undefined>(() => virtualScrollerEl.value?.$el)
    const virtualScrollerSize = useResize(virtualScrollerDom);
    const virtualScrollerHasScrollbar = ref(false);

    watch(virtualScrollerSize.current, () => {
      const vDom = virtualScrollerDom.value;
      if (!vDom) {
        return;
      }
      const scrollHeight = vDom.scrollHeight;
      const clientHeight = vDom.clientHeight;
      virtualScrollerHasScrollbar.value = scrollHeight > clientHeight;
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// DRAGGING
    ///-------------------------------------------------------------------

    const dragId = ref<string>();
    const dragoverId = ref<string>();

    const ghostElement = ref<HTMLElement>();

    function dragstart(event: DragEvent) {
      const row = (event.target as HTMLElement).closest('.bp-table__row') as HTMLDivElement;
      if (!row) {
        return;
      }
      dragId.value = row.getAttribute('data-table-row-id') as string;

      // GHOST ELEMENT
      if (event.dataTransfer) {
        ghostElement.value = row.querySelector('.bp-table__cell-content')?.cloneNode(true) as HTMLElement;

        document.body.appendChild(ghostElement.value);
        ghostElement.value.style.position = 'relative';
        ghostElement.value.style.left = '-100%';
        ghostElement.value.style.background = 'var(--theme-background)';
        ghostElement.value.style.color = 'var(--theme-text)';
        ghostElement.value.style.borderRadius = '100vw';

        event.dataTransfer.setDragImage(ghostElement.value, 0, 0);
      }
    }

    function dragenter(event: DragEvent) {
      const row = (event.target as HTMLElement).closest('.bp-table__row') as HTMLDivElement;
      if (!row) {
        return;
      }
      dragoverId.value = row.getAttribute('data-table-row-id') as string;
      ctx.emit('rows:dragenter', dragoverId.value, dragId.value);
    }

    // function dragleave(event: DragEvent) {
    //   const row = (event.target as HTMLElement).closest('.bp-table__row') as HTMLDivElement;
    //   const relatedRow = (event.relatedTarget as HTMLElement).closest('.bp-table__row') as HTMLDivElement;
    //   if (!row || !relatedRow || row.getAttribute('data-table-row-id') === relatedRow.getAttribute('data-table-row-id')) {
    //     return;
    //   }
    //   ctx.emit('rows:dragleave', row.getAttribute('data-table-row-id'), dragId.value);
    // }

    function dragend() {
      ctx.emit('rows:dragend', dragoverId.value, dragId.value);
      dragoverId.value = undefined;
      dragId.value = undefined;

      // GHOST ELEMENT
      if (ghostElement.value) {
        document.body.removeChild(ghostElement.value);
        ghostElement.value = undefined;
      }
    }

    function drop(event: DragEvent) {
      const row = (event.target as HTMLElement).closest('.bp-table__row') as HTMLDivElement;
      ctx.emit('rows:drop', row ? row.getAttribute('data-table-row-id') : null, dragId.value);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      actionsWidth,
      calculateActionsWidth,
      clearSearch,
      clearSearchColumn,
      columns,
      compactMode,
      copyToClipboard,
      count,
      currentLanguage,
      dragend,
      dragenter,
      // dragleave,
      filterColumn,
      dragstart,
      drop,
      expandedRef,
      getItem,
      hasActionsColumn,
      hiddenColumns,
      hideableColumns,
      innerWidth,
      isFirefox,
      isSafari,
      isTreeStructure,
      menu,
      prefixEl,
      preventSelection,
      removeHTML,
      searchColumn,
      searchLoading,
      searchValues,
      searching,
      selection,
      sortColumn,
      sorting,
      style,
      tableData,
      tableEl,
      tableRowHeight,
      toggle,
      toggleAll,
      toggleColumn,
      toggleExpanded,
      tooltip,
      unescapeRegExp,
      unique,
      value,
      virtualScrollerEl,
      virtualScrollerHasScrollbar,
    };
  }
});
