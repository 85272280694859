/**
 * Checks if the browser is safari and needs special care.
 */
export function isSafari() {
  const userAgent = window.navigator.userAgent;
  return userAgent.includes('Safari') && !userAgent.includes('Chrome');
}

/**
 * Checks if the browser is firefox and needs special care.
 */
export function isFirefox() {
  const userAgent = window.navigator.userAgent;
  return userAgent.includes('Firefox');
}