import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-masonry-grid', { 'bp-masonry-grid--compact': _ctx.compactMode }]),
    ref: "gridEl",
    style: _normalizeStyle({ '--columns': _ctx.columns })
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}