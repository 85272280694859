import { Component, reactive } from 'vue';
import { timeout } from '@/utils/time';
import { ColorAlias } from '@/utils/color';

type Optional<T> = { [P in keyof T]?: T[P] };

/**
 * All available toast positions.
 */
export enum ToastPosition {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',

  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right'
}

/**
 * The state a toast is in.
 */
export enum ToastState {
  NONE = 'none',
  FADE_IN = 'fade-in',
  DISPLAY = 'display',
  FADE_OUT = 'fade-out'
}

/**
 * A toast.
 */
export interface Toast {
  /**
   * The ID of the toast. (Autogenerated)
   */
  _id: string;

  /**
   * Can the toast be closed?
   */
  closable: boolean;

  /**
   * The content of the toast. Can either be a string or a Vue component if rich HTML markup is required. (Default: `''`)
   */
  content: string | Component;

  /**
   * The color of the toast. (Default: `'grey'`)
   */
  color: ColorAlias;

  /**
   * After how many milliseconds should the toast be hidden? (Default: `5000`)
   * A duration of `-1` will set it's display time to infinite.
   */
  duration: number;

  /**
   * For how many milliseconds should the toast be faded? (Default: `300`)
   */
  fadeDuration: number;

  /**
   * The icon of the toast. (Default: `'circle-info'`)
   */
  icon: string[] | string;

  /**
   * The position of the toast. (Default: `TOP_CENTER`)
   */
  position: ToastPosition;

  /**
   * The state of the toast.
   */
  state: ToastState;

  /**
   * The toast title
   */
  title: string;
}

const DEFAULT_TOAST_SETTINGS: Toast = {
  _id: '',
  closable: true,
  content: '',
  duration: 5000,
  color: 'light-blue',
  fadeDuration: 300,
  icon: 'circle-info',
  position: ToastPosition.TOP_CENTER,
  state: ToastState.NONE,
  title: ''
};

/**
 * Wrapper class to show toasts.
 */
export default class BpToast {
  ///-------------------------------------------------------------------
  /// PROPERTIES & GETTERS
  ///-------------------------------------------------------------------

  private static _state = reactive<{ toasts: Toast[] }>({
    toasts: []
  }) as { toasts: Toast[] };

  /**
   * All currently active toasts.
   */
  public static get toasts(): Toast[] {
    return BpToast._state.toasts;
  }

  ///-------------------------------------------------------------------
  /// LIFECYCLE & EVENTS
  ///-------------------------------------------------------------------

  ///-------------------------------------------------------------------
  /// PUBLIC API
  ///-------------------------------------------------------------------

  /**
   * Shows a toast.
   * @returns The toast ID that can be used to dismiss the toast.
   */
  public static show(settings: Optional<Toast>): string {
    const toast: Toast = {
      ...DEFAULT_TOAST_SETTINGS,
      _id: BpToast.randomId(),
      ...settings
    };
    if (toast.state !== ToastState.NONE) {
      throw new Error('Initial toast state must be none.');
    }
    if (this.getToast(toast._id)) {
      return toast._id;
    }
    this._state.toasts.push(toast);
    const newToast = this.getToast(toast._id);
    if (newToast) {
      this.fadeIn(newToast).catch(error => console.error('Error while fading toast', error));
    }
    return toast._id;
  }

  /**
   * Hides/dismisses a toast.
   * @param id The toast ID to hide.
   * @remarks Noop if no toast with this ID was found.
   */
  public static async hide(id: string) {
    const toast = this.getToast(id);
    if (toast) {
      await this.fadeOut(toast);
    }
  }

  /**
   * Hides all active toasts.
   */
  public static async hideAll() {
    const all = [];
    for (const toast of this._state.toasts) {
      all.push(this.fadeOut(toast));
    }
    await Promise.all(all);
  }

  /**
   * Gets a toast with the given ID. Has `O(n)` complexity due to the toasts not being indexed.
   * @param id The toast Id.
   * @returns The toast or `null` if not found.
   */
  public static getToast(id: string): Toast | null {
    return this._state.toasts.find(t => t._id === id) || null;
  }

  ///-------------------------------------------------------------------
  /// PRIVATE API
  ///-------------------------------------------------------------------

  private static async fadeIn(toast: Toast) {
    await timeout(100);
    toast.state = ToastState.FADE_IN;
    await timeout(toast.fadeDuration);
    toast.state = ToastState.DISPLAY;
    if (toast.duration <= 0) {
      return;
    }
    await timeout(toast.duration);
    await this.fadeOut(toast);
  }

  private static async fadeOut(toast: Toast) {
    toast.state = ToastState.FADE_OUT;
    await timeout(toast.fadeDuration);
    toast.state = ToastState.NONE;
    this.remove(toast);
  }

  private static remove(toast: Toast) {
    this._state.toasts = this._state.toasts.filter(t => t._id !== toast._id);
  }

  private static randomId() {
    return Math.random().toString(16).substr(2);
  }
}
