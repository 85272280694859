import { cssPath } from './css';

type SetFn = (key: string, value: string) => void;
type GetFn = (key: string) => string | null;

const setFnLocalStorage: SetFn = localStorage.setItem.bind(localStorage);
const getFnLocalStorage: GetFn = localStorage.getItem.bind(localStorage);

/**
 * Stores component based data in the local storage of the browser.
 * @param component The name of the component. (e.g. `table`)
 * @param element The HTML element.
 * @param data The data to store in the local storage.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function saveComponentCache(component: string, element: HTMLElement | null, data: any, setFn: SetFn = setFnLocalStorage) {
  const key = keyOf(component, element);
  if (!key) { return }
  try {
    setFn(key, JSON.stringify(data));
  } catch(error) {
    console.warn('Error while saving component cache of', component, '(key:', key, ')', error);
  }
}

/**
 * Loads component based data stored in the local storage of the browser.
 * @param component The name of the component. (e.g. `table`)
 * @param element The HTML element.
 * @returns The data stored in the local storage.
 */
export function loadComponentCache(component: string, element: HTMLElement | null, getFn: GetFn = getFnLocalStorage) {
  const key = keyOf(component, element);
  if (!key) { return null }
  try {
    const data = getFn(key);
    if (data) {
      return JSON.parse(data);
    }
  } catch(error) {
    console.warn('Error while loading component cache of', component, '(key:', key, ')', error);
  }
  return null;
}

function keyOf(component: string, element: HTMLElement | null) {
  let key = null;
  try {
    key = `bp:component:${component}${element && document.body.contains(element) ? ':' + (element.id ? element.id : window.btoa(cssPath(element, 'global'))) : ''}`;
  } catch(error) {
    console.warn('Error when getting key of', component, error)
  }
  return key
}