import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon", true)!

  return (_ctx.customIcons.map(i => i.toLowerCase()).includes(`${_ctx.icon}`.toLowerCase()))
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: `/static/icon/${`${_ctx.icon}`.toLowerCase()}${_ctx.isDarkBackground ? '_white' : ''}.svg`,
        style: {"height":"1em","width":"1.25em","vertical-align":"-0.125em"},
        ref: "imgRef"
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type === 'layer' ? 'font-awesome-layers' : 'span'), {
        key: 1,
        class: _normalizeClass([
      'bp-icon',
      {
        'bp-icon--border': _ctx.border,
        'bp-icon--disabled': _ctx.disabled,
        'bp-icon--image': _ctx.type === 'image',
        'bp-icon--layers': _ctx.type === 'layer',
      }
    ]),
        style: _normalizeStyle({ ..._ctx.variablesOf(_ctx.icon), ..._ctx.baseStyleOf(_ctx.icon) }),
        "fixed-width": _ctx.fixedWidth
      }, {
        default: _withCtx(() => [
          (_ctx.type === 'icon')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent('font-awesome-icon'), {
                key: 0,
                icon: _ctx.icon,
                border: _ctx.border,
                "fixed-width": _ctx.fixedWidth,
                flip: _ctx.flip,
                inverse: _ctx.inverse,
                "list-item": _ctx.listItem,
                mask: _ctx.mask,
                pull: _ctx.pull,
                pulse: _ctx.pulse,
                rotation: _ctx.rotation,
                spin: _ctx.spin,
                size: _ctx.size,
                "swap-opacity": _ctx.swapOpacity,
                symbol: _ctx.symbol,
                title: _ctx.title,
                transform: _ctx.transform
              }, null, 8, ["icon", "border", "fixed-width", "flip", "inverse", "list-item", "mask", "pull", "pulse", "rotation", "spin", "size", "swap-opacity", "symbol", "title", "transform"]))
            : (_ctx.isComplexIcon(_ctx.icon))
              ? (_openBlock(), _createBlock(_resolveDynamicComponent('font-awesome-icon'), _mergeProps({ key: 1 }, _ctx.icon, { "fixed-width": _ctx.fixedWidth }), null, 16, ["fixed-width"]))
              : (_ctx.isImageIcon(_ctx.icon))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: _ctx.icon
                  }, null, 8, _hoisted_2))
                : (_ctx.isLayers(_ctx.icon))
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.icon, (layer, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_ctx.typeOf(layer) === 'complex-icon')
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              icon: layer,
                              style: _normalizeStyle(_ctx.variablesOf(layer)),
                              seamless: ""
                            }, null, 8, ["icon", "style"]))
                          : (_ctx.isTextLayer(layer))
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent('font-awesome-layers-text'), {
                                key: 1,
                                counter: layer.counter,
                                position: layer.position,
                                transform: layer.transform,
                                value: layer.value,
                                style: _normalizeStyle(_ctx.variablesOf(layer, layer.counter ? { transform: 'scale(0.4)' } : layer.textStyle))
                              }, null, 8, ["counter", "position", "transform", "value", "style"]))
                            : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "style", "fixed-width"]))
}