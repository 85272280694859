import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-navigation-entry__header"
}
const _hoisted_2 = ["data-navigation-depth"]
const _hoisted_3 = { class: "bp-navigation-entry__item" }
const _hoisted_4 = { class: "bp-navigation-entry__name-wrapper" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "bp-navigation-entry__children" }
const _hoisted_7 = {
  key: 0,
  class: "bp-navigation-entry__header"
}
const _hoisted_8 = {
  key: 1,
  class: "bp-navigation-entry__footer"
}
const _hoisted_9 = { class: "bp-navigation-entry__name-wrapper" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["data-navigation-depth", "onClick"]
const _hoisted_12 = { class: "bp-navigation-entry__name-wrapper" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 3,
  class: "bp-navigation-entry__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_navigation_entry = _resolveComponent("bp-navigation-entry", true)!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bp-navigation-entry",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.navigation && _ctx.navigation.length === 1 && _ctx.children(_ctx.navigation[0]).length > 0)
      ? (_openBlock(), _createBlock(_component_bp_navigation_entry, {
          key: 1,
          color: _ctx.color,
          navigation: _ctx.inheritChildren(_ctx.navigation[0]),
          onClickEntry: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-entry', $event)))
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.slots, (slotName) => {
            return {
              name: slotName,
              fn: _withCtx((slotScope) => [
                _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
              ])
            }
          })
        ]), 1032, ["color", "navigation"]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.navigation, (entry, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: entry._id
          }, [
            (_ctx.children(entry).length === 1)
              ? (_openBlock(), _createBlock(_component_bp_navigation_entry, {
                  key: 0,
                  color: _ctx.color,
                  navigation: _ctx.inheritChildren(entry),
                  onClickEntry: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-entry', $event)))
                }, _createSlots({ _: 2 }, [
                  _renderList(_ctx.slots, (slotName) => {
                    return {
                      name: slotName,
                      fn: _withCtx((slotScope) => [
                        _renderSlot(_ctx.$slots, slotName, _mergeProps({ ref_for: true }, slotScope), undefined, true)
                      ])
                    }
                  })
                ]), 1032, ["color", "navigation"]))
              : (_ctx.children(entry).length > 0)
                ? (_openBlock(), _createElementBlock("details", {
                    key: 1,
                    "data-navigation-depth": _ctx.depth,
                    open: ""
                  }, [
                    _createElementVNode("summary", {
                      class: _normalizeClass(['bp-navigation-entry__summary', {
                'bp-navigation-entry__summary--active': _ctx.isEntryActive(entry),
              }])
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _renderSlot(_ctx.$slots, _ctx.slotName(entry), {
                          option: entry,
                          index: index
                        }, () => [
                          _createElementVNode("span", _hoisted_4, [
                            (entry.icon)
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  class: "bp-navigation-entry__icon",
                                  icon: entry.icon
                                }, null, 8, ["icon"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", {
                              class: "bp-navigation-entry__name",
                              innerHTML: entry.name
                            }, null, 8, _hoisted_5)
                          ])
                        ], true)
                      ]),
                      _createVNode(_component_bp_icon, {
                        class: "bp-navigation-entry__marker",
                        icon: "angle-right"
                      })
                    ], 2),
                    _createElementVNode("div", _hoisted_6, [
                      (_ctx.$slots['header-' + entry._id])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _renderSlot(_ctx.$slots, 'header-' + entry._id, {
                              option: entry,
                              index: index
                            }, undefined, true)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_bp_navigation_entry, {
                        color: _ctx.color,
                        navigation: _ctx.inheritChildren(entry),
                        depth: (_ctx.depth + 1),
                        onClickEntry: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-entry', $event)))
                      }, _createSlots({ _: 2 }, [
                        _renderList(_ctx.slots, (slotName) => {
                          return {
                            name: slotName,
                            fn: _withCtx((slotScope) => [
                              _renderSlot(_ctx.$slots, slotName, _mergeProps({ ref_for: true }, slotScope), undefined, true)
                            ])
                          }
                        })
                      ]), 1032, ["color", "navigation", "depth"]),
                      (_ctx.$slots['footer-' + entry._id])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _renderSlot(_ctx.$slots, 'footer-' + entry._id, {
                              option: entry,
                              index: index
                            }, undefined, true)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ], 8, _hoisted_2))
                : (entry.to)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 2,
                      class: _normalizeClass(['bp-navigation-entry__item', 'bp-navigation-entry__item--no-children', {
            'bp-navigation-entry__item--active': _ctx.isEntryActive(entry),
          }]),
                      "data-navigation-depth": _ctx.depth,
                      to: entry.to,
                      onClick: ($event: any) => (_ctx.$emit('click-entry', entry))
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, _ctx.slotName(entry), {
                          option: entry,
                          index: index
                        }, () => [
                          _createElementVNode("span", _hoisted_9, [
                            (entry.icon)
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  class: "bp-navigation-entry__icon",
                                  icon: entry.icon
                                }, null, 8, ["icon"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", {
                              class: "bp-navigation-entry__name",
                              innerHTML: entry.name
                            }, null, 8, _hoisted_10)
                          ])
                        ], true)
                      ]),
                      _: 2
                    }, 1032, ["class", "data-navigation-depth", "to", "onClick"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: _normalizeClass(['bp-navigation-entry__item', 'bp-navigation-entry__item--no-children']),
                      "data-navigation-depth": _ctx.depth,
                      onClick: entry.click
                    }, [
                      _renderSlot(_ctx.$slots, _ctx.slotName(entry), {
                        option: entry,
                        index: index
                      }, () => [
                        _createElementVNode("span", _hoisted_12, [
                          (entry.icon)
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 0,
                                class: "bp-navigation-entry__icon",
                                icon: entry.icon
                              }, null, 8, ["icon"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", {
                            class: "bp-navigation-entry__name",
                            innerHTML: entry.name
                          }, null, 8, _hoisted_13)
                        ])
                      ], true)
                    ], 8, _hoisted_11))
          ], 64))
        }), 128)),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}