import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bp-fieldset__legend-content" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "bp-fieldset__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass(['bp-fieldset', {
    'bp-fieldset--collapsed': _ctx.isCollapsed,
    'bp-fieldset--compact': _ctx.compactMode,
    'bp-fieldset--collapsible': _ctx.collapsible,
    'bp-fieldset--dog-eared': _ctx.dogEared,
  }]),
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.legend || _ctx.$slots.legend)
      ? (_openBlock(), _createElementBlock("legend", {
          key: 0,
          class: "bp-fieldset__legend",
          ref: "legendEl",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsible ? _ctx.isCollapsed = !_ctx.isCollapsed : undefined))
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.collapsible)
              ? (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 0,
                  class: "bp-fieldset__dropdown-icon",
                  icon: "chevron-right"
                }))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "legend", {}, () => [
              _createElementVNode("span", { innerHTML: _ctx.legend }, null, 8, _hoisted_2)
            ], true)
          ])
        ], 512))
      : _createCommentVNode("", true),
    (!_ctx.isCollapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}