import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 3 }
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.tooltip || _ctx.errors)
    ? _withDirectives((_openBlock(), _createElementBlock("span", {
        class: "bp-form-button__wrapper",
        key: JSON.stringify(_ctx.buttonTooltip)
      }, [
        _createVNode(_component_bp_button, _mergeProps({
          class: ['bp-form-button', 'bp-form-button--' + _ctx.type]
        }, _ctx.buttonProps, {
          color: _ctx.buttonColor,
          disabled: _ctx.buttonDisabled,
          icon: _ctx.buttonIcon,
          onClick: _withModifiers(_ctx.click, ["prevent"])
        }), {
          default: _withCtx(() => [
            (_ctx.type === 'save')
              ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Save")
                  ])),
                  _: 1
                }))
              : (_ctx.type === 'reset')
                ? (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Reset")
                    ])),
                    _: 1
                  }))
                : (_ctx.type === 'cancel')
                  ? (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Cancel")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, [
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ]))
          ]),
          _: 3
        }, 16, ["class", "color", "disabled", "icon", "onClick"])
      ])), [
        [_directive_tooltip, _ctx.buttonTooltip]
      ])
    : (_openBlock(), _createBlock(_component_bp_button, _mergeProps({
        key: 1,
        class: ['bp-form-button', 'bp-form-button--' + _ctx.type]
      }, _ctx.buttonProps, {
        color: _ctx.buttonColor,
        disabled: _ctx.buttonDisabled,
        icon: _ctx.buttonIcon,
        onClick: _withModifiers(_ctx.click, ["prevent"])
      }), {
        default: _withCtx(() => [
          (_ctx.type === 'save')
            ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              }))
            : (_ctx.type === 'reset')
              ? (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Reset")
                  ])),
                  _: 1
                }))
              : (_ctx.type === 'cancel')
                ? (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]))
        ]),
        _: 3
      }, 16, ["class", "color", "disabled", "icon", "onClick"]))
}