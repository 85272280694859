
import { computed, defineComponent, ref, watch } from 'vue';
import { $gettext } from 'vue-gettext';
import zxcvbn from 'zxcvbn';

export default defineComponent({
  name: 'bp-password-validator',
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  emits: [
    'change-valid',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// REQUIREMENTS
    ///-------------------------------------------------------------------

    const valid = computed(() => {
      const value = !(Object.values(requirements.value).filter(value => !value).length > 0);
      ctx.emit('change-valid', value ? '' : $gettext('Not all requirements are fulfilled'));
      return value;
    });

    const requirements = ref<{ length: boolean, lowercase: boolean, number: boolean, specialCharacter: boolean, uppercase: boolean }>({
      length: false,
      lowercase: false,
      number: false,
      specialCharacter: false,
      uppercase: false,
    });
    watch(() => props.password, password => {
      requirements.value.length = password.length >= 8;
      requirements.value.lowercase = /[a-z]/.test(password);
      requirements.value.number = /[0-9]/.test(password);
      requirements.value.specialCharacter = /[\W_]/.test(password);
      requirements.value.uppercase = /[A-Z]/.test(password);
    }, { deep: true, immediate: true });

    ///-------------------------------------------------------------------
    /// SCORE
    ///-------------------------------------------------------------------

    const score = computed(() => {
      const { score } = zxcvbn(props.password);
      return score;
    })

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      requirements,
      score,
      valid,
    }
  }
})
