import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sketch_color_picker = _resolveComponent("sketch-color-picker")!

  return (_openBlock(), _createBlock(_component_sketch_color_picker, {
    class: "bp-wysiwyg-editor-color-picker",
    "preset-colors": _ctx.presetColors,
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.updateColor,
    ref: "colorPicker"
  }, null, 8, ["preset-colors", "model-value", "onUpdate:modelValue"]))
}