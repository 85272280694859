import { deepValue, KeyOf } from './object';
import { stringify } from './string';

export function isSetEqual(a: Set<unknown>, b: Set<unknown>) {
  return a.size !== b.size || !([...a].every((el) => b.has(el)))
}

/**
 * Splits an array into two new arrays.
 * If the filter function returns `true` for any given element, it will be added to the first result array.
 * If the filter function returns `false` for any given element, it will be added to the second result array.
 * @param array The array.
 * @param filter The filter function which has to return a boolean value.
 * @returns A two element tuple containing two arrays, the first has all element that passed the filter function, the other elements are in the second array.
 * @example
 * const [pass, fail] = partition([1, 3, 5, 7, 9], el => el < 5);
 * // pass = [1, 3]
 * // fail = [5, 7, 9]
 */
export function partition<T>(array: T[], filter: (element: T) => boolean): [T[], T[]] {
  return array.reduce(([pass, fail]: [T[], T[]], element) => {
    return filter(element) ? [[...pass, element], fail] : [pass, [...fail, element]];
  }, [[], []]);
}

/**
 * The order, either `asc` or `desc`.
 */
export type Order = 'asc' | 'desc';

/**
 * Generates a compare function that sorts an array of objects by the given key in the given order. This function can then be used in the `Array.sort` function.
 * @param key The key to sort by.
 * @param order (optional) The order, either `asc` or `desc`. Defaults to `asc`.
 * @returns A `compareFn` to use in the `Array.sort` function.
 */
export function byKey<T>(key?: KeyOf<T> | ((item: T) => KeyOf<T>), order: Order = 'asc') {
  return (a: T, b: T): number => {
    const aKey = key ? (typeof key === 'function' ? key(a) : key) : '_id' as KeyOf<T>;
    const bKey = key ? (typeof key === 'function' ? key(b) : key) : '_id' as KeyOf<T>;
    const aVal = getValueByKey(a, aKey);
    const bVal = getValueByKey(b, bKey);
    const aValue = typeof aVal !== typeof bVal ? stringify(aVal) : aVal;
    const bValue = typeof bVal !== typeof aVal ? stringify(bVal) : bVal;
    if ((aValue === '' || (typeof aValue === 'string' && aValue.includes('—'))) && (bValue !== '' && (typeof bValue === 'string' && !bValue.includes('—')))) {
      return 1;
    } else if ((bValue === '' || (typeof bValue === 'string' && bValue.includes('—'))) && (aValue !== '' && (typeof aValue === 'string' && !aValue.includes('—')))) {
      return -1;
    }
    if (order === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else if (order === 'desc') {
      return aValue < bValue ? 1 : -1;
    }
    return 0;
  }
}

function getValueByKey<T>(obj: T, key: KeyOf<T>) {
  const value = deepValue(obj, key);
  if (typeof value === 'string') {
    if (/^\d{4}-\d{2}-\d{2}/.test(value) || /^\d{2}\/\d{2}\/\d{4}/.test(value)) {
      return new Date(value).getTime(); // date (english)
    } else if (/^\d{2}\.\d{2}\.\d{4}/.test(value)) {
      const match = value.match(/^(\d{2})\.(\d{2})\.(\d{4})/) || [];
      return new Date(`${match[2]}/${match[1]}/${match[3]}`).getTime(); // date (german)
    } else if (/^-?(?:\d+[,.]\d*|\d*[,.]\d+|\d+)$/.test(value)) {
      return parseFloat(value); // number which is given as strings
    }
    return value.toLowerCase().trim(); // string
  } else if (typeof value === 'object' || Array.isArray(value)) {
    return JSON.stringify(value); // object, array
  }
  return value; // number, boolean, unknown
}