import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-view-header__title" }
const _hoisted_2 = { class: "bp-view-header__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-view-header',{
    'bp-view-header--compact': _ctx.compactMode
  }]),
    style: _normalizeStyle({ '--image-url': 'url(' + _ctx.image + ')' })
  }, [
    _createElementVNode("span", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_bp_icon, {
            key: 0,
            class: "bp-view-header__icon",
            icon: _ctx.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 6))
}