
import { computed, defineComponent, PropType } from 'vue'
import { $gettext } from 'vue-gettext';

export default defineComponent({
  name: 'bp-loading',
  props: {
    modelValue: Boolean,
    fullscreen: Boolean,
    icon: {
      type: [String, Array] as PropType<string | string[]>,
      default: () => ['fad', 'spinner-third'],
    },
    inline: Boolean,
    text: String,
  },
  setup(props) {
    const loadingText = computed(() => props.text || $gettext('Loading…'));
    return { loadingText }
  }
})
