
import useCompactMode from '@/compositions/use-compact-mode';
import { Modifier } from '@/utils/color';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, defineComponent, nextTick, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import BpCard from './card/BpCard.vue';

export default defineComponent({
  name: 'BpWindow',
  props: {
    modelValue: Boolean,
    closable: {
      type: Boolean,
      default: true,
    },
    seamlessContent: Boolean,
    type: String as PropType<'error' | 'info' | 'success' | 'warning'>,
  },
  emits: [
    'update:modelValue'
  ],
  inheritAttrs: false,
  setup(props, ctx) {
    function close() {
      if (props.closable) {
        ctx.emit('update:modelValue', false);
      }
    }

    const color = computed(() => {
      switch (props.type) {
        case 'error': return 'red';
        case 'info': return 'blue';
        case 'success': return 'green';
        case 'warning': return 'yellow';
        default: return undefined;
      }
    });

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isTablet = useMedia(Breakpoint.MIN_SM);

    ///-------------------------------------------------------------------
    /// CALCULATE CONTENT HEIGHT
    ///-------------------------------------------------------------------

    const windowEl = ref<typeof BpCard>();
    const contentEl = ref<HTMLDivElement>();

    function calculateHeight() {
      nextTick(() => {
        if (!windowEl.value || !contentEl.value) {
          return;
        }
        const headerEl = windowEl.value.$el.querySelector('.bp-card__header');
        const footerEl = windowEl.value.$el.querySelector('.bp-card__footer');
        if (!headerEl || !footerEl) {
          return;
        }
        // Desktop calculation
        if (isTablet.value) {
          contentEl.value.style.height = 'auto';
          const borderWidth = parseFloat(window.getComputedStyle(windowEl.value.$el).getPropertyValue('--card-border-width')) * 16;
          const contentHeight = windowEl.value.$el.getBoundingClientRect().height - 2 * borderWidth - headerEl.getBoundingClientRect().height - footerEl.getBoundingClientRect().height;
          if (contentEl.value.getBoundingClientRect().height > contentHeight) {
            contentEl.value.style.height = contentHeight + 'px';
          }
        }
        // Mobile calculation (fullscreen window)
        else {
          contentEl.value.style.height = '0px';
          contentEl.value.style.height = (footerEl.getBoundingClientRect().top - contentEl.value.getBoundingClientRect().top) + 'px';
        }
      });
    }

    const observer = new MutationObserver(calculateHeight);
    const resizeObserver = new ResizeObserver(calculateHeight);
    watch(() => [windowEl.value, contentEl.value], () => {
      if (!contentEl.value) { return }
      calculateHeight();
      observer.observe(contentEl.value, { childList: true });
      resizeObserver.observe(contentEl.value);
    }, { immediate: true });
    onMounted(() => addEventListener('resize', calculateHeight, { passive: true }));
    onUnmounted(() => removeEventListener('resize', calculateHeight));

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode()

    return {
      close,
      color,
      compactMode,
      Modifier,
      contentEl,
      windowEl,
    }
  }
});
