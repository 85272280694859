import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-download-preview__name" }
const _hoisted_2 = { class: "bp-download-preview__version" }
const _hoisted_3 = { class: "bp-download-preview__date" }
const _hoisted_4 = { class: "bp-download-preview__filesize" }
const _hoisted_5 = { class: "bp-download-preview__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_translate = _resolveComponent("translate")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-download-preview', { 'bp-download-preview--compact': _ctx.compactMode, 'bp-download-preview--hide-changelog': _ctx.hideChangelog }])
  }, [
    _createVNode(_component_bp_icon, {
      class: "bp-download-preview__icon",
      icon: _ctx.downloadStore.getIcon(_ctx.modelValue)
    }, null, 8, ["icon"]),
    _createElementVNode("strong", _hoisted_1, _toDisplayString(_ctx.downloadStore.getFeaturedName(_ctx.modelValue)), 1),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_bp_icon, { icon: "v" }),
      _createTextVNode(_toDisplayString(_ctx.versionStore.getVersionById(_ctx.modelValue.version) || '–'), 1)
    ]),
    _createElementVNode("span", _hoisted_3, [
      _createVNode(_component_bp_icon, { icon: ['far', 'calendar'] }),
      _createTextVNode(_toDisplayString(_ctx.localizeDate(_ctx.modelValue.date)), 1)
    ]),
    _createElementVNode("span", _hoisted_4, [
      _createVNode(_component_bp_icon, {
        icon: _ctx.subIcon('circle', 'circle-info', ['far', 'file'])
      }, null, 8, ["icon"]),
      _createTextVNode(_toDisplayString(_ctx.formatBytes(Object.values(_ctx.modelValue._attachments)[0].length)), 1)
    ]),
    (!_ctx.hideChangelog)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "bp-download-preview__changelog",
          to: { name: 'changelog', query: { tab: _ctx.versionStore.getUUIDPathById(_ctx.modelValue.version).join('_'), version: _ctx.versionStore.getVersionById(_ctx.modelValue.version) }}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Show changelog")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_5, [
      _createVNode(_component_bp_tooltip, {
        condition: _ctx.modelValue._disabled,
        tooltip: _ctx.$gettext(`You don't have a valid license to download this file`)
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_bp_button, {
            color: "green",
            download: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadStore.download(_ctx.modelValue))),
            disabled: _ctx.modelValue._disabled
          }, null, 8, ["disabled"]), [
            [_directive_tooltip, _ctx.$gettext('Download')]
          ])
        ]),
        _: 1
      }, 8, ["condition", "tooltip"])
    ])
  ], 2))
}