import { BaseDoc } from '@/components/virtual-scroller/BpVirtualScroller';
import { currentLanguageISOString, isTranslated, Translated } from '@/translation';
import { deepValue, Flat, KeyOf } from '@/utils/object';
import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import { toTitleCase } from '@/utils/string';

interface State {
  config: Config;
  comments: Comments;
  loading: number;
  loaded: boolean;
}

/**
 * A config.
 */
export interface Config {
  [x: string]: ConfigSection;
}
export interface ConfigSection {
  [x: string]: ConfigSection | string;
}

export interface Comments {
  [x: string]: CommentsSection;
}
export interface CommentsSection {
  [x: string]: CommentsSection | string[];
}

/**
 * The config store.
 */
export const useConfigStore = () => {
  // Define a local store reference
  const useStore = defineStore('config', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        config: {},
        comments: {},
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for email settings by their ID.
       * @param ids The ID of the email.
       * @returns The settings of the email containing `recipients`, `subject` and `body`.
       */
      getEmailSettings: (state: State) => {
        return (email: string) => {
          let emailSettings = state.config.email_settings as ConfigSection;
          for (const section of email.split('.')) {
            emailSettings = emailSettings[section] as ConfigSection
          }
          return {
            recipients: emailSettings.recipients as string,
            subject: emailSettings.subject as Translated<string>,
            body: emailSettings.template as Translated<string>,
          }
        }
      },
            /**
       * Search for email settings by their ID.
       * @param ids The ID of the email.
       * @returns The settings of the email containing `recipients`, `subject` and `body`.
       */
      getVersions: (state: State) => {
        return (path: string) => {
          const value = deepValue(state.config.versions, path);
          if (typeof value === 'string') {
            return value;
          }
          return '';
        }
      },
      getVersionMeta: (state: State) => {
        return (path: string, id: string) => {
          if (!path || !id) {
            return;
          }
          const value = deepValue(state.config.versions, path);
          if (typeof value !== 'string') {
            return;
          }
          const headers = (value.split(',').shift() || '')
            .split(';')
            .map(header => header.includes('|')
              ? (header.split('|')
                .find((langHeader) => langHeader.startsWith(currentLanguageISOString() + ':')) || '').substring(currentLanguageISOString().length + 1)
              : header);
          const result = value.match(new RegExp(`${id}(?:;|$)[^,]*`)) as string[];
          if (!result) {
            return;
          }
          const [match] = result;
          if (!match) {
            return;
          }
          const item = match.split(';').reduce((acc: BaseDoc, col, index) => {
            acc[headers[index]] = col;
            return acc;
          }, { _id: match.split(';')[0] }) as BaseDoc
          const mapValues = headers.map(header => [header, item[header]] as [string, string]);
          return new Map(mapValues);
        }
      },
      getComments: (state: State) => {
        return (path: KeyOf<Comments>) => {
          const comments = deepValue(state.comments, path);
          return ((isTranslated(comments) ? comments.en : comments) ?? []) as string[];
        }
      },
      /**
       * Search for configs by their ID.
       * @param ids The ID or an array of IDs of the configs.
       * @returns An array of configs from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const config = state.config;
          return ids.length === 1 ? config[0] : config;
        }) as ((ids: string | [string]) => Config) & ((ids: string[]) => Config[])
      },
      getSections: (state: State) => {
        function *flatSections() {
          function *iterate(section: Flat<[string, string | ConfigSection]>, path = '', depth = 0): Generator<ConfigSection> {
            const [key, value] = section;
            const actualPath = !path ? key : `${path}.${key}`;
            yield { _id: actualPath, name: toTitleCase(key.replaceAll('_', ' ').trim()), _depth: section._depth || depth } as Flat<BaseDoc>;
            if (typeof value !== 'string' && !isTranslated(value)) {
              for (const nestedSection of Object.entries(value)
                .filter(([, value]) => typeof value !== 'string' && !isTranslated(value))
                .sort(([keyA], [keyB]) => keyA < keyB ? -1 : 1)
              ) {
                yield *iterate(nestedSection as Flat<[string, string | ConfigSection]>, actualPath, depth + 1);
              }
            }
          }

          for (const section of Object.entries(state.config).sort(([keyA], [keyB]) => {
            if (keyA === 'general') {
              return -1;
            }
            if (keyB === 'general') {
              return 1;
            }
            return keyA < keyB ? -1 : 1;
          })) {
            yield *iterate(section as Flat<[string, string | ConfigSection]>);
          }
        }

        return () => {
          const sections = [];
          for (const doc of flatSections()) {
            sections.push(doc);
          }
          return sections as Flat<ConfigSection>[];
        }
      }
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const { config, comments } = await json<{ config: Config, comments: Comments }>('/api/store/config', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.config = config;
          this.comments = comments;
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reloadConfig() {
        this.loading++;
        try {
          const config = await json<Config>('/api/store/config', {
            method: 'POST',
            json: {
              action: 'load-config',
            }
          });
          this.config = config;
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reloadComments() {
        this.loading++;
        try {
          const comments = await json<Comments>('/api/store/config', {
            method: 'POST',
            json: {
              action: 'load-comments',
            }
          });
          this.comments = comments;
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting config.
       * The config will automatically be saved in the database and updated in the store.
       * @param config The config.
       */
      async update(config: Config) {
        this.loading++;
        try {
          const { config: updatedConfig, comments: updatedComments } = await json<{ config: Config, comments: Comments }>('/api/store/config', {
            method: 'POST',
            json: {
              action: 'update',
              config,
            }
          });

          if (updatedConfig && updatedComments) {
            this.config = updatedConfig;
            this.comments = updatedComments;
            return {
              success: true,
              data: {
                config: updatedConfig,
                comments: updatedComments
              },
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  store.load();

  // Return the store reference
  return store;
};