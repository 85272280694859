import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex gap-md pb-md" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex m--sm" }
const _hoisted_4 = ["onClick", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_button_group = _resolveComponent("bp-button-group")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_popover = _resolveComponent("bp-popover")!
  const _component_bp_wysiwyg_editor_color_picker = _resolveComponent("bp-wysiwyg-editor-color-picker")!
  const _component_translate = _resolveComponent("translate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock("div", {
        class: "bp-wysiwyg-editor-actions",
        key: _ctx.currentLanguage
      }, [
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'rotate-left', color: 'var(--color-white)' },
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editor.chain().focus().undo().run()))
            }, null, 512), [
              [_directive_tooltip, _ctx.$gettext('Undo')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'rotate-right', color: 'var(--color-white)' },
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editor.chain().focus().redo().run()))
            }, null, 512), [
              [_directive_tooltip, _ctx.$gettext('Redo')]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_bp_icon, {
          class: "bp-wysiwyg-editor-actions__separator",
          icon: "horizontal-rule",
          transform: "rotate-90"
        }),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'bold', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('bold'),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editor.chain().focus().toggleBold().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Bold')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'italic', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('italic'),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editor.chain().focus().toggleItalic().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Italic')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'underline', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('underline'),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editor.chain().focus().toggleUnderline().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Underline')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'strikethrough', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('strike'),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.editor.chain().focus().toggleStrike().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Strikethrough')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'subscript', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('subscript'),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editor.chain().focus().toggleSubscript().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Subscript')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'superscript', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('superscript'),
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editor.chain().focus().toggleSuperscript().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Superscript')]
            ])
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_bp_button, {
          icon: { icon: 'code', color: 'var(--color-white)' },
          active: _ctx.editor.isActive('code'),
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.editor.chain().focus().toggleCode().run()))
        }, null, 8, ["active"]), [
          [_directive_tooltip, _ctx.$gettext('Code')]
        ]),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_bp_button, {
              disabled: _ctx.editor.isActive('orderedList') || _ctx.editor.isActive('bulletList'),
              icon: { icon: 'quote-left', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('blockquote'),
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editor.chain().focus().toggleBlockquote().run()))
            }, null, 8, ["disabled", "active"]), [
              [_directive_tooltip, _ctx.$gettext('Blockquote')]
            ]),
            (_ctx.allowAlerts)
              ? (_openBlock(), _createBlock(_component_bp_popover, {
                  key: 0,
                  "model-value": _ctx.alertVisible,
                  "onUpdate:modelValue": _ctx.hideAlert,
                  data: _ctx.alertActions,
                  "search-property": "name",
                  onClickOption: _ctx.hideAlert
                }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_bp_button, {
                      disabled: _ctx.editor.isActive('orderedList') || _ctx.editor.isActive('bulletList'),
                      icon: _ctx.alertIcon('var(--color-white)'),
                      onClick: _cache[10] || (_cache[10] = ($event: any) => ((_ctx.alertVisible = !_ctx.alertVisible)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_bp_icon, {
                          class: "bp-wysiwyg-editor-actions__dropdown-icon",
                          icon: { icon: 'angle-right', color: 'var(--color-white)' },
                          disabled: _ctx.editor.isActive('orderedList') || _ctx.editor.isActive('bulletList')
                        }, null, 8, ["disabled"])
                      ]),
                      _: 1
                    }, 8, ["disabled", "icon"])), [
                      [_directive_tooltip, _ctx.$gettext('Alerts')]
                    ])
                  ]),
                  _: 1
                }, 8, ["model-value", "onUpdate:modelValue", "data", "onClickOption"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_bp_button, {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setTextColor(_ctx.textColor))),
              key: _ctx.textColor,
              icon: _ctx.colorPreviewIcon('font', _ctx.textColor)
            }, null, 8, ["icon"])), [
              [_directive_tooltip, _ctx.$gettext('Apply text color')]
            ]),
            _createVNode(_component_bp_popover, {
              "model-value": _ctx.textColorPickerVisible,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.textColorPickerVisible = !_ctx.textColorPickerVisible))
            }, {
              floating: _withCtx(() => [
                _createVNode(_component_bp_wysiwyg_editor_color_picker, {
                  class: "bp-wysiwyg-editor-actions__color-picker",
                  modelValue: _ctx.textColor,
                  "onUpdate:modelValue": [
                    _cache[13] || (_cache[13] = ($event: any) => ((_ctx.textColor) = $event)),
                    _ctx.setTextColor
                  ]
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_bp_button, {
                  icon: { icon: 'angle-right', color: 'var(--color-white)' },
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.textColorPickerVisible = !_ctx.textColorPickerVisible))
                }, null, 512), [
                  [_directive_tooltip, _ctx.$gettext('Select new text color')]
                ])
              ]),
              _: 1
            }, 8, ["model-value"])
          ]),
          _: 1
        }),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_bp_button, {
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setHighlightColor(_ctx.highlightColor))),
              key: _ctx.highlightColor,
              icon: _ctx.colorPreviewIcon('highlighter', _ctx.highlightColor)
            }, null, 8, ["icon"])), [
              [_directive_tooltip, _ctx.$gettext('Apply highlight color')]
            ]),
            _createVNode(_component_bp_popover, {
              "model-value": _ctx.highlightColorPickerVisible,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.highlightColorPickerVisible = !_ctx.highlightColorPickerVisible))
            }, {
              floating: _withCtx(() => [
                _createVNode(_component_bp_wysiwyg_editor_color_picker, {
                  class: "bp-wysiwyg-editor-actions__color-picker",
                  modelValue: _ctx.highlightColor,
                  "onUpdate:modelValue": [
                    _cache[17] || (_cache[17] = ($event: any) => ((_ctx.highlightColor) = $event)),
                    _ctx.setHighlightColor
                  ]
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_bp_button, {
                  icon: { icon: 'angle-right', color: 'var(--color-white)' },
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.highlightColorPickerVisible = !_ctx.highlightColorPickerVisible))
                }, null, 512), [
                  [_directive_tooltip, _ctx.$gettext('Select new highlight color')]
                ])
              ]),
              _: 1
            }, 8, ["model-value"])
          ]),
          _: 1
        }),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_bp_button, {
              active: _ctx.isActiveLink('link'),
              icon: { icon: 'link', color: 'var(--color-white)' },
              onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.addLink()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Insert link')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              disabled: !_ctx.editor.getAttributes('link').href,
              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.editor.chain().focus().unsetLink().run())),
              icon: _ctx.slashIcon('link', 'var(--color-white)')
            }, null, 8, ["disabled", "icon"]), [
              [_directive_tooltip, _ctx.$gettext('Remove link')]
            ])
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_bp_button, {
          onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.editor.chain().focus().unsetAllMarks().run())),
          icon: _ctx.slashIcon('brush', 'var(--color-white)')
        }, null, 8, ["icon"]), [
          [_directive_tooltip, _ctx.$gettext('Remove all styles')]
        ]),
        _createVNode(_component_bp_icon, {
          class: "bp-wysiwyg-editor-actions__separator",
          icon: "horizontal-rule",
          transform: "rotate-90"
        }),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textAlignOptions, (textAlign) => {
              return _withDirectives((_openBlock(), _createBlock(_component_bp_button, {
                key: textAlign._id,
                icon: { icon: textAlign.icon, color: 'var(--color-white)' },
                active: _ctx.editor.isActive({ textAlign: textAlign._id }),
                onClick: ($event: any) => (
          _ctx.editor.isActive({ textAlign: textAlign._id })
            ? _ctx.editor.chain().focus().unsetTextAlign().run()
            : _ctx.editor.chain().focus().setTextAlign(textAlign._id).run())
              }, null, 8, ["icon", "active", "onClick"])), [
                [_directive_tooltip, textAlign.name]
              ])
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_bp_icon, {
          class: "bp-wysiwyg-editor-actions__separator",
          icon: "horizontal-rule",
          transform: "rotate-90"
        }),
        _createVNode(_component_bp_popover, {
          "model-value": _ctx.tableVisible,
          "onUpdate:modelValue": _ctx.hideTable,
          "max-width": `${_ctx.tablePopoverWidth}px`,
          "min-width": `${_ctx.tablePopoverWidth}px`,
          data: _ctx.tableActions,
          "search-property": "name",
          onClickOption: _ctx.hideTable
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_bp_icon, {
                style: {"color":"var(--theme-text-disabled)"},
                icon: [
            {
              icon: 'table',
              opacity: 0.4,
              color: 'green',
            },
            {
              icon: 'plus',
              transform: 'shrink-8 right-13',
              color: 'green',
            },
          ]
              }),
              _createElementVNode("span", null, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Insert table")
                  ])),
                  _: 1
                }),
                (_ctx.colHoverIndex >= 0 && _ctx.rowHoverIndex >= 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, " " + _toDisplayString(`(${_ctx.colHoverIndex + 1} × ${_ctx.rowHoverIndex + 1})`), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, colIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: col,
                  class: "flex-column"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: row,
                      class: _normalizeClass(['bp-wysiwyg-editor-actions__table-insert-cell', {
                'bp-wysiwyg-editor-actions__table-insert-cell--hover': colIndex <= _ctx.colHoverIndex && rowIndex <= _ctx.rowHoverIndex,
              }]),
                      onClick: ($event: any) => (_ctx.insertTable(colIndex + 1, rowIndex + 1)),
                      onMouseenter: ($event: any) => (_ctx.setHoverIndex(colIndex, rowIndex)),
                      onMouseleave: _cache[23] || (_cache[23] = ($event: any) => (_ctx.resetHoverIndex()))
                    }, null, 42, _hoisted_4))
                  }), 128))
                ]))
              }), 128))
            ])
          ]),
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_bp_button, {
              icon: { icon: 'table', color: 'var(--color-white)' },
              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.tableVisible = !_ctx.tableVisible))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bp_icon, {
                  class: "bp-wysiwyg-editor-actions__dropdown-icon",
                  icon: { icon: 'angle-right', color: 'var(--color-white)' }
                })
              ]),
              _: 1
            })), [
              [_directive_tooltip, _ctx.$gettext('Tables')]
            ])
          ]),
          _: 1
        }, 8, ["model-value", "onUpdate:modelValue", "max-width", "min-width", "data", "onClickOption"]),
        _createVNode(_component_bp_icon, {
          class: "bp-wysiwyg-editor-actions__separator",
          icon: "horizontal-rule",
          transform: "rotate-90"
        }),
        _createVNode(_component_bp_button_group, null, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'list-ol', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('orderedList'),
              onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.editor.chain().focus().toggleOrderedList().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Toggle ordered list')]
            ]),
            _withDirectives(_createVNode(_component_bp_button, {
              icon: { icon: 'list-ul', color: 'var(--color-white)' },
              active: _ctx.editor.isActive('bulletList'),
              onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.editor.chain().focus().toggleBulletList().run()))
            }, null, 8, ["active"]), [
              [_directive_tooltip, _ctx.$gettext('Toggle bullet list')]
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}