import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.condition && _ctx.tooltip)
    ? _withDirectives((_openBlock(), _createElementBlock("span", _mergeProps({
        key: 0,
        class: "bp-tooltip__wrapper"
      }, _ctx.$attrs, {
        key: JSON.stringify(_ctx.tooltip)
      }), [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), undefined, true)
      ], 16)), [
        [_directive_tooltip, _ctx.tooltip]
      ])
    : _renderSlot(_ctx.$slots, "default", _normalizeProps(_mergeProps({ key: 1 }, _ctx.$attrs)), undefined, true)
}