import { arrow, autoUpdate, computePosition, flip, Middleware, offset, shift, size } from '@floating-ui/dom';
import { ref } from 'vue';

/**
 * Floating Opts.
 */
export interface FloatingOpts {
  // Floating UI
  arrow: boolean;
  flip: boolean;
  matchReferenceSize: boolean;
  offset: number | [number, number]
  placement: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end';
  // Duration
  duration: number;
  // Text
  text?: string;
  // Interactive
  interactive?: boolean;
  // Elements
  referenceEl?: HTMLElement;
  floatingEl?: HTMLElement;
  arrowEl?: HTMLElement;
}

/**
 * Default floating opts.
 */
export const defaultFloatingOpts: FloatingOpts = {
  arrow: true,
  flip: false,
  matchReferenceSize: false,
  offset: 0,
  placement: 'top',
  duration: 0,
  interactive: false,
}

const cleanup = ref();

///-------------------------------------------------------------------
/// TIMER
///-------------------------------------------------------------------

const timer = ref(NaN);

export function startTimer(opts: Partial<FloatingOpts>) {
  const floatingOpts = { ...defaultFloatingOpts, ...opts };
  timer.value = setTimeout(hide, floatingOpts.duration);
}

export function stopTimer() {
  if (!isNaN(timer.value)) {
    clearTimeout(timer.value);
    timer.value = NaN;
  }
}

///-------------------------------------------------------------------
/// SHOW
///-------------------------------------------------------------------

export function show(opts: Partial<FloatingOpts>) {
  // HIDE OLD FLOATING ELEMENTS
  hide(opts);

  // OPTS
  const floatingOpts = { ...defaultFloatingOpts, ...opts };
  if (!floatingOpts.text && !floatingOpts.floatingEl) { return }

  // REFERENCE & FLOATING ELEMENTS
  const reference = floatingOpts.referenceEl;
  const floating = floatingOpts.floatingEl ?? document.getElementById('bp-tooltip');
  if (!reference || !floating) { return }

  // TOOLTIP TEXT & ARROW ELEMENTS
  const textEl = floatingOpts.floatingEl ? null : document.getElementById('bp-tooltip__text');
  const arrowEl = floatingOpts.floatingEl && floatingOpts.arrowEl ? floatingOpts.arrowEl : document.getElementById('bp-tooltip__arrow');
  if (textEl) {
    textEl.innerHTML = floatingOpts.text || '';
  }

  // PADDING
  const padding = {
    top: (() => {
      const layout = document.getElementById('bp-layout');
      if (!layout) { return 16 }
      return parseFloat(window.getComputedStyle(layout).getPropertyValue('--portal-header-height'));
    })(),
    right: 16,
    bottom: 16,
    left: 16,
  }

  // FLIPPED
  const detectFlip = (): Middleware => ({
    name: 'detectFlip',
    fn(args) {
      if (floatingOpts.floatingEl) {
        // TODO: flipped: true/false -> flipped: 'none'/'horizontal'/'vertical'/'both'
        // const [placement, placementModifier] = args.placement.split('-');
        // const [initialPlacement, initialPlacementModifier] = args.initialPlacement.split('-');
        // if (['top', 'bottom'].includes(initialPlacement) && initialPlacement !== placement) {
        //   if (initialPlacementModifier !== placementModifier) {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'both');
        //   } else {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'vertical');
        //   }
        // } else if (['top', 'bottom'].includes(initialPlacement) && initialPlacement === placement) {
        //   if (initialPlacementModifier !== placementModifier) {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'horizontal');
        //   } else {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'none');
        //   }
        // } else if (['left', 'right'].includes(initialPlacement) && initialPlacement !== placement) {
        //   if (initialPlacementModifier !== placementModifier) {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'both');
        //   } else {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'horizontal');
        //   }
        // } else if (['left', 'right'].includes(initialPlacement) && initialPlacement === placement) {
        //   if (initialPlacementModifier !== placementModifier) {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'vertical');
        //   } else {
        //     floatingOpts.floatingEl.setAttribute('data-flipped', 'none');
        //   }
        // }
        floatingOpts.floatingEl.setAttribute('data-flipped', '' + (args.initialPlacement !== args.placement));
      }
      return {}
    }
  });

  // FLOATING UI API
  cleanup.value = autoUpdate(reference, floating, () => {
    computePosition(reference, floating, {
      placement: floatingOpts.placement,
      middleware: [
        // FLIP
        flip({
          mainAxis: floatingOpts.flip,
          crossAxis: floatingOpts.flip,
          padding: padding
        }),

        // OFFSET
        offset({
          mainAxis: Array.isArray(floatingOpts.offset)
            ? floatingOpts.offset[0] + (!floatingOpts.floatingEl && floatingOpts.arrow ? 12 : 0)
            : floatingOpts.offset + (!floatingOpts.floatingEl && floatingOpts.arrow ? 12 : 0),
          crossAxis: Array.isArray(floatingOpts.offset)
            ? floatingOpts.offset[1]
            : floatingOpts.offset,
        }),

        // SHIFT
        shift({ padding: padding }),

        // SIZE
        size({
          apply({availableHeight, elements}) {
            elements.floating.style.setProperty('--floating-max-height', `${availableHeight}px`);
          },
          padding: padding,
        }),

        // ARROW
        ...(floatingOpts.arrow && arrowEl ? [arrow({
          element: arrowEl
        })] : []),

        // DETECT FLIP
        detectFlip(),
      ],
    }).then(({x, y, middlewareData}) => {
      if (floatingOpts.matchReferenceSize) {
        const referenceRect = reference.getBoundingClientRect();
        Object.assign(floating.style, {
          left: `${referenceRect.x}px`,
          top: `${y}px`,
          width: `${referenceRect.width}px`
        });
      } else {
        Object.assign(floating.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      }

      // ARROW
      if (floatingOpts.arrow && arrowEl && middlewareData.arrow) {
        const {x: arrowX, y: arrowY} = middlewareData.arrow;

        Object.assign(arrowEl.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
        });
      }
    });
  });

  // TOOLTIP STYLE
  if (!floatingOpts.floatingEl && floating) {
    floating.style.display = 'block';
  }
}

///-------------------------------------------------------------------
/// HIDE
///-------------------------------------------------------------------

export function hide(opts: Partial<FloatingOpts>) {
  // OPTS
  const floatingOpts = { ...defaultFloatingOpts, ...opts };

  // TIMER
  stopTimer();

  // FLOATING UI API
  if (cleanup.value) {
    cleanup.value();
    cleanup.value = undefined;
  }

  // TOOLTIP STYLE
  if (!floatingOpts.floatingEl) {
    const floating = document.getElementById('bp-tooltip');
    if (!floating) { return }
    floating.style.display = 'none';
  }
}