import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';
import type { Editor } from '@tiptap/vue-3';

type ColorOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    color: {
      /**
       * Set the color
       */
      setColor: (color: string) => ReturnType;
      /**
       * Unset the color
       */
      unsetColor: () => ReturnType;
    };
  }
}

const Color = Extension.create<ColorOptions>({
  name: 'color',

  addOptions() {
    return {
      ...this.parent?.(),
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: `color: ${attributes.color}`,
              };
            },
            parseHTML: (element) => ({
              color: element.style.color,
            }),
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setColor:
        (color) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { color }).run();
        },
      unsetColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { color: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export function getColor(editor: Editor): string | null {
  return editor.getAttributes('textStyle').color || null;
}

export default Color;
