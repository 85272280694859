import { defaultFloatingOpts, FloatingOpts, show, startTimer, stopTimer } from './floating';
import { Directive, DirectiveBinding } from 'vue';

///-------------------------------------------------------------------
/// DIRECTIVE
///-------------------------------------------------------------------

export type Tooltip = ((el: HTMLElement) => Partial<FloatingOpts> | undefined) | Partial<FloatingOpts> | string | undefined;

export const tooltip: Directive = {
  mounted: (el, bindings: DirectiveBinding<Tooltip>) => {
    const floatingOpts: Partial<FloatingOpts> = typeof bindings.value === 'function'
      ? bindings.value(el) ?? {}
      : typeof bindings.value === 'object'
        ? bindings.value
        : { text: bindings.value }
    if (!floatingOpts.text) { return }
    const opts: FloatingOpts = {
      ...defaultFloatingOpts,
      ...floatingOpts,
      referenceEl: el,
    }

    const tooltipEl = document.getElementById('bp-tooltip');
    if (!tooltipEl) { return }

    addListeners(el, ['mouseover', 'touchstart'], () => enterReference(tooltipEl, opts));
    addListeners(el, ['mouseleave', 'touchend', opts.interactive ? '' : 'mouseup'], () => leaveReference(tooltipEl, opts));

    if (opts.interactive) {
      addListeners(tooltipEl, ['mouseover', 'touchstart'], () => enterFloating(tooltipEl, opts));
      addListeners(tooltipEl, ['mouseleave', 'touchend', opts.interactive ? '' : 'mouseup'], () => leaveFloating(tooltipEl, opts));
    }
  },
  unmounted: (el, bindings: DirectiveBinding<Tooltip>) => {
    const floatingOpts: Partial<FloatingOpts> = typeof bindings.value === 'function'
      ? bindings.value(el) ?? {}
      : typeof bindings.value === 'object'
        ? bindings.value
        : { text: bindings.value }
    if (!floatingOpts.text) { return }
    const opts: FloatingOpts = {
      ...defaultFloatingOpts,
      ...floatingOpts,
      referenceEl: el,
    }

    const tooltipEl = document.getElementById('bp-tooltip');
    if (!tooltipEl) { return }

    removeListeners(el, ['mouseover', 'touchstart'], () => enterReference(tooltipEl, opts));
    removeListeners(el, ['mouseleave', 'touchend', opts.interactive ? '' : 'mouseup'], () => leaveReference(tooltipEl, opts));

    if (opts.interactive) {
      removeListeners(tooltipEl, ['mouseover', 'touchstart'], () => enterFloating(tooltipEl, opts));
      removeListeners(tooltipEl, ['mouseleave', 'touchend', opts.interactive ? '' : 'mouseup'], () => leaveFloating(tooltipEl, opts));
    }
  }
}

///-------------------------------------------------------------------
/// CALLBACK FUNCTIONS
///-------------------------------------------------------------------

function enterReference(tooltipEl: HTMLElement, opts: Partial<FloatingOpts>) {
  tooltipEl.style.pointerEvents = opts.interactive ? '' : 'none';
  show(opts);
}

function leaveReference(tooltipEl: HTMLElement, opts: Partial<FloatingOpts>) {
  tooltipEl.style.pointerEvents = opts.interactive ? '' : 'none';
  startTimer(opts);
}

function enterFloating(tooltipEl: HTMLElement, opts: Partial<FloatingOpts>) {
  tooltipEl.style.pointerEvents = opts.interactive ? '' : 'none';
  stopTimer();
}

function leaveFloating(tooltipEl: HTMLElement, opts: Partial<FloatingOpts>) {
  tooltipEl.style.pointerEvents = opts.interactive ? '' : 'none';
  startTimer(opts);
}

///-------------------------------------------------------------------
/// LISTENERS
///-------------------------------------------------------------------

function addListeners(el: HTMLElement, events: string[], callbackfn: () => void) {
  events.filter(event => event).forEach(event => {
    el.addEventListener(event, (evt: Event) => {
      evt.stopImmediatePropagation();
      callbackfn();
    }, { passive: true });
  })
}

function removeListeners(el: HTMLElement, events: string[], callbackfn: () => void) {
  events.filter(event => event).forEach(event => {
    el.removeEventListener(event, (evt: Event) => {
      evt.stopImmediatePropagation();
      callbackfn();
    });
  })
}