import { ajax, defaultInit } from '@sahnee/ajax';
import { App, computed } from 'vue';
import { po2json } from '@sahnee/po';
import Gettext, { $gettext, $ngettext, plugin } from 'vue-gettext';
import { reloadStores } from './utils/store';

export type TranslatedKey = 'de' | 'en';
export type Translated<T> = Partial<Record<TranslatedKey, T>>;

/**
 * Checks if any given object is of type `Translated`, meaning the only keys in the object are language codes.
 * @param obj Any object.
 * @returns Whether the object is a valid `Translated` object or not.
 */
export function isTranslated<T>(obj: unknown): obj is Translated<T> {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return false;
  }
  return !Object.keys(obj)
    .map(lang => Object.keys(availableLanguages).map(l => getTranslatedKey(l) as string).includes(lang.toLowerCase()))
    .includes(false);
}

/**
 * Removes the region information from the given language.
 * @param lang The language.
 * @returns The given language in ISO 639-1 form, a two-letter lowercase abbreviation.
 */
export function getTranslatedKey(lang: string) {
  return lang.substring(0, 2).toLowerCase() as TranslatedKey;
}

/**
 * Returns the translation for the currently active language.
 * @param obj The translated object.
 * @returns The translation.
 */
export function getTranslated(obj: unknown) {
  if (isTranslated(obj)) {
    const translated = obj[currentLanguageISOString()];
    if (translated === undefined || translated === null) {
      return '';
    }
    if (['boolean', 'object'].includes(typeof translated)) {
      return JSON.stringify(translated);
    }
    return typeof translated !== 'string' ? translated.toString() : translated;
  }
  return '';
}

/**
 * Converts a language code to a string containing a fitting flag emoji and its name.
 * @param lang The language.
 * @returns A fitting language flag emoji and the name of the language.
 */
export function getTranslatedName(lang: string) {
  switch (getTranslatedKey(lang)) {
    case 'de': { return '🇩🇪 ' + $gettext('German') }
    case 'en': { return '🇺🇸 ' + $gettext('English') }
    default: { return '❓ ' + lang }
  }
}

/**
 * Generates an empty default object of all available languages as keys.
 * @returns An object containing all avilable languages as keys, mapped to an empty string.
 */
export function defaultTranslated() {
  return Object.fromEntries(Object.keys(availableLanguages).map(lang => [getTranslatedKey(lang), '']));
}

async function loadTranslation(lang: string) {
  const response = await ajax(`/lang/${lang}/LC_MESSAGES/default.po`, {
    method: 'GET'
  });
  const poFile = await response.text();
  const poJson = po2json(poFile);
  // console.log('Translation for "' + lang + '":', poJson)
  return poJson;
}

/**
 * The currently active language.
 */
export const currentLanguage = computed({
  get: () => plugin ? plugin.current : Object.keys(availableLanguages)[0],
  set: (lang: string) => setLanguage(lang)
});

/**
 * Removes the region information from the currently active language.
 * @returns The current language in ISO 639-1 form, a two-letter lowercase abbreviation.
 */
export function currentLanguageISOString() {
  return getTranslatedKey(currentLanguage.value);
}

/**
 * The available languages
 */
export const availableLanguages = {
  'en_US': 'English',
  'de': 'German',
};

function setLanguage(lang: string) {
  if (!plugin || plugin.current === lang) {
    return;
  }
  localStorage.setItem('bp:lang', lang);
  document.documentElement.setAttribute('lang', lang);
  plugin.current = lang;
  updateLanguageHeader(lang);
  reloadStores();
}

function detectPreferredLanguage() {
  if (navigator.language.toLowerCase().includes('de')) {
    return 'de';
  }
  return 'en_US';
}

export default async function registerTranslation(app: App): Promise<App> {
  const savedLanguage = localStorage.getItem('bp:lang') || detectPreferredLanguage();
  updateLanguageHeader(savedLanguage);
  document.documentElement.setAttribute('lang', savedLanguage);
  const de = await loadTranslation('de');
  app = app.use(Gettext, {
    availableLanguages,
    defaultLanguage: savedLanguage,
    translations: {
      de: de.messages
    },
    silent: true
  });
  return app;
}

function updateLanguageHeader(lg: string) {
  defaultInit.headers['Accept-Language'] = lg;
  document.cookie = 'lang=' + lg + '; path=/';
}

export function getTranslatedPlural(msgid: string, n: number) {
  switch (msgid) {
    case 'customer': case 'customers': return $ngettext('customer', 'customers', n);
    case 'contact': case 'contacts': return $ngettext('contact', 'contacts', n);
    case 'user': case 'users': return $ngettext('user', 'users', n);
    case 'product': case 'products': return $ngettext('product', 'products', n);
    case 'component': case 'components': return $ngettext('component', 'components', n);
    case 'versionNumber': case 'versionNumbers': return $ngettext('version number', 'version numbers', n);
    case 'folder': case 'folders': return $ngettext('folder', 'folders', n);
    case 'file': case 'files': return $ngettext('file', 'files', n);
    case 'category': case 'categories': return $ngettext('category', 'categories', n);
    case 'recording': case 'recordings': return $ngettext('recording', 'recordings', n);
    case 'webinar': case 'webinars': return $ngettext('webinar', 'webinars', n);
    case 'chat': case 'chats': return $ngettext('chat', 'chats', n);
    default: return '';
  }
}
