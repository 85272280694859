import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bp-video-preview__date" }
const _hoisted_2 = { class: "bp-video-preview__time" }
const _hoisted_3 = {
  key: 1,
  class: "bp-video-preview__description-wrapper"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "bp-video-preview__button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.preview)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['bp-video-preview', {
    'bp-video-preview--compact': _ctx.compact,
    'bp-video-preview--compact-mode': _ctx.compactMode,
    'bp-video-preview--hide-button': _ctx.hideButton,
    'bp-video-preview--hide-description': _ctx.hideDescription,
    'bp-video-preview--hide-icon': _ctx.hideIcon,
  }])
      }, [
        (!_ctx.hideWebinarStatus && _ctx.isWebinar(_ctx.preview))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.preview.webinar.active && new Date(_ctx.preview.webinar.date).getTime() > new Date().getTime())
                ? (_openBlock(), _createBlock(_component_translate, {
                    key: 0,
                    tag: "p"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("The following webinar will start shortly:")
                    ])),
                    _: 1
                  }))
                : (_ctx.preview.webinar.active)
                  ? (_openBlock(), _createBlock(_component_translate, {
                      key: 1,
                      tag: "p"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("The following webinar is currently taking place:")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_translate, {
                      key: 2,
                      tag: "p"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("The following webinar will take place in the near future:")
                      ])),
                      _: 1
                    }))
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(['bp-video-preview__wrapper', {
      'bp-video-preview__wrapper--webinar': _ctx.isWebinar(_ctx.preview),
    }])
        }, [
          (!_ctx.hideIcon)
            ? (_openBlock(), _createBlock(_component_bp_icon, {
                key: 0,
                class: "bp-video-preview__icon",
                icon: _ctx.isWebinar(_ctx.preview) ? 'video' : 'circle-play'
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.compact ? 'strong' : 'h3'), {
            class: _normalizeClass(['bp-video-preview__name', { 'bp-video-preview__name--compact': _ctx.compact }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTranslated(_ctx.preview.name)), 1)
            ]),
            _: 1
          }, 8, ["class"])),
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_bp_icon, { icon: ['far', 'calendar'] }),
            _createTextVNode(_toDisplayString(_ctx.localizeDate((_ctx.isWebinar(_ctx.preview) ? _ctx.preview.webinar.date : _ctx.preview.date) || '')), 1)
          ]),
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_bp_icon, { icon: ['far', 'clock'] }),
            _createTextVNode(_toDisplayString(_ctx.localizeTime((_ctx.isWebinar(_ctx.preview) ? _ctx.preview.webinar.date : _ctx.preview.date) || '')), 1)
          ]),
          (!_ctx.hideDescription)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                (_ctx.getTranslated(_ctx.preview.description))
                  ? (_openBlock(), _createElementBlock("em", {
                      key: 0,
                      class: _normalizeClass(['bp-video-preview__description', {
            'bp-video-preview__description--dense': _ctx.isSafari() && !_ctx.isExpanded,
            'bp-video-preview__description--expanded': _ctx.isExpanded,
          }]),
                      ref: "descriptionEl",
                      innerHTML: _ctx.getTranslated(_ctx.preview.description)
                    }, null, 10, _hoisted_4))
                  : _createCommentVNode("", true),
                (_ctx.expandible)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: "bp-video-preview__description-read-more",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
                    }, [
                      (_ctx.isExpanded)
                        ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Show less")
                            ])),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("Show more")
                            ])),
                            _: 1
                          })),
                      _createVNode(_component_bp_icon, {
                        class: "ml-sm",
                        icon: _ctx.isExpanded ? 'arrow-up' : 'arrow-down'
                      }, null, 8, ["icon"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideButton)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                (_ctx.isWebinar(_ctx.preview))
                  ? (_openBlock(), _createBlock(_component_bp_tooltip, {
                      key: 0,
                      class: "bp-download-preview__button",
                      condition: _ctx.preview._disabled,
                      tooltip: _ctx.$gettext(`You don't have the permission to access the webinar`)
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_bp_button, {
                          color: "green",
                          icon: "right-to-bracket",
                          to: { name: 'video.play', params: { slug: _ctx.preview.slug }},
                          disabled: _ctx.preview._disabled
                        }, null, 8, ["to", "disabled"]), [
                          [_directive_tooltip, _ctx.$gettext('Participate')]
                        ])
                      ]),
                      _: 1
                    }, 8, ["condition", "tooltip"]))
                  : (_openBlock(), _createBlock(_component_bp_tooltip, {
                      key: 1,
                      condition: _ctx.preview._disabled,
                      tooltip: _ctx.$gettext(`You don't have a valid license to watch this video`)
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_bp_button, {
                          color: "green",
                          icon: "play",
                          to: { name: 'video.play', params: { slug: _ctx.preview.slug }},
                          disabled: _ctx.preview._disabled
                        }, null, 8, ["to", "disabled"]), [
                          [_directive_tooltip, _ctx.$gettext('Play video')]
                        ])
                      ]),
                      _: 1
                    }, 8, ["condition", "tooltip"]))
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2))
    : (!_ctx.hideWebinarStatus)
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 1,
          tag: "div"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("There is currently no further webinar scheduled.")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
}