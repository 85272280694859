import { ColorAlias } from '@/utils/color';
import { FontAwesomeIconProps, FontAwesomeLayersTextProps } from '@fortawesome/vue-fontawesome';
import { CSSProperties, PropType } from 'vue';

export type Icon = string | string[] | IconObject | IconObject[];
export type IconObject = Partial<FontAwesomeIconProps> & Partial<FontAwesomeLayersTextProps> & {
  color?: ColorAlias,
  opacity?: number,
  primaryColor?: ColorAlias,
  secondaryColor?: ColorAlias,
  primaryOpacity?: number,
  secondaryOpacity?: number,
  style?: CSSProperties;
  textStyle?: CSSProperties,
  transformMask?: string;
}

export const IconProp = [String, Array, Object] as PropType<string | string[] | IconObject | IconObject[]>;

export const customIcons = ['Cordaware', 'bestinformed', 'Infoclient', 'bestzero', 'Appsbox'];

///-------------------------------------------------------------------
/// CORDAWARE ICON
///-------------------------------------------------------------------

export function cordawareIcon(): Icon {
  return [
    {
      icon: 'square-full',
      opacity: 0.4,
      transform: 'rotate-48 shrink-4.7'
    },
    {
      icon: ['far', 'c'],
      transform: 'shrink-7.5 left-1.1 up-1.3'
    },
    {
      icon: ['far', 'o'],
      transform: 'shrink-11 right-4.45 down-0.25'
    }
  ];
}

///-------------------------------------------------------------------
/// SLASH ICON
///-------------------------------------------------------------------

/**
 * Adds a slash icon on top of the given icon. It will also add the font awesome typical empty space beneath the slash icon.
 * @param icon The icon.
 * @returns Icon layers which adds a slash icon on top of the given icon.
 */
export function slashIcon(icon: Icon, color?: ColorAlias | undefined): Icon {
  return [
    {
      icon: 'slash',
      mask: icon,
      transform: 'left-1.15 down-1',
      opacity: 0.4,
      color,
    },
    {
      icon: 'slash',
      color
    }
  ]
}

///-------------------------------------------------------------------
/// CALENDAR ICON
///-------------------------------------------------------------------

/**
 * Adds the current or given day on top of a calendar icon.
 * @param day The day.
 * @returns Icon layers which adds the given day on top of a calendar icon.
 */
export function calendarIcon(day: number | string = new Date().getDate()): Icon {
  if (typeof day === 'string' && !day) {
    return ['far', 'calendar'];
  }
  return [
    {
      icon: ['far', 'calendar'],
    },
    {
      value: typeof day === 'number' ? day.toString() : day,
      transform: 'shrink-8 down-2.4',
      textStyle: {
        fontFamily: 'Roboto Mono, monospace'
      },
    }
  ]
}

///-------------------------------------------------------------------
/// COLOR PREVIEW ICON
///-------------------------------------------------------------------

/**
 * Adds a colored bar below a given icon.
 * @param icon The duotone icon.
 * @param color The color.
 * @param primarySecondary Decide whether the primary or the secondary color of the given icon should be colored. Either `primary` or `secondary`, defaults to `primary`.
 * @returns The icon with a colored bar beneath it.
 */
export function colorPreviewIcon(icon: string, color: ColorAlias | string, primarySecondary: 'primary' | 'secondary' = 'primary'): Icon {
  return [
    {
      icon: ['fad', icon],
      primaryColor: primarySecondary === 'primary' ? color as ColorAlias : 'var(--color-white)',
      secondaryColor: primarySecondary === 'secondary' ? color as ColorAlias : 'var(--color-white)',
      secondaryOpacity: 1,
      transform: 'up-2.5'
    },
    {
      icon: 'hyphen',
      color: color as ColorAlias,
      transform: 'grow-8 down-8'
    }
  ];
}

///-------------------------------------------------------------------
/// COPY ICON
///-------------------------------------------------------------------

/**
 * Adds an icon into the copy icon.
 * @param icon The icon which will be displayed in the copy icon.
 * @returns The copy icon.
 */
export function copyIcon(icon: Icon) {
  return [
    {
      icon: ['far', 'copy'],
    },
    {
      icon: icon,
      secondaryOpacity: 0,
      transform: 'shrink-11 right-3 up-0.75',
    },
  ]
}

///-------------------------------------------------------------------
/// PASTE ICON
///-------------------------------------------------------------------

/**
 * Adds an icon into the paste icon.
 * @param icon The icon which will be displayed in the paste icon.
 * @returns The paste icon.
 */
export function pasteIcon(icon: Icon) {
  return [
    {
      icon: ['far', 'paste'],
    },
    {
      icon: icon,
      secondaryOpacity: 0,
      transform: 'shrink-10 right-3 down-3.25',
    },
  ]
}

///-------------------------------------------------------------------
/// FILE ICON
///-------------------------------------------------------------------

/**
 * Adds a file extension to a file icon.
 * @param fileExtension The file extension.
 * @returns The file icon.
 */
export function fileIcon(fileExtension: string): Icon {
  let icon: IconObject = {
    icon: 'rectangle-wide',
    mask: ['far', 'file-csv'],
    transform: 'shrink-4 down-5.5 right-2.5',
  };
  let text: IconObject = {
    value: fileExtension.toUpperCase(),
    textStyle: {
      fontFamily: '\'Roboto Mono\', monospace',
      fontSize: '0.425em',
      fontWeight: 900,
      translate: '0.375em 0.795em',
      pointerEvents: 'none',
    },
  };
  switch (fileExtension.toLowerCase()) {
    case 'zip':
      icon = {
        ...icon,
        mask: ['far', 'file-zipper'],
        transform: 'shrink-4 down-5.5 right-4.5',
        transformMask: 'left-2',
      };
      text = {
        ...text,
        textStyle: {
          ...text.textStyle,
          translate: '0.375em 0.795em',
        },
      };
      break;
    default:
      break;
  }
  return [
    icon,
    text,
  ]
}

///-------------------------------------------------------------------
/// SUBSCRIPT ICON
///-------------------------------------------------------------------

type Shape = 'badge' | 'circle' | 'diamond' | 'hexagon' | 'octagon' | 'seal' | 'shield' | 'square';

/**
 * TODO: Add documentation
 */
interface SubIconOpts {
  down: number;
  iconShrink: number;
  right: number;
  shapeColor: ColorAlias;
  shapeShrink: number;
}

const DEFAULT_SUB_ICON_OPTS: SubIconOpts = {
  down: 3.5,
  iconShrink: 7,
  right: 5.5,
  shapeColor: 'currentColor',
  shapeShrink: 5,
};

/**
 * Adds a given shape based icon in the bottom right corner of a given icon. (Subscript)
 * @param shape The shape which the given `shapeIcon` is based on.
 * @param shapeIcon The icon which should be added to the given `icon`.
 * @param icon The icon.
 * @param rawOpts The options, the transform values of the icon.
 * @returns The icon with the given shape based icon.
 */
export function subIcon(shape: Shape, shapeIcon: Icon, icon: Icon, rawOpts?: Partial<SubIconOpts>): Icon {
  const opts: SubIconOpts = {
    ...DEFAULT_SUB_ICON_OPTS,
    shapeShrink: shape === 'shield' ? 4.25 : DEFAULT_SUB_ICON_OPTS.shapeShrink,
    ...rawOpts
  };

  return [
    {
      icon: shape,
      mask: icon,
      transform: `right-${opts.right.toString()} down-${opts.down.toString()} shrink-${opts.shapeShrink.toString()}`,
    },
    ...(typeof shapeIcon === 'object' && !Array.isArray(shapeIcon) ? [{
      transform: `right-${opts.right.toString()} down-${opts.down.toString()} shrink-${opts.iconShrink.toString()}`,
      color: opts.shapeColor,
      ...shapeIcon,
    }] : [{
      icon: shapeIcon,
      transform: `right-${opts.right.toString()} down-${opts.down.toString()} shrink-${opts.iconShrink.toString()}`,
      color: opts.shapeColor,
    }])
  ]
}

///-------------------------------------------------------------------
/// SHAPE ICON VARIANTS
///-------------------------------------------------------------------

/**
 * Adds a plus icon to a given icon.
 * @param icon The icon.
 * @returns The icon with a plus icon.
 */
export function plusIcon(icon: Icon): Icon {
  return subIcon('circle', 'circle-plus', icon);
}

/**
 * Adds a minus icon to a given icon.
 * @param icon The icon.
 * @returns The icon with a minus icon.
 */
export function minusIcon(icon: Icon): Icon {
  return subIcon('circle', 'circle-minus', icon);
}

/**
 * Adds a trash icon to a given icon.
 * @param icon The icon.
 * @returns The icon with a trash icon.
 */
export function trashIcon(icon: Icon): Icon {
  return subIcon('circle', 'circle-trash', icon);
}

/**
 * Adds an xmark icon to a given icon.
 * @param icon The icon.
 * @returns The icon with an xmark icon.
 */
export function xmarkIcon(icon: Icon): Icon {
  return subIcon('circle', 'circle-xmark', icon);
}
