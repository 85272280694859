
import useCompactMode from '@/compositions/use-compact-mode';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'bp-banner',
  props: {
    modelValue: Boolean,
    closable: Boolean,
    delay: {
      type: Number,
      default: 0,
    },
    position: {
      type: String as PropType<'top' | 'bottom'>,
      default: 'bottom',
    },
  },
  emits: [
    'update:model-value'
  ],
  setup(props) {
    ///-------------------------------------------------------------------
    /// BANNER
    ///-------------------------------------------------------------------
    
    const visible = ref(false)
    window.setTimeout(() => visible.value = true, props.delay);

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------
    
    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      visible,
    }
  }
});
