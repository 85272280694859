
import { $gettext } from 'vue-gettext';
import { ColorProp, cssColor, extractShade, Modifier } from '@/utils/color';
import { computed, CSSProperties, defineComponent, nextTick, onActivated, onDeactivated, onMounted, onUnmounted, PropType, ref, watch, watchEffect } from 'vue';
import { Flat, flatten, Tree } from '@/utils/object';
import { Group, Tab } from './BpCard';
import { IconProp } from '../icon/BpIcon';
import { useRouter } from 'vue-router';
import clone from '@sahnee/clone';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'BpCard',
  props: {
    // TODO: Add property "areas" and allow an array of strings which define sections like the "css grid areas" - this is a replacement for the "tabs-position" logic - maybe add "rows" & "columns" with a size definition too
    // e.g. { areas: [['video', 'chat'], ['video', 'message-input']], rows: [1, 0], columns: [3, 1] }
    // There will then be 3 slots to put content into: 'area-video', 'area-chat' & 'area-message-input'
    // banner: { type: Boolean, default: false },
    activeTab: String,
    borderless: Boolean,
    borderWidth: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
    },
    color: {
      type: ColorProp,
      default: 'light-blue'
    },
    fillHeight: Boolean,
    fillWidth: Boolean,
    large: Boolean,
    largeHeader: Boolean,
    largeContent: Boolean,
    largeFooter: Boolean,
    loading: Boolean,
    maxHeight: String,
    maxWidth: String,
    ribbon: String,
    ribbonColor: {
      type: ColorProp,
      default: 'light-blue'
    },
    ribbonIcon: IconProp,
    seamless: Boolean,
    seamlessHeader: Boolean,
    seamlessContent: Boolean,
    seamlessFooter: Boolean,
    seamlessTabs: Boolean,
    tabs: Array as PropType<Tree<Tab>[] | Flat<Tab>[] | Flat<Tree<Tab>>[] | Group<Tree<Tab>>[] | Group<Flat<Tab>>[] | Group<Flat<Tree<Tab>>>[]>,
    tabsPosition: {
      type: String as PropType<'top' | 'inside' | 'inside-reverse'>,
      default: 'top',
    },
    opacity: {
      type: Number,
      default: 0.15,
    },
    opaque: Boolean,
  },
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// TABS
    ///-------------------------------------------------------------------

    const activeTabId = ref<string | undefined>(undefined);

    watch(() => [props.tabs, props.activeTab], () => {
      activeTabId.value = props.tabs && props.tabs.length > 0 ? props.activeTab || (router.currentRoute.value.query['tab'] as string || props.tabs[0]._id) : undefined;
    }, { immediate: true });

    watch(() => activeTabId.value, () => {
      const routerQuery = clone(router.currentRoute.value.query);
      const tab = [...(props.tabs ?? [])].find(t => t._id === activeTabId.value);
      for (const param of (tab?._excludedQueryParams || [])) {
        delete routerQuery[param];
      }
      const query = Object.entries({ ...routerQuery, tab: activeTabId.value }).map(([key, val]) => `${key}=${val}`).join('&');
      history.replaceState({}, '', `#${router.currentRoute.value.path}${query ? '?' + query : ''}`)
    })

    const flatTabs = computed(() => {
      if (!props.tabs) {
        return;
      }
      if (props.tabs.length > 0 && props.tabs.map(tab => !!tab._children).includes(true)) {
        return flatten(clone(props.tabs)) as Flat<Tree<Tab>>[];
      }
      // GROUPS
      const groups: string[] = Array.from(new Set(props.tabs.map(tab => tab._group).filter(group => group)));
      const groupedTabs = [];
      if (groups.length > 0) {
        const tabs = clone(props.tabs);
        for (const group of groups) {
          groupedTabs.push({ _id: `_group_${group}`, name: group, _is_group: true });
          const groupTabs = [];
          for (let i = tabs.length - 1; i >= 0; i--) {
            const tab = tabs[i];
            if (tab._group === group) {
              groupTabs.push(tab);
              tabs.splice(i, 1);
            }
          }
          groupedTabs.push(...groupTabs.reverse())
        }
        if (tabs.length > 0) {
          groupedTabs.push({ _id: '_other', name: $gettext('Other'), _is_group: true });
          groupedTabs.push(...tabs);
        }
        return groupedTabs as Group<Tab>[];
      }

      return clone(props.tabs) as Flat<Tree<Tab>>[];
    });

    ///-------------------------------------------------------------------
    /// SCROLL TO CONTENT
    ///-------------------------------------------------------------------

    const contentEl = ref<HTMLDivElement>();

    function scrollToContent() {
      const layout = document.getElementById('bp-layout');
      if (!layout) {
        return;
      }
      window.setTimeout(() => {
        if (!contentEl.value) {
          return;
        }
        const headerHeight = parseFloat(window.getComputedStyle(layout).getPropertyValue('--portal-header-height'));
        const { top } = contentEl.value.getBoundingClientRect();
        window.scrollTo({ left: 0, top: window.scrollY + top - headerHeight, behavior: 'smooth' })
      }, 0)
    }

    watch(() => activeTabId.value, scrollToContent);

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = computed<CSSProperties>(() => {
      const color = Array.isArray(props.color)
        ? props.color.length > 0 ? props.color[0] : 'light-blue' // Fallback if somehow color is an empty array
        : props.color;
      const ribbonColor = Array.isArray(props.ribbonColor)
        ? props.ribbonColor.length > 0 ? props.ribbonColor[0] : 'light-blue' // Fallback if somehow color is an empty array
        : props.ribbonColor;
      const ribbonShade = Array.isArray(props.ribbonColor)
        ? extractShade(props.ribbonColor)
        : undefined;
      return {
        '--card-background-color': props.opaque ? 'var(--theme-background)' : `rgba(${cssColor(color, Modifier.RGB)}, var(--card-opacity))`,
        '--card-border-color': cssColor(color),
        '--card-border-width': props.borderWidth === 'md' ? 'var(--border-width)' : `var(--border-width-${props.borderWidth})`,
        '--card-heading-color': props.opaque ? 'var(--theme-text)' : cssColor(color),
        '--card-opacity': props.opacity,
        '--card-text-color': 'var(--theme-text)',
        '--card-translucent-color': `rgba(${cssColor(color, Modifier.RGB)}, var(--card-opacity))`,
        '--card-max-height': props.maxHeight,
        '--card-max-width': props.maxWidth,
        // HEADER / FOOTER
        ...((hasHeader.value || hasFooter.value) ? {
          '--card-header-footer-background-color': props.opaque ? `rgba(${cssColor(color, Modifier.RGB)}, var(--card-opacity))` : 'var(--card-background-color)',
          '--card-header-footer-icon-color': 'var(--theme-text-secondary)',
          '--card-header-footer-text-color': 'var(--card-text-color)',
        } : {}),
        // RIBBON
        ...((props.ribbonIcon || props.ribbon) ? {
          '--card-ribbon-background-color': cssColor(ribbonColor, ribbonShade),
          '--card-ribbon-icon-color': cssColor(ribbonColor, ribbonShade, Modifier.TEXT_SECONDARY),
          '--card-ribbon-text-color': cssColor(ribbonColor, ribbonShade, Modifier.TEXT),
        } : {}),
      }
    });

    const tabsStyle = computed<CSSProperties>(() => {
      if (!props.tabs) {
        return {};
      }
      const color = Array.isArray(props.color)
        ? props.color.length > 0 ? props.color[0] : 'light-blue' // Fallback if somehow color is an empty array
        : props.color;
      return {
        '--card-tabs-background-color': 'var(--theme-background)',
        '--card-tab-active-color': cssColor(color),
        '--card-tab-active-icon-color': cssColor(color, Modifier.TEXT_SECONDARY),
        '--card-tab-active-text-color': cssColor(color, Modifier.TEXT),
      }
    });

    ///-------------------------------------------------------------------
    /// CALCULATE HEIGHT
    ///-------------------------------------------------------------------

    const cardEl = ref<HTMLDivElement>();
    const tabsEl = ref<HTMLDivElement>();

    const height = ref<CSSProperties>({});

    function calculateHeight() {
      const card = cardEl.value;
      const app = document.querySelector('#app');
      const main = document.querySelector('#bp-main');
      const footer = document.querySelector('#bp-footer');
      if (!props.fillHeight || !card || !app || !main || !footer) {
        height.value = {};
        return;
      }
      card.style.setProperty('--card-height', null);
      nextTick(() => {
        const appY = app.getBoundingClientRect().y;
        const cardY = card.getBoundingClientRect().y;
        const y = cardY - appY;
        const tabsHeight = tabsEl.value ? tabsEl.value.getBoundingClientRect().height : 0;
        const mainMarginBottom = parseInt(window.getComputedStyle(main).getPropertyValue('margin-bottom'));
        const footerHeight = footer.getBoundingClientRect().height;
        const windowHeight = window.innerHeight;
        height.value = {
          '--card-wrapper-height': (windowHeight - y - tabsHeight - mainMarginBottom - footerHeight) + 'px',
        };
      });
    }

    watch(() => compactMode?.value, calculateHeight);
    watchEffect(calculateHeight);
    onMounted(() => {
      calculateHeight();
      addEventListener('resize', calculateHeight, { passive: true });
    })
    onUnmounted(() => {
      calculateHeight();
      removeEventListener('resize', calculateHeight);
    })

    // Keep alive calls
    onActivated(() => {
      calculateHeight();
      addEventListener('resize', calculateHeight, { passive: true });
    })
    onDeactivated(() => {
      calculateHeight();
      removeEventListener('resize', calculateHeight);
    })

    ///-------------------------------------------------------------------
    /// HEADER & FOOTER
    ///-------------------------------------------------------------------

    const hasHeader = computed(() => !!ctx.slots.header || props.tabs && (ctx.slots['tab-header-' + activeTabId.value] || ctx.slots['tabs-header']));
    const hasFooter = computed(() => !!ctx.slots.footer || props.tabs && (ctx.slots['tab-footer-' + activeTabId.value] || ctx.slots['tabs-footer']));

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeTabId,
      cardEl,
      compactMode,
      contentEl,
      flatTabs,
      hasHeader,
      hasFooter,
      height,
      style,
      tabsEl,
      tabsStyle,
    }
  }
});
