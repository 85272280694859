
import BpToast, { Toast } from './BpToasts';
import { computed, CSSProperties, defineComponent } from 'vue';
import { cssColor, Modifier } from '@/utils/color';

export default defineComponent({
  name: 'bp-toast',
  setup() {
    ///-------------------------------------------------------------------
    /// LOGIC
    ///-------------------------------------------------------------------

    /**
     * All active toasts.
     */
    const toasts = computed(() => BpToast.toasts);

    /**
     * Gets or creates a group in a toast grouping.
     */
    function getOrCreateToastGroup(groups: Record<string, Record<string, Toast[]>>, vertical: string, horizontal: string) {
      let verticalGroup = groups[vertical];
      if (!verticalGroup) {
        verticalGroup = {};
        groups[vertical] = verticalGroup;
      }
      let horizontalGroup = verticalGroup[horizontal];
      if (!horizontalGroup) {
        horizontalGroup = [];
        verticalGroup[horizontal] = horizontalGroup;
      }
      return horizontalGroup;
    }

    /**
     * All toasts grouped by their position.
     */
    const groupedToasts = computed(() => {
      const allToasts = toasts.value;
      const groups: Record<string, Record<string, Toast[]>> = {};
      for (const toast of allToasts) {
        const [vertical, horizontal] = toast.position.split('-');
        const group = getOrCreateToastGroup(groups, vertical, horizontal);
        group.push(toast);
      }
      return groups;
    });

    /**
     * Called when the toast hide button is clicked.
     */
    function onClickHideToast(toast: Toast) {
      BpToast.hide(toast._id);
    }

    ///-------------------------------------------------------------------
    /// CSS HELPERS
    ///-------------------------------------------------------------------

    /**
     * Gets the CSS variables of a single toast.
     */
    function toastVariables(toast: Toast) {
      const color = Array.isArray(toast.color)
        ? toast.color.length > 0 ? toast.color[0] : 'light-blue' // Fallback if somehow color is an emtpy array
        : toast.color;
      return {
        '--toast-background-color': cssColor(color),
        '--toast-border-color': cssColor(color, Modifier.TEXT_SECONDARY),
        '--toast-progress-bar-color': cssColor(color, 700),
        '--toast-icon-color': cssColor(color, Modifier.TEXT_SECONDARY),
        '--toast-text-color': cssColor(color, Modifier.TEXT),
        '--toast-duration': `${toast.duration}ms`,
        '--toast-fade-duration': `${toast.fadeDuration}ms`
      } as CSSProperties
    }

    return {
      groupedToasts,
      onClickHideToast,
      toastVariables,
    };
  }
})
