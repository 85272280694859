import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "bp-code-editor__language-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_ace_editor = _resolveComponent("v-ace-editor")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!

  return (_openBlock(), _createBlock(_component_bp_label, _mergeProps({ class: "bp-code-editor" }, _ctx.labelProps), _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['bp-code-editor__wrapper', {
      'bp-code-editor__wrapper--disabled': _ctx.disabled,
      'bp-code-editor__wrapper--invalid': _ctx.hasError(),
      'bp-code-editor__wrapper--valid': _ctx.isDirty && _ctx.valid !== undefined && _ctx.valid,
    }]),
        style: _normalizeStyle(_ctx.style)
      }, [
        _createVNode(_component_v_ace_editor, {
          "max-lines": 20,
          "min-lines": 4,
          options: {
          useSoftTabs: true,
          navigateWithinSoftTabs: true,
          fontSize: '1rem',
          tabSize: 2,
        },
          "print-margin": false,
          theme: _ctx.darkMode ? 'tomorrow_night' : 'tomorrow',
          value: _ctx.value,
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = _ctx.format(_ctx.value))),
          "onUpdate:value": _ctx.update,
          class: "bp-code-editor__input",
          lang: "html"
        }, null, 8, ["theme", "value", "onUpdate:value"])
      ], 6)
    ]),
    _: 2
  }, [
    (_ctx.$slots.label || _ctx.$slots.default)
      ? {
          name: "label",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], true)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.isTranslated(_ctx.modelValue))
      ? {
          name: "text",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "text", {}, () => [
              _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_1)
            ], true),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableLanguages).sort((a, b) => a < b ? -1 : 1), (lang, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: lang.toLowerCase()
                }, [
                  (index !== 0)
                    ? (_openBlock(), _createBlock(_component_bp_icon, {
                        key: 0,
                        icon: "slash-forward",
                        "fixed-width": false
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_resolveDynamicComponent('bp-input'), {
                    "model-value": _ctx.language,
                    name: 'language-selector-' + _ctx.unique,
                    text: _ctx.getTranslatedName(lang),
                    value: _ctx.getTranslatedKey(lang),
                    "onUpdate:modelValue": _ctx.setLanguage,
                    seamless: "",
                    type: "radio"
                  }, null, 8, ["model-value", "name", "text", "value", "onUpdate:modelValue"]))
                ], 64))
              }), 128))
            ])
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.hasError())
      ? {
          name: "error",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "error", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.error || _ctx.errorMessage), 1)
            ], true)
          ]),
          key: "2"
        }
      : undefined,
    _renderList(_ctx.labelSlots, (slotName) => {
      return {
        name: slotName,
        fn: _withCtx((slotScope) => [
          _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
        ])
      }
    })
  ]), 1040))
}