
import { BaseDoc } from '../virtual-scroller/BpVirtualScroller';
import { ColorProp } from '@/utils/color';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';
import { cssPath } from '@/utils/css';
import { FloatingOpts, hide, show } from '@/utils/floating';
import { PopoverElement } from './BpPopoverMenu';
import BpIconVue from '../icon/BpIcon.vue';
import BpPopoverMenuVue from './BpPopoverMenu.vue';

/**
 * A popover wrapper component.
 */
export default defineComponent({
  name: 'bp-popover',
  components: {
    BpPopoverMenu: BpPopoverMenuVue,
  },
  props: {
    arrow: Boolean,
    closeOnClick: Boolean,
    color: ColorProp,
    data: Array as PropType<BaseDoc[]>,
    flat: Boolean,
    flip: {
      type: Boolean,
      default: true,
    },
    highlighted: Object as PropType<Set<string>>,
    loading: Boolean,
    matchReferenceSize: Boolean,
    modelValue: Boolean,
    offset: {
      type: [Number, Array] as PropType<number | [number, number]>,
      default: 0,
    },
    placement: {
      type: String as PropType<'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end'>,
      default: 'bottom',
    },
    reference: Object as PropType<HTMLElement>,
    seamless: Boolean,
    seamlessHeader: Boolean,
    seamlessOptions: Boolean,
    seamlessFooter: Boolean,
    searchProperty: [String, Function] as PropType<string | (<T>(item: T) => string)>,
    unwrap: Boolean,
    // POPOVER MENU
    hideSelected: Boolean,
    minWidth: [Number, String],
    maxWidth: [Number, String],
  },
  emits: [
    'clickOption',
    'closeKeyboard',
    'update:modelValue',
    'menu:mouseenter',
    'menu:mouseleave',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// FLOATING UI
    ///-------------------------------------------------------------------

    const opts = ref<FloatingOpts>({
      arrow: props.arrow,
      flip: props.flip,
      matchReferenceSize: props.matchReferenceSize,
      offset: props.offset,
      placement: props.placement,
      duration: 0,
    })

    watch(() => props.modelValue, async (expanded) => {
      if (expanded) {
        nextTick(() => show(opts.value));
      } else {
        hide(opts.value);
      }
    });

    // REFERENCE ELEMENT
    const referenceEl = ref<HTMLElement>();
    watch(() => referenceEl.value, reference => {
      if (props.reference) {
        opts.value.referenceEl = props.reference;
        return;
      }
      opts.value.referenceEl = reference;
    }, { immediate: true });

    // FLOATING ELEMENT
    const floatingEl = ref<HTMLElement>();
    watch(() => floatingEl.value, floating => {
      opts.value.floatingEl = floating;
    }, { immediate: true });

    // ARROW ELEMENT
    const arrowEl = ref<typeof BpIconVue>();
    watch(() => arrowEl.value, () => {
      opts.value.arrowEl = arrowEl.value?.$el;
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// CLICK AWAY
    ///-------------------------------------------------------------------

    const preparePreventClickAway = ref(false);
    const preventClickAway = ref(false);

    function setPreventClickAway() {
      // console.log('[POPOVER] setPreventClickAway()')
      preparePreventClickAway.value = true;
    }

    function checkPreventClickAway() {
      // console.log('[POPOVER] checkPreventClickAway()')
      if (preparePreventClickAway.value) {
        // console.log('... preventClickAway = true')
        preventClickAway.value = true;
      }
    }

    function resetPreventClickAway() {
      // console.log('[POPOVER] resetPreventClickAway()')
      nextTick(() => {
        preparePreventClickAway.value = false;
        // console.log('... preventClickAway = false')
        preventClickAway.value = false;
      })
    }

    function clickAway(event: PointerEvent) {
      const target = event.target as HTMLElement;
      // console.log('[POPOVER] clickAway(', event, ')')
      // console.log('... preventClickAway =', preventClickAway.value)
      if ((!referenceEl.value || !target.matches(cssPath(referenceEl.value) + ', ' + cssPath(referenceEl.value) + ' *, #floating-elements *')) && !preventClickAway.value) {
        emitClose();
      }
      if (props.hideSelected && preventClickAway.value) {
        resetPreventClickAway();
      }
    }

    ///-------------------------------------------------------------------
    /// CLOSE
    ///-------------------------------------------------------------------

    function emitClose() {
      // console.log('[POPOVER] emitClose()')
      ctx.emit('update:modelValue', false)
    }

    function closeKeyboard() {
      // console.log('[POPOVER] emitClose()')
      emitClose();
      ctx.emit('closeKeyboard');
    }

    ///-------------------------------------------------------------------
    /// CLICK OPTION
    ///-------------------------------------------------------------------

    function clickOption(option: PopoverElement, event: Event) {
      if (props.hideSelected) {
        preventClickAway.value = true;
      }
      if (props.closeOnClick) {
        emitClose();
      }
      ctx.emit('clickOption', option, event);
    }

    ///-------------------------------------------------------------------
    /// SLOTS
    ///-------------------------------------------------------------------

    const slots = computed(() => Object.keys(ctx.slots));

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      arrowEl,
      checkPreventClickAway,
      clickAway,
      clickOption,
      closeKeyboard,
      emitClose,
      floatingEl,
      referenceEl,
      resetPreventClickAway,
      setPreventClickAway,
      slots,
    };
  }
});
