
import { $gettext } from 'vue-gettext';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { ColorAlias } from '@/utils/color';
import { colorPreviewIcon, slashIcon } from '../icon/BpIcon';
import { computed, defineComponent, onMounted, onUnmounted, ref, type PropType } from 'vue';
import { currentLanguage } from '@/translation';
import { getFontSize } from './extensions/font-size';
import BpWysiwygEditorColorPickerVue from './BpWysiwygEditorColorPicker.vue';
import getCssVariable from '@/utils/css';
import type { Editor } from '@tiptap/vue-3';

/**
 * A Wysiwyg actions component.
 */
export default defineComponent({
  name: 'bp-wysiwyg-actions',
  components: {
    BpWysiwygEditorColorPicker: BpWysiwygEditorColorPickerVue,
  },
  props: {
    editor: {
      type: Object as PropType<Editor>,
      required: true,
      description: 'The current prosemirror editor instance.',
    },
    allowAlerts: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props) {
    ///-------------------------------------------------------------------
    /// TEXT VARIANTS
    ///-------------------------------------------------------------------

    // type Level = 1 | 2 | 3 | 4 | 5 | 6;

    // const textVariantOptions = [
    //   ...[1, 2, 3, 4, 5, 6].map((level) => ({
    //     _id: 'h' + level,
    //     name: `${$gettext('Heading')} ${level}`,
    //   })),
    //   {
    //     _id: 'paragraph',
    //     name: $gettext('Paragraph'),
    //   },
    // ];

    // const lastTextVariantIcon = ref('paragraph');

    // function selectTextVariant(event: Event) {
    //   const newValue = (event.target as HTMLSelectElement).value;
    //   if (newValue.startsWith('h')) {
    //     const level = parseInt(newValue.substring(1)) as Level;
    //     props.editor.chain().focus().setHeading({ level }).run();
    //   } else {
    //     props.editor.chain().focus().setParagraph().run();
    //   }
    //   lastTextVariantIcon.value = newValue;
    // }

    ///-------------------------------------------------------------------
    /// FONT FAMILIES
    ///-------------------------------------------------------------------

    // const fontFamilyOptions = [
    //   'Arial',
    //   'Courier New',
    //   'Helvetica',
    //   'Tahoma',
    //   'Times New Roman',
    //   'Verdana',
    // ].map((fontFamily) => ({
    //   _id: fontFamily,
    //   name: fontFamily,
    // }));

    // function selectFontFamily(event: Event) {
    //   const fontFamily = (event.target as HTMLSelectElement).value;
    //   props.editor.chain().focus().setFontFamily(fontFamily).run();
    // }

    ///-------------------------------------------------------------------
    /// FONT SIZE
    ///-------------------------------------------------------------------

    // const fontSizeOptions = [
    //   8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
    // ].map((fontSize) => ({
    //   _id: fontSize.toString(),
    //   name: fontSize.toString(),
    // }));

    // function selectFontSize(event: Event) {
    //   const fontFamily = parseFloat((event.target as HTMLSelectElement).value);
    //   props.editor.commands.setFontSize(fontFamily);
    // }

    ///-------------------------------------------------------------------
    /// COLOR
    ///-------------------------------------------------------------------

    const textColorPickerVisible = ref(false);
    const highlightColorPickerVisible = ref(false);

    const textColor = ref('#ff0000');
    const highlightColor = ref('#ffff00');

    function setTextColor(color: string) {
      textColor.value = color;
      props.editor.commands.setColor(color);
    }

    function setHighlightColor(color: string) {
      highlightColor.value = color;
      props.editor.commands.setHighlight({ color });
    }

    ///-------------------------------------------------------------------
    /// TEXT ALIGN
    ///-------------------------------------------------------------------

    const textAlignOptions = [
      {
        _id: 'left',
        name: $gettext('Align left'),
        icon: 'align-left',
      },
      {
        _id: 'center',
        name: $gettext('Align center'),
        icon: 'align-center',
      },
      {
        _id: 'right',
        name: $gettext('Align right'),
        icon: 'align-right',
      },
      {
        _id: 'justify',
        name: $gettext('Align justify'),
        icon: 'align-justify',
      },
    ];

    ///-------------------------------------------------------------------
    /// IMAGE
    ///-------------------------------------------------------------------

    // const showContentPicker = ref(false);

    // function insertImage(url: string | undefined) {
    //   if (url) {
    //     props.editor.chain().focus().setImage({ src: url }).run();
    //   }
    //   showContentPicker.value = false;
    // }

    ///-------------------------------------------------------------------
    /// LINK
    ///-------------------------------------------------------------------

    const showLinkInserter = ref(false);
    const linkInserterUrl = ref('https://');

    function isActiveLink(type: string) {
      switch (type) {
        case 'link':
          return props.editor.isActive('link');
      }
    }

    function addLink() {
      const defaultUrl = props.editor.getAttributes('link').href || 'https://';
      const url = window.prompt('', defaultUrl);
      const selection = props.editor.state.selection;
      const [node] = selection.$anchor.marks();
      if (url) {
        if (!selection.empty || (node && node.type.name === 'link')) {
          props.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run();
        } else {
          const mark = props.editor.schema.marks.link.create({ href: url });
          const from = props.editor.state.selection.from;
          const transaction = props.editor.state.tr.insertText(url);
          transaction.addMark(from, from + url.length, mark);
          props.editor.view.dispatch(transaction);
        }
      }
    }

    ///-------------------------------------------------------------------
    /// ALERT
    ///-------------------------------------------------------------------

    const alertVisible = ref(false);

    function alertIcon(color?: ColorAlias) {
      return [
        {
          icon: 'rectangle-vertical',
          opacity: 0.4,
          transform: 'left-5.5 shrink-5',
          color,
        },
        {
          icon: ['far', 'rectangle-wide'],
          color,
        },
        {
          icon: 'circle',
          transform: 'shrink-12 left-5',
          color,
        },
      ]
    }

    const alertActions = computed(() => [
      {
        _id: 'info',
        name: props.editor.isActive('alert') ? $gettext('Change into info alert') : $gettext('Insert info alert'),
        icon: alertIcon('light-blue'),
        click: () => props.editor.chain().focus().unsetAlert().setAlert('info').run(),
      },
      {
        _id: 'warning',
        name: props.editor.isActive('alert') ? $gettext('Change into warning alert') : $gettext('Insert warning alert'),
        icon: alertIcon('yellow'),
        click: () => props.editor.chain().focus().unsetAlert().setAlert('warning').run(),
      },
      {
        _id: 'error',
        name: props.editor.isActive('alert') ? $gettext('Change into error alert') : $gettext('Insert error alert'),
        icon: alertIcon('red'),
        click: () => props.editor.chain().focus().unsetAlert().setAlert('error').run(),
      },
      {
        _id: 'success',
        name: props.editor.isActive('alert') ? $gettext('Change into success alert') : $gettext('Insert success alert'),
        icon: alertIcon('green'),
        click: () => props.editor.chain().focus().unsetAlert().setAlert('success').run(),
      },
      {
        _id: 'success',
        name: $gettext('Delete alert'),
        icon: alertIcon(),
        click: () => props.editor.chain().focus().unsetAlert().run(),
      },
    ]);

    function hideAlert() {
      window.setTimeout(() => alertVisible.value = false, 0);
    }

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------
    
    const isDesktop = useMedia(Breakpoint.MIN_MD);

    ///-------------------------------------------------------------------
    /// TABLE
    ///-------------------------------------------------------------------

    const tableVisible = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cols = ref<any[]>([]);
    const rows = computed(() => Array(Math.ceil((cols.value.length / 16 * 9) / (isDesktop.value ? 1 : 2))).fill(undefined));
    const tablePopoverWidth = computed(() => cols.value.length * 24 + 8);

    const colHoverIndex = ref(-1);
    const rowHoverIndex = ref(-1);

    function tableIcon(type: 'cols' | 'rows', action: 'before' | 'after' | 'delete') {
      return [
        ...(type === 'cols' ? [
          {
            icon: 'rectangle-vertical',
            transform: `shrink-10 up-3`,
            opacity: 0.4,
            color: action === 'delete' ? 'red' : 'green',
          },
          {
            icon: 'rectangle-vertical',
            transform: `shrink-10 down-3`,
            opacity: 0.4,
            color: action === 'delete' ? 'red' : 'green',
          }
        ] : [{
          icon: 'hyphen',
          transform: `grow-8`,
          opacity: 0.4,
          color: action === 'delete' ? 'red' : 'green',
        }]),
        {
          icon: ['far', 'table-cells'],
        },
        {
          icon: action === 'delete' ? 'xmark' : 'arrow-up',
          transform: `shrink-8 ${type === 'rows' ? 'rotate--90 right-13' : 'down-12'} ${action === 'before'
            ? type === 'rows' ? 'up-2' : 'left-2.5'
            : action === 'after'
              ? type === 'rows' ? 'down-2' : 'right-2.5'
              : ''}`,
          color: action === 'delete' ? 'red' : 'green',
        }
      ]
    }

    const tableActions = ref([
      {
        _id: 'cols',
        name: $gettext('Columns'),
        icon: 'table-columns',
        children: [
          {
            _id: 'cols_insert_before',
            name: $gettext('Insert before'),
            icon: tableIcon('cols', 'before'),
            click: () => props.editor.chain().focus().addColumnBefore().run(),
          },
          {
            _id: 'cols_insert_after',
            name: $gettext('Insert after'),
            icon: tableIcon('cols', 'after'),
            click: () => props.editor.chain().focus().addColumnAfter().run(),
          },
          {
            _id: 'cols_delete',
            name: $gettext('Delete'),
            icon: tableIcon('cols', 'delete'),
            click: () => props.editor.chain().focus().deleteColumn().run(),
          },
        ]
      },
      {
        _id: 'rows',
        name: $gettext('Rows'),
        icon: 'table-rows',
        children: [
          {
            _id: 'rows_insert_before',
            name: $gettext('Insert before'),
            icon: tableIcon('rows', 'before'),
            click: () => props.editor.chain().focus().addRowBefore().run(),
          },
          {
            _id: 'rows_insert_after',
            name: $gettext('Insert after'),
            icon: tableIcon('rows', 'after'),
            click: () => props.editor.chain().focus().addRowAfter().run(),
          },
          {
            _id: 'rows_delete',
            name: $gettext('Delete'),
            icon: tableIcon('rows', 'delete'),
            click: () => props.editor.chain().focus().deleteRow().run(),
          },
        ]
      },
      {
        _id: 'table_delete',
        name: $gettext('Delete Table'),
        icon: [
          {
            icon: 'table',
            opacity: 0.4,
            color: 'red',
          },
          {
            icon: 'xmark',
            transform: 'shrink-8 right-13',
            color: 'red',
          },
        ],
        click: () => props.editor.chain().focus().deleteTable().run(),
      },
    ]);

    function setHoverIndex(col: number, row: number) {
      colHoverIndex.value = col;
      rowHoverIndex.value = row;
    }

    function resetHoverIndex() {
      colHoverIndex.value = -1;
      rowHoverIndex.value = -1;
    }

    function insertTable(cols: number, rows: number) {
      props.editor.chain().focus().insertTable({ rows, cols, withHeaderRow: false }).run();
      resetHoverIndex();
    }

    function hideTable() {
      window.setTimeout(() => tableVisible.value = false, 0);
    }

    function updateCols() {
      const padding = parseFloat(getCssVariable('padding')) * 16;
      const maxColumns = Math.floor(20 * 16 /* = 20rem */ / 24);
      const columns = Math.floor(((window.innerWidth - 2 * padding) - (padding / 2)) / 24);
      cols.value = Array(Math.min(maxColumns, columns)).fill(undefined);
    }
    updateCols();

    onMounted(() => addEventListener('resize', updateCols, { passive: true }));
    onUnmounted(() => removeEventListener('resize', updateCols));

    ///-------------------------------------------------------------------
    /// RETURN --- used icons: ['fad', 'highlighter'], ['fad', 'font']
    ///-------------------------------------------------------------------

    return {
      addLink,
      alertActions,
      alertIcon,
      alertVisible,
      colHoverIndex,
      colorPreviewIcon,
      cols,
      currentLanguage,
      getFontSize,
      hideAlert,
      hideTable,
      highlightColor,
      highlightColorPickerVisible,
      insertTable,
      isActiveLink,
      linkInserterUrl,
      resetHoverIndex,
      rowHoverIndex,
      rows,
      setHighlightColor,
      setHoverIndex,
      setTextColor,
      showLinkInserter,
      slashIcon,
      tableActions,
      tableIcon,
      tablePopoverWidth,
      tableVisible,
      textAlignOptions,
      textColor,
      textColorPickerVisible,
    };
  },
});
