
import { ColorProp, cssColor } from '@/utils/color';
import { CSSProperties, defineComponent, ref, watch } from 'vue';
import { dedent } from 'ts-dedent';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-fieldset',
  props: {
    borderWidth: {
      type: Number,
      default: 2,
    },
    borderColor: ColorProp,
    collapsed: Boolean,
    collapsible: Boolean,
    dogEared: Boolean,
    intenseBorder: Boolean,
    legend: String,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// LEGEND
    ///-------------------------------------------------------------------

    const legendEl = ref<HTMLLegendElement>();
    const style = ref<CSSProperties>({});

    watch(() => legendEl.value, legendEl => {
      if (!legendEl) {
        return;
      }
      style.value = {
        '--fieldset-border-color': props.borderColor ? cssColor(props.borderColor) : `var(--theme-${props.intenseBorder ? 'intense-' : ''}border)`,
        '--fieldset-border-width': `${props.borderWidth}px`,
        '--fieldset-legend-height': `${legendEl.getBoundingClientRect().height}px`,
        '--fieldset-clip-path': dedent`polygon(
          0 0,
          calc(100% - var(--padding) - 0.5px) 0,
          calc(100% - var(--padding)) calc(var(--fieldset-legend-height) / 2 - var(--fieldset-border-width) / 2),
          100% calc(var(--fieldset-legend-height) / 2 - var(--fieldset-border-width) / 2 + var(--padding) + 0.5px),
          100% 100%,
          0 100%
        )`,
      }
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// COLLAPSED
    ///-------------------------------------------------------------------

    const isCollapsed = ref(props.collapsed);
    watch(() => props.collapsed, () => isCollapsed.value = props.collapsed);

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      isCollapsed,
      legendEl,
      style,
    }
  }
})
