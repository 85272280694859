import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "bp-popover__reference",
  ref: "referenceEl"
}
const _hoisted_2 = {
  key: 0,
  ref: "floatingEl",
  class: "bp-popover__floating"
}
const _hoisted_3 = {
  key: 0,
  "data-arrow": "",
  ref: "arrowEl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_popover_menu = _resolveComponent("bp-popover-menu")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bp-popover",
    onMousedown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setPreventClickAway && _ctx.setPreventClickAway(...args))),
    onTouchstartPassive: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.setPreventClickAway && _ctx.setPreventClickAway(...args))),
    onMousemove: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.checkPreventClickAway && _ctx.checkPreventClickAway(...args))),
    onTouchmovePassive: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.checkPreventClickAway && _ctx.checkPreventClickAway(...args))),
    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.resetPreventClickAway && _ctx.resetPreventClickAway(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512),
    (_openBlock(), _createBlock(_Teleport, { to: "#floating-elements" }, [
      (_ctx.modelValue)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "floating", {}, () => [
              (_ctx.data)
                ? (_openBlock(), _createBlock(_component_bp_popover_menu, {
                    key: 0,
                    color: _ctx.color,
                    data: _ctx.data,
                    flat: _ctx.flat,
                    highlighted: _ctx.highlighted,
                    loading: _ctx.loading,
                    "match-reference-size": _ctx.matchReferenceSize,
                    "max-width": _ctx.maxWidth,
                    "min-width": _ctx.minWidth,
                    placement: _ctx.placement,
                    seamless: _ctx.seamless,
                    "seamless-footer": _ctx.seamlessFooter,
                    "seamless-header": _ctx.seamlessHeader,
                    "seamless-options": _ctx.seamlessOptions,
                    "search-property": _ctx.searchProperty,
                    unwrap: _ctx.unwrap,
                    "onNested:mouseenter": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menu:mouseenter', $event))),
                    "onNested:mouseleave": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('menu:mouseleave', $event))),
                    onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('menu:mouseenter', $event))),
                    onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('menu:mouseleave', $event))),
                    onCloseKeyboard: _ctx.closeKeyboard,
                    onClickOption: _ctx.clickOption
                  }, _createSlots({ _: 2 }, [
                    _renderList(_ctx.slots, (slotName) => {
                      return {
                        name: slotName,
                        fn: _withCtx((slotScope) => [
                          _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
                        ])
                      }
                    })
                  ]), 1032, ["color", "data", "flat", "highlighted", "loading", "match-reference-size", "max-width", "min-width", "placement", "seamless", "seamless-footer", "seamless-header", "seamless-options", "search-property", "unwrap", "onCloseKeyboard", "onClickOption"]))
                : _createCommentVNode("", true)
            ], true),
            (_ctx.arrow)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[9] || (_cache[9] = [
                  _createElementVNode("div", null, null, -1)
                ]), 512))
              : _createCommentVNode("", true)
          ])), [
            [_directive_click_away, _ctx.clickAway]
          ])
        : _createCommentVNode("", true)
    ]))
  ], 32))
}