
import { defineComponent } from 'vue';
import { IconProp } from './icon/BpIcon';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'BpViewHeader',
  props: {
    image: String,
    icon: IconProp,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode
    };
  }
});
