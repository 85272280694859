import { onUnmounted, ref, watch, WatchSource } from 'vue';

export type UseResize = ReturnType<typeof useResize>;

/**
 * Creates a new resize observer that will automatically be destroyed on component unmount.
 * @param dom The DOM element to observer
 * @returns The resize listener
 */
export default function useResize(dom: WatchSource<Element | undefined>, callback?: (entry: ResizeObserverEntry) => void) {
  const current = ref<ResizeObserverEntry>();
  const currentDom = ref<Element>();

  const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const entry = entries.find(e => e.target === currentDom.value);
    if (entry) {
      current.value = entry;
      callback?.(entry);
    }
  });

  watch(dom, (newDom, oldDom) => {
    currentDom.value = newDom;
    rewatch(newDom, oldDom);
  }, { immediate: true });

  function rewatch(newDom?: Element, oldDom?: Element) {
    if (oldDom) {
      observer.unobserve(oldDom);
    }
    if (newDom) {
      observer.observe(newDom);
    }
  }

  onUnmounted(() => {
    observer.disconnect();
  });

  return {
    current,
    observer,
    dom: currentDom,
  };
}
