
import { BaseDoc } from '../virtual-scroller/BpVirtualScroller';
import { children } from '../navigation/BpNavigation';
import { ColorProp, cssColor, Modifier } from '@/utils/color';
import { computed, CSSProperties, defineComponent, PropType, ref } from 'vue';
import { deepValue } from '@/utils/object';
import { getTranslated, isTranslated } from '@/translation';
import { isAbsoluteUrl } from '@/utils/string';
import { PopoverElement, seekOption } from './BpPopoverMenu';
import { Tooltip } from '@/utils/tooltip';
import { useRouter } from 'vue-router';
import useCompactMode from '@/compositions/use-compact-mode';

/**
 * A Popover menu component.
 */
export default defineComponent({
  name: 'bp-popover-menu',
  props: {
    color: {
      type: ColorProp,
      default: ['light-blue', 600],
    },
    data: {
      type: Array as PropType<BaseDoc[]>,
      required: true
    },
    flat: Boolean,
    highlighted: Object as PropType<Set<string>>,
    loading: Boolean,
    matchReferenceSize: Boolean,
    minWidth: {
      type: [Number, String],
      default: '20rem',
    },
    maxWidth: {
      type: [Number, String],
      default: 30,
    },
    placement: {
      type: String as PropType<'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end'>,
      default: 'bottom',
    },
    seamless: Boolean,
    seamlessHeader: Boolean,
    seamlessOptions: Boolean,
    seamlessFooter: Boolean,
    searchProperty: [String, Function] as PropType<string | (<T>(item: T) => string)>,
    unwrap: Boolean,
  },
  emits: [
    'clickOption',
    'closeKeyboard',
    'nested:mouseenter',
    'nested:mouseleave'
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// COMPACT MODE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// SEARCH PROPERTY
    ///-------------------------------------------------------------------

    const searchKey = (item?: unknown) => item
      ? (typeof props.searchProperty === 'function' ? props.searchProperty(item) : props.searchProperty) || '_id'
      : typeof props.searchProperty === 'string' ? props.searchProperty : '_id';

    ///-------------------------------------------------------------------
    /// SCROLLING / LAZY LOAD
    ///-------------------------------------------------------------------

    const dom = ref<HTMLDivElement>();

    function isExternalLink(element: PopoverElement) {
      return typeof element.to === 'string' && isAbsoluteUrl(element.to);
    }

    function clickOption(option: PopoverElement | null | undefined, event: Event) {
      if (!option || option._disabled) {
        return;
      }
      if (option.to) {
        if (isExternalLink(option)) {
          window.open(option.to as string, '_blank', 'noopener');
        } else {
          router.push(option.to);
        }
      }
      if (option.click) {
        option.click();
      }
      ctx.emit('clickOption', option, event);
    }

    const style = computed(() => {
      return {
        '--popover-color': cssColor(props.color),
        '--popover-icon-color': cssColor(props.color, Modifier.TEXT_SECONDARY),
        '--popover-text-color': cssColor(props.color, Modifier.TEXT),
        // '--popover-max-height': typeof props.maxHeight === 'string' ? props.maxHeight : `calc(var(--vh) * ${props.maxHeight})`,
        '--popover-max-width': props.loading ? undefined : typeof props.maxWidth === 'string' ? props.maxWidth : `calc(var(--vw) * ${props.maxWidth})`,
        '--popover-min-width': typeof props.minWidth === 'string' ? props.minWidth : `calc(var(--vw) * ${props.minWidth})`,
      } as CSSProperties;
    });

    function slotName(element: PopoverElement) {
      return 'option-' + element._id.replace(/\s/g, '_');
    }

    // TODO
    const optionIndex = ref(0);

    ///-------------------------------------------------------------------
    /// KEYBOARD NAVIGATION
    ///-------------------------------------------------------------------

    /**
     * Focusses either the first or last element of the available menu options.
     * | Hierarchy |
     * |:-|
     * | `active element` — Button *(focussable element)* |
     * | `1. parent element` — Virtual scroller node |
     * | `2. parent element` — Virtual scroller nodes container |
     * | `3. parent element` — Virtual scroller content *(height = total height of all alements)* |
     * | `4. parent element` — Scrollable container |
     * @param activeElement The active element.
     * @param firstLast Whether to focus the `first` or `last` element of the options.
     */
    function focusElement(activeElement: HTMLButtonElement, firstLast: 'first' | 'last') {
      const virtaulScrollerNodes = activeElement.parentElement?.parentElement as HTMLDivElement;
      const y = parseFloat((virtaulScrollerNodes.parentElement as HTMLDivElement).style.height);
      virtaulScrollerNodes.parentElement?.parentElement?.scrollTo(0, firstLast === 'first' ? 0 : y);
      setTimeout(() => {
        const nodeCount = virtaulScrollerNodes.children.length;
        const element = seekOption(virtaulScrollerNodes.children[firstLast === 'first' ? 0 : nodeCount - 1].children[0] as HTMLButtonElement, firstLast === 'first' ? 'next' : 'prev');
        if (!element) return;
        element.focus()
      }, 50)
    }

    /**
     * Handles keyboard input within the popover menu.
     * Implements the whole logic to traverse all menu items with the arrow keys.
     * @param event The event.
     */
    function handleKeyboardInput(event: KeyboardEvent) {
      let activeElement = document.activeElement as HTMLButtonElement | null;
      if (!activeElement) {
        return;
      }
      switch (event.code) {
        case 'Enter': {
          activeElement = seekOption(activeElement);
          if (!activeElement) break;
          activeElement.click();
          break;
        }
        case 'ArrowDown': {
          event.preventDefault();
          const nextElement = seekOption(activeElement.parentElement?.nextElementSibling?.children[0] as HTMLButtonElement | null);
          if (!nextElement) {
            focusElement(activeElement, 'first');
            break;
          }
          nextElement.focus();
          break;
        }
        case 'ArrowUp': {
          event.preventDefault();
          const previousElement = seekOption(activeElement.parentElement?.previousElementSibling?.children[0] as HTMLButtonElement | null, 'prev');
          if (!previousElement) {
            focusElement(activeElement, 'last');
            break;
          }
          previousElement.focus();
          break;
        }
        case 'Tab':
        case 'Escape': {
          event.preventDefault();
          ctx.emit('closeKeyboard');
          break;
        }
      }
    }

    ///-------------------------------------------------------------------
    /// SUBMENU
    ///-------------------------------------------------------------------

    const submenu = ref<string>();
    const submenuTimeout = ref(NaN);

    function showSubmenu(element: PopoverElement) {
      clearTimeout(submenuTimeout.value);
      submenuTimeout.value = NaN;
      submenu.value = element._id;
      ctx.emit('nested:mouseenter', element);
    }

    function hideSubmenu(event: MouseEvent) {
      // TODO: Multiple nested needs additional logic
      // const relatedTarget = event.relatedTarget as HTMLElement;
      // if (relatedTarget && !relatedTarget.matches('#floating-elements *')) {
        submenuTimeout.value = window.setTimeout(() => submenu.value = undefined, 100);
      // }
      ctx.emit('nested:mouseleave', event);
    }

    ///-------------------------------------------------------------------
    /// TOOLTIP
    ///-------------------------------------------------------------------

    function tooltip(text: string): Tooltip {
      const offset: [number, number] = [-12, 0];

      // Return tooltip
      return (el: HTMLElement) => {
        const content = el.querySelector('.bp-popover-menu__option-content') as HTMLElement;
        if (!content) {
          return;
        }

        // Calculate whether a tooltip has to be shown based on the exacts widths of the content and its container element
        let width = 0;
        for (const child of content.children) {
          width += child.getBoundingClientRect().width;
        }
        if (width) {
          const computedStyle = window.getComputedStyle(el);
          const paddingRight = parseFloat(computedStyle.getPropertyValue('padding-right'));
          const offsetLeft = (content.getBoundingClientRect().left - el.getBoundingClientRect().left);
          if (width > (el.getBoundingClientRect().width - offsetLeft - paddingRight)) {
            return { offset, text };
          }
        }

        // Otherwise use the built in scrollWidth & clientWidth instead (these numbers are rounded therefore possibly inaccurate!)
        else if (content.scrollWidth > content.clientWidth) {
          return { offset, text };
        }
      }
    }

    ///-------------------------------------------------------------------
    /// TRANSLATED
    ///-------------------------------------------------------------------
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function itemValue(item: any) {
      const keyString = searchKey(item);
      if (keyString) {
        const value = deepValue(item, keyString);
        return isTranslated(value) ? getTranslated(value) : value;
      }
      return item._id;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      children,
      clickOption,
      compactMode,
      cssColor,
      deepValue,
      dom,
      handleKeyboardInput,
      hideSubmenu,
      isExternalLink,
      itemValue,
      Modifier,
      optionIndex,
      searchKey,
      showSubmenu,
      slotName,
      style,
      submenu,
      tooltip,
    };
  },
});
