
import { availableColors, Color, cssColor, Modifier } from '@/utils/color';
import { copyToClipboard } from '@/utils/navigator';
import { CSSProperties, defineComponent, PropType, ref, watch } from 'vue';
import { getTranslated } from '@/translation';
import { localizeDate } from '@/utils/date';
import { removeHTML } from '@/utils/string';
import { Timeline } from './BpTimeline';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-timeline',
  props: {
    active: String,
    emptyText: String,
    opacity: {
      type: Number,
      default: 0.15,
    },
    timeline: {
      type: Array as PropType<Timeline>,
      required: true,
    },
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// TIMELINE ENTRIES
    ///-------------------------------------------------------------------

    const timelineEntries = ref<Timeline>([]);
    watch(() => props.timeline, timeline => {
      timelineEntries.value = clone(timeline)
        .sort((a, b) => new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1)
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    function style(index: number): CSSProperties {
      const colorCount = availableColors.filter(color => !color.endsWith('gray')).length;
      const offset = availableColors.findIndex(color => color === 'light-blue');
      const color: Color = availableColors.filter(color => !color.endsWith('gray'))[(index * 2 + offset) % colorCount];
      return {
        '--timeline-entry-color': cssColor(color),
        '--timeline-entry-icon-color': cssColor(color, Modifier.TEXT_SECONDARY),
        '--timeline-entry-line-color': `rgba(${cssColor(color, Modifier.RGB)}, ${props.opacity})`,
        '--timeline-entry-text-color': cssColor(color, Modifier.TEXT),
        '--timeline-entry-rgb-color': cssColor(color, Modifier.RGB),
      }
    }

    ///-------------------------------------------------------------------
    /// ACTIVE
    ///-------------------------------------------------------------------

    const timelineEl = ref<HTMLDivElement>();
    watch(() => timelineEl.value, () => {
      if (props.active) {
        window.setTimeout(() => {
          if (!timelineEl.value) {
            return;
          }
          const activeEl = timelineEl.value.querySelector(`[data-timeline-entry-id="${props.active}"]`)
          if (!activeEl) {
            return;
          }
          activeEl.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    })

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      copyToClipboard,
      getTranslated,
      localizeDate,
      removeHTML,
      style,
      timelineEl,
      timelineEntries,
    }
  }
})
