
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { ColorProp, cssColor } from '@/utils/color';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { IconProp } from '../icon/BpIcon';
import { children, Navigation, NavigationEntry } from './BpNavigation';
import { useRouter } from 'vue-router';
import BpNavigationEntryVue from './BpNavigationEntry.vue';
import useDarkMode from '@/compositions/use-dark-mode';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-navigation',
  components: {
    BpNavigationEntry: BpNavigationEntryVue,
  },
  props: {
    activeId: String,
    color: {
      type: ColorProp,
      default: 'light-blue',
    },
    isActive: Function as PropType<(entry: NavigationEntry) => boolean>,
    navigation: {
      type: Array as PropType<Navigation>,
      required: true,
    },
    position: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
    seamless: Boolean,
    seamlessHeader: Boolean,
    seamlessOptions: Boolean,
    seamlessFooter: Boolean,
    toggleIcon: {
      type: IconProp,
      default: 'bars',
    },
  },
  emits: [
    'update:active-id',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const iconColor = computed(() => cssColor(props.color));

    ///-------------------------------------------------------------------
    /// SUBMENU
    ///-------------------------------------------------------------------

    const submenu = ref<string>();
    const submenuTimeout = ref(NaN);

    function showSubmenu(entry: NavigationEntry) {
      clearTimeout(submenuTimeout.value);
      submenuTimeout.value = NaN;
      submenu.value = entry._id;
    }

    function hideSubmenu(event: MouseEvent) {
      const relatedTarget = event.relatedTarget as HTMLElement;
      if (relatedTarget && !relatedTarget.matches('#floating-elements *')) {
        submenuTimeout.value = window.setTimeout(() => submenu.value = undefined, 100);
      }
    }

    ///-------------------------------------------------------------------
    /// ACTIVE
    ///-------------------------------------------------------------------

    const navigationEl = ref<HTMLElement>();
    const activeElId = ref(props.activeId || props.navigation.find(entry => isEntryActive(entry))?._id);
    const activeBarEl = ref<HTMLDivElement>();

    watch(() => activeElId.value, activeId => {
      setTimeout(() => {
        if (!activeId) {
          return;
        }
        const activeEl = document.querySelector(`[data-id="${activeId}"]`) as HTMLElement | undefined;
        if (!navigationEl.value || !activeBarEl.value || !activeEl) {
          return;
        }
        activeBarEl.value.style.width = activeEl.getBoundingClientRect().width  + 'px';
        activeBarEl.value.style.transform = `translateX(${(activeEl.getBoundingClientRect().x - navigationEl.value.getBoundingClientRect().x)}px)`;
      }, 0)
    }, { immediate: true });

    function setActive(id: string) {
      activeElId.value = id;
      ctx.emit('update:active-id', activeElId.value);
    }

    function maybeSetActive(entry: NavigationEntry, option: NavigationEntry) {
      if (option.to) {
        setActive(entry._id);
      }
    }

    function isEntryActive(entry: NavigationEntry) {
      if (props.isActive !== undefined) {
        return props.isActive(entry);
      }

      if (!router.currentRoute.value.name) {
        return false;
      }

      function *elements(el: NavigationEntry): Generator<NavigationEntry> {
        yield el;
        if (el.children) {
          for (const child of el.children) {
            yield *elements(child);
          }
        }
      }

      const routes = [];
      for (const element of elements(entry)) {
        routes.push((element.to && typeof element.to !== 'string' && 'name' in element.to) ? element.to.name : '');
      }
      return routes.includes(router.currentRoute.value.name);
    }

    function active(entry: NavigationEntry) {
      if (!entry.children) {
        return;
      }

      function *elements(el: NavigationEntry): Generator<NavigationEntry> {
        if (el._id !== entry._id) {
          yield el;
        }
        if (el.children) {
          for (const child of el.children) {
            yield *elements(child);
          }
        }
      }

      const els = [];
      for (const el of elements(entry)) {
        els.push(el);
      }

      return new Set(els.filter(child => isEntryActive(child)).map(child => child._id || ''));
    }

    ///-------------------------------------------------------------------
    /// MOBILE DRAWER
    ///-------------------------------------------------------------------

    const open = ref(false);

    function close() {
      if (open.value) {
        open.value = false;
      }
    }

    function clickEntry(entry: NavigationEntry) {
      ctx.emit('update:active-id', entry._id);
      open.value = false;
    }

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: darkMode } = useDarkMode();
    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      active,
      activeBarEl,
      children,
      clickEntry,
      close,
      compactMode,
      hideSubmenu,
      isEntryActive,
      isDesktop,
      maybeSetActive,
      navigationEl,
      open,
      setActive,
      showSubmenu,
      submenu,
      darkMode,
      iconColor,
      router,
    }
  }
})
