import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bp-navigation__mobile-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["data-id", "data-color"]
const _hoisted_4 = ["data-id", "data-color", "onClick", "onMouseenter", "onTouchstartPassive"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_navigation_entry = _resolveComponent("bp-navigation-entry")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bp_popover = _resolveComponent("bp-popover")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_component_bp_icon, _mergeProps({
          key: 0,
          class: "bp-navigation__mobile-toggle",
          color: _ctx.color,
          icon: _ctx.toggleIcon
        }, _ctx.$attrs, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
        }), null, 16, ["color", "icon"]))
      : _createCommentVNode("", true),
    (!_ctx.isDesktop)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['bp-navigation__wrapper', 'bp-navigation__wrapper--position-' + _ctx.position, {
    'bp-navigation__wrapper--open': _ctx.open,
    'bp-navigation__wrapper--compact': _ctx.compactMode,
  }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "bp-navigation__logo",
              src: _ctx.darkMode ? '/static/logo/cordaware_white.png' : '/static/logo/cordaware.png'
            }, null, 8, _hoisted_2),
            _createVNode(_component_bp_icon, {
              class: "bp-navigation__mobile-toggle",
              icon: "xmark",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = !_ctx.open))
            })
          ]),
          _createElementVNode("nav", {
            class: _normalizeClass(['bp-navigation', { 'bp-navigation--compact': _ctx.compactMode }])
          }, [
            _createVNode(_component_bp_navigation_entry, {
              color: _ctx.color,
              navigation: [..._ctx.navigation].sort((a, b) => (((_ctx.children(a)?.length ?? 0) <= 1 ? 0 : 1) < ((_ctx.children(b)?.length ?? 0) <= 1 ? 0 : 1)) ? -1 : 1),
              onClickEntry: _ctx.clickEntry,
              "is-active": _ctx.isActive
            }, _createSlots({ _: 2 }, [
              _renderList(Object.keys(_ctx.$slots), (slotName) => {
                return {
                  name: slotName,
                  fn: _withCtx((slotScope) => [
                    _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)))
                  ])
                }
              })
            ]), 1032, ["color", "navigation", "onClickEntry", "is-active"])
          ], 2)
        ], 2)), [
          [_directive_click_away, _ctx.close]
        ])
      : (_openBlock(), _createElementBlock("nav", _mergeProps({
          key: 2,
          class: ['bp-navigation', { 'bp-navigation--compact': _ctx.compactMode }]
        }, _ctx.$attrs), [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (entry) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: entry._id
            }, [
              (entry.to)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: _normalizeClass(['bp-navigation__entry', {
          'bp-navigation__entry--active': _ctx.isEntryActive(entry),
          'bp-navigation__entry--hover': _ctx.submenu === entry._id,
        }]),
                    to: entry.to,
                    "data-id": entry._id,
                    "data-color": _ctx.iconColor,
                    style: _normalizeStyle({
          '--navigation-entry-icon-color': _ctx.iconColor,
        }),
                    onClick: ($event: any) => (_ctx.setActive(entry._id)),
                    onMouseenter: ($event: any) => (_ctx.showSubmenu(entry)),
                    onMouseleave: _ctx.hideSubmenu,
                    onTouchstartPassive: ($event: any) => (_ctx.showSubmenu(entry))
                  }, {
                    default: _withCtx(() => [
                      (entry.icon)
                        ? (_openBlock(), _createBlock(_component_bp_icon, {
                            key: 0,
                            color: _ctx.color,
                            icon: entry.icon
                          }, null, 8, ["color", "icon"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(entry.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "to", "data-id", "data-color", "style", "onClick", "onMouseenter", "onMouseleave", "onTouchstartPassive"]))
                : (entry.children)
                  ? (_openBlock(), _createBlock(_component_bp_popover, {
                      key: 1,
                      color: _ctx.color,
                      data: entry.children,
                      highlighted: _ctx.active(entry),
                      "model-value": _ctx.submenu === entry._id,
                      "seamless-footer": _ctx.seamlessFooter,
                      "seamless-header": _ctx.seamlessHeader,
                      "seamless-options": _ctx.seamlessOptions,
                      seamless: _ctx.seamless,
                      "close-on-click": "",
                      "max-width": "24.5rem",
                      "min-width": "24.5rem",
                      placement: "bottom-start",
                      "search-property": "name",
                      onClickOption: ($event: any) => (_ctx.maybeSetActive(entry, $event)),
                      "onMenu:mouseenter": ($event: any) => (_ctx.showSubmenu(entry)),
                      "onMenu:mouseleave": _ctx.hideSubmenu,
                      onMouseenter: ($event: any) => (_ctx.showSubmenu(entry)),
                      onMouseleave: _ctx.hideSubmenu,
                      onTouchstartPassive: ($event: any) => (_ctx.showSubmenu(entry)),
                      "onUpdate:modelValue": _ctx.hideSubmenu
                    }, _createSlots({
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(['bp-navigation__entry', {
          'bp-navigation__entry--active': _ctx.isEntryActive(entry),
          'bp-navigation__entry--hover': _ctx.submenu === entry._id,
        }]),
                          "data-id": entry._id,
                          "data-color": _ctx.iconColor,
                          style: _normalizeStyle({
          '--navigation-entry-icon-color': _ctx.iconColor,
        })
                        }, [
                          (entry.icon)
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 0,
                                color: _ctx.color,
                                icon: entry.icon
                              }, null, 8, ["color", "icon"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(entry.name), 1),
                          _createVNode(_component_bp_icon, {
                            class: "bp-navigation__dropdown-icon",
                            icon: "angle-right"
                          })
                        ], 14, _hoisted_3)
                      ]),
                      _: 2
                    }, [
                      (_ctx.$slots['header-' + entry._id])
                        ? {
                            name: "header",
                            fn: _withCtx(() => [
                              _renderSlot(_ctx.$slots, 'header-' + entry._id, { option: entry })
                            ]),
                            key: "0"
                          }
                        : undefined,
                      (_ctx.$slots['footer-' + entry._id])
                        ? {
                            name: "footer",
                            fn: _withCtx(() => [
                              _renderSlot(_ctx.$slots, 'footer-' + entry._id, { option: entry })
                            ]),
                            key: "1"
                          }
                        : undefined,
                      _renderList(Object.keys(_ctx.$slots), (slotName) => {
                        return {
                          name: slotName,
                          fn: _withCtx((slotScope) => [
                            _renderSlot(_ctx.$slots, slotName, _mergeProps({ ref_for: true }, slotScope))
                          ])
                        }
                      })
                    ]), 1032, ["color", "data", "highlighted", "model-value", "seamless-footer", "seamless-header", "seamless-options", "seamless", "onClickOption", "onMenu:mouseenter", "onMenu:mouseleave", "onMouseenter", "onMouseleave", "onTouchstartPassive", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(['bp-navigation__entry', {
          'bp-navigation__entry--hover': _ctx.submenu === entry._id,
        }]),
                      "data-id": entry._id,
                      "data-color": _ctx.iconColor,
                      style: _normalizeStyle({
          '--navigation-entry-icon-color': _ctx.iconColor,
        }),
                      onClick: ($event: any) => (_ctx.setActive(entry._id)),
                      onMouseenter: ($event: any) => (_ctx.showSubmenu(entry)),
                      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hideSubmenu && _ctx.hideSubmenu(...args))),
                      onTouchstartPassive: ($event: any) => (_ctx.showSubmenu(entry))
                    }, [
                      (entry.icon)
                        ? (_openBlock(), _createBlock(_component_bp_icon, {
                            key: 0,
                            color: _ctx.color,
                            icon: entry.icon
                          }, null, 8, ["color", "icon"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(entry.name), 1)
                    ], 46, _hoisted_4))
            ], 64))
          }), 128))
        ], 16))
  ], 64))
}