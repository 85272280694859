
import { defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import { getTranslated } from '@/translation';
import { isSafari } from '@/utils/user-agent';
import { localizeDate, localizeTime } from '@/utils/date';
import { Preview, Recording, useVideoStore, Webinar } from '@/stores/video';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-video-preview',
  props: {
    modelValue: Object as PropType<Preview<Recording> | Preview<Webinar> | null>,
    compact: Boolean,
    expanded: Boolean,
    hideWebinarStatus: Boolean,
    hideDescription: Boolean,
    hideButton: Boolean,
    hideIcon: Boolean,
  },
  emits: [
    'update:expanded',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// PREVIEW
    ///-------------------------------------------------------------------

    const preview = ref<Preview<Recording> | Preview<Webinar>>();

    function isWebinar(video: Preview<Recording> | Preview<Webinar>): video is Preview<Webinar> {
      return 'webinar' in video;
    }

    ///-------------------------------------------------------------------
    /// DESCRIPTION
    ///-------------------------------------------------------------------

    const descriptionEl = ref<HTMLElement>();

    const isExpanded = ref(props.expanded);
    const expandible = ref(false);
    watch(() => [props.modelValue, descriptionEl.value], () => {
      updateExpandible()
    }, { immediate: true, deep: true });

    function updateExpandible() {
      window.setTimeout(() => {
        if (!descriptionEl.value) {
          return;
        }
        expandible.value = descriptionEl.value.scrollHeight > Math.ceil(descriptionEl.value.getBoundingClientRect().height);
      }, 0);
    }

    onMounted(() => {
      addEventListener('resize', updateExpandible, { passive: true });
    })
    onUnmounted(() => {
      removeEventListener('resize', updateExpandible);
    })

    function toggle() {
      isExpanded.value = !isExpanded.value;
      ctx.emit('update:expanded', isExpanded.value);
    }

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const videoStore = useVideoStore();

    watch(() => props.modelValue, async () => {
      if (props.modelValue) {
        preview.value = props.modelValue;
      } else {
        preview.value = await videoStore.loadNextWebinar();
      }
    }, { immediate: true, deep: true })

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// TITLE TEXT
    ///-------------------------------------------------------------------
    
    function getTitle() {
      if (!preview.value) return '';
      if (preview.value.featured && preview.value.featured_text) {
        return getTranslated(preview.value.featured_text);
      }
      return getTranslated(preview.value.name);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      descriptionEl,
      expandible,
      getTitle,
      getTranslated,
      isExpanded,
      isSafari,
      isWebinar,
      localizeDate,
      localizeTime,
      preview,
      toggle,
      videoStore,
    }
  }
})

