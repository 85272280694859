
import { ColorProp } from '@/utils/color';
import { computed, defineComponent, PropType } from 'vue';
import { FormError, useFormErrors } from '@/utils/form-errors';
import { IconProp } from './icon/BpIcon';
import { RouteLocationRaw } from 'vue-router';
import { Tooltip } from '@/utils/tooltip';

export default defineComponent({
  name: 'bp-form-button',
  props: {
    action: Function,
    errors: Object as PropType<Map<string, FormError>>,
    tooltip: [String, Object] as PropType<Tooltip>,
    type: {
      type: String as PropType<'save' | 'reset' | 'cancel' | 'custom'>,
      default: 'custom',
    },
    // BUTTON
    active: Boolean,
    color: {
      type: ColorProp,
      default: 'light-blue',
    },
    disabled: Boolean,
    icon: IconProp,
    iconPosition: {
      type: String as PropType<'left' | 'top' | 'right' | 'bottom'>,
      default: 'left'
    },
    large: Boolean,
    to: [String, Object] as PropType<string | RouteLocationRaw>,
  },
  emits: [
    'click',
  ],
  setup(props) {
    ///-------------------------------------------------------------------
    /// COLOR
    ///-------------------------------------------------------------------

    const buttonColor = computed(() => {
      switch (props.type) {
        case 'save': { return 'green' }
        case 'reset': { return 'orange' }
        case 'cancel': { return 'red' }
        default: { return props.color }
      }
    });

    ///-------------------------------------------------------------------
    /// ICON
    ///-------------------------------------------------------------------

    const buttonIcon = computed(() => {
      switch (props.type) {
        case 'save': { return ['far', 'floppy-disk'] }
        case 'reset': { return 'eraser' }
        case 'cancel': { return 'xmark' }
        default: { return props.icon }
      }
    });

    ///-------------------------------------------------------------------
    /// PROPS
    ///-------------------------------------------------------------------

    const buttonProps = computed(() => {
      return Object.fromEntries(Object.entries(props).filter(([key]) => !['action', 'errors', 'type'].includes(key)))
    });

    ///-------------------------------------------------------------------
    /// DISABLED
    ///-------------------------------------------------------------------

    const buttonDisabled = computed(() => props.disabled || (props.errors && props.errors.size > 0));

    ///-------------------------------------------------------------------
    /// TOOLTIP
    ///-------------------------------------------------------------------

    const { formatErrors } = useFormErrors();

    const buttonTooltip = computed<Tooltip>(() => {
      if (props.tooltip) {
        return props.tooltip
      }
      return formatErrors(props.errors);
    });

    ///-------------------------------------------------------------------
    /// CLICK
    ///-------------------------------------------------------------------

    function click() {
      if (props.action) {
        props.action();
      }
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      buttonColor,
      buttonDisabled,
      buttonIcon,
      buttonProps,
      buttonTooltip,
      click,
    }
  }
});
