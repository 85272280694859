import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "bp-card__ribbon" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "bp-card__inner-content",
  ref: "contentEl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_loading = _resolveComponent("bp-loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-card', _ctx.color, {
      'bp-card--borderless': _ctx.borderless,
      'bp-card--compact': _ctx.compactMode,
      'bp-card--fill-height': _ctx.fillHeight,
      'bp-card--fill-width': _ctx.fillWidth,
      'bp-card--has-header': _ctx.hasHeader,
      'bp-card--has-footer': _ctx.hasFooter,
      'bp-card--has-ribbon': _ctx.$slots.ribbon || _ctx.ribbon || _ctx.ribbonIcon,
      'bp-card--max-height': !!_ctx.maxHeight,
      'bp-card--max-width': !!_ctx.maxWidth,
      'bp-card--opaque': _ctx.opaque,
      ['bp-card--tabs-position-' + _ctx.tabsPosition]: _ctx.tabs,
    }]),
    ref: "cardEl",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.flatTabs && _ctx.flatTabs.length > 0 && _ctx.tabsPosition === 'top')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['bp-card__tabs', {
        'bp-card__tabs--seamless': _ctx.seamlessTabs || _ctx.seamless,
      }]),
          ref: "tabsEl",
          style: _normalizeStyle(_ctx.tabsStyle)
        }, [
          _renderSlot(_ctx.$slots, "tabs", {
            tabs: _ctx.tabs,
            flatTabs: _ctx.flatTabs,
            activeTabId: _ctx.activeTabId
          }, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flatTabs, (tab) => {
              return (_openBlock(), _createElementBlock("div", {
                key: tab._id,
                class: _normalizeClass(['bp-card__tab', {
          'bp-card__tab--active': _ctx.activeTabId === tab._id
        }]),
                onClick: ($event: any) => (_ctx.activeTabId = tab._id)
              }, [
                _renderSlot(_ctx.$slots, 'tab-' + tab._id, {
                  tabs: _ctx.tabs,
                  flatTabs: _ctx.flatTabs,
                  tab: tab,
                  activeTabId: _ctx.activeTabId
                }, () => [
                  (tab.icon)
                    ? (_openBlock(), _createBlock(_component_bp_icon, {
                        key: 0,
                        class: "bp-card__tab-icon",
                        icon: tab.icon
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    innerHTML: tab.name
                  }, null, 8, _hoisted_2)
                ], true)
              ], 10, _hoisted_1))
            }), 128))
          ], true)
        ], 6))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "bp-card__wrapper",
      style: _normalizeStyle(_ctx.fillHeight ? _ctx.height : {})
    }, [
      (_ctx.ribbonIcon || _ctx.ribbon || _ctx.$slots.ribbon)
        ? _renderSlot(_ctx.$slots, "ribbon", { key: 0 }, () => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.ribbonIcon)
                ? (_openBlock(), _createBlock(_component_bp_icon, {
                    key: 0,
                    class: "bp-card__ribbon-icon",
                    icon: _ctx.ribbonIcon
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              (_ctx.ribbon)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.ribbon), 1))
                : _createCommentVNode("", true)
            ])
          ], true)
        : _createCommentVNode("", true),
      (_ctx.hasHeader)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['bp-card__header', {
        'bp-card__header--large': _ctx.largeHeader || _ctx.large,
        'bp-card__header--seamless': _ctx.seamlessHeader || _ctx.seamless,
      }])
          }, [
            (_ctx.tabs)
              ? _renderSlot(_ctx.$slots, 'tab-header-' + _ctx.activeTabId, { key: 0 }, () => [
                  _renderSlot(_ctx.$slots, "tabs-header", {}, undefined, true)
                ], true)
              : _renderSlot(_ctx.$slots, "header", { key: 1 }, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['bp-card__content', {
        'bp-card__content--large': _ctx.largeContent || _ctx.large,
        'bp-card__content--reverse': _ctx.tabs && _ctx.tabsPosition === 'inside-reverse',
        'bp-card__content--seamless': _ctx.seamlessContent || _ctx.seamless,
      }])
      }, [
        _createVNode(_component_bp_loading, { "model-value": _ctx.loading }, null, 8, ["model-value"]),
        (_ctx.tabs && _ctx.tabsPosition.startsWith('inside'))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.flatTabs && _ctx.flatTabs.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['bp-card__inner-tabs', {
              'bp-card__inner-tabs--seamless': _ctx.seamlessTabs || _ctx.seamless,
            }]),
                    style: _normalizeStyle(_ctx.tabsStyle)
                  }, [
                    _renderSlot(_ctx.$slots, "tabs", {
                      tabs: _ctx.tabs,
                      flatTabs: _ctx.flatTabs,
                      activeTabId: _ctx.activeTabId
                    }, () => [
                      (!!_ctx.flatTabs.find(t => t._is_group))
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.flatTabs.filter(t => t._is_group), (group) => {
                            return (_openBlock(), _createElementBlock("details", {
                              key: group._id,
                              class: "bp-card__tab-group",
                              open: ""
                            }, [
                              _createElementVNode("summary", null, [
                                _createVNode(_component_bp_icon, {
                                  class: "bp-card__tab-group-marker",
                                  icon: "angle-right"
                                }),
                                _createElementVNode("div", {
                                  class: _normalizeClass(['bp-card__tab', {
                        'bp-card__tab--active': _ctx.activeTabId === group._id,
                        'bp-card__tab--group': group._is_group,
                      }]),
                                  style: _normalizeStyle({ '--card-tab-depth': group._depth }),
                                  onClick: ($event: any) => (_ctx.activeTabId = group._id)
                                }, [
                                  _renderSlot(_ctx.$slots, 'tab-' + group._id, {
                                    tabs: _ctx.tabs,
                                    flatTabs: _ctx.flatTabs,
                                    tab: group,
                                    activeTabId: _ctx.activeTabId
                                  }, () => [
                                    (group.icon && !group._is_group)
                                      ? (_openBlock(), _createBlock(_component_bp_icon, {
                                          key: 0,
                                          class: "bp-card__tab-icon",
                                          icon: group.icon
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("span", {
                                      innerHTML: group.name
                                    }, null, 8, _hoisted_6)
                                  ], true)
                                ], 14, _hoisted_5)
                              ]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flatTabs.filter(t => t._group === group.name), (tab) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: tab._id,
                                  class: _normalizeClass(['bp-card__tab', {
                      'bp-card__tab--active': _ctx.activeTabId === tab._id,
                      'bp-card__tab--group': tab._is_group,
                    }]),
                                  style: _normalizeStyle({ '--card-tab-depth': tab._depth }),
                                  onClick: ($event: any) => (_ctx.activeTabId = tab._id)
                                }, [
                                  _renderSlot(_ctx.$slots, 'tab-' + tab._id, {
                                    tabs: _ctx.tabs,
                                    flatTabs: _ctx.flatTabs,
                                    tab: tab,
                                    activeTabId: _ctx.activeTabId
                                  }, () => [
                                    (tab.icon && !tab._is_group)
                                      ? (_openBlock(), _createBlock(_component_bp_icon, {
                                          key: 0,
                                          class: "bp-card__tab-icon",
                                          icon: tab.icon
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("span", {
                                      innerHTML: tab.name
                                    }, null, 8, _hoisted_8)
                                  ], true)
                                ], 14, _hoisted_7))
                              }), 128))
                            ]))
                          }), 128))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.flatTabs, (tab) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: tab._id,
                              class: _normalizeClass(['bp-card__tab', {
                    'bp-card__tab--active': _ctx.activeTabId === tab._id,
                    'bp-card__tab--group': tab._is_group,
                  }]),
                              style: _normalizeStyle({ '--card-tab-depth': tab._depth }),
                              onClick: ($event: any) => (_ctx.activeTabId = tab._id)
                            }, [
                              _renderSlot(_ctx.$slots, 'tab-' + tab._id, {
                                tabs: _ctx.tabs,
                                flatTabs: _ctx.flatTabs,
                                tab: tab,
                                activeTabId: _ctx.activeTabId
                              }, () => [
                                (tab.icon && !tab._is_group)
                                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                                      key: 0,
                                      class: "bp-card__tab-icon",
                                      icon: tab.icon
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", {
                                  innerHTML: tab.name
                                }, null, 8, _hoisted_10)
                              ], true)
                            ], 14, _hoisted_9))
                          }), 128))
                    ], true)
                  ], 6))
                : _createCommentVNode("", true),
              (_ctx.flatTabs && _ctx.flatTabs.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flatTabs, ({ _id: tabId }) => {
                      return _withDirectives((_openBlock(), _createElementBlock("div", { key: tabId }, [
                        _renderSlot(_ctx.$slots, 'tab-content-' + tabId, { activeTabId: _ctx.activeTabId }, () => [
                          _renderSlot(_ctx.$slots, "tabs-content", { activeTabId: _ctx.activeTabId }, undefined, true)
                        ], true)
                      ], 512)), [
                        [_vShow, tabId === _ctx.activeTabId]
                      ])
                    }), 128))
                  ], 512))
                : _createCommentVNode("", true)
            ], 64))
          : (_ctx.flatTabs && _ctx.flatTabs.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.flatTabs, ({ _id: tabId }) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", { key: tabId }, [
                  _renderSlot(_ctx.$slots, 'tab-content-' + tabId, { activeTabId: _ctx.activeTabId }, () => [
                    _renderSlot(_ctx.$slots, "tabs-content", { activeTabId: _ctx.activeTabId }, undefined, true)
                  ], true)
                ], 512)), [
                  [_vShow, tabId === _ctx.activeTabId]
                ])
              }), 128))
            : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
      ], 2),
      (_ctx.hasFooter)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(['bp-card__footer', {
        'bp-card__footer--large': _ctx.largeFooter || _ctx.large,
        'bp-card__footer--seamless': _ctx.seamlessFooter || _ctx.seamless,
      }])
          }, [
            (_ctx.tabs)
              ? _renderSlot(_ctx.$slots, 'tab-footer-' + _ctx.activeTabId, { key: 0 }, () => [
                  _renderSlot(_ctx.$slots, "tabs-footer", {}, undefined, true)
                ], true)
              : _renderSlot(_ctx.$slots, "footer", { key: 1 }, undefined, true)
          ], 2))
        : _createCommentVNode("", true)
    ], 4)
  ], 6))
}