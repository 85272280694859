
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { getTranslated } from '@/translation';
import { defineComponent, ref, watch, onMounted, onUnmounted, PropType, computed } from 'vue';
import { fileIcon, IconProp } from './icon/BpIcon';
import { formatBytes } from '@/utils/string';
import { logout } from '@/utils/auth';
import { Navigation, NavigationEntry } from './navigation/BpNavigation';
import { useRouter } from 'vue-router';
import { useUser } from '@/utils/user';
import BpNavigationVue from './navigation/BpNavigation.vue';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-header',
  props: {
    mainNav: Array as PropType<Navigation>,
    mainNavIcon: IconProp,
    sideNav: Array as PropType<Navigation>,
    sideNavIcon: IconProp,
    isActive: Function as PropType<(entry: NavigationEntry) => boolean>,
  },
  components: {
    BpNavigation: BpNavigationVue,
  },
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const user = useUser();

    ///-------------------------------------------------------------------
    /// NAVIGATION
    ///-------------------------------------------------------------------

    const mainNavEl = ref<typeof BpNavigationVue>();

    ///-------------------------------------------------------------------
    /// ACCOUNT MENU
    ///-------------------------------------------------------------------

    const sideNavEl = ref<typeof BpNavigationVue>();

    ///-------------------------------------------------------------------
    /// SLOTS
    ///-------------------------------------------------------------------

    const mainNavSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => slot.startsWith('main-nav-')).map(slot => [slot, slot.substring('main-nav-'.length)]);
    })
    const sideNavSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => slot.startsWith('side-nav-')).map(slot => [slot, slot.substring('side-nav-'.length)]);
    })

    ///-------------------------------------------------------------------
    /// ACTIVE
    ///-------------------------------------------------------------------

    const isActiveFn = computed(() => props.isActive !== undefined ? props.isActive : isActive);

    const router = useRouter();
    const activeId = ref(findActiveId());

    // DOM elements
    const headerEl = ref<HTMLElement>();
    const activeBarEl = ref<HTMLDivElement>();

    function updateActiveBar() {
      setTimeout(() => {
        if (!activeId.value) {
          return;
        }
        const activeEl = document.querySelector(`[data-id="${activeId.value}"]`) as HTMLElement | undefined;
        if (!headerEl.value || !activeBarEl.value || !activeEl) {
          return;
        }
        const padding = parseFloat(window.getComputedStyle(activeBarEl.value).getPropertyValue('--padding')) * 16 / 2;
        const background = activeEl.getAttribute('data-color') || 'var(--color-light-blue-500)';
        const width = (activeEl.getBoundingClientRect().width - 2 * padding) + 'px';
        const transform = `translateX(${(activeEl.getBoundingClientRect().x - headerEl.value.getBoundingClientRect().x + padding)}px)`;
        activeBarEl.value.style.background = background;
        activeBarEl.value.style.width = width;
        activeBarEl.value.style.transform = transform;
      }, 0)
    }

    watch(() => [activeId.value, activeBarEl.value, mainNavEl.value, sideNavEl.value, user.value], updateActiveBar, { immediate: true, deep: true });
    onMounted(() => addEventListener('resize', updateActiveBar, { passive: true }));
    onUnmounted(() => removeEventListener('resize', updateActiveBar));
    router.afterEach(() => {
      updateActiveId()});

    function findActiveId() {
      return [...(props.mainNav ? props.mainNav : []), ...(props.sideNav ? props.sideNav : [])].find((entry: NavigationEntry) => isActiveFn.value(entry))?._id || '';
    }

    function updateActiveId(id?: string) {
      if (!id) {
        id = findActiveId();
      }
      activeId.value = id;
    }

    function isActive(entry: NavigationEntry) {
      if (!router.currentRoute.value.name) {
        return false;
      }

      function *elements(el: NavigationEntry): Generator<NavigationEntry> {
        yield el;
        if (el.children) {
          for (const child of el.children) {
            yield *elements(child);
          }
        }
      }

      const routes = [];
      for (const element of elements(entry)) {
        routes.push((element.to && typeof element.to !== 'string' && 'name' in element.to) ? element.to.name : '');
      }
      return routes.includes(router.currentRoute.value.name);
    }

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);

    ///-------------------------------------------------------------------
    /// COMPACT MODE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeBarEl,
      activeId,
      compactMode,
      fileIcon,
      formatBytes,
      getTranslated,
      headerEl,
      isActiveFn,
      isDesktop,
      logout,
      mainNavEl,
      mainNavSlots,
      sideNavEl,
      sideNavSlots,
      updateActiveId,
      user,
    }
  },
});
