import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-toast__icon"
}
const _hoisted_2 = { class: "bp-toast__content-wrapper" }
const _hoisted_3 = {
  key: 1,
  class: "bp-toast__title"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedToasts, (vertical, verticalPosition) => {
    return (_openBlock(), _createElementBlock("div", {
      key: verticalPosition,
      class: _normalizeClass(['bp-toasts', 'bp-toasts__vertical', 'bp-toasts__vertical--' + verticalPosition])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vertical, (horizontal, horizontalPosition) => {
        return (_openBlock(), _createElementBlock("div", {
          key: horizontalPosition,
          class: _normalizeClass(['bp-toasts__horizontal', 'bp-toasts__horizontal--' + horizontalPosition])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(horizontal, (toast) => {
            return (_openBlock(), _createElementBlock("div", {
              key: toast._id,
              class: _normalizeClass({
          'bp-toast': true,
          ['bp-toast--state-' + toast.state]: true
        }),
              style: _normalizeStyle(_ctx.toastVariables(toast))
            }, [
              (toast.icon)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                    _createVNode(_component_bp_icon, {
                      icon: toast.icon
                    }, null, 8, ["icon"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                (toast.closable)
                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                      key: 0,
                      class: "bp-toast__close-icon",
                      icon: "xmark",
                      onClick: _withModifiers(($event: any) => (_ctx.onClickHideToast(toast)), ["stop"])
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (toast.title)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(toast.title), 1))
                  : _createCommentVNode("", true),
                (toast.content)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "bp-toast__content",
                      innerHTML: toast.content
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]),
              (toast.duration > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    ref_for: true,
                    ref: toast._id,
                    class: "bp-toast__progress-bar"
                  }, null, 512))
                : _createCommentVNode("", true)
            ], 6))
          }), 128))
        ], 2))
      }), 128))
    ], 2))
  }), 128))
}