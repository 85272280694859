import { clearStores } from './store';
import { json } from '@sahnee/ajax';
import { SessionUser } from './user';
import router from '@/router';

export async function logout(user?: SessionUser) {
  if (!user) {
    return;
  }

  const response = await json<{ logged_out: boolean }>('/api/logout', {
    method: 'POST',
    json: user,
  });

  if (response.logged_out) {
    router.replace({ name: 'login' });

    // Clear all stores
    window.setTimeout(clearStores, 0)
  }
}