
import { defineComponent, PropType } from 'vue';
import { Tooltip } from '@/utils/tooltip';

export default defineComponent({
  name: 'bp-tooltip',
  props: {
    tooltip: [String, Object] as PropType<Tooltip>,
    condition: Boolean,
  },
  inheritAttrs: false,
  setup() {
    return {}
  }
});
