
import { defineComponent, PropType } from 'vue';
import { fileIcon, subIcon } from '../icon/BpIcon';
import { formatBytes } from '@/utils/string';
import { getTranslated } from '@/translation';
import { File, useDownloadStore } from '@/stores/download';
import { useVersionStore } from '@/stores/version';
import { localizeDate } from '@/utils/date';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-download-preview',
  props: {
    modelValue: {
      type: Object as PropType<File>,
      required: true,
    },
    hideChangelog: Boolean
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORES
    ///-------------------------------------------------------------------

    const downloadStore = useDownloadStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// APPREARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      downloadStore,
      fileIcon,
      formatBytes,
      getTranslated,
      localizeDate,
      subIcon,
      versionStore,
    }
  }
});
