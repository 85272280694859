import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "bp-toggle-button__item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-toggle-button', { 'bp-toggle-button--compact': _ctx.compactMode }]),
    ref: "toggleEl",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(['bp-toggle-button__item', {
      'bp-toggle-button__item--active': _ctx.activeId === item._id
    }]),
        key: item._id,
        onClick: ($event: any) => (_ctx.click(item))
      }, [
        (item.icon)
          ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
              class: "bp-toggle-button__item-icon",
              key: _ctx.activeId,
              icon: item.icon
            }, null, 8, ["icon"])), [
              [_directive_tooltip, _ctx.activeId !== item._id ? item.text : undefined]
            ])
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.text), 1)
      ], 10, _hoisted_1))
    }), 128))
  ], 6))
}