import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bp-loading__icon" }
const _hoisted_2 = { class: "bp-loading__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['bp-loading', { 'bp-loading--fullscreen': _ctx.fullscreen, 'bp-loading--inline': _ctx.inline }])
      }, [
        _createElementVNode("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_component_bp_icon, {
              icon: _ctx.icon,
              spin: ""
            }, null, 8, ["icon"])
          ], true)
        ]),
        _createElementVNode("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createElementVNode("span", null, _toDisplayString(_ctx.loadingText), 1)
          ], true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}