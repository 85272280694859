import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, renderList as _renderList, createSlots as _createSlots, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-placement"]
const _hoisted_2 = {
  key: 0,
  class: "bp-popover-menu__header"
}
const _hoisted_3 = ["onClick", "data-option-id"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick", "data-option-id"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 3,
  class: "bp-popover-menu__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_virtual_scroller = _resolveComponent("bp-virtual-scroller")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_popover = _resolveComponent("bp-popover")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'bp-popover-menu',
      {
        'bp-popover-menu--compact': _ctx.compactMode,
        'bp-popover-menu--has-data': _ctx.data.length > 0,
        'bp-popover-menu--has-footer': _ctx.$slots.footer,
        'bp-popover-menu--has-header': _ctx.$slots.header,
        'bp-popover-menu--highlighted-first': _ctx.data.length > 0 && _ctx.highlighted?.has(_ctx.data[0]._id),
        'bp-popover-menu--highlighted-last': _ctx.data.length > 0 && _ctx.highlighted?.has(_ctx.data[_ctx.data.length - 1]._id),
        'bp-popover-menu--is-flat': _ctx.flat,
        'bp-popover-menu--is-loading': _ctx.loading,
        'bp-popover-menu--match-reference-size': _ctx.matchReferenceSize,
        'bp-popover-menu--seamless-header': _ctx.seamless || _ctx.seamlessHeader,
        'bp-popover-menu--seamless-options': _ctx.seamless || _ctx.seamlessOptions,
        'bp-popover-menu--seamless-footer': _ctx.seamless || _ctx.seamlessFooter,
        'bp-popover-menu--unwrap': _ctx.unwrap,
      }
    ]),
    "data-placement": _ctx.placement,
    ref: "dom",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_bp_virtual_scroller, {
          key: 1,
          class: "bp-popover-menu__options",
          data: [
        {
          _id: 'loading',
          name: 'Loading…'
        }
      ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_bp_loading, { "model-value": true })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_bp_virtual_scroller, {
          key: 2,
          class: "bp-popover-menu__options",
          data: _ctx.data,
          "row-height": _ctx.compactMode ? 34.4 : 50.4
        }, {
          default: _withCtx(({ item, index }) => [
            (item && !!_ctx.children(item) && _ctx.children(item).length > 0)
              ? (_openBlock(), _createBlock(_component_bp_popover, _mergeProps({ key: 0 }, {..._ctx.$props, ..._ctx.$attrs}, {
                  highlighted: _ctx.highlighted,
                  data: _ctx.children(item),
                  "model-value": _ctx.submenu === item._id,
                  offset: [-2, 0],
                  unwrap: _ctx.unwrap,
                  flat: "",
                  placement: "right-start",
                  onClickOption: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('clickOption', $event);}),
                  "onMenu:mouseenter": ($event: any) => (_ctx.showSubmenu(item)),
                  "onMenu:mouseleave": _ctx.hideSubmenu,
                  onMouseenter: ($event: any) => (_ctx.showSubmenu(item)),
                  onMouseleave: _ctx.hideSubmenu,
                  onTouchstartPassive: ($event: any) => (_ctx.showSubmenu(item)),
                  "onUpdate:modelValue": _ctx.hideSubmenu
                }), _createSlots({
                  default: _withCtx(() => [
                    (item || _ctx.$slots[_ctx.slotName(item)] || _ctx.$slots.option)
                      ? _withDirectives((_openBlock(), _createElementBlock("div", {
                          onClick: ($event: any) => (_ctx.clickOption(item, $event)),
                          onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleKeyboardInput && _ctx.handleKeyboardInput(...args))),
                          class: _normalizeClass(['bp-popover-menu__option', {
              'bp-popover-menu__option--disabled': item._disabled,
              'bp-popover-menu__option--first': index === 0,
              'bp-popover-menu__option--highlighted': _ctx.highlighted?.has(item._id),
              'bp-popover-menu__option--subheading': item._subheading,
            }]),
                          tabindex: "0",
                          "data-option-id": item._id,
                          key: _ctx.dom?.getBoundingClientRect().width
                        }, [
                          _renderSlot(_ctx.$slots, _ctx.slotName(item), {
                            option: item,
                            index: index,
                            disabled: item._disabled,
                            highlighted: _ctx.highlighted?.has(item._id),
                            settings: { ..._ctx.$props }
                          }, () => [
                            _renderSlot(_ctx.$slots, "option", {
                              option: item,
                              index: index,
                              disabled: item._disabled,
                              highlighted: _ctx.highlighted?.has(item._id),
                              settings: { ..._ctx.$props }
                            }, () => [
                              (item.icon && !item._subheading)
                                ? (_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 0,
                                    class: "bp-popover-menu__option-icon",
                                    icon: item.icon
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                class: "bp-popover-menu__option-content",
                                innerHTML: _ctx.itemValue(item)
                              }, null, 8, _hoisted_4),
                              _createVNode(_component_bp_icon, {
                                class: "bp-popover-menu__submenu-icon",
                                icon: "angle-right"
                              })
                            ], true)
                          ], true)
                        ], 42, _hoisted_3)), [
                          [_directive_tooltip, _ctx.tooltip(_ctx.itemValue(item))]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, [
                  _renderList(Object.keys(_ctx.$slots).filter(slot => slot.startsWith('option')), (slotName) => {
                    return {
                      name: slotName,
                      fn: _withCtx((slotScope) => [
                        _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
                      ])
                    }
                  })
                ]), 1040, ["highlighted", "data", "model-value", "unwrap", "onMenu:mouseenter", "onMenu:mouseleave", "onMouseenter", "onMouseleave", "onTouchstartPassive", "onUpdate:modelValue"]))
              : (item || _ctx.$slots[_ctx.slotName(item)] || _ctx.$slots.option)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    onClick: ($event: any) => (_ctx.clickOption(item, $event)),
                    onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyboardInput && _ctx.handleKeyboardInput(...args))),
                    class: _normalizeClass(['bp-popover-menu__option', {
            'bp-popover-menu__option--disabled': item._disabled,
            'bp-popover-menu__option--first': index === 0,
            'bp-popover-menu__option--highlighted': _ctx.highlighted?.has(item._id),
            'bp-popover-menu__option--subheading': item._subheading,
          }]),
                    tabindex: "0",
                    "data-option-id": item._id,
                    key: _ctx.dom?.getBoundingClientRect().width
                  }, [
                    _renderSlot(_ctx.$slots, _ctx.slotName(item), {
                      option: item,
                      index: index
                    }, () => [
                      _renderSlot(_ctx.$slots, "option", {
                        option: item,
                        index: index
                      }, () => [
                        (item.icon && !item._subheading)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              class: "bp-popover-menu__option-icon",
                              icon: item.icon
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: "bp-popover-menu__option-content",
                          innerHTML: _ctx.itemValue(item)
                        }, null, 8, _hoisted_6),
                        (_ctx.isExternalLink(item))
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 1,
                              class: "bp-popover-menu__submenu-icon",
                              icon: "up-right-from-square"
                            }))
                          : _createCommentVNode("", true)
                      ], true)
                    ], true)
                  ], 42, _hoisted_5)), [
                    [_directive_tooltip, _ctx.tooltip(_ctx.itemValue(item))]
                  ])
                : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["data", "row-height"])),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}