import { inject, provide, Ref, ref } from 'vue';

const DARK_MODE_SYMBOL = ('~DARKMODE_SYMBOL');

export function useDarkModeProvider(opts: { initialDarkMode: boolean }) {
  const darkMode = ref<boolean>(opts.initialDarkMode);

  provide(DARK_MODE_SYMBOL, darkMode);

  return {
    darkMode,
  };
}

export default function useDarkMode() {
  const injectedDarkMode = inject<Ref<boolean>>(DARK_MODE_SYMBOL);

  function toggleDarkMode() {
    const root = document.querySelector('html');
    if (root) {
      const oldDarkMode = injectedDarkMode?.value;
      const newDarkMode = !oldDarkMode;
      if (injectedDarkMode) {
        injectedDarkMode.value = newDarkMode;
      }
    }
  }

  return {
    current: injectedDarkMode,
    toggle: toggleDarkMode,
  };
}
