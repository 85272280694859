import { BaseDoc } from '@/components/virtual-scroller/BpVirtualScroller';
import { partition } from './array';
import clone from '@sahnee/clone';

export type RequiredKeys<T, K extends keyof T> = T extends unknown ? T & { [P in K]-?: T[P] } : never;
export type OptionalKeys<T, K extends keyof T> = T extends unknown ? Pick<Partial<T>, K> & Omit<T, K> : never;

/**
 * **Preserves IntelliSense hints of type `T` but allows any `string` as its value.**
 *
 * From the type system's point of view, `string` is a supertype of any string literal like 'my' or 'text', and so a union `string | 'my' | 'text'` is the same as `string` and the compiler
 * aggressively reduces such unions to `string`.
 *
 * The type `string & Record<never, never>` is conceptually the same as `string`, since the empty type `Record<never, never>` matches any non-`null` and non-`undefined` type.
 * But the compiler does not perform this reduction. From this type you can build your union like `(string & Record<never, never>) | 'my' | 'text'`, and the compiler will keep this representation
 * long enough to give you IntelliSense hints.
 */
export type LiteralUnion<T> = (string & Record<never, never>) | T

///-------------------------------------------------------------------
/// TREE
///-------------------------------------------------------------------

export type Tree<T> = T & { _children?: Tree<T>[] }
export type Flat<T> = T & { _depth: number }

export function tree<T extends BaseDoc>(array: T[], parentKey: KeyOf<T>) {
  // Clone the array to allow mutation
  array = clone(array);

  // Find root elements
  const [tree] = [, array] = partition(array, node => node[parentKey] === 'root');

  // Build the tree based on the root elements
  function buildTree(root: T[]) {
    for (const parent of root) {
      const [children] = [, array] = partition(array, (node => node[parentKey] === parent._id));
      if (children.length > 0) {
        (parent as Tree<T>)._children = children as Tree<T>[];
        buildTree(children)
      }
    }
  }
  buildTree(tree);

  // Return the built tree
  return tree as Tree<T>[];
}

export function isTree<T extends BaseDoc>(tree: Tree<T> | [Tree<T>]): boolean;
export function isTree<T extends BaseDoc>(tree: Tree<T>[]): boolean;
export function isTree<T extends BaseDoc>(tree: Tree<T> | Tree<T>[]): boolean;
export function isTree<T extends BaseDoc>(tree: Tree<T> | Tree<T>[]) {
  return Array.isArray(tree) ? tree.find(doc => '_children' in doc) !== undefined : '_children' in tree;
}

export function flatten<T extends BaseDoc>(tree: Tree<T> | [Tree<T>]): Flat<Tree<T>>[];
export function flatten<T extends BaseDoc>(tree: Tree<T>[]): Flat<Tree<T>>[];
export function flatten<T extends BaseDoc>(tree: Tree<T> | Tree<T>[]): Flat<Tree<T>>[];
export function flatten<T extends BaseDoc>(tree: Tree<T> | Tree<T>[]) {
  function *flatDocs() {
    function *iterate(doc: Flat<Tree<T>>, depth = 0): Generator<T> {
      yield { ...doc, _depth: doc._depth || depth } as Flat<Tree<T>>;
      if (doc._children) {
        for (const child of doc._children) {
          yield *iterate(child as Flat<T>, depth + 1);
        }
      }
    }

    if (Array.isArray(tree)) {
      for (const doc of tree) {
        yield *iterate(doc as Flat<Tree<T>>);
      }
    } else {
      yield *iterate(tree as Flat<Tree<T>>);
    }
  }

  const docs: T[] = [];
  for (const doc of flatDocs()) {
    docs.push(doc);
  }
  return docs as Flat<Tree<T>>[];
}

export function subtree<T extends BaseDoc>(tree: Tree<T>[], id: string) {
  function iterate(doc: Tree<T>): Tree<T> | undefined {
    if (doc._id === id) {
      return doc;
    }
    if (doc._children) {
      for (const child of doc._children) {
        const found = iterate(child);
        if (found) {
          return found;
        }
      }
    }
  }

  for (const doc of tree) {
    const found = iterate(doc);
    if (found) {
      return found;
    }
  }
}

export function path<T extends BaseDoc>(array: T[], ids: string | [string]): T[];
export function path<T extends BaseDoc>(array: T[], ids: string[]): T[][];
export function path<T extends BaseDoc>(array: T[], ids: string | string[]): T[] | T[][];
export function path<T extends BaseDoc>(array: T[], ids: string | string[]): T[] | T[][] {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }
  const paths = []
  for (const id of ids) {
    let element = array.find(el => el._id === id);
    const path: T[] = [];
    while (element && element.parent_id !== 'root') {
      const parent = array.find(el => el._id === element?.parent_id);
      if (parent) {
        path.unshift(parent);
      }
      element = parent;
    }
    paths.push(path);
  }
  return ids.length === 1 ? paths[0] : paths;
}

/**
 * Create an object type from `T`, where all the individual properties are mapped to a string type if the value is not
 * an object or union of string types containing the current and descendant possibilities when it's an object type.
 */
export type KeyOf<T> = {
  [TKey in keyof T & (string | number)]: KeyOfHandleValue<T[TKey], `${TKey}`>;
}[keyof T & (string | number)];

/**
 * This type does the same as `KeyOf`, but since we're handling nested properties at this point, it creates the
 * strings for property access and index access
 */
type KeyOfNested<T> = {
  [TKey in keyof T & (string | number)]: KeyOfHandleValue<T[TKey], `.${TKey}`>;
}[keyof T & (string | number)];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyOfHandleValue<TValue, Text extends string> = TValue extends any[]
  ? Text
  : TValue extends Record<string, unknown>
    ? Text | `${Text}${KeyOfNested<TValue>}`
    : Text;

/**
 * Gets a possibly deeply nested value for a given key, which is given in a dotted string format.
 * @param obj The object.
 * @param keyString The dotted string of possibly nested keys of the object.
 * @returns The value by the possibly deeply nested key of the object.
 */
export function deepValue<T>(obj: T, keyString: KeyOf<T>) {
  const keys = keyString.split('.') as (keyof T)[];

  let key: keyof T;
  let idx = 0;
  while (idx < keys.length - 1) {
    key = keys[idx];

    const value = obj[key];
    if (value !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      obj = value as any;
      idx++
    } else {
      break;
    }
  }
  return obj[keys[idx]];
}

/**
 * Checks if the given item is an object or not.
 * @returns Whether the item is an object or not.
 */
export function isObject<T>(item: T) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Merges two objects deeply.
 * @param target The target object.
 * @param source The source object.
 * @returns The merged object.
 */
export default function deepMerge<T extends Record<string, unknown>>(target: T, source: T) {
  const output: T = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key: keyof T) => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = deepMerge(target[key] as T, source[key] as T) as T[keyof T];
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * Checks the equality of two given objects in all of their key value pairs.
 * @param obj1 The first object
 * @param obj2 The seconds object
 * @returns Whether the objects are equal in all of their key value pairs or not.
 */
export function isEqual<T extends Record<string, unknown>>(obj1: T, obj2: T) {
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (let i = 0; i < props1.length; i++) {
    const val1 = obj1[props1[i]];
    const val2 = obj2[props1[i]];
    const isObjects = isObject(val1) && isObject(val2);
    if (
      (isObjects && !isEqual(val1 as T, val2 as T)) ||
      (!isObjects && Array.isArray(val1) && Array.isArray(val2) && val1.length !== val2.length) && !val1.every((val, index) => val === val2[index]) ||
      (!isObjects && !Array.isArray(val1) && !Array.isArray(val2) && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Declares a function which will be used as the predicate to filter the objects entries.
 */
export type ObjectKeyValueFunction<T, TReturn, TKey extends keyof T = keyof T> = (key: TKey, value: T[TKey], obj: T) => TReturn;

 /**
  * Filters a given object by the given predicate function.
  * @param obj The object.
  * @param predicate A function to filter by. It is called one time for each entry in the object.
  * @returns The filtered object.
  */
export function filterObject<T extends { [key: string]: unknown }, K extends KeyOf<T>, V extends T[K]>(obj: T, predicate: ObjectKeyValueFunction<T, boolean>): T {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => predicate(key as K, value as V, obj))) as T;
}
