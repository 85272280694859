
import 'normalize.css';
import { $gettext } from 'vue-gettext';
import { Breakpoint, useMedia } from './utils/responsive-breakpoints';
import { CheckSession, SessionUser } from './utils/user';
import { computed, defineComponent, onMounted, onUnmounted, provide, ref, watch, watchEffect } from 'vue';
import { getTranslated } from './translation';
import { json } from '@sahnee/ajax';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import { useCompactModeProvider } from './compositions/use-compact-mode';
import { useConfigStore } from './stores/config';
import { useDarkModeProvider } from '@/compositions/use-dark-mode';
import { useVideoStore, Webinar } from './stores/video';
import { uuid } from './utils/string';
import BpLayout from './components/layout/BpLayout.vue';

export default defineComponent({
  components: {
    BpLayout
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const configStore = useConfigStore();
    const videoStore = useVideoStore();

    ///-------------------------------------------------------------------
    /// LAYOUT
    ///-------------------------------------------------------------------

    const useLayout = computed<boolean>(() => !!router.currentRoute.value.meta.useLayout);

    ///-------------------------------------------------------------------
    /// ROUTING GUARDS
    ///-------------------------------------------------------------------

    const user = ref<SessionUser>();
    const router = useRouter();

    setInterval(async () => {
      if (!user.value) {
        return
      }
      const response = await json<CheckSession>('/api/check/login', { method: 'POST' });
      if (response.status === 'logged_in') {
        user.value.roles = response.user.roles;
        if (!user.value.roles.includes('admin') || user.value.roles?.includes('impersonate_user')) {
          if (router.currentRoute.value.name?.toString().startsWith('admin.')) {
            router.replace({
              name: 'home',
            });
          }
        } else {
          if (router.currentRoute.value.name?.toString().startsWith('home')) {
            router.replace({
              name: 'admin.dashboard',
            });
          }
        }
        return;
      }
      if (router.currentRoute.value.name === 'login') {
        return;
      }
      router.replace({
        name: 'login',
      })
    }, 5000);

    router.beforeEach(async (to, from) => {
      // Ensure that no select components are left open, once the route changes
      const e = new CustomEvent('click-select', { detail: '' });
      document.body.dispatchEvent(e);

      // Fullscreen loading mask
      loading.value = true;

      // Check if user has session and cookie
      const response = await json<CheckSession>('/api/check/login', { method: 'POST' });

      // User has session and cookie
      if (response.status === 'logged_in') {
        user.value = {
          _id: response.user._id,
          email: response.user.email,
          company: response.user.company,
          roles: response.user.roles,
        }

        // If the user initially wanted to go to a specific page, use the redirect information of the source destination to redirect the user to the initial destination
        const redirectTo = localStorage.getItem(`bp:redirect_${from.query['redirect']}`) !== null
          ? JSON.parse(window.atob(localStorage.getItem(`bp:redirect_${from.query['redirect']}`) as string)) as RouteLocationNormalized
          : undefined;
        localStorage.removeItem('bp:redirect_' + from.query['redirect']);
        const redirect = !!(redirectTo && redirectTo.fullPath !== to.fullPath);
        to = redirect ? redirectTo : to;

        // Checks if the user is allowed to go to the apps
        //-------------------------------------------------------------------
        // USER
        //-------------------------------------------------------------------
        if (user.value.roles?.includes('user') || user.value.roles?.includes('impersonate_user')) {
          // limit the special admin apps
          if (to.name?.toString().startsWith('admin.')) {
            return { name: 'home' };
          }
          // If we try to go to the login page, reroute to the home view
          // except when we click on a link in an webinar reminder email - there we have a token AND the action webinar on the login page -> then reroute directly to the video area
          else if (to.name === 'login') {
            if (to.query.action === 'webinar' && !!to.query.token) {
              if (videoStore.loaded) {
                const video = (videoStore.videos.find(video => 'webinar' in video ? video.webinar.token === to.query.token : false) as Webinar);
                if (!video) {
                  return { name: 'video.area' }
                }
                const allowed = await videoStore.checkVideo(video._id);
                if (allowed) {
                  return { name: 'video.play', params: { slug: video.slug } }
                }
              }
              return { name: 'video.area' }
            }
            return { name: 'home' }
          } else if (to.name?.toString() === 'video.play') {
            let videoSlug = Array.isArray(to.params.slug) ? to.params.slug[0] : to.params.slug;
            const allowed = await videoStore.checkVideo(videoSlug);
            return allowed ? (redirect ? to : true) : { name: 'home' };
          } else {
            return redirect ? to : true;
          }
        }
        //-------------------------------------------------------------------
        // ANONYMOUS
        //-------------------------------------------------------------------
        else if (user.value.roles?.includes('anonymous')) {
          return to.name?.toString() === 'video.play';
        }
        //-------------------------------------------------------------------
        // ADMIN
        //-------------------------------------------------------------------
        else {
          if (to.name?.toString().startsWith('home')) {
            return { name: 'admin.dashboard' };
          }
          // If we try to go to the login page, reroute to the admin dashboard
          // except when we click on a link in an webinar reminder email - there we have a token AND the action webinar on the login page -> then reroute directly to the webinar instead
          else if (to.name === 'login') {
            if (videoStore.loaded && to.query.action === 'webinar' && !!to.query.token) {
              const slug = (videoStore.videos.find(video => 'webinar' in video ? video.webinar.token === to.query.token : false) as Webinar)?.slug || undefined;
              if (slug) {
                return { name: 'video.play', params: { slug } }
              }
            }
            return { name: 'admin.dashboard' }
          } else {
            return redirect ? to : true;
          }
        }
      }
      if (to.name === 'login'){
        return true;
      }

      if (to.name !== 'home') {
        const redirectId = uuid();
        localStorage.setItem('bp:redirect_' + redirectId, window.btoa(JSON.stringify(to)));
        return { name: 'login', query: { redirect: redirectId } };
      }
      return { name: 'login' };
    })

    router.afterEach(() => {
      // Fullscreen loading mask
      loading.value = false;
    })

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const htmlEl = document.querySelector("html") as HTMLElement;

    // DARK MODE
    const DARK_MODE_KEY = "bp:dark-mode";
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const localStorageDarkMode = localStorage.getItem(DARK_MODE_KEY)
    const initialDarkMode = localStorageDarkMode ? localStorageDarkMode === 'true' : prefersDarkMode;
    const { darkMode } = useDarkModeProvider({ initialDarkMode });
    watch(darkMode, (newDarkMode, oldDarkMode) => {
      htmlEl.setAttribute("data-dark-mode", '' + newDarkMode);
      // Don't remove the "undefined" dark mode in the immediate handler. Also no need to set the default dark mode as the preference.
      if (oldDarkMode !== undefined) {
        localStorage.setItem(DARK_MODE_KEY, '' + newDarkMode);
      }
    }, { immediate: true });

    // COMPACT MODE
    const COMPACT_MODE_KEY = "bp:compact-mode"
    const localStorageCompactMode = localStorage.getItem(COMPACT_MODE_KEY)
    const initialCompactMode = localStorageCompactMode ? localStorageCompactMode === 'true' : false;
    const { compactMode } = useCompactModeProvider({ initialCompactMode });
    watch(compactMode, (newCompactMode, oldCompactMode) => {
      htmlEl.setAttribute("data-compact-mode", '' + newCompactMode);
      // Don't remove the "undefined" compact mode in the immediate handler. Also no need to set the default compact mode as the preference.
      if (oldCompactMode !== undefined) {
        localStorage.setItem(COMPACT_MODE_KEY, '' + newCompactMode);
      }
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// GOOGLE RECAPTCHA
    ///-------------------------------------------------------------------

    // onMounted(() => {
    //   const grecapthcaScript: HTMLScriptElement = document.createElement('script');
    //   grecapthcaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY);
    //   document.head.appendChild(grecapthcaScript);
    // });

    // let grecaptchaStyle: HTMLStyleElement = document.createElement('style');
    // grecaptchaStyle.setAttribute('id', 'grecaptcha');
    // document.head.appendChild(grecaptchaStyle);

    // router.beforeEach((to, from) => {
    //   if (to.name !== 'login' && from.name !== 'login') {
    //     return;
    //   }
    //   grecaptchaStyle = setGrecaptchaStyle((to.name === 'login' && to.query.action === 'register') ? 'visible' : 'hidden')
    // });

    // function setGrecaptchaStyle(visibility = 'hidden') {
    //   return setStyles({
    //     '.grecaptcha-badge': {
    //       'visibility': visibility,
    //     }
    //   }, grecaptchaStyle);
    // }

    // setGrecaptchaStyle();

    // onUnmounted(() => {
    //   if (grecaptchaStyle) {
    //     grecaptchaStyle.remove();
    //   }
    // })

    ///-------------------------------------------------------------------
    /// PROVIDE
    ///-------------------------------------------------------------------

    provide('user', user);

    ///-------------------------------------------------------------------
    /// TITLE
    ///-------------------------------------------------------------------

    watchEffect(() => document.title = $gettext('Cordaware customer portal'));

    ///-------------------------------------------------------------------
    /// LOADING
    ///-------------------------------------------------------------------

    const loading = ref(false);

    ///-------------------------------------------------------------------
    /// LANG
    ///-------------------------------------------------------------------

    const lang = ref(document.documentElement.getAttribute('lang'));
    const observer = new MutationObserver(() => lang.value = document.documentElement.getAttribute('lang'));
    onMounted(() => observer.observe(document.documentElement, { attributes: true }))
    onUnmounted(() => observer.disconnect())

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------
    
    const isDesktop = useMedia(Breakpoint.MIN_MD);

    ///-------------------------------------------------------------------
    /// COOKIE BANNER
    ///-------------------------------------------------------------------
    
    onMounted(() => document.documentElement.addEventListener('cookie-banner', () => cookieBanner.value = true));
    onUnmounted(() => document.documentElement.removeEventListener('cookie-banner', () => cookieBanner.value = true));
    
    // Using gettext in script instead of template because of a bug in the gettext library which doesn't allow using it in the template of the main app file
    const cookieBanner = ref(!document.cookie.split('; ').find(cookie => cookie.startsWith('best_portal_cookie_consent')));
    const cookieHeading = computed(() => $gettext('This website uses cookies'));
    const cookieMessage = computed(() => $gettext('The cookies we place on your browser ensure that the Cordaware customer portal delivers you information and services securely and optimally.'));
    const cookieStrictlyNecessary = computed(() => $gettext('Strictly necessary cookies'));
    const cookieThirdParty = computed(() => $gettext('Third party cookies'));
    const cookieShowDetails = computed(() => $gettext('Show details'));
    const cookieName = computed(() => $gettext('Name'));
    const cookiePurpose = computed(() => $gettext('Purpose'));
    const cookieExpires = computed(() => $gettext('Expires'));
    const cookieProvider = computed(() => $gettext('Provider'));
    const cookiePrivacyPolicyHref = computed(() => $gettext('https://www.cordaware.com/eng/data-privacy-policy'));
    const cookiePrivacyPolicy = computed(() => $gettext('Data privacy policy'));
    const cookieImprintHref = computed(() => $gettext('https://www.cordaware.com/eng/imprint'));
    const cookieImprint = computed(() => $gettext('Imprint'));
    const cookieAcceptOnlyStictlyNecessary = computed(() => $gettext('Reject all'));
    const cookieAcceptCurrent = computed(() => $gettext('Allow selection'));
    const cookieAcceptAll = computed(() => $gettext('Accept all'));
    const cookieAcceptThirdParty = ref(document.cookie.split('; ').find(cookie => cookie.startsWith('best_portal_cookie_consent'))?.split('=').pop() === 'true');
    const cookies = ref<{ name: string, purpose: string, expires: string, provider: string }[]>([])

    function acceptCookies(includeThirdParty?: boolean) {
      const oldAcceptThirdParty = document.cookie.split('; ').find(cookie => cookie.startsWith('best_portal_cookie_consent'))?.split('=').pop() === 'true';
      if (typeof includeThirdParty === 'boolean') {
        cookieAcceptThirdParty.value = includeThirdParty;
      }
      const today = new Date();
      const date = new Date(
        today.getFullYear(), today.getMonth(), today.getDate() + 395,
        today.getHours(), today.getMinutes(), today.getSeconds()
      ).toUTCString();
      // console.log('accept cookies ->', { old: oldAcceptThirdParty, new: cookieAcceptThirdParty.value });
      document.cookie = `best_portal_cookie_consent=${cookieAcceptThirdParty.value}; expires=${date}; path=/`;
      document.documentElement.dispatchEvent(new CustomEvent('accept-cookies'));
      if (oldAcceptThirdParty !== cookieAcceptThirdParty.value) {
        window.location.reload();
      } else {
        cookieBanner.value = false;
      }
    }

    watch(() => [configStore.isLoading(), lang.value], () => {
      if (!configStore.loaded || configStore.isLoading()) { return }
      cookies.value = Object.entries(configStore.config['cookies'])
        .filter(([key]) => key !== 'cookies')
        .map(([name, data]) => {
          const { purpose, expires, provider } = data as { purpose: string, expires: string, provider: string };
          return {
            name,
            purpose: getTranslated(purpose),
            expires: getTranslated(expires),
            provider
          }
        }) as { name: string, purpose: string, expires: string, provider: string }[];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      acceptCookies,
      cookieAcceptAll,
      cookieAcceptCurrent,
      cookieAcceptOnlyStictlyNecessary,
      cookieAcceptThirdParty,
      cookieBanner,
      cookieExpires,
      cookieHeading,
      cookieImprint,
      cookieImprintHref,
      cookieMessage,
      cookieName,
      cookiePrivacyPolicy,
      cookiePrivacyPolicyHref,
      cookieProvider,
      cookiePurpose,
      cookieShowDetails,
      cookieStrictlyNecessary,
      cookieThirdParty,
      cookies,
      lang,
      loading,
      isDesktop,
      useLayout,
      user,
    }
  },
})
