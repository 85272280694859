import { inject, provide, Ref, ref } from 'vue';

const COMPACT_MODE_SYMBOL = ('~COMPACT_MODE_SYMBOL');

export function useCompactModeProvider(opts: { initialCompactMode: boolean }) {
  const compactMode = ref<boolean>(opts.initialCompactMode);

  provide(COMPACT_MODE_SYMBOL, compactMode);

  return {
    compactMode,
  };
}

export default function useCompactMode() {
  const injectedCompactMode = inject<Ref<boolean>>(COMPACT_MODE_SYMBOL);

  function toggleCompactMode() {
    const root = document.querySelector('html');
    if (root) {
      const oldCompactMode = injectedCompactMode?.value;
      const newCompactMode = !oldCompactMode;
      if (injectedCompactMode) {
        injectedCompactMode.value = newCompactMode;
      }
    }
  }

  return {
    current: injectedCompactMode,
    toggle: toggleCompactMode,
  };
}
