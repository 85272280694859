import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "flex gap-sm mb-md",
  style: {"align-items":"center"}
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "bp-app__cookies-grid"
}
const _hoisted_4 = { class: "mono ws-nw" }
const _hoisted_5 = {
  key: 1,
  class: "pl-md",
  style: {"font-size":"smaller"}
}
const _hoisted_6 = { class: "mt-xs mb-xs" }
const _hoisted_7 = { class: "mono" }
const _hoisted_8 = { class: "mt-xs mb-xs" }
const _hoisted_9 = { class: "mt-xs mb-xs" }
const _hoisted_10 = { class: "mt-xs mb-xs" }
const _hoisted_11 = {
  key: 0,
  class: "bp-app__cookies-grid"
}
const _hoisted_12 = { class: "mono ws-nw" }
const _hoisted_13 = {
  key: 1,
  class: "pl-md",
  style: {"font-size":"smaller"}
}
const _hoisted_14 = { class: "mt-xs mb-xs" }
const _hoisted_15 = { class: "mono" }
const _hoisted_16 = { class: "mt-xs mb-xs" }
const _hoisted_17 = { class: "mt-xs mb-xs" }
const _hoisted_18 = { class: "mt-xs mb-xs" }
const _hoisted_19 = ["href"]
const _hoisted_20 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_layout = _resolveComponent("bp-layout")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_toasts = _resolveComponent("bp-toasts")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_banner = _resolveComponent("bp-banner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.useLayout)
      ? (_openBlock(), _createBlock(_component_bp_layout, { key: 0 }))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
    _cache[14] || (_cache[14] = _createStaticVNode("<svg id=\"bp-squircle\"><defs><clipPath id=\"squircle\" clipPathUnits=\"objectBoundingBox\"><path d=\"M .5,0 C .1,0 0,.1 0,.5 0,.9 .1,1 .5,1 .9,1 1,.9 1,.5 1,.1 .9,0 .5,0 Z\"></path></clipPath></defs></svg><div id=\"floating-elements\"><div id=\"bp-tooltip\"><p id=\"bp-tooltip__text\"></p><div id=\"bp-tooltip__arrow\"><div></div></div></div></div>", 2)),
    _createVNode(_component_bp_loading, {
      "model-value": _ctx.loading,
      fullscreen: ""
    }, null, 8, ["model-value"]),
    _createVNode(_component_bp_toasts),
    _createVNode(_component_bp_banner, {
      "model-value": _ctx.cookieBanner && _ctx.cookies.length > 0,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.cookieBanner = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_bp_icon, {
            icon: {
        icon: ['fad', 'cookie-bite'],
        primaryColor: 'var(--color-warm-gray-900)',
        secondaryColor: 'var(--color-amber-500)',
        secondaryOpacity: 1,
        transform: 'grow-16',
      },
            class: "p-sm"
          }),
          _createElementVNode("h2", null, _toDisplayString(_ctx.cookieHeading), 1)
        ]),
        _createElementVNode("span", { innerHTML: _ctx.cookieMessage }, null, 8, _hoisted_2),
        (_ctx.cookies.filter(cookie => cookie.provider.startsWith('Cordaware')).length > 0)
          ? (_openBlock(), _createBlock(_component_bp_fieldset, {
              key: 0,
              class: "mt-md mb-md",
              collapsible: "",
              collapsed: ""
            }, {
              legend: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.cookieStrictlyNecessary), 1),
                _createVNode(_component_bp_input, {
                  type: "toggle",
                  "model-value": true,
                  disabled: ""
                })
              ]),
              default: _withCtx(() => [
                (_ctx.isDesktop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookieName), 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookiePurpose), 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookieExpires), 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookieProvider), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cookies.filter(cookie => cookie.provider.startsWith('Cordaware')), (cookie) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: cookie.name
                        }, [
                          _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "m-0" }, null, -1)),
                          _createElementVNode("div", _hoisted_4, _toDisplayString(cookie.name), 1),
                          _createElementVNode("div", null, _toDisplayString(cookie.purpose), 1),
                          _createElementVNode("div", null, _toDisplayString(cookie.expires), 1),
                          _createElementVNode("div", null, _toDisplayString(cookie.provider), 1)
                        ], 64))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cookies.filter(cookie => cookie.provider.startsWith('Cordaware')), (cookie) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: cookie.name
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookieName) + ":", 1),
                            _cache[5] || (_cache[5] = _createTextVNode()),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(cookie.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookiePurpose) + ":", 1),
                            _cache[6] || (_cache[6] = _createTextVNode()),
                            _createElementVNode("span", null, _toDisplayString(cookie.purpose), 1)
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookieExpires) + ":", 1),
                            _cache[7] || (_cache[7] = _createTextVNode()),
                            _createElementVNode("span", null, _toDisplayString(cookie.expires), 1)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookieProvider) + ":", 1),
                            _cache[8] || (_cache[8] = _createTextVNode()),
                            _createElementVNode("span", null, _toDisplayString(cookie.provider), 1)
                          ])
                        ]))
                      }), 128))
                    ]))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cookies.filter(cookie => !cookie.provider.startsWith('Cordaware')).length > 0)
          ? (_openBlock(), _createBlock(_component_bp_fieldset, {
              key: 1,
              class: "mt-md mb-md",
              collapsible: "",
              collapsed: ""
            }, {
              legend: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.cookieThirdParty), 1),
                _createVNode(_component_bp_input, {
                  type: "toggle",
                  modelValue: _ctx.cookieAcceptThirdParty,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cookieAcceptThirdParty) = $event))
                }, null, 8, ["modelValue"])
              ]),
              default: _withCtx(() => [
                (_ctx.isDesktop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookieName), 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookiePurpose), 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookieExpires), 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.cookieProvider), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cookies.filter(cookie => !cookie.provider.startsWith('Cordaware')), (cookie) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: cookie.name
                        }, [
                          _cache[9] || (_cache[9] = _createElementVNode("hr", { class: "m-0" }, null, -1)),
                          _createElementVNode("div", _hoisted_12, _toDisplayString(cookie.name), 1),
                          _createElementVNode("div", null, _toDisplayString(cookie.purpose), 1),
                          _createElementVNode("div", null, _toDisplayString(cookie.expires), 1),
                          _createElementVNode("div", null, _toDisplayString(cookie.provider), 1)
                        ], 64))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cookies.filter(cookie => !cookie.provider.startsWith('Cordaware')), (cookie) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: cookie.name
                        }, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookieName) + ":", 1),
                            _cache[10] || (_cache[10] = _createTextVNode()),
                            _createElementVNode("span", _hoisted_15, _toDisplayString(cookie.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookiePurpose) + ":", 1),
                            _cache[11] || (_cache[11] = _createTextVNode()),
                            _createElementVNode("span", null, _toDisplayString(cookie.purpose), 1)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookieExpires) + ":", 1),
                            _cache[12] || (_cache[12] = _createTextVNode()),
                            _createElementVNode("span", null, _toDisplayString(cookie.expires), 1)
                          ]),
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.cookieProvider) + ":", 1),
                            _cache[13] || (_cache[13] = _createTextVNode()),
                            _createElementVNode("span", null, _toDisplayString(cookie.provider), 1)
                          ])
                        ]))
                      }), 128))
                    ]))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.isDesktop ? 'flex gap-md' : 'flex-column gap-sm'),
          style: _normalizeStyle(_ctx.isDesktop ? 'align-items: center;' : undefined)
        }, [
          _createElementVNode("a", {
            href: _ctx.cookiePrivacyPolicyHref,
            target: "_blank"
          }, [
            _createTextVNode(_toDisplayString(_ctx.cookiePrivacyPolicy) + " ", 1),
            _createVNode(_component_bp_icon, { icon: ['far', 'arrow-up-right-from-square'] })
          ], 8, _hoisted_19),
          (_ctx.isDesktop)
            ? (_openBlock(), _createBlock(_component_bp_icon, {
                key: 0,
                class: "ml--sm mr--sm",
                color: "var(--theme-border)",
                icon: "horizontal-rule",
                transform: "rotate-90"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("a", {
            href: _ctx.cookieImprintHref,
            target: "_blank"
          }, [
            _createTextVNode(_toDisplayString(_ctx.cookieImprint) + " ", 1),
            _createVNode(_component_bp_icon, { icon: ['far', 'arrow-up-right-from-square'] })
          ], 8, _hoisted_20),
          _createVNode(_component_bp_button, {
            class: _normalizeClass(_ctx.isDesktop ? 'ml-auto' : undefined),
            icon: "ban",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.acceptCookies(false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cookieAcceptOnlyStictlyNecessary), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_bp_button, {
            color: "green",
            icon: ['far', 'floppy-disk'],
            onClick: _ctx.acceptCookies
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cookieAcceptCurrent), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_bp_button, {
            color: "green",
            icon: "check",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.acceptCookies(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cookieAcceptAll), 1)
            ]),
            _: 1
          })
        ], 6)
      ]),
      _: 1
    }, 8, ["model-value"])
  ], 64))
}