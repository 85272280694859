
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { CSSProperties, defineComponent, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { useUser } from '@/utils/user';
import version from '../version';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-footer',
  setup() {
    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const user = useUser();
    const isDevelopmentMode = ref(process.env.NODE_ENV === 'development');

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = ref<CSSProperties>({})

    function updateStyle() {
      function em(value: number) {
        return value * 16;
      }
      const headerEl = document.getElementById('bp-header');
      if (!headerEl) {
        style.value = {};
        return;
      }
      const padding = parseFloat(window.getComputedStyle(headerEl).getPropertyValue('--padding')) * 16;
      const headerWidth = headerEl.getBoundingClientRect().width;
      style.value = {
        '--footer-color-stop-1': `calc(50% - ${headerWidth / 2 - (em(1.25) + 2 * padding)}px)`,
        '--footer-color-stop-2': `calc(50% - ${headerWidth / 2 - (headerWidth * 0.3333)}px)`,
        '--footer-color-stop-3': `calc(50% + ${headerWidth / 2 - (headerWidth * 0.3333)}px)`,
        '--footer-color-stop-4': `calc(50% + ${headerWidth / 2 - (em(1.25) + 2 * padding)}px)`,
      }
    }
    nextTick(updateStyle);

    onMounted(() => {
      addEventListener('resize', updateStyle, { passive: true });
    })
    onUnmounted(() => {
      removeEventListener('resize', updateStyle);
    })

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      isAdmin: user.value?.roles.includes('admin'),
      isDesktop,
      isDevelopmentMode,
      style,
      version,
    }
  }
});
