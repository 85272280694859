import { createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "bp-header__active-bar",
  ref: "activeBarEl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_navigation = _resolveComponent("bp-navigation")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(['bp-header', { 'bp-header--compact': _ctx.compactMode }]),
    ref: "headerEl"
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "bp-header__logo",
      src: "/static/logo/cordaware_white.png"
    }, null, -1)),
    (_ctx.mainNav)
      ? (_openBlock(), _createBlock(_component_bp_navigation, {
          key: 0,
          "active-id": _ctx.activeId,
          color: [_ctx.isDesktop ? 'light-blue' : 'blue', 500],
          "is-active": _ctx.isActiveFn,
          navigation: _ctx.mainNav,
          "toggle-icon": _ctx.mainNavIcon,
          class: "bp-header__main-nav",
          ref: "mainNavEl"
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.mainNavSlots, ([slotName, nestedName]) => {
            return {
              name: nestedName,
              fn: _withCtx((slotScope) => [
                _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
              ])
            }
          })
        ]), 1032, ["active-id", "color", "is-active", "navigation", "toggle-icon"]))
      : _createCommentVNode("", true),
    (_ctx.sideNav)
      ? (_openBlock(), _createBlock(_component_bp_navigation, {
          key: 1,
          "active-id": _ctx.activeId,
          color: ['blue', 500],
          navigation: _ctx.sideNav,
          "toggle-icon": _ctx.sideNavIcon,
          class: "bp-header__side-nav",
          position: "right",
          ref: "sideNavEl",
          "seamless-footer": ""
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.sideNavSlots, ([slotName, nestedName]) => {
            return {
              name: nestedName,
              fn: _withCtx((slotScope) => [
                _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
              ])
            }
          })
        ]), 1032, ["active-id", "navigation", "toggle-icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, null, 512)
  ], 2))
}