import { Ref, ref } from "vue";
import getCssVariable from "./css";

/**
 * A responsive breakpoint.
 */
export enum Breakpoint {
  /**
   * Small and up.
   */
  MIN_SM = 'min-sm',
  /**
   * Medium and up.
   */
  MIN_MD = 'min-md',
  /**
   * Large and up.
   */
  MIN_LG = 'min-lg',
  /**
   * Extra large and up.
   */
  MIN_XL = 'min-xl',

  MAX_XS = 'max-xs',
  MAX_SM = 'max-sm',
  MAX_MD = 'max-md',
  MAX_LG = 'max-lg',

  /**
   * Exactly extra small.
   */
  ONLY_XS = 'only-xs',
  /**
   * Exactly small.
   */
  ONLY_SM = 'only-sm',
  /**
   * Exactly medium.
   */
  ONLY_MD = 'only-md',
  /**
   * Exactly large.
   */
  ONLY_LG = 'only-lg',
  /**
   * Exactly extra large.
   */
  ONLY_XL = 'only-xl'
}

const breakpoints = new Map<Breakpoint, Ref<boolean>>()

function checkQuery(bp: Breakpoint) {
  return window.matchMedia(getCssVariable(bp)).matches;
}

function refreshNow() {
  Object.values(Breakpoint).forEach(bp => {
    let currentRef = breakpoints.get(bp);
    const checked = checkQuery(bp);
    if (!currentRef) {
      currentRef = ref(checked);
      breakpoints.set(bp, currentRef);
    } else {
      currentRef.value = checked;
    }
  });
}

window.addEventListener('resize', refreshNow);

refreshNow();
window.setTimeout(refreshNow, 0);

/**
 * Checks if a breadpoint applies.
 * @param bp The breakpoint.
 */
export function useMedia(bp: Breakpoint) {
  const currentRef = breakpoints.get(bp)
  if (!currentRef) {
    throw new Error('No media data for breakpoint ' + bp);
  }
  return currentRef;
}
