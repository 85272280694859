
import useCompactMode from '@/compositions/use-compact-mode';
import { currentLanguage } from '@/translation';
import { ColorProp, cssColor, extractColor, extractShade, Modifier } from '@/utils/color';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { PopoverElement } from './popover/BpPopoverMenu';

interface Item extends PopoverElement {
  text: string;
}

export default defineComponent({
  name: 'bp-toggle-button',
  props: {
    color: {
      type: ColorProp,
      default: 'light-blue',
    },
    data: {
      type: Array as PropType<Item[]>,
      required: true,
    }
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ACTIVE
    ///-------------------------------------------------------------------

    const activeId = ref(props.data[0]?._id || '');

    function click(item: Item) {
      activeId.value = item._id;
      if (item.click !== undefined && typeof item.click === 'function') {
        item.click();
      }
    }

    ///-------------------------------------------------------------------
    /// TOGGLE
    ///-------------------------------------------------------------------

    const toggleEl = ref<HTMLDivElement>();
    const maxWidth = ref(-1);

    watch(() => [toggleEl.value, currentLanguage.value], () => {
      if (!toggleEl.value) {
        return;
      }
      const activeEl = toggleEl.value.querySelector('.bp-toggle-button__item--active > .bp-toggle-button__item-text') as HTMLButtonElement;
      if (!activeEl) {
        return;
      }
      const style = window.getComputedStyle(activeEl);
      const padding = parseFloat(style.getPropertyValue('padding-left')) + parseFloat(style.getPropertyValue('padding-right'))
      window.setTimeout(() => {
        if (!toggleEl.value) {
          return;
        }
        maxWidth.value = -1;
        for (const childEl of toggleEl.value.children) {
          const child = childEl as HTMLButtonElement;
  
          // Find max width
          const text = child.querySelector('.bp-toggle-button__item-text') as HTMLSpanElement;
          text.style.setProperty('width', 'auto');
          const width = text.getBoundingClientRect().width + padding;
          if (width > maxWidth.value) {
            maxWidth.value = width;
          }
          text.style.removeProperty('width');
        }
        toggleEl.value.style.setProperty('--width', `${maxWidth.value}px`);
      }, 0)
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = computed(() => {
      const color = extractColor(props.color);
      const shade = extractShade(props.color) || 600;
      return {
        '--toggle-button-color': cssColor(color, shade),
        '--toggle-button-icon-color': cssColor(color, Modifier.TEXT_SECONDARY),
        '--toggle-button-text-color': cssColor(color, Modifier.TEXT),
      }
    });

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeId,
      click,
      compactMode,
      style,
      toggleEl,
    }
  }
});
