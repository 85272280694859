import { $gettext } from 'vue-gettext';
import { BaseDoc } from '@/components/virtual-scroller/BpVirtualScroller';
import { byKey, Order } from '@/utils/array';
import { defaultTranslated, Translated } from '@/translation';
import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import clone from '@sahnee/clone';
import deepMerge, { Flat, flatten, KeyOf, path, RequiredKeys, subtree, tree, Tree } from '@/utils/object';

interface State {
  videos: Video[];
  chats: Chat[];
  loading: number;
  loaded: boolean;
}

interface GetParentsOpts {
  docTypes: ('video_category' | 'video_recording' | 'video_webinar')[],
  excludeIds: string | string[],
  includeRoot: boolean,
}

const defaultOpts: GetParentsOpts = {
  docTypes: ['video_category'],
  excludeIds: [],
  includeRoot: false,
}

/**
 * A base.
 */
interface Base {
  _id: string;
  _rev: string;
  parent_id: string;
}

/**
 * A video base.
 */
interface VideoBase extends Base {
  date: string;
  description: Translated<string>,
  featured: boolean;
  featured_text: Translated<string>,
  hint: Translated<string>,
  name: Translated<string>;
  order: number;
  tags: string[];
}

/**
 * A video.
 */
export type Video = Category | Recording | Webinar;

/**
 * A category.
 */
export interface Category extends VideoBase {
  doc_type: 'video_category';
}

/**
 * A recording.
 */
export interface Recording extends VideoBase {
  doc_type: 'video_recording';
  slug: string;
  youtube_id: string;
  product: string;
  email_notification: boolean;
  _disabled?: boolean;
}

/**
 * A webinar.
 */
export interface Webinar extends Omit<Recording, 'doc_type'> {
  doc_type: 'video_webinar';
  webinar: {
    date: string;
    active: boolean;
    token: string;
    participants: Translated<string[]>;
  }
}

export type Preview<T> = T extends Recording
  ? RequiredKeys<Partial<T>, 'name' | 'description' | 'slug' | '_id'>
  : T extends Webinar
    ? RequiredKeys<Partial<T>, 'name' | 'description' | 'slug' | '_id' | 'webinar'>
    : Partial<T>;

/**
 * A chat. Usually connected to a webinar.
 */
export interface Chat extends Base {
  doc_type: 'video_chat';
  messages: Message[];
  users: Participant[];
}

/**
 * A Participant
 */
export interface Participant extends BaseDoc {
  username: string;
  muted_datetime: string;
}

/**
 * A message.
 */
export interface Message extends BaseDoc {
  date: string;
  content: string;
  timestamp: number;
  type: 'text' | 'image'; // TODO: implement image type
  user: string;
  meta?: Record<string, unknown>;
}

type Opts = {
  filter?: Filter | Filter[],
  type?: Exclude<Type, 'category'>,
};

type Filter = 'featured' | 'non-featured' | 'featured-webinar' | undefined;

type Type = 'category' | 'recording' | 'webinar';

export type EmailNotificationPreview = Translated<{to: string[], subject: string, body: string}> & { users: number };

export const defaultCategory: Omit<Category, '_id' | '_rev'> = {
  doc_type: 'video_category',
  date: new Date().toISOString(),
  description: defaultTranslated(),
  featured: false,
  featured_text: defaultTranslated(),
  hint: defaultTranslated(),
  name: defaultTranslated(),
  order: 0,
  parent_id: 'root',
  tags: [],
}

export const defaultRecording: Omit<Recording, '_id' | '_rev'> = {
  ...defaultCategory,
  doc_type: 'video_recording',
  slug: '',
  youtube_id: '',
  product: '',
  email_notification: false,
}

export const defaultWebinar: Omit<Webinar, '_id' | '_rev'> = {
  ...defaultRecording,
  doc_type: 'video_webinar',
  webinar: {
    active: false,
    date: new Date().toISOString(),
    token: '',
    participants: {
      de: [],
      en: []
    }
  },
}

export const defaultChat: Omit<Chat, '_id' | '_rev'> = {
  doc_type: 'video_chat',
  parent_id: 'root',
  messages: [],
  users: [],
}

function prepareSlug(name: string | undefined) {
  return name ? name
    .replace(/_/g, '-')
    .replace(/\W/g, '-')
    .replace(/-{2,}/g, '-')
    .replace(/(^-*|-*$)/g, '') : '';
}

export function slug(video: Partial<Recording | Webinar>, videos?: (Recording | Webinar)[]) {
  if (!videos || videos.length === 0) {
    const store = useVideoStore();
    videos = store.getVideos() || [];
  }
  const slugs = videos
    ? video._id
      ? videos.filter(v => v._id !== video._id).map(v => v.slug)
      : videos.map(v => v.slug)
    : [];
  let name = prepareSlug(video.slug || video.name?.en?.toLowerCase());
  while (name && slugs.includes(name)) {
    const count = slugs.filter(slug => slug.startsWith(name as string)).length
    name += `-${count + 1}`
  }
  return name;
}

function prepare(videoOrChat: Partial<Video | Chat>) {
  let fullVideoOrChat: Partial<Video | Chat> = {};
  let defaultKeys: string[] = [];
  // Webinar
  if ('webinar' in videoOrChat) {
    fullVideoOrChat = deepMerge(defaultWebinar, videoOrChat as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultWebinar)];
  }
  // Recording
  else if ('youtube_id' in videoOrChat) {
    fullVideoOrChat = deepMerge(defaultRecording, videoOrChat as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultRecording)];
  }
  // Chat
  else if ('messages' in videoOrChat) {
    fullVideoOrChat = deepMerge(defaultChat, videoOrChat as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultChat)];
  }
  // Category
  else {
    fullVideoOrChat = deepMerge(defaultCategory, videoOrChat as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultCategory)];
  }
  const additionalKeys = Object.keys(fullVideoOrChat).filter(key => !defaultKeys.includes(key)) as (keyof (Video | Chat))[];
  for (const key of additionalKeys) {
    delete fullVideoOrChat[key];
  }

  // Update slug
  if ('youtube_id' in fullVideoOrChat && fullVideoOrChat.name) {
    fullVideoOrChat.slug = slug(fullVideoOrChat);
  }

  return fullVideoOrChat;
}

/**
 * The video store.
 */
export const useVideoStore = (preload = false) => {
  // Define a local store reference
  const useStore = defineStore('video', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        videos: [],
        chats: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for videos by their ID.
       * @param ids The ID or an array of IDs of the videos.
       * @returns An array of videos or a single video from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const videos = state.videos.filter(video => ids.includes(video._id));
          return ids.length === 1 ? videos[0] : videos
        }) as ((ids: string | [string]) => Video) & ((ids: string[]) => Video[])
      },
      /**
       * Search for videos by their slug.
       * @param slugs The slug or an array of slugs of the videos.
       * @returns An array of videos or a single video from the store.
       */
      getBySlug: (state: State) => {
        return ((slugs: string | string[]) => {
          if (!Array.isArray(slugs)) {
            slugs = [slugs];
          }
          const videos = state.videos.filter(video => 'slug' in video && slugs.includes(video.slug)) as (Recording | Webinar)[];
          return slugs.length === 1 ? videos[0] : videos
        }) as ((slugs: string | [string]) => Recording | Webinar) & ((slugs: string[]) => (Recording | Webinar)[])
      },
      /**
       * Gets the featured name of a given video.
       * @param video: The video.
       * @returns The featured name.
       */
      // getFeaturedName() {
      //   return (video: OptionalKeys<Preview<Video>, '_id'>) => {
      //     if (video.featured || this.isFeaturedWebinar(video._id || '')) {
      //       return getTranslated(video.featured_text) || getTranslated(video.name);
      //     }
      //     return this.getFeaturedCategoryName(video);
      //   }
      // },
      /**
       * Gets the featured name of a given video.
       * @param video: The video.
       * @returns The featured name.
       */
      // getFeaturedCategoryName() {
      //   return (video: OptionalKeys<Preview<Video>, '_id'>) => {
      //     const grandparentFolders = this.getPathById(video.parent_id || '', 'featured');
      //     const parentFolders = [this.getById(video.parent_id || '')].filter(folder => folder && folder.featured) as Category[];
      //     const featuredFolder = [...grandparentFolders, ...parentFolders].pop();
      //     if (!featuredFolder) {
      //       return '';
      //     }
      //     return getTranslated(featuredFolder.featured_text) || `${$gettext('Latest')} ${getTranslated(featuredFolder.name)}`;
      //   }
      // },
      /**
       * Search for chats by their ID.
       * @param ids The ID or an array of IDs of the chat.
       * @returns An array of chats or a single chat from the store.
       */
      getChatById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const chats = state.chats.filter(chat => ids.includes(chat._id));
          return ids.length === 1 ? chats[0] : chats
        }) as ((ids: string | [string]) => Chat) & ((ids: string[]) => Chat[])
      },
      /**
       * Search for chats by their corresponding webinar ID.
       * @param ids The ID or an array of IDs of the corresponding webinar.
       * @returns An array of chats or a single chat from the store.
       */
      getChatByWebinarId: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const chats = state.chats.filter(chat => ids.includes(chat.parent_id));
          return ids.length === 1 ? chats[0] : chats
        }) as ((ids: string | [string]) => Chat) & ((ids: string[]) => Chat[])
      },
      /**
       * Search for the path to a given video by their ID.
       * @param ids The ID or an array of IDs of videos.
       * @returns The path, as an ordered array of the videos, or an array of paths.
       */
      getPathById() {
        return ((ids: string | string[], filters?: Filter | Filter[]) => {
          const categories = path(this.videos, ids) as (Category | Category[])[];
          if (filters && !Array.isArray(filters)) {
            filters = [filters];
          } else if (!filters) {
            filters = [undefined]
          }
          const found: (Category | Category[])[] = [];
          for (const filter of filters) {
            switch (filter) {
              case 'featured': {
                found.push(...categories.filter(category => Array.isArray(category) ? category.filter(c => this.isFeatured(c._id)) : this.isFeatured(category._id)));
                break;
              }
              case 'non-featured': {
                found.push(...categories.filter(category => Array.isArray(category) ? category.filter(c => !this.isFeatured(c._id)) : !this.isFeatured(category._id)));
                break;
              }
              default: {
                found.push(...categories);
                break;
              }
            }
          }
          return found;
        }) as ((ids: string | [string], filters?: Filter | Filter[]) => Category[]) & ((ids: string[], filters?: Filter | Filter[]) => Category[][]);
      },
      /**
       * Checks whether the given ID or array of IDs is a featured video or is featured by its parent category is tagged as featured.
       * @param ids The ID or an array of IDs of videos to check if they are featured.
       * @returns A boolean or a boolean array whether the video/videos are featured or not.
       */
      isFeatured: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const featured: boolean[] = [];
          for (const id of ids) {
            const recording = state.videos.find(video => video._id === id && ['video_recording', 'video_webinar'].includes(video.doc_type));
            if (!recording) {
              featured.push(false);
              continue;
            } else if (recording.featured) {
              featured.push(true);
              continue;
            }
            const category = state.videos.find(video => video._id === recording.parent_id && video.doc_type === 'video_category');
            if (!category) {
              featured.push(false);
              continue;
            }
            const latest = state.videos.filter(video => video.parent_id === category._id).sort(byKey('date', 'desc'))[0];
            featured.push(category.featured && latest._id === recording._id);
          }
          return ids.length === 1 ? featured[0] : featured;
        }) as ((ids: string | [string]) => boolean) & ((ids: string[]) => boolean[])
      },
      isCategory: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const videos = state.videos.filter(video => ids.includes(video._id));
          return ids.length === 1 ? videos[0].doc_type === 'video_category' : videos.map(video => video.doc_type === 'video_category')
        }) as ((ids: string | [string]) => boolean) & ((ids: string[]) => boolean[])
      },
      getParents() {
        return (opts: Partial<GetParentsOpts> = {}) => {
          const actualOpts = { ...defaultOpts, ...opts };
          // Ensure we have a list of excluded IDs
          if (actualOpts.excludeIds === undefined) {
            actualOpts.excludeIds = [];
          } else if (typeof actualOpts.excludeIds === 'string') {
            actualOpts.excludeIds = [actualOpts.excludeIds];
          }
          // If we have excluded IDs also exclude any ID which is in the entire sub tree of that download
          actualOpts.excludeIds = actualOpts.excludeIds.filter(id => !!id).flatMap(id => this.getFlatSubtree(id).map(video => video._id));
          const root = actualOpts.includeRoot ? [{
            _id: 'root',
            name: {
              de: `Kein Über${actualOpts.docTypes.map(docType => {
                switch (docType) {
                  case 'video_category': return 'kategorie';
                  case 'video_recording': return 'aufzeichnung';
                  case 'video_webinar': return 'webinar';
                }
              }).join(' / -')}`,
              en: `No parent ${actualOpts.docTypes.map(docType => {
                switch (docType) {
                  case 'video_category': return 'category';
                  case 'video_recording': return 'recording';
                  case 'video_webinar': return 'webinar';
                }
              }).join(' / ')}`,
            },
            doc_type: actualOpts.docTypes[0],
            order: -1,
          } as Video] : [];
          const videos = this.videos.filter(video => {
            return actualOpts.docTypes.includes(video.doc_type) && (actualOpts.excludeIds.length > 0
              ? !actualOpts.excludeIds.includes(video._id) && !actualOpts.excludeIds.includes(video.parent_id)
              : true);
          });
          return [
            ...root,
            ...videos
          ]
        }
      },
      /**
       * Search for categorys within the videos.
       * @returns An array of categorys from the store.
       */
      getCategories: (state: State) => {
        return () => state.videos.filter(category => category.doc_type === 'video_category') as Category[];
      },
      /**
       *
       */
      getCategoriesWithRoot: (state: State) => {
        return (id?: string) => {
          if (!id) {
            id = '';
          }
          return [
            {
              _id: 'root',
              name: {
                de: 'Kein Überordner',
                en: 'No parent category'
              },
            },
            ...state.videos.filter(category => id !== '' ? (category.doc_type === 'video_category' && category.parent_id !== id && category._id !== id) : category.doc_type === 'video_category')
          ] as RequiredKeys<Partial<Category>, '_id' | 'name'>[];
        }
      },
      /**
       * Search for recordings and/or webinars within the videos.
       * @returns An array of recordings and/or webinars from the store.
       */
      getVideos() {
        return (opts: Opts = {}) => {
          const docTypes = opts.type ? ['video_' + opts.type] : ['video_recording', 'video_webinar'];
          const videos = this.videos.filter(video => docTypes.includes(video.doc_type)) as Video[];
          if (opts.filter && !Array.isArray(opts.filter)) {
            opts.filter = [opts.filter];
          } else if (!opts.filter) {
            opts.filter = [undefined]
          }
          const found: Video[] = [];
          for (const filter of opts.filter) {
            switch (filter) {
              case 'featured': {
                found.push(...videos.filter(video => this.isFeatured(video._id) && !this.isFeaturedWebinar(video._id)));
                break;
              }
              case 'non-featured': {
                found.push(...videos.filter(video => !this.isFeatured(video._id) && !this.isFeaturedWebinar(video._id)));
                break;
              }
              case 'featured-webinar': {
                found.push(...videos.filter(video => this.isFeaturedWebinar(video._id)));
                break;
              }
              default: {
                found.push(...videos);
              }
            }
          }
          if (opts.type === 'recording') {
            return found as Recording[];
          } else if (opts.type === 'webinar') {
            return found as Webinar[];
          }
          return found as (Recording | Webinar)[];
        };
      },
      isFeaturedWebinar() {
        return (id: string) => {
          const video = this.getById(id);
          if (!video) {
            return false;
          }
          return video.doc_type === 'video_webinar' && (video.webinar.active || new Date(video.webinar.date).getTime() >= new Date().getTime());
        }
      },
      /**
       * Builds a tree hierarchy of all video categorys & videos by connecting them via their `parent_id`.
       * @returns The tree hierarchy.
       */
      getTree() {
        // return () => tree(state.videos, 'parent_id');
        return (key: KeyOf<Video> = 'order', order: Order = 'asc') => {
          const feautredWebinars: Video[] = this.videos.filter(el => this.isFeaturedWebinar(el._id));
          return [
            // All featured webinars will be excluded from the tree strucutre at the top of the table
            ...(feautredWebinars.length > 0 ? [{ _id: '_subheading_planned_webinars', _subheading: true, name: $gettext('Planned webinars') }] : []),
            ...feautredWebinars,
            ...(feautredWebinars.length > 0 ? [{ _id: '_subheading_videos', _subheading: true, name: $gettext('Videos') }] : []),
            // All other videos will be displayed normally in a tree structure
            ...tree(clone(this.videos.filter(el => !this.isFeaturedWebinar(el._id))).sort(byKey(key, order)), 'parent_id')
          ];
        };
      },
      getFlatTree() {
        return () => flatten(this.getTree());
      },
      getSubtree() {
        const tree = this.getTree();
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const videos: Tree<Video>[] = [];
          for (const id of ids) {
            const found = subtree(tree, id) as Tree<Video>;
            if (found) {
              videos.push(found);
            }
          }
          return ids.length === 1 ? videos[0] : videos;
        }) as ((ids: string | [string]) => Tree<Video>) & ((ids: string[]) => Tree<Video>[])
      },
      getFlatSubtree() {
        return (ids: string | string[]) => {
          const videos: Flat<Tree<Video>>[] = [];
          for (const video of flatten(Array.isArray(ids) ? this.getSubtree(ids as string[]) : [this.getSubtree(ids as string)])) {
            if (videos.map(dl => dl._id).includes(video._id)) {
              continue;
            }
            videos.push(video);
          }
          return videos;
        };
      },
      /**
       * Search for categorys that are valid as the new parent of a given video.
       * @param id The ID of the video for which a valid parent category is beeing searched.
       * @returns An array of valid categorys as the new parent.
       */
      getValidParentCategories() {
        return (id: string) => {
          const invalidIds = this.getFlatSubtree(id).map(dl => dl._id);
          return this.getCategories().filter(category => !invalidIds.includes(category._id))
        };
      },
      getVideosOfSubtree() {
        return (ids: string | string[], opts: Opts = {}) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const docTypes = opts.type ? ['video_' + opts.type] : ['video_recording', 'video_webinar'];
          const videos = this.getFlatSubtree(ids).filter(video => docTypes.includes(video.doc_type)) as Video[];
          if (opts.filter && !Array.isArray(opts.filter)) {
            opts.filter = [opts.filter];
          } else if (!opts.filter) {
            opts.filter = [undefined]
          }
          const found: Video[] = [];
          for (const filter of opts.filter) {
            switch (filter) {
              case 'featured': {
                found.push(...videos.filter(video => this.isFeatured(video._id) && !this.isFeaturedWebinar(video._id)));
                break;
              }
              case 'non-featured': {
                found.push(...videos.filter(video => !this.isFeatured(video._id) && !this.isFeaturedWebinar(video._id)));
                break;
              }
              case 'featured-webinar': {
                found.push(...videos.filter(video => this.isFeaturedWebinar(video._id)));
                break;
              }
              default: {
                found.push(...videos);
              }
            }
          }
          return found;
        };
      },
      hasChildren() {
        return ((ids: string | string[], type?: Type) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const featured: boolean[] = [];
          const data = this.videos.filter(el => !this.isFeaturedWebinar(el._id));
          for (const id of ids) {
            switch (type) {
              case 'category': {
                featured.push(!!data.filter(dl => dl.doc_type === 'video_category').find(dl => dl.parent_id === id));
                break;
              }
              case 'recording': {
                featured.push(!!data.filter(dl => dl.doc_type === 'video_recording').find(dl => dl.parent_id === id));
                break;
              }
              case 'webinar': {
                featured.push(!!data.filter(dl => dl.doc_type === 'video_webinar').find(dl => dl.parent_id === id));
                break;
              }
              default: {
                featured.push(!!data.find(dl => dl.parent_id === id));
                break;
              }
            }
          }
          return ids.length === 1 ? featured[0] : featured;
        }) as ((ids: string | [string], type?: Type) => boolean) & ((ids: string[], type?: Type) => boolean[])
      },
      getTags: (state: State) => {
        return () => [...new Set(state.videos.map(el => el.tags).flat())].map(tag => ({
          _id: tag,
          name: tag
        }));
      },
      getSlug() {
        return (video: Recording | Webinar) => video.slug || slug(video, this.getVideos())
      },
      getSlugById() {
        return (id: string) => {
          const video = this.getById(id);
          if (!video || !('youtube_id' in video)) {
            return '';
          }
          return this.getSlug(video);
        }
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      async loadNextWebinar() {
        this.loading++;
        try {
          const webinar = await json<Preview<Webinar>>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'load-next-webinar',
            }
          });
          return Object.keys(webinar).length > 0 ? webinar : undefined;
        } finally {
          this.loading--;
        }
      },
      async loadNextWebinarActiveStatus() {
        this.loading++;
        try {
          const active = await json<boolean>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'load-next-webinar-active-status',
            }
          });
          return !!active;
        } finally {
          this.loading--;
        }
      },
      async loadChatByWebinarId(id: string) {
        this.loading++;
        try {
          const newChat = await json<Chat>('api/store/videos', {
            method: 'POST',
            json: {
              action: 'load-chat',
              parentId: id,
            }
          });
          const idx = this.chats.findIndex(chat => chat._id === newChat._id);
          if (idx >= 0) {
            this.chats.splice(idx, 1);
          }
          this.chats.push(newChat);
          return newChat;
        } finally {
          this.loading--;
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const response = await json<{ videos: Video[], chats: Chat[] }>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          if (response.videos.length > 0 || response.chats.length > 0) {
            this.videos = response.videos;
            this.chats = response.chats;
            this.loaded = true;
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Creates a new video.
       * The video will automatically be saved in the database and added to the store.
       * @param video The video.
       */
      async create(video: Partial<Video>) {
        this.loading++;
        try {
          const created = await json<{ video: Video, chat?: Chat }>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'create',
              video: prepare(video)
            }
          });
          if (created.video) {
            this.videos.push(created.video);
            if (created.chat) {
              this.chats.push(created.chat);
            }
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting video.
       * The video will automatically be saved in the database and updated in the store.
       * @param video The video.
       */
      async update(video: Partial<Video>) {
        this.loading++;
        try {
          const updated = await json<Video>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'update',
              video: prepare(video)
            }
          });
          if (updated) {
            const index = this.videos.findIndex((video: Video) => video._id === updated._id);
            this.videos[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },

      /**
       * Fetches an email notification preview.
       * The video will automatically be saved in the database and updated in the store.
       * @param video The video.
       */
      async previewEmailNotification(video: Partial<Video>) {
        this.loading++;
        try {
          const preview = await json<EmailNotificationPreview>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'preview-email-notification',
              video: prepare(video)
            }
          });
          return preview;
        } catch (error: unknown) {
          return (error as Error).message;
        } finally {
          this.loading--;
        }
      },
      async startWebinar(video: Video) {
        this.loading++;
        try {
          const started = await json<Video>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'start-webinar',
              video: prepare(video)
            }
          });
          if (started) {
            const index = this.videos.findIndex((video: Video) => video._id === started._id);
            this.videos[index] = started;
            return {
              success: true,
              data: started,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      async stopWebinar(video: Video) {
        this.loading++;
        try {
          const stopped = await json<Video>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'stop-webinar',
              video: prepare(video)
            }
          });
          if (stopped) {
            const index = this.videos.findIndex((video: Video) => video._id === stopped._id);
            this.videos[index] = stopped;
            return {
              success: true,
              data: stopped,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      addMessageToChat(webinarId: string, message: Message) {
        const chat = this.getChatByWebinarId(webinarId);
        chat.messages.push(message);
      },
      updateMessagesInChat(webinarId: string, messages: Message[]) {
        const chat = this.getChatByWebinarId(webinarId);
        chat.messages = messages;
      },
      updateParticipantsInChat(webinarId: string, users: Participant[]) {
        const chat = this.getChatByWebinarId(webinarId);
        chat.users = users;
      },
      /**
       * Delete an exisiting video.
       * The video will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of videos to delete.
       */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
        this.loading++;
        try {
          const deleted = await json<{ videos: Video[], chats: Chat[] }>('/api/store/videos', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deletedVideo of deleted.videos) {
            const index = this.videos.findIndex((video: Video) => video._id === deletedVideo._id);
            this.videos.splice(index, 1);
          }
          for (const deletedChat of deleted.chats) {
            const index = this.chats.findIndex((chat: Chat) => chat._id === deletedChat._id);
            this.chats.splice(index, 1);
          }
          return {
            success: true,
            data: deleted,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      async checkVideo(id: string) {
        this.loading++;
        try {
          return await json<boolean>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'check-video',
              id
            }
          });
        } catch (error: unknown) {
          return false;
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting video.
       * The video will automatically be saved in the database and updated in the store.
       * @param video The video.
       */
      async move(id: string, parent: string) {
        this.loading++;
        try {
          const moved = await json<Video>('/api/store/videos', {
            method: 'POST',
            json: {
              action: 'move',
              id,
              parent,
            }
          });
          if (moved) {
            const index = this.videos.findIndex((video: Video) => video._id === moved._id);
            this.videos[index] = moved;
            return {
              success: true,
              data: moved,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  if (preload) {
    store.load();
  }

  // Return the store reference
  return store;
};
