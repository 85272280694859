import { $gettext } from 'vue-gettext';
import { byKey, Order } from '@/utils/array';
import { defaultTranslated, getTranslated, Translated } from '@/translation';
import { defineStore } from 'pinia';
import { fileIcon } from '@/components/icon/BpIcon';
import { json, url } from '@sahnee/ajax';
import clone from '@sahnee/clone';
import deepMerge, { Flat, flatten, KeyOf, OptionalKeys, path, RequiredKeys, subtree, tree, Tree } from '@/utils/object';

interface State {
  downloads: Download[];
  loading: number;
  loaded: boolean;
}

interface GetParentsOpts {
  docTypes: ('download_folder' | 'download_file')[],
  excludeIds: string | string[],
  includeRoot: boolean,
}

const defaultOpts: GetParentsOpts = {
  docTypes: ['download_folder'],
  excludeIds: [],
  includeRoot: false,
}

/**
 * A download base.
 */
interface DownloadBase {
  _id: string;
  _rev: string;
  compatibilities: string[] | null,
  description: Translated<string>,
  featured: boolean;
  featured_text: Translated<string>,
  hint: Translated<string>,
  order: number;
  parent_id: string;
}

/**
 * A download.
 */
export type Download = Folder | File;

/**
 * A folder.
 */
export interface Folder extends DownloadBase {
  doc_type: 'download_folder';
  name: Translated<string>;
}

/**
 * A file.
 */
export interface File extends DownloadBase {
  doc_type: 'download_file';
  icon: string;
  version: string;
  show_checksum: boolean;
  date: string;
  email_notification: boolean;
  _disabled?: boolean;
  _attachments: {
    [name: string]: AttachmentData
  };
}

export interface AttachmentData {
  content_type: string;
  revpos: number;
  digest: string;
  length: number;
  stub: boolean;
}

export type FileFilter = 'featured' | 'non-featured' | undefined;

/**
 * An attachment
 */
export interface Attachment {
  name: string;
  data: string;
}

export type EmailNotificationPreview = Translated<{to: string[], subject: string, body: string}> & { users: number };

const defaultDownloadBase: Omit<DownloadBase, '_id' | '_rev'> = {
  compatibilities: [],
  description: defaultTranslated(),
  featured: false,
  featured_text: defaultTranslated(),
  hint: defaultTranslated(),
  order: 0,
  parent_id: 'root',
}

export const defaultFolder: Omit<Folder, '_id' | '_rev'> = {
  ...defaultDownloadBase,
  doc_type: 'download_folder',
  name: defaultTranslated(),
}

export const defaultFile: Omit<File, '_id' | '_rev'> = {
  ...defaultDownloadBase,
  date: new Date().toISOString(),
  doc_type: 'download_file',
  _attachments: {},
  icon: 'default',
  show_checksum: false,
  version: '',
  email_notification: false,
}

function prepare(download: Partial<Download>) {
  let fullDownload: Partial<Download> = {};
  let defaultKeys: string[] = [];
  // Folder
  if ('name' in download) {
    fullDownload = deepMerge(defaultFolder, download as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultFolder)];
  }
  // File
  else {
    fullDownload = deepMerge(defaultFile, download as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultFile)];
  }
  const additionalKeys = Object.keys(fullDownload).filter(key => !defaultKeys.includes(key)) as (keyof Download)[];
  for (const key of additionalKeys) {
    delete fullDownload[key];
  }
  return fullDownload;
}


/**
 * The download store.
 */
export const useDownloadStore = () => {
  // Define a local store reference
  const useStore = defineStore('download', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        downloads: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },

      getAttachment: () => {
        return (file: File) => {
          return Object.values(file._attachments)[0] as AttachmentData;
        }
      },
      getName: () => {
        return (download: OptionalKeys<Download, '_id' | '_rev'>) => {
          if ('name' in download) {
            return getTranslated(download.name);
          }
          return Object.keys(download._attachments).join()
        }
      },
      getFeaturedName() {
        return (download: OptionalKeys<Download, '_id' | '_rev'>) => {
          if (download.featured) {
            return getTranslated(download.featured_text) || this.getName(download);
          }
          return this.getFeaturedFolderName(download);
        }
      },
      getFeaturedFolderName() {
        return (download: OptionalKeys<Download, '_id' | '_rev'>) => {
          const grandparentFolders = this.getPathById(download.parent_id, 'featured');
          const parentFolders = [this.getById(download.parent_id)].filter(folder => folder && folder.featured) as Folder[];
          const featuredFolder = [...grandparentFolders, ...parentFolders].pop();
          if (!featuredFolder) {
            return '';
          }
          return getTranslated(featuredFolder.featured_text) || `${$gettext('Latest')} ${this.getName(featuredFolder)}`;
        }
      },
      getIcon() {
        return (file: File | string | undefined) => {
          if (typeof file === 'string') {
            file = this.getById(file) as File;
          }
          if (!file) {
            return fileIcon('???')
          }
          const filename = this.getName(file);
          return (file.icon && file.icon !== 'default') ? file.icon : fileIcon(filename.includes('.') ? filename.split('.').pop() as string : '???')
        }
      },
      /**
       * Search for downloads by their ID.
       * @param ids The ID or an array of IDs of the downloads.
       * @returns An array of downloads from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const downloads = state.downloads.filter(download => ids.includes(download._id));
          return ids.length === 1 ? downloads[0] : downloads
        }) as ((ids: string | [string]) => Download) & ((ids: string[]) => Download[])
      },
      /**
       * Search for downloads by their ID.
       * @param ids The ID or an array of IDs of the downloads.
       * @returns An array of downloads from the store.
       */
      getRootById: (state: State) => {
        return ((ids: string | string[]) => path(state.downloads, ids).shift()) as ((ids: string | [string]) => Download) & ((ids: string[]) => Download[])
      },
      /**
       * Search for the path to a given download by their ID.
       * @param ids The ID or an array of IDs of downloads.
       * @returns The path, as an ordered array of the downloads, or an array of paths.
       */
      getPathById() {
        return ((ids: string | string[], filters?: FileFilter | FileFilter[]) => {
          const folders = path(this.downloads, ids) as (Folder | Folder[])[];
          if (filters && !Array.isArray(filters)) {
            filters = [filters];
          } else if (!filters) {
            filters = [undefined]
          }
          const found: (Folder | Folder[])[] = [];
          for (const filter of filters) {
            switch (filter) {
              case 'featured': {
                found.push(...folders.filter(folder => Array.isArray(folder) ? folder.filter(f => !!f.featured) : !!folder.featured));
                break;
              }
              case 'non-featured': {
                found.push(...folders.filter(folder => Array.isArray(folder) ? folder.filter(f => !f.featured) : !folder.featured));
                break;
              }
              default: {
                found.push(...folders);
                break;
              }
            }
          }
          return found;
        }) as ((ids: string | [string], filters?: FileFilter | FileFilter[]) => Folder[]) & ((ids: string[], filters?: FileFilter | FileFilter[]) => Folder[][]);
      },
      isFolder: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const downloads = state.downloads.filter(download => ids.includes(download._id));
          return ids.length === 1 ? downloads[0].doc_type === 'download_folder' : downloads.map(dl => dl.doc_type === 'download_folder')
        }) as ((ids: string | [string]) => boolean) & ((ids: string[]) => boolean[])
      },
      /**
       * Checks whether the given ID or array of IDs is a featured download or is featured by its parent folder is tagged as featured.
       * @param ids The ID or an array of IDs of downloads to check if they are featured.
       * @returns A boolean or a boolean array whether the file/files are featured or not.
       */
      isFeatured() {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const featured: boolean[] = [];
          for (const id of ids) {
            const file = this.downloads.find(download => download._id === id && download.doc_type === 'download_file');
            if (!file) {
              featured.push(false);
              continue;
            } else if (file.featured) {
              featured.push(true);
              continue;
            }
            const folder = path(this.downloads, id).filter(f => !!f.featured).pop();
            if (!folder) {
              featured.push(false);
              continue;
            }
            const latest = this.getFilesOfSubtree(folder._id).sort(byKey('date', 'desc')).shift();
            featured.push(!!latest && (latest._id === id));
          }
          return ids.length === 1 ? featured[0] : featured;
        }) as ((ids: string | [string]) => boolean) & ((ids: string[]) => boolean[])
      },
      getParents() {
        return (opts: Partial<GetParentsOpts> = {}) => {
          const actualOpts = { ...defaultOpts, ...opts };
          // Ensure we have a list of excluded IDs
          if (actualOpts.excludeIds === undefined) {
            actualOpts.excludeIds = [];
          } else if (typeof actualOpts.excludeIds === 'string') {
            actualOpts.excludeIds = [actualOpts.excludeIds];
          }
          // If we have excluded IDs also exclude any ID which is in the entire sub tree of that download
          actualOpts.excludeIds = actualOpts.excludeIds.filter(id => !!id).flatMap(id => this.getFlatSubtree(id).map(download => download._id));
          const root = actualOpts.includeRoot ? [{
            _id: 'root',
            name: {
              de: `Kein Über${actualOpts.docTypes.map(docType => {
                switch (docType) {
                  case 'download_folder': return 'ordner';
                  case 'download_file': return 'datei';
                }
              }).join(' / -')}`,
              en: `No parent ${actualOpts.docTypes.map(docType => {
                switch (docType) {
                  case 'download_folder': return 'folder';
                  case 'download_file': return 'file';
                }
              }).join(' / ')}`,
            },
            doc_type: actualOpts.docTypes[0],
            order: -1,
          } as Download] : [];
          const downloads = this.downloads.filter(download => {
            return actualOpts.docTypes.includes(download.doc_type) && (actualOpts.excludeIds.length > 0
              ? !actualOpts.excludeIds.includes(download._id) && !actualOpts.excludeIds.includes(download.parent_id)
              : true);
          });
          return [
            ...root,
            ...downloads
          ]
        }
      },
      /**
       * Search for folders within the downloads.
       * @returns An array of folders from the store.
       */
      getFolders: (state: State) => {
        return () => state.downloads.filter(folder => folder.doc_type === 'download_folder') as Folder[];
      },
      /**
       *
       */
      getFoldersWithRoot: (state: State) => {
        return (id?: string) => {
          if (!id) {
            id = '';
          }
          return [
            {
              _id: 'root',
              name: {
                de: 'Kein Überordner',
                en: 'No parent folder'
              },
              order: -1,
            },
            ...state.downloads.filter(folder => id !== '' ? (folder.doc_type === 'download_folder' && folder.parent_id !== id && folder._id !== id) : folder.doc_type === 'download_folder')
          ] as RequiredKeys<Partial<Folder>, '_id' | 'name' | 'order'>[];
        };
      },
      getCompatibilities() {
        return (download: Download | string) => {
          if (typeof download === 'string') {
            download = this.getById(download);
          }
          if (!download) {
            return [];
          }
          let compatibilities: string[] = [];
          let parentId = '';
          while (compatibilities.length === 0) {
            compatibilities = download.compatibilities ?? [];
            download = this.getById(download.parent_id);
            if (!download || parentId === download.parent_id) {
              break;
            } else {
              parentId = download.parent_id;
            }
          }
          return compatibilities;
        }
      },
      /**
       * Search for files within the downloads.
       * @returns An array of files from the store.
       */
      getFiles() {
        return (filters?: FileFilter | FileFilter[]) => {
          const files = this.downloads.filter(file => file.doc_type === 'download_file') as File[];
          if (filters && !Array.isArray(filters)) {
            filters = [filters];
          } else if (!filters) {
            filters = [undefined]
          }
          const found: File[] = [];
          for (const filter of filters) {
            switch (filter) {
              case 'featured': {
                found.push(...files.filter(file => this.isFeatured(file._id)));
                break;
              }
              case 'non-featured': {
                found.push(...files.filter(file => !this.isFeatured(file._id)));
                break;
              }
              default: {
                found.push(...files);
              }
            }
          }
          return found;
        };
      },
      /**
       * Builds a tree hierarchy of all download folders & files by connecting them via their `parent_id`.
       * @returns The tree hierarchy.
       */
      getTree: (state: State) => {
        // return () => tree(state.downloads, 'parent_id');
        return (key: KeyOf<Download> = 'order', order: Order = 'asc') => tree(clone(state.downloads).sort(byKey(key, order)), 'parent_id');
      },
      getFlatTree() {
        return () => flatten(this.getTree());
      },
      getSubtree() {
        const tree = this.getTree();
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const downloads: Tree<Download>[] = [];
          for (const id of ids) {
            const found = subtree(tree, id);
            if (found) {
              downloads.push(found);
            }
          }
          return ids.length === 1 ? downloads[0] : downloads;
        }) as ((ids: string | [string]) => Tree<Download>) & ((ids: string[]) => Tree<Download>[])
      },
      getFlatSubtree() {
        return (ids: string | string[]) => {
          const downloads: Flat<Tree<Download>>[] = [];
          for (const download of flatten(Array.isArray(ids) ? this.getSubtree(ids as string[]) : [this.getSubtree(ids as string)])) {
            if (downloads.map(dl => dl._id).includes(download._id)) {
              continue;
            }
            downloads.push(download);
          }
          return downloads;
        };
      },
      /**
       * Search for folders that are valid as the new parent of a given download.
       * @param id The ID of the download for which a valid parent folder is beeing searched.
       * @returns An array of valid folders as the new parent.
       */
      getValidParentFolders() {
        return (id: string) => {
          const invalidIds = this.getFlatSubtree(id).map(dl => dl._id);
          return this.getFolders().filter(folder => !invalidIds.includes(folder._id))
        };
      },
      getFilesOfSubtree() {
        return (ids: string | string[], filters?: FileFilter | FileFilter[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const files = this.getFlatSubtree(ids).filter(file => file.doc_type === 'download_file') as Flat<Tree<File>>[];
          if (filters && !Array.isArray(filters)) {
            filters = [filters];
          } else if (!filters) {
            filters = [undefined]
          }
          const found: Flat<Tree<File>>[] = [];
          for (const filter of filters) {
            switch (filter) {
              case 'featured': {
                found.push(...files.filter(file => this.isFeatured(file._id)));
                break;
              }
              case 'non-featured': {
                found.push(...files.filter(file => !this.isFeatured(file._id)));
                break;
              }
              default: {
                found.push(...files);
                break;
              }
            }
          }
          return found;
        };
      },
      hasChildren: (state: State) => {
        return ((ids: string | string[], type?: 'folder' | 'file') => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const featured: boolean[] = [];
          for (const id of ids) {
            switch (type) {
              case 'folder': {
                featured.push(!!state.downloads.filter(dl => dl.doc_type === 'download_folder').find(dl => dl.parent_id === id));
                break;
              }
              case 'file': {
                featured.push(!!state.downloads.filter(dl => dl.doc_type === 'download_file').find(dl => dl.parent_id === id));
                break;
              }
              default: {
                featured.push(!!state.downloads.find(dl => dl.parent_id === id));
                break;
              }
            }
          }
          return ids.length === 1 ? featured[0] : featured;
        }) as ((ids: string | [string], type?: 'folder' | 'file') => boolean) & ((ids: string[], type?: 'folder' | 'file') => boolean[])
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const downloads = await json<Download[]>('/api/store/downloads', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.downloads = downloads;
          // if (downloads.length > 0) {
            this.loaded = true;
          // }
        } finally {
          this.loading--;
        }
      },
      /**
       * Creates a new download.
       * The download will automatically be saved in the database and added to the store.
       * @param download The download.
       */
      async create(download: Partial<Download>, attachment?: unknown) {
        this.loading++;
        try {
          const created = await json<Download>('/api/store/downloads', {
            method: 'POST',
            json: {
              action: 'create',
              attachment: attachment || null,
              download: prepare(download)
            }
          });
          if (created) {
            this.downloads.push(created);
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      async download(file: File) {
        window.location.href = url({
          url: '/api/store/downloads',
          search: {
            action: 'download',
            id: file._id,
          }
        });
      },
      /**
       * Updates an exisiting download.
       * The download will automatically be saved in the database and updated in the store.
       * @param download The download.
       */
      async update(download: Partial<Download>, attachment?: unknown) {
        this.loading++;
        try {
          const updated = await json<Download>('/api/store/downloads', {
            method: 'POST',
            json: {
              action: 'update',
              attachment: attachment || null,
              download: prepare(download)
            }
          });
          if (updated) {
            const index = this.downloads.findIndex((download: Download) => download._id === updated._id);
            this.downloads[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Fetches an email notification preview.
       * The download will automatically be saved in the database and updated in the store.
       * @param download The download.
       */
      async previewEmailNotification(download: Partial<Download>) {
        this.loading++;
        try {
          const preview = await json<EmailNotificationPreview>('/api/store/downloads', {
            method: 'POST',
            json: {
              action: 'preview-email-notification',
              download: prepare(download)
            }
          });
          return preview;
        } catch (error: unknown) {
          return (error as Error).message;
        } finally {
          this.loading--;
        }
      },
      /**
       * Delete an exisiting download.
       * The download will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of downloads to delete.
       */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
        this.loading++;
        try {
          const deletedDocs = await json<Download[]>('/api/store/downloads', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deleted of deletedDocs) {
            const index = this.downloads.findIndex((download: Download) => download._id === deleted._id);
            this.downloads.splice(index, 1);
          }
          return {
            success: true,
            data: deletedDocs,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting download.
       * The download will automatically be saved in the database and updated in the store.
       * @param download The download.
       */
      async move(id: string, parent: string) {
        this.loading++;
        try {
          const moved = await json<Download>('/api/store/downloads', {
            method: 'POST',
            json: {
              action: 'move',
              id,
              parent,
            }
          });
          if (moved) {
            const index = this.downloads.findIndex((download: Download) => download._id === moved._id);
            this.downloads[index] = moved;
            return {
              success: true,
              data: moved,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  store.load();

  // Return the store reference
  return store;
};