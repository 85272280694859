import { defineStore } from 'pinia';
import { $gettext } from 'vue-gettext';

interface State {
  languages: Language[];
}

/**
 * A language.
 */
export interface Language {
  _id: string;
  name: string;
  flag: string;
}

/**
 * The language store.
 */
export const useLanguageStore = defineStore('language', {
  ///-------------------------------------------------------------------
  /// STATE
  ///-------------------------------------------------------------------
  state: (): State => {
    return {
      languages: [
        {
          _id: 'Deutsch',
          name: $gettext('German'),
          flag: '🇩🇪',
        },
        {
          _id: 'Englisch',
          name: $gettext('English'),
          flag: '🇺🇸',
        },
      ]
    }
  },
  ///-------------------------------------------------------------------
  /// GETTERS
  ///-------------------------------------------------------------------
  getters: {
    /**
     * Searches for the language with the given ID and returns its name.
     * @param id The ID of the language.
     * @returns The name of the language.
     */
    getNameOf: (state: State) => {
      return (id: string | null, gap: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'xs') => {
        const compactMode = document.documentElement.getAttribute('data-compact-mode') === 'true';
        let whiteSpace = '&nbsp;';
        switch (gap) {
          case 'sm': whiteSpace = compactMode ? '&nbsp;' : '&ensp;'; break;
          case 'md': whiteSpace = compactMode ? '&ensp;' : '&emsp;'; break;
          case 'lg': whiteSpace = compactMode ? '&emsp;' : '&emsp;&emsp;'; break;
          case 'xl': whiteSpace = compactMode ? '&emsp;&emsp;' : '&emsp;&emsp;&emsp;&emsp;'; break;
        }
        if (!id || typeof id !== 'string') { return `❓${whiteSpace}${$gettext('Unknown')}` }
        const lang = state.languages.find(language => language._id === id);
        return lang
          ? `<span style="display: inline-block; text-align: center; width: 1.25rem;">${lang.flag}</span>${whiteSpace}${lang.name}`
          : `<span style="display: inline-block; text-align: center; width: 1.25rem;">❓</span>${whiteSpace}${$gettext('Unknown')}`;
      }
    },
  },
})