
import { $gettext } from 'vue-gettext';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { CSSProperties, defineComponent, ref, watch } from 'vue';
import { currentLanguage, getTranslated } from '@/translation';
import { fileIcon } from '../icon/BpIcon';
import { formatBytes } from '@/utils/string';
import { json } from '@sahnee/ajax';
import { logout } from '@/utils/auth';
import { Navigation } from '../navigation/BpNavigation';
import { reloadStores } from '@/utils/store';
import { useDownloadStore } from '@/stores/download';
import { useLanguageStore } from '@/stores/language';
import { useRouter } from 'vue-router';
import { useUser } from '@/utils/user';
import { useVersionStore } from '@/stores/version';
import { useVideoStore } from '@/stores/video';
import BpHeader from '../BpHeader.vue';
import useCompactMode from '@/compositions/use-compact-mode';
import useDarkMode from '@/compositions/use-dark-mode';

export default defineComponent({
  name: 'bp-layout',
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);

    ///-------------------------------------------------------------------
    /// STORES
    ///-------------------------------------------------------------------

    const downloadStore = useDownloadStore();
    const languageStore = useLanguageStore();
    const versionStore = useVersionStore();
    const videoStore = useVideoStore(true);

    ///-------------------------------------------------------------------
    /// HEADER HEIGHT CALCULATION
    ///-------------------------------------------------------------------

    const headerEl = ref<typeof BpHeader>();
    const mainEl = ref<HTMLElement>();

    const style = ref<CSSProperties>({});
    watch(() => [headerEl.value, mainEl.value], () => {
      const header = headerEl.value && headerEl.value.$el as HTMLElement | undefined;
      const main = mainEl.value;
      if (!header || !main) {
        style.value = {};
        return;
      }
      const boundingRect = header.getBoundingClientRect();
      const marginTop = parseFloat(window.getComputedStyle(main).getPropertyValue('margin-top'))
      style.value = {
        '--portal-header-height': (boundingRect.height + boundingRect.y + marginTop) + 'px',
      }
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: darkMode, toggle: toggleDarkMode } = useDarkMode();
    const { current: compactMode, toggle: toggleCompactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// MAIN NAV
    ///-------------------------------------------------------------------

    const user = useUser();

    // NAVIGATION
    const mainNav = ref<Navigation>([]);
    watch(() => [user.value, currentLanguage.value], () => {
      if (!user.value || !user.value.roles) {
        return;
      }
      if (user.value?.roles.includes('admin') && !user.value.roles.includes('impersonate_user')) {
        mainNav.value = [
          {
            _id: 'dashboard',
            name: $gettext('Dashboard'),
            icon: 'gauge-high',
            to: { name: 'admin.dashboard' },
          },
          {
            _id: 'management',
            name: $gettext('Management'),
            icon: 'user-gear',
            children: [
              {
                _id: 'customer',
                name: $gettext('Customers'),
                icon: 'users',
                to: { name: 'admin.customer.overview' },
                children: [
                  {
                    _id: 'customer_new',
                    hidden: true,
                    to: { name: 'admin.customer.new' },
                  },
                  {
                    _id: 'customer_edit',
                    hidden: true,
                    to: { name: 'admin.customer.edit' },
                  }
                ]
              },
              {
                _id: 'contact',
                name: $gettext('Contacts'),
                icon: 'address-book',
                to: { name: 'admin.contact.overview' },
                children: [
                  {
                    _id: 'contact_new',
                    hidden: true,
                    to: { name: 'admin.contact.new' },
                  },
                  {
                    _id: 'contact_edit',
                    hidden: true,
                    to: { name: 'admin.contact.edit' },
                  }
                ]
              },
              {
                _id: 'user',
                name: $gettext('Registered users'),
                icon: 'user-check',
                to: { name: 'admin.user.overview' },
              },
              {
                _id: 'product_and_version',
                name: $gettext('Products & Versions'),
                icon: 'v',
                children: [
                  {
                    _id: 'version',
                    name: $gettext('Product & version overview'),
                    icon: 'v',
                    to: { name: 'admin.version.overview' },
                    children: [
                      {
                        _id: 'version_product_new',
                        hidden: true,
                        to: { name: 'admin.version.product.new' },
                      },
                      {
                        _id: 'version_product_edit',
                        hidden: true,
                        to: { name: 'admin.version.product.edit' },
                      },
                      {
                        _id: 'version_component_new',
                        hidden: true,
                        to: { name: 'admin.version.component.new' },
                      },
                      {
                        _id: 'version_component_edit',
                        hidden: true,
                        to: { name: 'admin.version.component.edit' },
                      },
                      {
                        _id: 'version_number_new',
                        hidden: true,
                        to: { name: 'admin.version.number.new' },
                      },
                      {
                        _id: 'version_number_edit',
                        hidden: true,
                        to: { name: 'admin.version.number.edit' },
                      },
                    ]
                  },
                  {
                    _id: 'changelog',
                    name: $gettext('Changelog') + ' (' + $gettext('customer view') + ')',
                    icon: 'list-timeline',
                    to: { name: 'changelog' },
                  },
                ],
              },
              {
                _id: 'operating_systems',
                name: $gettext('Operating systems'),
                icon: 'compact-disc',
                to: { name: 'admin.operating-system.overview' },
                children: [
                  {
                    _id: 'operating_system_designation_new',
                    hidden: true,
                    to: { name: 'admin.operating-system.designation.new' },
                  },
                  {
                    _id: 'operating_system_designation_edit',
                    hidden: true,
                    to: { name: 'admin.operating-system.designation.edit' },
                  },
                  {
                    _id: 'operating_system_version_new',
                    hidden: true,
                    to: { name: 'admin.operating-system.version.new' },
                  },
                  {
                    _id: 'operating_system_version_edit',
                    hidden: true,
                    to: { name: 'admin.operating-system.version.edit' },
                  },
                ]
              },
            ],
          },
          {
            _id: 'download',
            name: $gettext('Downloads'),
            icon: 'download',
            children: [
              {
                _id: 'download_overview',
                name: $gettext('Download overview'),
                icon: 'download',
                to: { name: 'admin.download.overview' },
                children: [
                  {
                    _id: 'download_folder_new',
                    hidden: true,
                    to: { name: 'admin.download.folder.new' },
                  },
                  {
                    _id: 'download_folder_edit',
                    hidden: true,
                    to: { name: 'admin.download.folder.edit' },
                  },
                  {
                    _id: 'download_file_new',
                    hidden: true,
                    to: { name: 'admin.download.file.new' },
                  },
                  {
                    _id: 'download_file_edit',
                    hidden: true,
                    to: { name: 'admin.download.file.edit' },
                  },
                ]
              },
              {
                _id: 'download_area',
                name: $gettext('All downloads') + ' (' + $gettext('customer view') + ')',
                icon: 'download',
                to: { name: 'download.area' },
              },
            ],
          },
          {
            _id: 'video',
            name: $gettext('Videos'),
            icon: 'circle-play',
            children: [
              {
                _id: 'video_overview',
                name: $gettext('Video overview'),
                icon: 'circle-play',
                to: { name: 'admin.video.overview' },
                children: [
                  {
                    _id: 'video_category_new',
                    hidden: true,
                    to: { name: 'admin.video.category.new' },
                  },
                  {
                    _id: 'video_category_edit',
                    hidden: true,
                    to: { name: 'admin.video.category.edit' },
                  },
                  {
                    _id: 'video_recording_new',
                    hidden: true,
                    to: { name: 'admin.video.recording.new' },
                  },
                  {
                    _id: 'video_recording_edit',
                    hidden: true,
                    to: { name: 'admin.video.recording.edit' },
                  },
                  {
                    _id: 'video_webinar_new',
                    hidden: true,
                    to: { name: 'admin.video.webinar.new' },
                  },
                  {
                    _id: 'video_webinar_edit',
                    hidden: true,
                    to: { name: 'admin.video.webinar.edit' },
                  },
                ]
              },
              {
                _id: 'video_area',
                name: $gettext('All videos') + ' (' + $gettext('customer view') + ')',
                icon: 'circle-play',
                to: { name: 'video.area' },
                children: [
                  {
                    _id: 'video_play',
                    hidden: true,
                    to: { name: 'video.play' },
                  },
                ]
              },
            ],
          },
          {
            _id: 'tools',
            name: $gettext('Tools'),
            icon: 'screwdriver-wrench',
            children: [
              {
                _id: 'mail_list_generator',
                name: $gettext('Mail list generator'),
                icon: ['far', 'clipboard-list'],
                to: { name: 'admin.tools.mail-list-generator' },
              },
              {
                _id: 'license_certificate_generator',
                name: $gettext('License certificate generator'),
                icon: 'file-certificate',
                to: { name: 'admin.tools.license-certificate-generator' },
              },
            ],
          },
        ];
        return;
      } else if (user.value.roles.includes('user') || user.value.roles.includes('impersonate_user')) {
        mainNav.value = [
          {
            _id: 'home',
            name: $gettext('Home'),
            icon: 'house-chimney',
            to: { name: 'home' },
          },
          {
            _id: 'license',
            name: $gettext('License'),
            icon: 'file-certificate',
            children: [
              {
                _id: 'license_overview',
                name: $gettext('License overview'),
                icon: 'file-certificate',
                to: { name: 'license' },
              },
              {
                _id: 'license_extension',
                name: $gettext('License extension'),
                icon: 'file-certificate',
                to: { name: 'license.extend' },
              },
            ],
          },
          {
            _id: 'download',
            name: $gettext('Downloads'),
            icon: 'download',
            children: [
              {
                _id: 'download_area',
                name: $gettext('All downloads'),
                icon: 'download',
                to: { name: 'download.area' },
              },
            ],
          },
          {
            _id: 'video',
            name: $gettext('Videos'),
            icon: 'circle-play',
            children: [
              {
                _id: 'video_area',
                name: $gettext('All videos'),
                icon: 'circle-play',
                to: { name: 'video.area' },
                children: [
                  {
                    _id: 'video_play',
                    hidden: true,
                    to: { name: 'video.play' },
                  },
                ]
              },
            ],
          },
          {
            _id: 'help',
            name: $gettext('Help'),
            icon: 'square-question',
            children: [
              {
                _id: 'support',
                name: $gettext('Support'),
                icon: 'ticket',
                to: { name: 'support' },
              },
              {
                _id: 'consultation',
                name: $gettext('Consultation'),
                icon: 'envelope',
                to: { name: 'consultation' },
              },
              {
                _id: 'changelog',
                name: $gettext('Changelog'),
                icon: 'list-timeline',
                to: { name: 'changelog' },
              },
            ],
          },
        ];
        return;
      }
      mainNav.value = [];
    }, { immediate: true, deep: true });

    // DOWNLOAD HEADER
    const showDownloadHeader = ref(false);
    watch(() => [downloadStore.isLoading(), isDesktop.value], () => {
      if (!downloadStore.loaded || downloadStore.isLoading()) { return }
      showDownloadHeader.value = isDesktop.value && downloadStore.getFiles('featured').length > 0;
    }, { immediate: true })

    // VIDEO HEADER
    const showVideoHeader = ref(false);
    watch(() => [videoStore.isLoading(), isDesktop.value], () => {
      if (!videoStore.loaded || videoStore.isLoading()) { return }
      showVideoHeader.value = isDesktop.value && videoStore.getVideos({ filter: ['featured-webinar'] }).length > 0;
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// SIDE NAV
    ///-------------------------------------------------------------------

    // NAVIGATION
    const sideNav = ref<Navigation>([])
    watch(() => [user.value, compactMode?.value, currentLanguage.value], () => {
      if(!user.value || !user.value.roles) {
        return;
      }
      if (user.value.roles.includes('anonymous')) {
        sideNav.value = [
          {
            _id: 'account',
            name: $gettext('Account'),
            icon: 'circle-user',
            children: [
              {
                _id: 'customize_appearance',
                name: $gettext('Customize appearance'),
                icon: 'circle-half-stroke',
                children: [
                  {
                    _id: 'dark_mode',
                    name: $gettext('Dark mode'),
                    icon: 'moon',
                    click: toggleDarkMode,
                  },
                  {
                    _id: 'compact_mode',
                    name: $gettext('Compact mode'),
                    icon: 'arrows-to-dot',
                    click: toggleCompactMode,
                  },
                ]
              },
              {
                _id: 'language',
                name: `${$gettext('Language')}: <strong>${currentLanguage.value === 'de' ? $gettext('German') : $gettext('English')}</strong>`,
                icon: 'language',
                children: languageStore.languages.map(lang => ({
                  ...lang,
                  name: languageStore.getNameOf(lang._id, 'md'),
                  click: () => currentLanguage.value = lang._id === 'Deutsch' ? 'de' : 'en_US',
                })),
              },
              {
                _id: 'cookie_consent',
                name: $gettext('Update cookie consent'),
                icon: ['far', 'cookie-bite'],
                click: openCookieBanner,
              },
            ],
          },
        ]
        return;
      } else {
        sideNav.value = [
          {
            _id: 'account',
            name: $gettext('Account'),
            icon: 'circle-user',
            children: [
              {
                _id: 'datamanagement',
                name: $gettext('Manage own user data'),
                icon: 'address-card',
                to: { name: 'datamanagement' },
              },
              ...(user.value.roles.includes('admin') && !user.value.roles.includes('impersonate_user') ? [{
                _id: 'global_settings',
                name: $gettext('Global settings'),
                icon: 'gears',
                to: { name: 'admin.settings' },
              }] : []),
              {
                _id: 'customize_appearance',
                name: $gettext('Customize appearance'),
                icon: 'circle-half-stroke',
                children: [
                  {
                    _id: 'dark_mode',
                    name: $gettext('Dark mode'),
                    icon: 'moon',
                    click: toggleDarkMode,
                  },
                  {
                    _id: 'compact_mode',
                    name: $gettext('Compact mode'),
                    icon: 'arrows-to-dot',
                    click: toggleCompactMode,
                  },
                ]
              },
              {
                _id: 'language',
                name: `${$gettext('Language')}: <strong>${currentLanguage.value === 'de' ? $gettext('German') : $gettext('English')}</strong>`,
                icon: 'language',
                children: languageStore.languages.map(lang => ({
                  ...lang,
                  name: languageStore.getNameOf(lang._id, 'md'),
                  click: () => currentLanguage.value = lang._id === 'Deutsch' ? 'de' : 'en_US',
                })),
              },
              {
                _id: 'cookie_consent',
                name: $gettext('Update cookie consent'),
                icon: ['far', 'cookie-bite'],
                click: openCookieBanner,
              },
            ],
          },
        ]
        return;
      }
      // sideNav.value = [];
    }, { immediate: true, deep: true });

    // BACK TO ADMIN
    async function backToAdmin() {
      const response = await json<{ success: boolean }>('/api/session-user/edit', { method: 'POST', json: { customerId: '', action: 'back_to_admin' } });
      if (response.success) {
        reloadStores(true);
        router.replace({
          name: 'admin.dashboard',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// COOKIE CONSENT
    ///-------------------------------------------------------------------
    
    function openCookieBanner() {
      document.documentElement.dispatchEvent(new CustomEvent('cookie-banner'))
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      backToAdmin,
      compactMode,
      darkMode,
      downloadStore,
      fileIcon,
      formatBytes,
      getTranslated,
      headerEl,
      isDesktop,
      logout,
      mainEl,
      mainNav,
      router,
      showDownloadHeader,
      showVideoHeader,
      sideNav,
      style,
      user,
      versionStore,
      videoStore,
    }
  }
});
