import BpToast from '@/components/toast/BpToasts';
import { $gettext } from 'vue-gettext';
import { getCssPropertyValue } from './css';
import { limitLength, removeHTML, uuid } from './string';

export function copyToClipboard(text: string) {
  text = removeHTML(text);
  try {
    navigator.clipboard.writeText(text);
    BpToast.show({ content: $gettext('"<strong>%{text}</strong>" copied to clipboard.', { text: limitLength(text, 100) }), duration: 3000, icon: ['far', 'copy'] });
  } catch (error: unknown) {
    console.info((error as Error).message + '\nFallback to displaying the message in the toast + the duration is infinite.');
    const unique = uuid();
    BpToast.show({ content: `<div style="margin-bottom: calc(-1 * var(--border-width))">
      <p>${$gettext('Copy to clipboard')}:</p>
      <br>
      <textarea id="bp-toast__textarea-${unique}" style="width: calc(100% - var(--padding)); box-sizing: content-box; padding: var(--padding-sm); border: 0; border-radius: var(--border-radius-sm); resize: vertical;">${text}</textarea>
    </div>`, duration: -1, icon: ['far', 'copy'] });
    window.setTimeout(() => {
      const toast = document.querySelector(`.bp-toast:has(#bp-toast__textarea-${unique})`) as HTMLDivElement;
      const textarea = document.getElementById(`bp-toast__textarea-${unique}`) as HTMLTextAreaElement;
      if (!toast || !textarea) {
        return;
      }
      const padding = parseFloat(getCssPropertyValue('padding-top', textarea)) + parseFloat(getCssPropertyValue('padding-bottom', textarea))
      const height = `${textarea.scrollHeight - padding}px`;
      textarea.style.height = height;
      const margin = toast.scrollHeight - textarea.scrollHeight + parseFloat(getCssPropertyValue('padding-top', toast)) + parseFloat(getCssPropertyValue('padding-bottom', toast));
      const maxHeight = `calc(50 * var(--vh) - var(--offset-vertical) - ${margin}px - 2rem)`;
      textarea.style.minHeight = `min(${height}, ${maxHeight})`;
      textarea.style.maxHeight = maxHeight;
      textarea.focus()
      textarea.select();
    }, 0);
  }
}