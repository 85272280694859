import { finder } from '@medv/finder';

/**
 * Returns the value for a given CSS variable. The `--` prefix can be omitted. Variables surrounded by the `var` function can also be resolved.
 * @param variable The CSS variable.
 * @returns The value of the given variable or an empty string if the variable is missing.
 */
export default function getCssVariable(variable: string, el = document.documentElement): string {
  if (variable.startsWith('var(')) {
    variable = variable.substring('var('.length, variable.length - 1);
  } else if (!variable.startsWith('--')) {
    variable = '--' + variable;
  }
  return getCssPropertyValue(variable, el);
}

export function getCssPropertyValue(property: string, el: Element = document.documentElement): string {
  return getComputedStyle(el).getPropertyValue(property).trim();
}

type StyleSheet = Record<string, Rule>;
type Rule = Record<string, string | Record<string, string>>;

/**
 * Applies the given styles to the document or the given stylesheet.
 * @param rules The rules.
 * @param styleEl (optional) An already existing stylesheet to overwrite.
 * @returns The stylesheet.
 */
export function setStyles(rules: StyleSheet, styleEl?: HTMLStyleElement): HTMLStyleElement {
  if (!styleEl) {
    styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
  }
  let style = '\n';
  for (const [selector, rule] of Object.entries(rules)) {
    let propStr = '';
    for (const [property, value] of Object.entries(rule)) {
      if (typeof value === 'string') {
        propStr += '  ' + property + ': ' + value + ';\n';
      } else {
        propStr += '  ' + property + ' {\n';
        for (const [nestedProperty, nestedValue] of Object.entries(value)) {
          propStr += '    ' + nestedProperty + ': ' + nestedValue + ';\n';
        }
        propStr += '  }\n';
      }
    }
    style += selector + ' {\n' + propStr + '}\n\n';
  }
  styleEl.innerHTML = style;

  return styleEl;
}

/**
 * Generates a unique CSS selector for a given element.
 * @param element The HTML element.
 * @param unique Whether the CSS path should be unique in the current view (`view`) or globally (`global`).
 * @returns The unique CSS selector.
 */
export function cssPath(element: Element, unique: 'global' | 'view' = 'view') {
  if (unique === 'view') {
    return finder(element);
  }
  // unique === 'global'
  return finder(element, {
    root: document.getElementById('bp-main') as Element,
    idName: () => false,
    className: (name) => !name.includes('__'),
    attr: () => false,
    seedMinLength: 1000,
    optimizedMinLength: 1000,
  });
}
