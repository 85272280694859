import '@/utils/vh';
import './icons';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { tooltip } from './utils/tooltip';
import App from './App.vue';
import BpAlert from '@/components/BpAlert.vue';
import BpBanner from '@/components/banner/BpBanner.vue';
import BpButton from '@/components/BpButton.vue';
import BpButtonGroup from '@/components/BpButtonGroup.vue';
import BpCard from '@/components/card/BpCard.vue';
import BpCodeEditor from '@/components/BpCodeEditor.vue';
import BpDownloadPreview from '@/components/download-preview/BpDownloadPreview.vue';
import BpFieldset from '@/components/BpFieldset.vue';
import BpFooter from '@/components/BpFooter.vue';
import BpFormButton from '@/components/BpFormButton.vue';
import BpFragment from '@/components/BpFragment.vue';
import BpHeader from '@/components/BpHeader.vue';
import BpIcon from '@/components/icon/BpIcon.vue';
import BpIframe from '@/components/iframe/BpIframe.vue';
import BpInput from '@/components/BpInput.vue';
import BpLabel from '@/components/BpLabel.vue';
import BpLoading from '@/components/BpLoading.vue';
import BpMasonryGrid from '@/components/BpMasonryGrid.vue';
import BpPasswordValidator from '@/components/BpPasswordValidator.vue';
import BpPopover from '@/components/popover/BpPopover.vue';
import BpSelect from '@/components/BpSelect.vue';
import BpSpacer from '@/components/BpSpacer.vue';
import BpTable from '@/components/table/BpTable.vue';
import BpTimeline from '@/components/timeline/BpTimeline.vue';
import BpToasts from '@/components/toast/BpToasts.vue';
import BpToggleButton from '@/components/BpToggleButton.vue';
import BpTooltip from '@/components/tooltip/BpTooltip.vue';
import BpVideoPreview from '@/components/video/BpVideoPreview.vue';
import BpViewHeader from '@/components/BpViewHeader.vue';
import BpVirtualScroller from '@/components/virtual-scroller/BpVirtualScroller.vue';
import BpWindow from '@/components/BpWindow.vue';
import BpWysiwygEditor from '@/components/wysiwyg-editor/BpWysiwygEditor.vue';
import registerTranslation from './translation';
import router from './router';
import VueClickAway from "vue3-click-away";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import YouTube from 'vue3-youtube';

const pinia = createPinia();

const app = createApp(App);

registerTranslation(app).then(a => {
  a
    .component('bp-alert', BpAlert)
    .component('bp-banner', BpBanner)
    .component('bp-button-group', BpButtonGroup)
    .component('bp-button', BpButton)
    .component('bp-card', BpCard)
    .component('bp-code-editor', BpCodeEditor)
    .component('bp-download-preview', BpDownloadPreview)
    .component('bp-fieldset', BpFieldset)
    .component('bp-footer', BpFooter)
    .component('bp-form-button', BpFormButton)
    .component('bp-fragment', BpFragment)
    .component('bp-header', BpHeader)
    .component('bp-icon', BpIcon)
    .component('bp-iframe', BpIframe)
    .component('bp-input', BpInput)
    .component('bp-label', BpLabel)
    .component('bp-loading', BpLoading)
    .component('bp-masonry-grid', BpMasonryGrid)
    .component('bp-password-validator', BpPasswordValidator)
    .component('bp-popover', BpPopover)
    .component('bp-qrcode', VueQrcode)
    .component('bp-select', BpSelect)
    .component('bp-spacer', BpSpacer)
    .component('bp-table', BpTable)
    .component('bp-timeline', BpTimeline)
    .component('bp-toasts', BpToasts)
    .component('bp-toggle-button', BpToggleButton)
    .component('bp-tooltip', BpTooltip)
    .component('bp-video-preview', BpVideoPreview)
    .component('bp-view-header', BpViewHeader)
    .component('bp-virtual-scroller', BpVirtualScroller)
    .component('bp-window', BpWindow)
    .component('bp-wysiwyg-editor', BpWysiwygEditor)
    .component('bp-youtube-iframe', YouTube)
    .directive('tooltip', tooltip)
    .use(pinia)
    .use(router)
    .use(VueClickAway)
    .mount('#app');
});
