
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { availableLanguages, currentLanguage, currentLanguageISOString, getTranslatedKey, getTranslatedName, isTranslated, TranslatedKey } from '@/translation';
import { humanizedJoin, uuid } from '@/utils/string';
import { IconProp } from './icon/BpIcon';
import { VAceEditor } from 'vue3-ace-editor';
import format from 'pretty';
import useDarkMode from '@/compositions/use-dark-mode';
import { cssColor, Modifier } from '@/utils/color';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/theme-tomorrow';

export default defineComponent({
  name: 'bp-code-editor',
  components: {
    VAceEditor
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    valid: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },
    // LABEL
    disabled: Boolean,
    error: String,
    errorIcon: IconProp,
    label: String,
    labelPosition: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
    labelWidth: String,
    large: Boolean,
    indent: Number,
    required: Boolean,
    seamless: Boolean,
    text: String,
  },
  emits: [
    'update:model-value',
    'change-dirty',
    'change-valid',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// LABEL
    ///-------------------------------------------------------------------

    const labelProps = computed(() => Object.fromEntries(Object.entries(props)
      .filter(([key]) => [
        'disabled', 'error', 'errorIcon', 'innerGap', 'indent', 'label', 'labelPosition', 'labelWidth', 'required', 'seamless', 'text'
      ].includes(key))));
    const labelSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => ![
        'default',
        'label',
        hasError() && 'error',
        isTranslated(props.modelValue) && 'text'
      ].includes(slot));
    });

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const language = ref<TranslatedKey>(currentLanguageISOString());

    function setLanguage(lang: string) {
      language.value = getTranslatedKey(lang);
    }

    ///-------------------------------------------------------------------
    /// VALIDITY
    ///-------------------------------------------------------------------

    const hasError = () => !!((isDirty.value && (props.valid === false || !isValid.value || errorMessage.value)) || props.error || ctx.slots.error);

    const errorMessage = ref('');
    const isDirty = ref(false);
    const isValid = ref<boolean>(props.valid !== undefined ? props.valid : true);
    const unique = uuid();

    function checkValidity() {
      const wasValid = isValid.value;
      const oldError = errorMessage.value;
      const value = props.modelValue as string;

      errorMessage.value = '';

      ///-------------------------------------------------------------------
      /// DISABLED -- TODO: Check if this logic is correct!
      ///-------------------------------------------------------------------

      if (props.disabled) {
        ctx.emit('change-valid', '', value);
        return;
      }

      ///-------------------------------------------------------------------
      /// REQUIRED
      ///-------------------------------------------------------------------

      if (props.required) {
        // TRANSLATED OBJECT
        if (isTranslated(value) && Object.values(value).filter(val => !val).length > 0) {
          isValid.value = false;
          const errorLanguages = Object.entries(value)
            .filter(([, val]) => !val)
            .map(([lang]) => getTranslatedName(lang))
            .sort((a, b) => a < b ? -1 : 1);
          errorMessage.value = humanizedJoin(errorLanguages) + ' ' + (errorLanguages.length > 1 ? $gettext('are required.') : $gettext('is required.'));
          ctx.emit('change-valid', errorMessage.value, value);
          return;
        }

        // STRING
        if (typeof value === 'string' && value.length === 0) {
          isValid.value = false;
          errorMessage.value = $gettext('Is required.');
          ctx.emit('change-valid', errorMessage.value, value);
          return;
        }
      }

      ///-------------------------------------------------------------------
      /// DIRTY
      ///-------------------------------------------------------------------

      if (!isDirty.value) {
        ctx.emit('change-valid', '', value);
        return;
      }

      ///-------------------------------------------------------------------
      /// VALIDITY
      ///-------------------------------------------------------------------

      // TODO: Ace editor validity check
      isValid.value = true;

      // Error prop/slot
      if (props.error || ctx.slots.error) {
        isValid.value = false;
        errorMessage.value = props.error || 'TODO';
      }

      if (wasValid !== isValid.value || oldError !== errorMessage.value) {
        ctx.emit('change-valid', isValid.value ? null : errorMessage.value, value);
      }
    }

    watch(
      () => [props.modelValue, currentLanguage.value, props.required, props.disabled],
      () => setTimeout(checkValidity, 0),
      { immediate: true, deep: true }
    );

    // function updateError(event: Event) {
    //   const message = (event.target as HTMLInputElement)?.validationMessage;
    //   errorMessage.value = props.error || message;
    //   ctx.emit('change-valid', errorMessage.value, props.modelValue);
    // }

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = computed(() => ({
      '--ace-editor-border-color': cssColor('light-blue'),
      '--ace-editor-focus-background-color': cssColor('light-blue'),
      '--ace-editor-focus-text-color': cssColor('light-blue', Modifier.TEXT),
    }));

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: darkMode } = useDarkMode();

    ///-------------------------------------------------------------------
    /// VALUE
    ///-------------------------------------------------------------------

    const value = ref('');
    watch (() => [props.modelValue, language.value], () => {
      if (props.modelValue && minify(value.value) !== props.modelValue) {
        value.value = format(props.modelValue);
      }
    }, { immediate: true, deep: true });

    function minify(code: string) {
      return code.replace(/\n\s*/g, '');
    }

    function update(code: string) {
      value.value = code;
      ctx.emit('update:model-value', minify(code));
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      availableLanguages,
      darkMode,
      errorMessage,
      format,
      getTranslatedKey,
      getTranslatedName,
      hasError,
      isDirty,
      isTranslated,
      labelProps,
      labelSlots,
      language,
      setLanguage,
      style,
      unique,
      update,
      value,
    }
  }
})

