
import { currentLanguage } from '@/translation';
import { defineComponent, nextTick, onActivated, onMounted, onUnmounted, ref, watch } from 'vue';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-masonry-grid',
  props: {
    columns: {
      type: Number,
      default: 3,
    },
    triggerResizeWhenThisChanges: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// GRID STYLE
    /// Masonry layout, see: https://medium.com/@andybarefoot/a-masonry-style-layout-using-css-grid-8c663d355ebb
    ///-------------------------------------------------------------------

    const gridEl = ref<HTMLDivElement>();

    function resizeGrid() {
      if (!gridEl.value) {
        return;
      }
      const rowHeight = parseInt(window.getComputedStyle(gridEl.value).getPropertyValue('grid-auto-rows'));
      const rowGap = parseInt(window.getComputedStyle(gridEl.value).getPropertyValue('grid-row-gap'));

      for (const child of gridEl.value.children) {
        const style = (child as HTMLElement).style;
        style.height = 'auto';
        const rowSpan = Math.ceil((child.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        style.height = '100%';
        style.gridRowEnd = `span ${rowSpan}`;
      }
    }

    watch(() => [compactMode?.value, currentLanguage.value, props.triggerResizeWhenThisChanges], () => {
      nextTick(resizeGrid)
    });

    const observer = new MutationObserver(resizeGrid);
    watch(() => [gridEl.value], () => {
      if (!gridEl.value) { return }
      resizeGrid();
      observer.observe(gridEl.value, { childList: true, subtree: true });
    }, { immediate: true, deep: true });
    onMounted(() => {
      addEventListener('resize', resizeGrid, { passive: true });
    })
    onUnmounted(() => {
      removeEventListener('resize', resizeGrid);
      observer.disconnect();
    })
    onActivated(resizeGrid);

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      gridEl,
    }
  }
})
