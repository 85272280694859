import { BaseDoc } from '../virtual-scroller/BpVirtualScroller';
import { Icon } from '../icon/BpIcon';
import { localizeDate, localizeDatetime, localizeTime } from '@/utils/date';
import { Tooltip } from '@/utils/tooltip';
import useCompactMode from '@/compositions/use-compact-mode';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn<T> = (item: any, value?: any) => T

export interface Header {
  _id: string;
  _group?: string;
  name: string;
  canCopy?: boolean;
  filter?: (BaseDoc & { detect?: Fn<boolean> })[];
  hidden?: boolean;
  icon?: Icon | Fn<Icon>;
  monospace?: boolean;
  notSortable?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  tooltip?: Fn<Tooltip>,
  transform?: Fn<unknown>,
  width?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const toBoolean = <T>(_item: T, value: unknown) => !!value;
export const toDate = <T>(_item: T, date: string | Date) => localizeDate(date);
export const toDatetime = <T>(_item: T, datetime: string | Date) => localizeDatetime(datetime);
export const toMail = <T>(_item: T, mail: string) => mail ? `<a href="mailto:${mail}">${mail}</a>` : '';
export const toTel = <T>(_item: T, tel: string) => tel ? `<a href="tel:${tel.replace(/\(0\)/g, '').replace(/[^0-9+]/g, '')}">${tel}</a>` : '';
export const toTime = <T>(_item: T, time: string | Date) => localizeTime(time);

export function actionTooltip(text: string) {
  const { current: compactMode } = useCompactMode();
  return { offset: compactMode?.value ? [-4, 0] : [-12, 0], text }
}