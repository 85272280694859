import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-alert__content" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-alert', { 'bp-alert--compact': _ctx.compactMode, 'bp-alert--small': _ctx.small }]),
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_bp_icon, {
          key: 0,
          class: "bp-alert__icon",
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_2)
      ], true)
    ])
  ], 6))
}