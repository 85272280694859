
import { computed, defineComponent, ref } from 'vue';
import { Sketch as SketchColorPicker } from '@ckpack/vue-color';
import { Color, cssColor } from '@/utils/color';
import getCssVariable from '@/utils/css';

interface ColorPickerColor {
  a: number;
  hex: string;
  hex8: string;
  hsl: {
    h: number;
    s: number;
    l: number;
    a: number;
  };
  hsv: {
    h: number;
    s: number;
    v: number;
    a: number;
  };
  rgba: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  source: string;
}

/**
 * A  app Color picker component.
 */
export default defineComponent({
  name: 'bp-wysiwyg-editor-color-picker',
  components: {
    SketchColorPicker,
  },
  props: {
    modelValue: {
      type: String,
      default: '#ffffff',
    },
  },
  emits: ['update:modelValue'],
  setup(_props, ctx) {
    const colorPicker = ref<typeof SketchColorPicker>();

    function updateColor(color: ColorPickerColor) {
      const pickedColor = color.a === 1 ? color.hex : color.hex8;
      ctx.emit('update:modelValue', pickedColor);
    }

    ///-------------------------------------------------------------------
    /// PRESETS
    ///-------------------------------------------------------------------

    /**
     * Gets an array of valid color prsets.
     * @returns The colors.
     */
    const presetColors = computed(() => {
      return ['red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'light-blue', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose']
        .map((c) => {
          const color = cssColor(c as Color, 500);
          if (!color) {
            return c;
          }
          return getCssVariable(color);
        })
        .concat(['transparent', 'white', 'black']);
    });

    return {
      updateColor,
      colorPicker,
      presetColors,
    };
  },
});
