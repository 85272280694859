import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bp-password-validator" }
const _hoisted_2 = { class: "bp-password-validator__strength-meter" }
const _hoisted_3 = { class: "flex-column gap-xs" }
const _hoisted_4 = { class: "flex gap-sm" }
const _hoisted_5 = { class: "flex gap-sm" }
const _hoisted_6 = { class: "flex gap-sm" }
const _hoisted_7 = { class: "flex gap-sm" }
const _hoisted_8 = { class: "flex gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_translate, { tag: "strong" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Password strength")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4], (segment) => {
        return _createElementVNode("div", {
          class: _normalizeClass(['bp-password-validator__strength-meter-segment', {
        'bp-password-validator__strength-meter-segment--filled': segment <= (_ctx.valid ? _ctx.score : 0),
      }]),
          key: segment
        }, [
          _cache[6] || (_cache[6] = _createElementVNode("div", null, null, -1)),
          (segment === (_ctx.valid ? _ctx.score : 0))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                ((_ctx.valid ? _ctx.score : 0) === 0)
                  ? _withDirectives((_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Poor")
                      ])),
                      _: 1
                    })), [
                      [_directive_tooltip, _ctx.$gettext('Risky password')]
                    ])
                  : ((_ctx.valid ? _ctx.score : 0) === 1)
                    ? _withDirectives((_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("Weak")
                        ])),
                        _: 1
                      })), [
                        [_directive_tooltip, _ctx.$gettext('Protection from throttled online attacks')]
                      ])
                    : ((_ctx.valid ? _ctx.score : 0) === 2)
                      ? _withDirectives((_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Medium")
                          ])),
                          _: 1
                        })), [
                          [_directive_tooltip, _ctx.$gettext('Protection from unthrottled online attacks')]
                        ])
                      : ((_ctx.valid ? _ctx.score : 0) === 3)
                        ? _withDirectives((_openBlock(), _createBlock(_component_translate, { key: 3 }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Strong")
                            ])),
                            _: 1
                          })), [
                            [_directive_tooltip, _ctx.$gettext('Moderate protection from offline slow-hash scenarios')]
                          ])
                        : ((_ctx.valid ? _ctx.score : 0) === 4)
                          ? _withDirectives((_openBlock(), _createBlock(_component_translate, { key: 4 }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("Very strong")
                              ])),
                              _: 1
                            })), [
                              [_directive_tooltip, _ctx.$gettext('Strong protection from offline slow-hash scenarios')]
                            ])
                          : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 2)
      }), 64))
    ]),
    _createVNode(_component_translate, { tag: "strong" }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode("Password requirements")
      ])),
      _: 1
    }),
    _createVNode(_component_translate, null, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [
        _createTextVNode("Your password has to fulfill the following requirements:")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_bp_icon, {
          color: _ctx.requirements.length ? 'green' : 'red',
          icon: _ctx.requirements.length ? 'check' : 'xmark'
        }, null, 8, ["color", "icon"]),
        _createVNode(_component_translate, {
          class: _normalizeClass(['bp-password-validator__requirement', {
          'bp-password-validator__requirement--checked': _ctx.requirements.length,
        }])
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("A minimum length of 8 characters")
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_bp_icon, {
          color: _ctx.requirements.lowercase ? 'green' : 'red',
          icon: _ctx.requirements.lowercase ? 'check' : 'xmark'
        }, null, 8, ["color", "icon"]),
        _createVNode(_component_translate, {
          class: _normalizeClass(['bp-password-validator__requirement', {
          'bp-password-validator__requirement--checked': _ctx.requirements.lowercase,
        }])
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("Contains a lowercase character")
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_bp_icon, {
          color: _ctx.requirements.uppercase ? 'green' : 'red',
          icon: _ctx.requirements.uppercase ? 'check' : 'xmark'
        }, null, 8, ["color", "icon"]),
        _createVNode(_component_translate, {
          class: _normalizeClass(['bp-password-validator__requirement', {
          'bp-password-validator__requirement--checked': _ctx.requirements.uppercase,
        }])
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Contains an uppercase character")
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_bp_icon, {
          color: _ctx.requirements.specialCharacter ? 'green' : 'red',
          icon: _ctx.requirements.specialCharacter ? 'check' : 'xmark'
        }, null, 8, ["color", "icon"]),
        _createVNode(_component_translate, {
          class: _normalizeClass(['bp-password-validator__requirement', {
          'bp-password-validator__requirement--checked': _ctx.requirements.specialCharacter,
        }])
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("Contains a special character")
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_bp_icon, {
          color: _ctx.requirements.number ? 'green' : 'red',
          icon: _ctx.requirements.number ? 'check' : 'xmark'
        }, null, 8, ["color", "icon"]),
        _createVNode(_component_translate, {
          class: _normalizeClass(['bp-password-validator__requirement', {
          'bp-password-validator__requirement--checked': _ctx.requirements.number,
        }])
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("Contains a numeric character")
          ])),
          _: 1
        }, 8, ["class"])
      ])
    ])
  ]))
}