import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, resolveDirective as _resolveDirective, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "bp-select__tag-content" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["placeholder", "tabindex"]
const _hoisted_4 = {
  key: 3,
  class: "bp-select__placeholder"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "bp-select__language-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_popover = _resolveComponent("bp-popover")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_bp_label, _mergeProps(_ctx.labelProps, {
    class: ['bp-select', {
      'bp-select--compact': _ctx.compactMode,
      'bp-select--expanded': _ctx.menu.state,
    }],
    style: _ctx.style
  }), _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_bp_popover, {
        "close-on-click": _ctx.closeOnClick,
        data: _ctx.menuData,
        flip: _ctx.flip,
        highlighted: new Set(Array.from(_ctx.selected)),
        loading: _ctx.loading,
        "model-value": _ctx.menu.state,
        offset: _ctx.offset,
        placement: _ctx.placement,
        "search-property": _ctx.transform !== undefined ? '_transform' : _ctx.searchProperty,
        unwrap: _ctx.unwrap,
        "hide-selected": _ctx.hideSelected,
        "onUpdate:modelValue": _ctx.updateMenuState,
        onClickOption: _ctx.toggleSelected,
        "match-reference-size": ""
      }, _createSlots({
        "option-bp-select-loading": _withCtx(() => [
          _createVNode(_component_bp_loading, { "model-value": true })
        ]),
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['bp-select__wrapper', {
          'bp-select__wrapper--disabled': _ctx.disabled,
          'bp-select__wrapper--readonly': _ctx.readonly,
          'bp-select__wrapper--invalid': _ctx.hasError(),
          'bp-select__wrapper--valid': _ctx.isDirty && _ctx.valid !== undefined && _ctx.valid,
          'bp-select__wrapper--unwrap': _ctx.unwrap,
        }]),
            key: _ctx.unwrap ? _ctx.selected.size + '_' + _ctx.innerWrapperOverflow : ''
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['bp-select__inner-wrapper', {
            'bp-select__inner-wrapper--overflow': _ctx.unwrap && _ctx.innerWrapperOverflow,
          }]),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args))),
              ref: "innerWrapperEl"
            }, [
              (_ctx.multiple && _ctx.unwrap)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Array.from(_ctx.selected), (tagId, tagIndex) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: tagId,
                      class: "bp-select__tag"
                    }, [
                      _renderSlot(_ctx.$slots, _ctx.slotName('tag', tagId), {
                        item: _ctx.getItem(tagId)
                      }, () => [
                        _renderSlot(_ctx.$slots, "tag", {
                          item: _ctx.getItem(tagId)
                        }, () => [
                          _renderSlot(_ctx.$slots, _ctx.slotName('option', tagId), {
                            item: _ctx.getItem(tagId)
                          }, () => [
                            _renderSlot(_ctx.$slots, "option", {
                              item: _ctx.getItem(tagId)
                            }, () => [
                              (tagIndex > 0)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(", ")
                                  ], 64))
                                : _createCommentVNode("", true),
                              _createTextVNode(" " + _toDisplayString(_ctx.removeHTML(_ctx.getName(tagId))), 1)
                            ], true)
                          ], true)
                        ], true)
                      ], true)
                    ]))
                  }), 128))
                : (_ctx.multiple)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Array.from(_ctx.selected), (tagId) => {
                      return (_openBlock(), _createElementBlock("span", {
                        class: _normalizeClass(['bp-select__tag', {
              'bp-select__tag--disabled': _ctx.getItem(tagId)?._disabled,
              'bp-select__tag--only': !_ctx.clearable && _ctx.selected.size === 1,
            }]),
                        key: tagId
                      }, [
                        _createElementVNode("span", _hoisted_1, [
                          _renderSlot(_ctx.$slots, _ctx.slotName('tag', tagId), {
                            item: _ctx.getItem(tagId)
                          }, () => [
                            _renderSlot(_ctx.$slots, "tag", {
                              item: _ctx.getItem(tagId)
                            }, () => [
                              _renderSlot(_ctx.$slots, _ctx.slotName('option', tagId), {
                                item: _ctx.getItem(tagId)
                              }, () => [
                                _renderSlot(_ctx.$slots, "option", {
                                  item: _ctx.getItem(tagId)
                                }, () => [
                                  _createElementVNode("span", {
                                    innerHTML: _ctx.getName(tagId)
                                  }, null, 8, _hoisted_2)
                                ], true)
                              ], true)
                            ], true)
                          ], true)
                        ]),
                        (!_ctx.getItem(tagId)?._disabled)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              class: "bp-select__tag-clear-icon",
                              icon: "xmark",
                              onClick: _withModifiers(($event: any) => (_ctx.removeSelected(tagId)), ["stop"])
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ], 2))
                    }), 128))
                  : _createCommentVNode("", true),
              (_ctx.searchable)
                ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                    class: ['bp-select__input', {
              'bp-select__input--large': _ctx.large,
            }],
                    key: _ctx.unique + '_' + _ctx.language,
                    placeholder: /*unwrap && */_ctx.selected.size > 0 ? undefined : _ctx.placeholder,
                    tabindex: _ctx.disabled ? -1 : undefined,
                    ref: "inputEl"
                  }, _ctx.$attrs, {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openMenu && _ctx.openMenu(...args))),
                    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blurInput && _ctx.blurInput(...args))),
                    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleKeyboardInput && _ctx.handleKeyboardInput(...args)))
                  }), null, 16, _hoisted_3)), [
                    [_vModelDynamic, _ctx.search]
                  ])
                : (_ctx.placeholder && _ctx.selected.size === 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.placeholder), 1))
                  : _createCommentVNode("", true)
            ], 2),
            (_ctx.clearable && _ctx.selected.size > 0)
              ? (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 0,
                  class: _normalizeClass(['bp-select__clear-icon', {
            'bp-select__clear-icon--large': _ctx.large,
            'bp-select__clear-icon--last': !_ctx.data || _ctx.data.length === 0,
          }]),
                  icon: "xmark",
                  onClick: _ctx.clearSelected
                }, null, 8, ["class", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.data && _ctx.data.length > 0)
              ? (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 1,
                  class: _normalizeClass(['bp-select__dropdown-icon', {
            'bp-select__dropdown-icon--large': _ctx.large,
          }]),
                  icon: "chevron-right",
                  onMousedown: _ctx.setMenuNextState,
                  onTouchstart: _ctx.setMenuNextState,
                  onMouseup: _ctx.resetMenuNextState,
                  onTouchend: _ctx.resetMenuNextState,
                  onClick: _ctx.setMenuState
                }, null, 8, ["class", "onMousedown", "onTouchstart", "onMouseup", "onTouchend", "onClick"]))
              : _createCommentVNode("", true)
          ], 2)), [
            [_directive_tooltip, (_ctx.unwrap && _ctx.innerWrapperOverflow) ? {
          offset: [-12, 0],
          text: Array.from(_ctx.selected).map(tag => _ctx.removeHTML(_ctx.getName(tag))).join(',\n')
        } : undefined]
          ])
        ]),
        _: 2
      }, [
        _renderList(_ctx.optionSlots, (slotName) => {
          return {
            name: slotName,
            fn: _withCtx((slotScope) => [
              _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
            ])
          }
        })
      ]), 1032, ["close-on-click", "data", "flip", "highlighted", "loading", "model-value", "offset", "placement", "search-property", "unwrap", "hide-selected", "onUpdate:modelValue", "onClickOption"])
    ]),
    _: 2
  }, [
    (_ctx.$slots.label || _ctx.$slots.default)
      ? {
          name: "label",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], true)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.isTranslated(_ctx.modelValue))
      ? {
          name: "text",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "text", {}, () => [
              _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_5)
            ], true),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableLanguages).sort((a, b) => a < b ? -1 : 1), (lang, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: lang.toLowerCase()
                }, [
                  (index !== 0)
                    ? (_openBlock(), _createBlock(_component_bp_icon, {
                        key: 0,
                        icon: "slash-forward",
                        "fixed-width": false
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_resolveDynamicComponent('bp-input'), {
                    "model-value": _ctx.language,
                    name: 'language-selector-' + _ctx.unique,
                    text: _ctx.getTranslatedName(lang),
                    value: _ctx.getTranslatedKey(lang),
                    "onUpdate:modelValue": _ctx.setLanguage,
                    seamless: "",
                    type: "radio"
                  }, null, 8, ["model-value", "name", "text", "value", "onUpdate:modelValue"]))
                ], 64))
              }), 128))
            ])
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.hasError())
      ? {
          name: "error",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "error", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.error || _ctx.errorMessage), 1)
            ], true)
          ]),
          key: "2"
        }
      : undefined,
    _renderList(_ctx.labelSlots, (slotName) => {
      return {
        name: slotName,
        fn: _withCtx((slotScope) => [
          _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
        ])
      }
    })
  ]), 1040, ["class", "style"]))
}