
import { defineComponent, nextTick, ref, watch } from 'vue';

export default defineComponent({
  name: 'bp-iframe',
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    const iFrameRef = ref<HTMLIFrameElement>();

    watch(() => [iFrameRef.value, props.modelValue], ([iframeEl]) => {
      if (!iframeEl || typeof iframeEl === 'string') return;

      // Get the iframes document element which holds the content of the iframe
      const document = iframeEl.contentDocument || iframeEl.contentWindow?.document;
      if (!document) {
        return;
      }

      // Update the iframe documents content to the value of the template above
      document.documentElement.innerHTML = props.modelValue;

      // Update styles in iframe document for preview
      let elementInHead = false;

      // Find existing stylesheet in document head
      let styleEl;
      for (const element of document.head.children) {
        if (element.getAttribute('id') === 'bp-iframe-style') {
          styleEl = element;
          elementInHead = true;
        }
      }

      // If no stylesheet could be found, create a new one
      if (!styleEl) {
        styleEl = document.createElement('style');
        styleEl.id = 'bp-iframe-style';
      }

      // Set the style of the stylesheet to a reasonable default (using ":where" pseudo-class in order to have a CSS rule with 0 specificity so any rule in the template will overwrite this fallback)
      styleEl.innerHTML = `:where(:root) {
        background: white;
        color: black;
      }`;

      // If the stylesheet is not in the documents head yet, we have to add it
      if (!elementInHead) {
        document.head.appendChild(styleEl);
      }

      // Update the iframes height to match the inner documents scroll height
      iframeEl.style.height = '1px';
      nextTick(() => iframeEl.style.height = `${document.documentElement.scrollHeight}px`);
    }, { immediate: true, deep: true });

    return {
      iFrameRef
    }
  }
});
