
import { isAbsoluteUrl } from '@/utils/string';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText, FontAwesomeLayersTextProps } from '@fortawesome/vue-fontawesome';
import { computed, CSSProperties, defineComponent, onActivated, onUnmounted, PropType, ref, watch } from 'vue';
import { customIcons, Icon, IconObject, IconProp } from './BpIcon';
import { cssColor, ColorProp, textColor } from '@/utils/color';
import { RequiredKeys } from '@/utils/object';
import { Transform } from '@fortawesome/fontawesome-svg-core';
import useDarkMode from '@/compositions/use-dark-mode';

export default defineComponent({
  name: 'bp-icon',
  components: {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
  },
  props: {
    ///-------------------------------------------------------------------
    /// REQUIRED PROPS
    ///-------------------------------------------------------------------
    icon: {
      type: IconProp,
      required: true,
    },
    ///-------------------------------------------------------------------
    /// OPTIONS PROPS
    ///-------------------------------------------------------------------
    disabled: Boolean,
    ///-------------------------------------------------------------------
    /// OPTIONAL ICON PROPS
    ///-------------------------------------------------------------------
    color: ColorProp,
    textStyle: {
      type: Object as PropType<CSSProperties>,
      default: () => ({}),
    },
    ///-------------------------------------------------------------------
    /// DUOTONE ICON PROPS
    ///-------------------------------------------------------------------
    primaryColor: ColorProp,
    primaryOpacity: Number,
    secondaryColor: ColorProp,
    secondaryOpacity: Number,
    ///-------------------------------------------------------------------
    /// FONT AWESOME ICON PROPS
    ///-------------------------------------------------------------------
    border: Boolean,
    fixedWidth: {
      type: Boolean,
      default: true,
    },
    flip: [Boolean, String] as PropType<IconObject['flip']>,
    inverse: Boolean,
    listItem: Boolean,
    mask: IconProp,
    pull: String as PropType<IconObject['pull']>,
    pulse: Boolean,
    rotation: [String, Number] as PropType<IconObject['rotation']>,
    spin: Boolean,
    size: String as PropType<IconObject['size']>,
    swapOpacity: Boolean,
    symbol: Boolean,
    title: String,
    transform: [Object, String],
    ///-------------------------------------------------------------------
    /// FONT AWESOME LAYERS TEXT PROPS
    ///-------------------------------------------------------------------
    counter: Boolean,
    position: String as PropType<FontAwesomeLayersTextProps['position']>,
    value: [String, Number],
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ICON TYPE
    ///-------------------------------------------------------------------

    function isComplexIcon(icon: Icon): icon is IconObject {
      return typeOf(icon) === 'complex-icon';
    }

    function isImageIcon(icon: Icon): icon is string {
      return typeOf(icon) === 'image';
    }

    function isLayers(icon: Icon): icon is IconObject[] {
      return typeOf(icon) === 'layer'
    }

    function isTextLayer(layer: IconObject): layer is RequiredKeys<IconObject, 'counter' | 'value' | 'position' | 'transform'> {
      return 'counter' in layer || 'value' in layer;
    }

    const type = computed(() => typeOf(props.icon));

    function typeOf(icon: Icon) {
      if (!icon) {
        return 'none';
      }
      if (Array.isArray(icon) && typeof icon[0] === 'object') {
        return 'layer';
      }
      if (typeof icon === 'string' && (icon.startsWith('/') || isAbsoluteUrl(icon))) {
        return 'image';
      }
      if (!Array.isArray(icon) && typeof icon === 'object') {
        if (icon.value) {
          return 'text';
        }
        return 'complex-icon';
      }
      return 'icon';
    }

    function baseStyleOf(icon: Icon): CSSProperties {
      if (typeOf(icon) !== 'layer') {
        return {};
      }
      const layer = (icon as IconObject[]).find(layer => !('icon' in layer) && 'style' in layer);
      if (!layer || !('style' in layer)) {
        return {};
      }
      return layer.style ?? {};
    }

    function variablesOf(icon: Icon, opts: CSSProperties = {}) {
      if (!isComplexIcon(icon)) {
        icon = props;
      }
      let transform: Required<Transform> = { flipX: false, flipY: false, rotate: 0, size: 1, x: 0, y: 0 };
      if (isComplexIcon(icon) && icon.transformMask) {
        transform = [...icon.transformMask.matchAll(/(?:(shrink|grow|up|right|down|left|rotate|flip)-(-?\d+\.?\d*|v|h)\s?)/g)].reduce(
          (acc, [, key, value]) => {
            switch (key) {
              case 'shrink':
              case 'grow':
                return {
                  ...acc,
                  size: transform.size + parseFloat(value) / (key === 'shrink' ? -16 : 16),
                };
              case 'up':
              case 'down':
                return {
                  ...acc,
                  y: transform.y + parseFloat(value) / (key === 'up' ? -16 : 16),
                };
              case 'right':
              case 'left':
                return {
                  ...acc,
                  x: transform.x + parseFloat(value) / (key === 'left' ? -16 : 16),
                };
              case 'rotate':
                return {
                  ...acc,
                  rotate: transform.rotate + parseFloat(value),
                };
              case 'flip':
                return {
                  ...acc,
                  flipX: value === 'h' ? !transform.flipX : transform.flipX,
                  flipY: value === 'v' ? !transform.flipY : transform.flipY,
                };
            }
            return acc;
          },
          transform
        );
      }
      return {
        '--icon-color': !props.disabled ? (icon.color !== undefined ? cssColor(icon.color) : undefined) : 'var(--theme-text-disabled)',
        '--fa-primary-color': !props.disabled ? (icon.primaryColor !== undefined ? cssColor(icon.primaryColor) : undefined) : 'var(--theme-text-disabled)',
        '--fa-primary-opacity': icon.primaryOpacity !== undefined ? icon.primaryOpacity.toString() : undefined,
        '--fa-secondary-color': !props.disabled ? (icon.secondaryColor !== undefined ? cssColor(icon.secondaryColor) : undefined) : 'var(--theme-text-disabled)',
        '--fa-secondary-opacity': icon.secondaryOpacity !== undefined ? icon.secondaryOpacity.toString() : undefined,
        translate: transform.x !== 0 || transform.y !== 0 ? `${transform.x}em ${transform.y}em` : undefined,
        rotate: transform.rotate !== 0 ? `${transform.rotate}deg` : undefined,
        scale:
          transform.size !== 1 || transform.flipX || transform.flipY
            ? `${transform.flipX ? -1 * transform.size : transform.size} ${transform.flipY ? -1 * transform.size : transform.size}`
            : undefined,
        transform:
          transform.x !== 0 || transform.y !== 0 || transform.rotate !== 0 || transform.size !== 1 || transform.flipX || transform.flipY
            ? `translateX(calc(-1 * ${(transform.size - 1) * 2}px / ${transform.size})) translateY(${0.5 / transform.size}em)`
            : undefined,
        ...opts,
      } as CSSProperties;
    }

    ///-------------------------------------------------------------------
    /// CUSTOM ICON
    ///-------------------------------------------------------------------
   
    const { current: darkMode } = useDarkMode();

    const imgRef = ref<HTMLImageElement>();
    const isDarkBackground = ref(false);
    const observer = ref<MutationObserver>();

    function checkDarkBackground(target: HTMLElement) {
        const backgroundColor = window.getComputedStyle(target).getPropertyValue('background-color');
        isDarkBackground.value = darkMode?.value || ((!backgroundColor.startsWith('rgba(') || !backgroundColor.endsWith('0)')) && textColor(backgroundColor) === '#FFFFFF')
      }

    watch(() => [imgRef.value, darkMode?.value], ([imgEl, dark]) => {
      if (dark) {
        isDarkBackground.value = true;
        return;
      }
      if (!imgEl || typeof imgEl === 'boolean' || !imgEl.parentElement) {
        return;
      }

      const target = imgEl.parentElement;
      const options = { attributes: true, childList: false, subtree: false };

      observer.value = new MutationObserver(() => checkDarkBackground(target));
      observer.value.observe(target, options);
      checkDarkBackground(target);
    }, { immediate: true })

    onActivated(() => {
      const target = imgRef.value?.parentElement;
      if (!target) return;
      checkDarkBackground(target);
    });
    
    onUnmounted(() => observer.value?.disconnect());

    return {
      baseStyleOf,
      customIcons,
      type,
      typeOf,
      imgRef,
      isDarkBackground,
      isComplexIcon,
      isImageIcon,
      isLayers,
      isTextLayer,
      variablesOf,
    };
  }
})
