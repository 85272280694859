import { currentLanguage } from '@/translation';

/**
 * Converts a given date string (e.g. from the database as `2021-08-17`) or `Date` to a localized format:
 *
 * - English: `08/17/2021`
 * - German: `17.08.2021`
 *
 * @param date The date.
 * @returns The localized date.
 */
export function localizeDate(date: string | Date): string {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNaN(date as any)) {
    return '';
  }
  return date.toLocaleString(currentLanguage.value.substring(0, 2), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

/**
 * Converts a given time string (e.g. from the database as `2022-07-01T12:00:00Z`) or `Date` to a localized format:
 *
 * - English: `12:00:00`
 * - German: `12:00:00`
 *
 * @param time The time.
 * @returns The localized time.
 */
export function localizeTime(time: string | Date, seconds = false): string {
  if (!(time instanceof Date)) {
    time = new Date(time);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNaN(time as any)) {
    return '';
  }
  return time.toLocaleString(currentLanguage.value.substring(0, 2), {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: seconds ? '2-digit' : undefined,
  });
}

/**
 * Converts a given datetime string (e.g. from the database as `2022-07-01T12:00:00Z`) or `Date` to a localized format:
 *
 * - English: `07/01/2022, 12:00:00`
 * - German: `01.07.2022, 12:00:00`
 *
 * @param datetime The datetime.
 * @returns The localized datetime.
 */
export function localizeDatetime(datetime: string | Date, seconds = false): string {
  if (!(datetime instanceof Date)) {
    datetime = new Date(datetime);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isNaN(datetime as any)) {
    return '';
  }
  return datetime.toLocaleString(currentLanguage.value.substring(0, 2), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: seconds ? '2-digit' : undefined,
  });
}