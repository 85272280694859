import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-button__icon"
}
const _hoisted_2 = {
  key: 1,
  class: "bp-button__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
    class: _normalizeClass(['bp-button', 'bp-button--icon-position-' + _ctx.iconPosition, {
      'bp-button--active': _ctx.active,
      'bp-button--compact': _ctx.compactMode,
      'bp-button--disabled': _ctx.disabled,
      'bp-button--large': _ctx.large,
    }]),
    tabindex: _ctx.disabled ? -1 : undefined,
    style: _normalizeStyle(_ctx.style),
    target: _ctx.isExternalLink() ? '_blank' : _ctx.$attrs['target'],
    to: _ctx.to,
    onClick: _ctx.onClick,
    type: _ctx.type
  }, {
    default: _withCtx(() => [
      (_ctx.$slots.icon || _ctx.iconVisible(_ctx.$attrs['download'], _ctx.icon))
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "icon", {}, () => [
              (_ctx.iconVisible(_ctx.$attrs['download'], _ctx.icon))
                ? (_openBlock(), _createBlock(_component_bp_icon, {
                    key: 0,
                    icon: _ctx.$attrs['download'] !== undefined ? 'download' : _ctx.icon,
                    disabled: _ctx.disabled
                  }, null, 8, ["icon", "disabled"]))
                : _createCommentVNode("", true)
            ], true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
            (_ctx.isExternalLink() && _ctx.$attrs['download'] === undefined)
              ? (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 0,
                  class: "bp-button__external-link-icon",
                  icon: {
        icon: ['far', 'arrow-up-right-from-square'],
        transform: 'shrink-2',
      },
                  disabled: _ctx.disabled
                }, null, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "tabindex", "style", "target", "to", "onClick", "type"]))
}