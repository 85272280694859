import { getActivePinia, Pinia } from 'pinia';

export function reloadStores(forceReload = false) {
  const activePinia = getActivePinia() as Pinia & { _s: Map<string, { $reset: () => void }>};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activePinia._s.forEach((store: Record<string, any>) => {
    if (store.loaded && store.reload !== undefined && typeof store.reload === 'function') {
      if (forceReload || store.forceReload) {
        store.reload();
      }
    } else {
      store.$reset();
    }
  });
}

export function clearStores() {
  const activePinia = getActivePinia() as Pinia & { _s: Map<string, { $reset: () => void }>};
  activePinia._s.forEach(store => store.$reset());
}