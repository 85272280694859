
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, CSSProperties, defineComponent, PropType, ref, watch } from 'vue';
import { IconProp } from './icon/BpIcon';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-label',
  props: {
    characterLimit: String,
    disabled: Boolean,
    error: String,
    errorIcon: {
      type: IconProp,
      default: 'triangle-exclamation',
    },
    innerGap: {
      type: [String, Number] as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl' | number>,
      default: 'md',
    },
    label: String,
    labelPosition: {
      type: String as PropType<'left' | 'top' | 'right' | 'bottom' | 'inline'>,
      default: 'left'
    },
    labelWidth: {
      type: String,
      default: '320px',
    },
    required: Boolean,
    seamless: Boolean,
    text: String,
  },
  emits: [],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// LABEL
    ///-------------------------------------------------------------------

    const hasCharacterLimit = () => props.characterLimit || ctx.slots.characterLimit;
    const hasError = () => props.error || ctx.slots.error;
    const hasLabel = () => props.label || ctx.slots.label;
    const hasText = () => props.text || ctx.slots.text;

    const labelEl = ref<HTMLDivElement>();
    const indent = ref(0);

    function calculateIndent() {
      if (!labelEl.value) {
        return;
      }

      // Count cards & fieldset
      let el: HTMLElement | null = labelEl.value;
      let fieldsetCount = 0;
      let cardCount = 0;
      while (el !== null) {
        if (el.classList.contains('bp-fieldset')) { fieldsetCount++; }
        if (el.classList.contains('bp-card')) { cardCount++; }
        el = el.parentElement;
      }

      // Get style
      const computedStyle = window.getComputedStyle(labelEl.value);
      const paddingFloat = parseFloat(computedStyle.getPropertyValue('--padding'));
      const paddingValue = (isNaN(paddingFloat) ? 1 : paddingFloat) * 16;
      const padding = compactMode?.value ? paddingValue / 2 : paddingValue;
      const borderWidthFloat = parseFloat(computedStyle.getPropertyValue('--border-width'));
      const borderWidth = (isNaN(borderWidthFloat) ? 0.25 : borderWidthFloat) * 16;

      // Calculate indent
      indent.value = (fieldsetCount + (cardCount - 1)) * padding + fieldsetCount * (borderWidth / 2) + (cardCount - 1) * borderWidth;
    }
    watch(() => [labelEl.value, compactMode?.value], calculateIndent, { immediate: true });

    const variables = computed<CSSProperties>(() => {
      let gapSuffix;
      switch (props.innerGap) {
        case 'sm': { gapSuffix = compactMode?.value ? 'xs' : 'sm'; break; }
        case 'md': { gapSuffix = compactMode?.value ? 'sm' : 'md'; break; }
        case 'lg': { gapSuffix = compactMode?.value ? 'md' : 'lg'; break; }
        case 'xl': { gapSuffix = compactMode?.value ? 'lg' : 'xl'; break; }
        default: { gapSuffix = props.innerGap }
      }
      return {
        '--label-inner-gap': typeof props.innerGap === 'string'
          ? `var(--padding${gapSuffix !== 'md'? '-' + gapSuffix : ''})`
          : `${compactMode?.value ? props.innerGap / 2 : props.innerGap}rem`,
        '--label-width': useMedia(Breakpoint.MAX_SM).value
          ? `calc(${props.labelWidth} / 2 - ${indent.value}px)`
          : `calc(${props.labelWidth} - ${indent.value}px)`
      }
    });

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      hasCharacterLimit,
      hasText,
      hasError,
      hasLabel,
      labelEl,
      variables,
    }
  }
});
