
import { computed, CSSProperties, defineComponent, PropType } from 'vue';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-spacer',
  props: {
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
    },
  },
  setup(props) {
    const { current: compactMode } = useCompactMode();

    const style = computed<CSSProperties>(() => {
      let suffix;
      switch (props.size) {
        case 'sm': { suffix = compactMode?.value ? 'xs' : 'sm'; break; }
        case 'md': { suffix = compactMode?.value ? 'sm' : 'md'; break; }
        case 'lg': { suffix = compactMode?.value ? 'md' : 'lg'; break; }
        case 'xl': { suffix = compactMode?.value ? 'lg' : 'xl'; break; }
        default: { suffix = props.size }
      }
      return {
        flexBasis: `var(--padding${suffix !== 'md' ? '-' + suffix : ''})`,
        flexGrow: 0,
        flexShrink: 0,
      }
    });

    return {
      style,
    }
  }
})
