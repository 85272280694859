import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, normalizeStyle as _normalizeStyle, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-input__before"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "bp-input__prefix"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["value", "onInput", "onKeydown", "disabled"]
const _hoisted_6 = { class: "bp-input__datepicker-date-time-toggle" }
const _hoisted_7 = { class: "bp-input__datepicker-date-time-toggle" }
const _hoisted_8 = {
  key: 2,
  class: "bp-input__suffix"
}
const _hoisted_9 = {
  key: 0,
  class: "bp-input__text"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "bp-input__text"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "bp-input__text"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["for"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 3,
  class: "bp-input__after"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "bp-input__description" }
const _hoisted_21 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_label = _resolveComponent("bp-label")!

  return (_openBlock(), _createBlock(_component_bp_label, _mergeProps({
    class: ['bp-input', 'bp-input--type-' + _ctx.type, {
      'bp-input--clearable': _ctx.clearable,
      'bp-input--compact': _ctx.compactMode,
      'bp-input--flush': _ctx.flush,
      'bp-input--seamless': _ctx.seamless,
    }],
    style: _ctx.style
  }, _ctx.labelProps, {
    text: ['checkbox', 'radio', 'toggle'].includes(_ctx.type) ? undefined : _ctx.text
  }), _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['bp-input__wrapper', {
        'bp-input__wrapper--chunks': _ctx.chunks,
        'bp-input__wrapper--disabled': _ctx.disabled,
        'bp-input__wrapper--readonly': _ctx.readonly,
        'bp-input__wrapper--invalid': _ctx.hasError(),
        'bp-input__wrapper--valid': _ctx.isDirty && _ctx.valid !== undefined && _ctx.valid,
        'bp-input__wrapper--has-before': _ctx.hasBefore(),
        'bp-input__wrapper--has-after': _ctx.hasAfter(),
        'bp-input__wrapper--has-prefix': _ctx.hasPrefix(),
        'bp-input__wrapper--has-suffix': _ctx.hasSuffix(),
      }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (['checkbox', 'toggle'].includes(_ctx.type) ? _ctx.toggle() : _ctx.select()), ["stop"]))
      }, [
        (_ctx.hasBefore())
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "before", {}, () => [
                _createElementVNode("span", { innerHTML: _ctx.before }, null, 8, _hoisted_2)
              ], true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasPrefix())
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "prefix", {}, () => [
                _createElementVNode("span", { innerHTML: _ctx.prefix }, null, 8, _hoisted_4)
              ], true)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.input.component), _mergeProps({
          class: ['bp-input__input', { 'bp-input__input--large': _ctx.large }],
          key: _ctx.unique + '_' + _ctx.language,
          style: _ctx.type === 'textarea' ? { resize: _ctx.resizable ? 'both' : 'none' } : undefined,
          tabindex: (_ctx.readonly || _ctx.disabled) ? -1 : undefined,
          onBeforeinput: _ctx.patternBeforeinput,
          onBlur: _ctx.checkValidity,
          onInvalid: _ctx.updateError,
          onPaste: _ctx.patternPaste,
          ref: "inputEl"
        }, _ctx.input.props, {
          type: _ctx.showPassword ? 'text' : _ctx.type
        }), {
          "dp-input": _withCtx(({ value, onInput, onEnter, onTab }) => [
            _createElementVNode("input", {
              type: "text",
              ref: "datePickerEl",
              value: value,
              onInput: onInput,
              onKeydown: [
                _withKeys(onEnter, ["enter"]),
                _withKeys(onTab, ["tab"])
              ],
              disabled: _ctx.disabled
            }, null, 40, _hoisted_5)
          ]),
          "clear-icon": _withCtx(({ clear }) => [
            (_ctx.modelValue)
              ? (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 0,
                  class: "bp-input__datepicker-icon",
                  icon: "xmark",
                  onClick: _withModifiers(clear, ["stop"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          "clock-icon": _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_bp_icon, { icon: ['far', 'clock'] }),
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Adjust time")
                ])),
                _: 1
              })
            ])
          ]),
          "calendar-icon": _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_bp_icon, { icon: ['far', 'calendar'] }),
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Adjust date")
                ])),
                _: 1
              })
            ])
          ]),
          "arrow-up": _withCtx(() => [
            _createVNode(_component_bp_icon, {
              class: "bp-input__datepicker-menu-icon",
              icon: "chevron-up"
            })
          ]),
          "arrow-right": _withCtx(() => [
            _createVNode(_component_bp_icon, {
              class: "bp-input__datepicker-menu-icon",
              icon: "chevron-right"
            })
          ]),
          "arrow-down": _withCtx(() => [
            _createVNode(_component_bp_icon, {
              class: "bp-input__datepicker-menu-icon",
              icon: "chevron-down"
            })
          ]),
          "arrow-left": _withCtx(() => [
            _createVNode(_component_bp_icon, {
              class: "bp-input__datepicker-menu-icon",
              icon: "chevron-left"
            })
          ]),
          _: 1
        }, 16, ["class", "style", "tabindex", "onBeforeinput", "onBlur", "onInvalid", "onPaste", "type"])),
        (_ctx.hasSuffix())
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "suffix", {}, () => [
                (['date', 'datetime', 'datetime-local', 'month', 'time', 'week', 'checkbox', 'toggle', 'radio', 'file', 'password'].includes(_ctx.type))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (['date', 'datetime', 'datetime-local', 'month', 'time', 'week'].includes(_ctx.type))
                        ? (_openBlock(), _createBlock(_component_bp_icon, {
                            key: 0,
                            class: "bp-input__datepicker-icon",
                            icon: ['far', 'calendar-pen'],
                            onClick: _withModifiers(_ctx.openDatepicker, ["stop"])
                          }, null, 8, ["onClick"]))
                        : (_ctx.type === 'checkbox')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createVNode(_component_bp_icon, {
                                class: "bp-input__checkbox",
                                icon: _ctx.icon('checkbox')
                              }, null, 8, ["icon"]),
                              (_ctx.text || _ctx.$slots.text)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                    _renderSlot(_ctx.$slots, "text", {}, () => [
                                      _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_10)
                                    ], true)
                                  ]))
                                : _createCommentVNode("", true)
                            ], 64))
                          : (_ctx.type === 'toggle')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(['bp-input__toggle', { 'bp-input__toggle--active': _ctx.modelValue, 'bp-input__toggle--disabled': _ctx.disabled }])
                                }, null, 2),
                                (_ctx.text || _ctx.$slots.text)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                      _renderSlot(_ctx.$slots, "text", {}, () => [
                                        _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_12)
                                      ], true)
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : (_ctx.type === 'radio')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                  _createVNode(_component_bp_icon, {
                                    class: "bp-input__radio",
                                    icon: _ctx.icon('radio')
                                  }, null, 8, ["icon"]),
                                  (_ctx.text || _ctx.$slots.text)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                        _renderSlot(_ctx.$slots, "text", {}, () => [
                                          _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_14)
                                        ], true)
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_ctx.type === 'file')
                                ? (_openBlock(), _createElementBlock("label", {
                                    key: 4,
                                    for: 'file-' + _ctx.unique,
                                    class: "bp-input__file"
                                  }, [
                                    (_ctx.modelValue && typeof _ctx.modelValue === 'string')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.modelValue.split('\\').pop()), 1))
                                      : (_openBlock(), _createBlock(_component_translate, {
                                          key: 1,
                                          tag: "em"
                                        }, {
                                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                                            _createTextVNode("Nothing selected")
                                          ])),
                                          _: 1
                                        })),
                                    _createVNode(_component_bp_icon, { icon: "upload" })
                                  ], 8, _hoisted_15))
                                : (_ctx.type === 'password')
                                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                                      key: 5,
                                      class: "bp-input__suffix-icon",
                                      icon: "eye",
                                      onMousedown: _ctx.password,
                                      onMouseup: _ctx.password,
                                      onTouchstart: _ctx.password,
                                      onTouchend: _ctx.password
                                    }, null, 8, ["onMousedown", "onMouseup", "onTouchstart", "onTouchend"]))
                                  : _createCommentVNode("", true)
                    ], 64))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      innerHTML: _ctx.suffix
                    }, null, 8, _hoisted_17))
              ], true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasAfter())
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _renderSlot(_ctx.$slots, "after", {}, () => [
                _createElementVNode("span", { innerHTML: _ctx.after }, null, 8, _hoisted_19)
              ], true)
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 2
  }, [
    (_ctx.$slots.label || _ctx.$slots.default)
      ? {
          name: "label",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], true)
          ]),
          key: "0"
        }
      : undefined,
    ((_ctx.maxLength > 0 || _ctx.desiredLength > 0) && typeof (_ctx.isTranslated(_ctx.modelValue) ? _ctx.modelValue[_ctx.language] : _ctx.modelValue) === 'string')
      ? {
          name: "characterLimit",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.isTranslated(_ctx.modelValue) ? (_ctx.modelValue[_ctx.language]?.length || 0) : (typeof _ctx.modelValue === 'string' ? _ctx.modelValue.length : 0)], (length) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: length }, [
                ((_ctx.maxLength > 0 || _ctx.desiredLength > 0))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "flex gap-sm",
                      style: _normalizeStyle({
          ..._ctx.maxLength > 0 ? (
            length === _ctx.maxLength ? { color: 'var(--color-red-600)'} :
            length / _ctx.maxLength >= 0.9 || length + 1 === _ctx.maxLength ? { color: 'var(--color-yellow-600)' } :
            {}
          ) : {},
          ..._ctx.desiredLength > 0 ? (
            length === _ctx.desiredLength ? { color: 'var(--color-green-600)' } :
            {}
          ) : {},
          'float': 'right',
          'justify-content': 'flex-end',
        })
                    }, [
                      (_ctx.maxLength > 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (length === _ctx.maxLength)
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 0,
                                  icon: "circle-xmark"
                                }))
                              : (length / _ctx.maxLength >= 0.9 || length + 1 === _ctx.maxLength)
                                ? (_openBlock(), _createBlock(_component_bp_icon, {
                                    key: 1,
                                    icon: "triangle-exclamation"
                                  }))
                                : _createCommentVNode("", true)
                          ], 64))
                        : (_ctx.desiredLength > 0 && length === _ctx.desiredLength)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 1,
                              icon: "check"
                            }))
                          : _createCommentVNode("", true),
                      (_openBlock(), _createBlock(_resolveDynamicComponent((_ctx.maxLength > 0 && (length === _ctx.maxLength || length / _ctx.maxLength >= 0.9 || length + 1 === _ctx.maxLength)) || (_ctx.desiredLength > 0 && length === _ctx.desiredLength) ? 'strong' : 'span'), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(length) + "/" + _toDisplayString(_ctx.desiredLength || _ctx.maxLength), 1)
                        ]),
                        _: 2
                      }, 1024))
                    ], 4))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.isTranslated(_ctx.modelValue))
      ? {
          name: "text",
          fn: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_20, [
                (_ctx.text || _ctx.$slots.text)
                  ? _renderSlot(_ctx.$slots, "text", { key: 0 }, () => [
                      _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_21)
                    ], true)
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isTranslated(_ctx.modelValue))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['bp-input__language-selector', { 'mt-sm': _ctx.text || _ctx.$slots.text }])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableLanguages).sort((a, b) => a < b ? -1 : 1), (lang, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: lang.toLowerCase()
                      }, [
                        (index !== 0)
                          ? (_openBlock(), _createBlock(_component_bp_icon, {
                              key: 0,
                              icon: "slash-forward",
                              "fixed-width": false
                            }))
                          : _createCommentVNode("", true),
                        (_openBlock(), _createBlock(_resolveDynamicComponent('bp-input'), {
                          "model-value": _ctx.language,
                          name: 'language-selector-' + _ctx.unique,
                          text: _ctx.getTranslatedName(lang),
                          value: _ctx.getTranslatedKey(lang),
                          "onUpdate:modelValue": _ctx.setLanguage,
                          seamless: "",
                          type: "radio"
                        }, null, 8, ["model-value", "name", "text", "value", "onUpdate:modelValue"]))
                      ], 64))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ]),
          key: "2"
        }
      : undefined,
    (_ctx.hasError())
      ? {
          name: "error",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "error", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.error || _ctx.errorMessage), 1)
            ], true)
          ]),
          key: "3"
        }
      : undefined,
    _renderList(_ctx.labelSlots, (slotName) => {
      return {
        name: slotName,
        fn: _withCtx((slotScope) => [
          _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
        ])
      }
    })
  ]), 1040, ["class", "style", "text"]))
}