import { PropType } from 'vue';

export type Color = 'red' | 'orange' | 'amber' | 'yellow' | 'lime' | 'green' | 'emerald' | 'teal' | 'cyan' | 'light-blue' | 'blue'
| 'indigo' | 'violet' | 'purple' | 'fuchsia' | 'pink' | 'rose' | 'warm-gray' | 'true-gray' | 'gray' | 'cool-gray' | 'blue-gray';

export type Shade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export enum Modifier {
  NONE = '',
  RGB = 'rgb',
  TEXT = 'text',
  TEXT_SECONDARY = 'text-secondary',
}
export type ColorAlias = Color | [Color, Shade] | [Color, Modifier] | [Color, Shade, Modifier] | `var(--${string})` | 'currentColor';

export const ColorProp = [String, Array] as PropType<ColorAlias>;

/**
 * Generates a CSS color variable name for a given color, shade and/or modifier.
 * @param color The color as a `Color` or a `[Color, Shade, Modifier]` tuple.
 *
 * **Possible Values**
 * | | | | | |
 * |-|-|-|-|-|
 * | `'red'`, | `'orange'`, | `'amber'`, | `'yellow'`, | `'lime'`, |
 * | `'green'`, | `'emerald'`, | `'teal'`, | `'cyan'`, | `'light-blue'`, |
 * | `'blue'`, | `'indigo'`, | `'violet'`, | `'purple'`, | `'fuchsia'`, |
 * | `'pink'`, | `'rose'`, | `'warm-gray'`, | `'true-gray'`, | `'gray'`, |
 * | `'cool-gray'`, | `'blue-gray'` |
 *
 * @param shade *(optional)* The shade of the color. Defaults to `600`.
 *
 * **Possible Values**
 *
 * `50`, `100`, `200`, `300`, `400`, `500`, `600`, `700`, `800`, `900`
 *
 * @param modifier *(optional)* The modifier of the color. Defaults to `Modifier.NONE`.
 *
 * **Possible Values**
 *
 * `Modifier.NONE`, `Modifier.RGB`, `Modifier.TEXT`, `Modifier.TEXT_SECONDARY`
 *
 * @returns The CSS color variable name.
 * @example cssColor('lime'); // var(--color-lime-600)
 * cssColor('cyan', 700); // var(--color-cyan-700)
 * cssColor('violet', Modifier.TEXT); // var(--color-violet-600-text)
 * cssColor('rose', 300, Modifier.TEXT_SECONDARY) // var(--color-rose-300-text-secondary)
 * cssColor(['cool-gray', 200, Modifier.RGB]) // var(--color-cool-gray-200-rgb)
 */
export function cssColor(color?: ColorAlias): string | undefined;
export function cssColor(color?: Color | ColorAlias): string | undefined;
export function cssColor(color?: Color | ColorAlias, shade?: Shade): string | undefined;
export function cssColor(color?: Color | ColorAlias, modifier?: Modifier): string | undefined;
export function cssColor(color?: Color | ColorAlias, shade?: Shade, modifier?: Modifier): string | undefined;
export function cssColor(color?: Color | ColorAlias, modifier?: Modifier, shade?: Shade): string | undefined;
export function cssColor(color?: Color | ColorAlias, shade?: Shade | Modifier, modifier?: Shade | Modifier): string | undefined {
  // If color is not defined, just ignore this call
  if (!color) {
    return undefined;
  }

  // If the color is already a valid CSS color (hex)
  if (typeof color === 'string' && /^(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)$/i.test(color)) {
    return color;
  }

  // If the color is already a valid CSS variable ...
  if (typeof color === 'string' && color.startsWith('var(--')) {
    // ... which is not a color variable from our color palette OR we do not want to adjust the shade or the modifier, just use it as it is
    if (!color.startsWith('var(--color-') || (!shade && !modifier)) {
      return color;
    }
    // ... otherwise, if we want to adjust the shade or the modifier, extract the color, shade and modifier used in the CSS variable and continue
    else {
      const matched = color.match(/var\(--color-(\D+)-(\d+)-?(\D+)?\)/);
      if (matched && matched.length === 4) {
        const matchedColor = matched[1] as Color;
        color = matchedColor;
        const matchedShade = parseFloat(matched[2]) as Shade;
        shade = shade || matchedShade;
        const matchedModifier = matched[3] as Modifier;
        modifier = modifier || matchedModifier;
      }
    }
  }

  // Extract the actual color, shade & modifier given
  const actualColor = extractColor(color);
  const actualShade = extractShade(color, shade, modifier) || 600;
  const actualModifier = extractModifier(color, shade, modifier) || Modifier.NONE;

  // Return the CSS variable based on the actual color, shade & modifier given
  return `var(--color-${actualColor}-${actualShade}${actualModifier ? '-' + actualModifier : ''})`;
}

///-------------------------------------------------------------------
/// COLOR
///-------------------------------------------------------------------

export function extractColor(color: Color | ColorAlias): Color {
  const fallbackColor: Color = 'light-blue';
  if (typeof color === 'string' && color.startsWith('var(--')) {
    if (color.startsWith('var(--color-')) {
      const matched = color.match(/var\(--color-(\D+)-(\d+).*\)/);
      if (matched && matched.length > 1) {
        return matched[1] as Color;
      }
    } else{
      return fallbackColor;
    }
  }
  return Array.isArray(color)
    ? color.length > 0 ? color[0] : fallbackColor
    : color as Color;
}

export const availableColors: Color[] = [
  // COLOURS
  'red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'light-blue', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose',
  // SHADES OF GRAY
  'warm-gray', 'true-gray', 'gray', 'cool-gray', 'blue-gray'
]

///-------------------------------------------------------------------
/// SHADE
///-------------------------------------------------------------------

export function extractShade(color?: Color | ColorAlias, shade?: Shade | Modifier, modifier?: Shade | Modifier): Shade | undefined {
  if (shade && typeof shade === 'number') {
    return shade;
  } else if (modifier && typeof modifier === 'number') {
    return modifier;
  } else if (Array.isArray(color)) {
    if (color.length === 2 && color[1] && typeof color[1] === 'number') {
      return color[1];
    } else if (color.length === 3 && color[2] && typeof color[2] === 'number') {
      return color[2];
    }
  }
}

export function incrementShade(shade: Shade, by: number) {
  return Math.min(Math.round((shade + by) / 100) * 100, 900) as Shade
}

///-------------------------------------------------------------------
/// MODIFIER
///-------------------------------------------------------------------

export function extractModifier(color?: Color | ColorAlias, shade?: Shade | Modifier, modifier?: Shade | Modifier): Modifier | undefined {
  if (shade && typeof shade === 'string') {
    return shade
  } else if (modifier && typeof modifier === 'string') {
    return modifier
  } else if (Array.isArray(color) && color[2] && typeof color[2] === 'string') {
    return color[2];
  } else if (Array.isArray(color) && color[1] && typeof color[1] === 'string') {
    return color[1];
  }
}

///-------------------------------------------------------------------
/// TEXT COLOR
///-------------------------------------------------------------------

function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = new RegExp(/^#?([a-f\d])([a-f\d])([a-f\d])$/, 'i');
  hex = hex.replace(shorthandRegex, (match, r, g, b) => r + r + g + g + b + b);
  const regex = new RegExp(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/, 'i');
  const match = hex.match(regex);
  return match
    ? { r: parseInt(match[1], 16), g: parseInt(match[2], 16), b: parseInt(match[3], 16) }
    : { r: 0, g: 0, b: 0 };
}

/**
 * Returns a hex color that has the most contrast against the given background color.
 * @param color The background color (hex).
 * @returns The text color, either black or white.
 */
export function textColor(color: string) {
  let r = 0, g = 0, b = 0;
  if (color.startsWith('#')) {
    ({r, g, b} = hexToRgb(color));
  } else if (color.startsWith('rgb')) {
    const match = color.match(/rgba?\((\d+)[,\s]*(\d+)[,\s]*(\d+)[,\s]*/);
    if (match && match.length >= 4) {
      const [, rString, gString, bString] = match;
      r = parseInt(rString);
      g = parseInt(gString);
      b = parseInt(bString);
    }
  }
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#000000' : '#FFFFFF';
}