import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-window__backdrop"
}
const _hoisted_2 = { class: "bp-window__header-wrapper" }
const _hoisted_3 = { class: "bp-window__header" }
const _hoisted_4 = { class: "bp-window__content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_bp_card, _mergeProps({
            class: ['bp-window', {
        'bp-window--closable': _ctx.closable,
        'bp-window--compact': _ctx.compactMode,
        ['bp-window--type-' + _ctx.type]: _ctx.type !== undefined,
      }],
            "seamless-header": "",
            "seamless-content": "",
            color: _ctx.color
          }, _ctx.$attrs, { ref: "windowEl" }), _createSlots({
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                ]),
                (_ctx.closable)
                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                      key: 0,
                      class: "bp-window__close-icon",
                      icon: "xmark",
                      onClick: _ctx.close
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(['bp-window__content', { 'bp-window__content--seamless': _ctx.seamlessContent }]),
                  ref: "contentEl"
                }, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ], 2)
              ])
            ]),
            _: 2
          }, [
            _renderList(Object.keys(_ctx.$slots).filter(slot => !['header', 'default'].includes(slot)), (slotName) => {
              return {
                name: slotName,
                fn: _withCtx((slotScope) => [
                  _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotScope)), undefined, true)
                ])
              }
            })
          ]), 1040, ["class", "color"])
        ]))
      : _createCommentVNode("", true)
  ]))
}