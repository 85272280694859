import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bp-footer__row" }
const _hoisted_2 = { class: "bp-footer__item" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "bp-footer__item" }
const _hoisted_5 = { class: "bp-footer__item" }
const _hoisted_6 = { class: "bp-footer__item" }
const _hoisted_7 = {
  key: 0,
  class: "bp-footer__item"
}
const _hoisted_8 = { class: "bp-footer__row bp-footer__row--dark" }
const _hoisted_9 = { class: "bp-footer__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_translate = _resolveComponent("translate")!

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(['bp-footer', { 'bp-footer--compact': _ctx.compactMode }]),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_bp_icon, {
          color: ['blue', 500],
          icon: "phone"
        }),
        _createElementVNode("a", {
          href: _ctx.isDevelopmentMode ? `/#${_ctx.$route.fullPath}` : 'tel:+4984418593200'
        }, "+49 (0) 8441 85 93 200", 8, _hoisted_3)
      ]),
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_bp_icon, {
          color: [_ctx.isDesktop ? 'light-blue' : 'blue', 500],
          icon: "envelope"
        }, null, 8, ["color"]),
        _cache[0] || (_cache[0] = _createElementVNode("a", { href: "mailto:support@cordaware.com" }, "support@cordaware.com", -1))
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_bp_icon, {
          color: [_ctx.isDesktop ? 'light-blue' : 'blue', 500],
          icon: "shield-halved"
        }, null, 8, ["color"]),
        _createVNode(_component_translate, {
          tag: "a",
          href: _ctx.$gettext('https://www.cordaware.com/eng/data-privacy-policy'),
          target: "_blank"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Data privacy policy")
          ])),
          _: 1
        }, 8, ["href"])
      ]),
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_bp_icon, {
          color: [_ctx.isAdmin && _ctx.isDesktop ? 'light-blue' : 'blue', 500],
          icon: "clock"
        }, null, 8, ["color"]),
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Available from Mon - Fri 9:00 am to 5:30 pm CET")
          ])),
          _: 1
        })
      ]),
      (_ctx.isAdmin)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createVNode(_component_bp_icon, {
              color: ['blue', 500],
              icon: "v"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.version), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("span", _hoisted_9, [
        _createVNode(_component_bp_icon, { icon: ['far', 'copyright'] }),
        _createElementVNode("span", null, _toDisplayString(new Date().getFullYear()) + " Cordaware GmbH Informationslogistik", 1)
      ])
    ])
  ], 6))
}