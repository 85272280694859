import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  ref: "sentinel",
  class: "bi-virtual-scroller__sentinel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "bi-virtual-scroller",
    onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args))),
    ref: "viewport",
    style: _normalizeStyle({
      '--row-height': _ctx.rowHeight + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "bi-virtual-scroller__content",
      style: _normalizeStyle({
        height: _ctx.totalContentHeight + 'px',
      })
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.nodesTag), {
        class: "bi-virtual-scroller__nodes",
        ref: "nodes"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (item, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.nodeTag), {
              class: "bi-virtual-scroller__node",
              key: item._id
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {
                  item: item,
                  index: _ctx.startNode + index
                }, () => [
                  _createTextVNode(_toDisplayString(item._id), 1)
                ], true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 3
      }, 512))
    ], 4),
    _createElementVNode("div", _hoisted_1, null, 512)
  ], 36))
}