import { $gettext } from 'vue-gettext';
import { currentLanguage } from '@/translation';
import { defineStore } from 'pinia';

interface State {
  salutations: Salutation[];
}

/**
 * A salutation.
 */
export interface Salutation {
  _id: string;
  name: string;
  salutation: string;
  example: string;
}

/**
 * The salutation store.
 */
export const useSalutationStore = defineStore('salutation', {
  ///-------------------------------------------------------------------
  /// STATE
  ///-------------------------------------------------------------------
  state: (): State => {
    return {
      salutations: [
        {
          _id: 'Herr',
          name: $gettext('Mr.'),
          salutation: $gettext('Mr.'),
          example: `${$gettext('e.g.')} ${$gettext('Hello')} ${$gettext('Mr. John Doe')}`,
        },
        {
          _id: 'Frau',
          name: $gettext('Ms.'),
          salutation: $gettext('Ms.'),
          example: `${$gettext('e.g.')} ${$gettext('Hello')} ${$gettext('Ms. Jane Doe')}`,
        },
        {
          _id: 'Herr/Frau',
          name: $gettext('Neutral salutation'),
          salutation: currentLanguage.value === 'de' ? '' : 'Mx.',
          example: `${$gettext('e.g.')} ${$gettext('Hello')} ${$gettext('Mx. J. Doe')}`,
        },
      ]
    }
  },
  ///-------------------------------------------------------------------
  /// GETTERS
  ///-------------------------------------------------------------------
  getters: {
    /**
     * Searches for the salutation with the given ID and returns its name.
     * @param id The ID of the salutation.
     * @returns The name of the salutation.
     */
    getNameOf: (state: State) => {
      return (id: string) => {
        return state.salutations.find(salutation => salutation._id === id)?.name || '';
      }
    },
    /**
     * Searches for the salutation with the given ID and returns its name.
     * @param id The ID of the salutation.
     * @returns The actual salutation to be used in a sentence.
     */
    getSalutationOf: (state: State) => {
      return (id: string) => {
        return state.salutations.find(salutation => salutation._id === id)?.salutation || '';
      }
    },
  },
})