import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#floating-elements" }, [
    _createElementVNode("div", {
      class: _normalizeClass(['bp-banner', `bp-banner--position-${_ctx.position}`, {
      'bp-banner--compact': _ctx.compactMode,
      'bp-banner--hidden': !_ctx.modelValue || !_ctx.visible,
    }])
    }, [
      (_ctx.closable)
        ? (_openBlock(), _createBlock(_component_bp_icon, {
            key: 0,
            class: "bp-banner__close-icon",
            icon: "xmark",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', false)))
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}