
import { ColorProp, cssColor, extractColor, extractShade, incrementShade, Modifier } from '@/utils/color';
import { computed, defineComponent, PropType } from 'vue';
import { Icon, IconProp } from './icon/BpIcon';
import { isAbsoluteUrl } from '@/utils/string';
import { type RouteLocationRaw, useRouter } from 'vue-router';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'BpButton',
  props: {
    active: Boolean,
    color: {
      type: ColorProp,
      default: 'light-blue',
    },
    disabled: Boolean,
    icon: IconProp,
    iconPosition: {
      type: String as PropType<'left' | 'top' | 'right' | 'bottom'>,
      default: 'left'
    },
    large: Boolean,
    to: [String, Object] as PropType<string | RouteLocationRaw>,
    type: {
      type: String as PropType<'button' | 'submit'>,
      default: 'button',
    }
  },
  emits: [
    'click',
  ],
  setup(props, ctx) {
    const router = useRouter();

    const component = computed(() => {
      if (typeof props.to === 'string' || ctx.attrs['href'] !== undefined || ctx.attrs['download'] !== undefined) {
        return 'a';
      } else if (props.to) {
        return 'router-link'
      }
      return 'button';
    });

    function iconVisible(download: unknown | undefined, icon: Icon | undefined): icon is Icon {
      return download !== undefined || !!icon;
    }

    function isExternalLink() {
      const href = ctx.attrs['href'] as string;
      return (typeof props.to === 'string' && isAbsoluteUrl(props.to)) || (href && isAbsoluteUrl(href));
    }

    function onClick(event: MouseEvent) {
      if (props.to) {
        event.stopImmediatePropagation();
        event.preventDefault();
        router.push(props.to);
      } else {
        ctx.emit('click', event);
      }
    }

    const { current: compactMode } = useCompactMode();

    const style = computed(() => {
      const color = extractColor(props.color);
      const shade = extractShade(props.color) || 600;
      return {
        '--button-background-color': cssColor(color, shade),
        '--button-background-color--dark': cssColor(color, incrementShade(shade, 100)),
        '--button-extrusion-color': cssColor(color, incrementShade(shade, 200)),
        '--button-extrusion-color--dark': cssColor(color, incrementShade(shade, 300)),
        '--button-icon-color': cssColor(color, Modifier.TEXT_SECONDARY),
        '--button-icon-color--dark': cssColor(color, incrementShade(shade, 100), Modifier.TEXT_SECONDARY),
        '--button-text-color': cssColor(color, Modifier.TEXT),
        '--button-text-color--dark': cssColor(color, incrementShade(shade, 100), Modifier.TEXT),
      }
    });

    return {
      compactMode,
      component,
      iconVisible,
      isExternalLink,
      onClick,
      style,
    }
  }
});
