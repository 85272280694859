import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bp-label__label"
}
const _hoisted_2 = {
  key: 0,
  class: "bp-label__label-required"
}
const _hoisted_3 = { class: "bp-label__wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "bp-label__baseline-setter"
}
const _hoisted_5 = { class: "bp-label__inner-wrapper" }
const _hoisted_6 = {
  key: 1,
  class: "bp-label__additionals"
}
const _hoisted_7 = {
  key: 0,
  class: "bp-label__character-limit"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "bp-label__text"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 2,
  class: "bp-label__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-label', {
      'bp-label--compact': _ctx.compactMode,
      'bp-label--disabled': _ctx.disabled,
      'bp-label--has-additionals': _ctx.hasCharacterLimit() || _ctx.hasError() || _ctx.hasText(),
      'bp-label--has-label': _ctx.hasLabel(),
      ['bp-label--position-' + _ctx.labelPosition]: _ctx.hasLabel(),
      'bp-label--seamless': _ctx.seamless,
    }]),
    ref: "labelEl",
    style: _normalizeStyle(_ctx.variables)
  }, [
    (_ctx.hasLabel())
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ], true),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " *"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.hasLabel() && _ctx.labelPosition === 'left')
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " "))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    (_ctx.hasCharacterLimit() || _ctx.hasError() || _ctx.hasText())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.hasCharacterLimit())
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _renderSlot(_ctx.$slots, "characterLimit", {}, () => [
                  _createElementVNode("span", { innerHTML: _ctx.characterLimit }, null, 8, _hoisted_8)
                ], true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasText())
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                _renderSlot(_ctx.$slots, "text", {}, () => [
                  _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_10)
                ], true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasError())
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                _createVNode(_component_bp_icon, { icon: _ctx.errorIcon }, null, 8, ["icon"]),
                _renderSlot(_ctx.$slots, "error", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.error), 1)
                ], true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}