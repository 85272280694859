import { RouteLocationRaw } from 'vue-router';
import { BaseDoc } from '../virtual-scroller/BpVirtualScroller';

export interface PopoverElement extends BaseDoc {
  click?: () => void;
  _disabled?: boolean;
  icon?: string | string[];
  to?: RouteLocationRaw;
}

export function seekOption(option: HTMLButtonElement | null, nextPrev: 'next' | 'prev' = 'next') {
  while (option && (option.classList.contains('bp-popover-menu__option--subheading') || option.classList.contains('bp-popover-menu__option--disabled'))) {
    option = (nextPrev === 'next' ? option.parentElement?.nextElementSibling?.children[0] : option.parentElement?.previousElementSibling?.children[0]) as HTMLButtonElement | null;
  }
  return option;
}