
import { $gettext } from 'vue-gettext';
import { availableLanguages, currentLanguage, currentLanguageISOString, getTranslatedKey, getTranslatedName, isTranslated, Translated, TranslatedKey } from '@/translation';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { EditorContent, useEditor } from '@tiptap/vue-3';
import { humanizedJoin, removeHTML, uuid } from '@/utils/string';
import { IconProp } from '../icon/BpIcon';
import { isEqual } from '@/utils/object';
import Alert from './extensions/alert';
import BpWysiwygEditorActionsVue from './BpWysiwygEditorActions.vue';
import Color from './extensions/color';
import FontFamily from '@tiptap/extension-font-family';
import FontSize from './extensions/font-size';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import CharacterCount from '@tiptap/extension-character-count';

/**
 * A Wysiwyg editor component.
 */
export default defineComponent({
  name: 'bp-wysiwyg-editor',
  components: {
    BpWysiwygEditorActions: BpWysiwygEditorActionsVue,
    EditorContent,
  },
  props: {
    modelValue: {
      type: [String, Object] as PropType<string | Translated<string>>,
      default: '<p></p>',
    },
    valid: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },
    allowAlerts: Boolean,
    maxlength: Number,
    desiredlength: Number,
    // LABEL
    disabled: Boolean,
    error: String,
    errorIcon: IconProp,
    label: String,
    labelPosition: String,
    labelWidth: String,
    large: Boolean,
    indent: Number,
    required: Boolean,
    seamless: Boolean,
    text: String,
  },
  emits: [
    'change-dirty',
    'change-valid',
    'update:model-value',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// LABEL
    ///-------------------------------------------------------------------

    const labelProps = computed(() => Object.fromEntries(Object.entries(props)
      .filter(([key]) => [
        'disabled', 'error', 'errorIcon', 'innerGap', 'indent', 'label', 'labelPosition', 'labelWidth', 'required', 'seamless', 'text'
      ].includes(key))));
    const labelSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => ![
        'default',
        'label',
        hasError() && 'error',
        isTranslated(props.modelValue) && 'text'
      ].includes(slot));
    });

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const language = ref<TranslatedKey>(currentLanguageISOString());

    function setLanguage(lang: string) {
      language.value = getTranslatedKey(lang);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editor.value?.commands.setContent((html.value as Translated<any>)[language.value])
    }

    ///-------------------------------------------------------------------
    /// AUTOCOMPLETE
    ///-------------------------------------------------------------------

    const autocompleteReference = ref<HTMLDivElement>();

    /**
     * Extends the current selection to the entire link.
     */
    function extendLinkSelection() {
      editor.value?.commands.extendMarkRange('link');
    }

    ///-------------------------------------------------------------------
    /// EDITOR
    ///-------------------------------------------------------------------

    const unique = uuid();

    const html = ref<string | Translated<string>>(props.modelValue);

    const editorContent = ref();
    const editor = useEditor({
      editorProps: { attributes: { class: 'bp-wysiwyg-editor__editor' } },
      content: isTranslated(html.value) ? html.value[language.value] : html.value,
      extensions: [
        Alert,
        Color,
        ...(props.maxlength || props.desiredlength ? [CharacterCount.configure({limit: props.maxlength || props.desiredlength})] : []),
        FontFamily,
        FontSize,
        Highlight.configure({ multicolor: true, HTMLAttributes: { style: 'color: inherit' } }),
        Image.configure({ inline: true }),
        Link.configure({ HTMLAttributes: { rel: undefined }, openOnClick: false }),
        StarterKit,
        Subscript,
        Superscript,
        Table.configure({ allowTableNodeSelection: true, HTMLAttributes: { style: 'border-spacing: 0.5rem; margin: -0.5rem;' } }),
        TableCell.configure({ HTMLAttributes: { style: 'padding: 0;' } }),
        TableHeader.configure({ HTMLAttributes: { style: 'padding: 0; text-align: left;' } }),
        TableRow,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        TextStyle,
        Typography,
        Underline,
      ],
      onBlur: () => {
        onChange();
        checkValidity();
      },
      onUpdate: () => {
        const editorValue = editor.value?.getHTML();
        const value = (!editorValue || !removeHTML(editorValue, { ALLOWED_TAGS: ['table', 'blockquote'] })) ? '' : editorValue;
        if (isTranslated(html.value)) {
          html.value = editor.value ? { ...html.value, [language.value]: value } : '';
        } else {
          html.value = editor.value ? value : '';
        }
        ctx.emit('update:model-value', html.value);
        if (!edited.value) {
          edited.value = true;
        }
      },
    });

    const edited = ref(false);
    watch(() => props.modelValue, () => {
      if (!editor.value) return;
      const editorValue = editor.value.getHTML();
      // If the new value (`props.modelValue`) is different from either the current value (`html.value`) or somehow the editor content (`editor.value.getHTML()`) we need to sync them again
      if (
        (typeof html.value === 'string' && typeof props.modelValue === 'string' && html.value !== props.modelValue) ||
        (typeof html.value !== 'string' && typeof props.modelValue !== 'string' && !isEqual(html.value, props.modelValue)) ||
        (typeof props.modelValue === 'string' && props.modelValue !== editorValue) ||
        (typeof props.modelValue !== 'string' && props.modelValue[language.value] !== editorValue)
      ) {
        // If the model value is an object containing text for multiple languages, treat every string individually
        if (isTranslated(props.modelValue)) {
          html.value = {};
          for (const [lang, value] of Object.entries(props.modelValue) as [TranslatedKey, string][]) {
            editor.value.commands.setContent(value, false, { preserveWhitespace: 'full' });
            html.value[lang] = editor.value.getHTML()
          }
          editor.value.commands.setContent(props.modelValue[language.value] || '', false, { preserveWhitespace: 'full' });
          if (!isEqual(props.modelValue, html.value)) {
            ctx.emit('update:model-value', html.value);
          }
        } else {
          editor.value.commands.setContent(props.modelValue, false, { preserveWhitespace: 'full' });
          html.value = editor.value.getHTML()
          if (props.modelValue !== html.value) {
            ctx.emit('update:model-value', html.value);
          }
        }
      }
    }, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// DIRTY
    ///-------------------------------------------------------------------

    const isDirty = ref(false);
    function onChange() {
      if (!isDirty.value && edited.value) {
        isDirty.value = true;
        ctx.emit('change-dirty', true);
      }
    }

    ///-------------------------------------------------------------------
    /// VALIDITY
    ///-------------------------------------------------------------------

    const hasError = () => !!((isDirty.value && (props.valid === false || !isValid.value || errorMessage.value)) || props.error || ctx.slots.error);

    const isValid = ref<boolean>(props.valid !== undefined ? props.valid : true);
    const errorMessage = ref('');

    function checkValidity() {
      const wasValid = isValid.value;
      const oldError = errorMessage.value;
      const value = html.value;

      errorMessage.value = '';

      ///-------------------------------------------------------------------
      /// DISABLED -- TODO: Check if this logic is correct!
      ///-------------------------------------------------------------------

      if (props.disabled) {
        ctx.emit('change-valid', '', value);
        return;
      }

      ///-------------------------------------------------------------------
      /// REQUIRED
      ///-------------------------------------------------------------------

      if (props.required) {
        // TRANSLATED OBJECT
        if (isTranslated(value) && Object.values(value).filter(val => !(removeHTML(val))).length > 0) {
          isValid.value = false;
          const errorLanguages = Object.entries(value)
            .filter(([, val]) => !(removeHTML(val)))
            .map(([lang]) => getTranslatedName(lang))
            .sort((a, b) => a < b ? -1 : 1);
          errorMessage.value = humanizedJoin(errorLanguages) + ' ' + (errorLanguages.length > 1 ? $gettext('are required.') : $gettext('is required.'));
          ctx.emit('change-valid', errorMessage.value, value);
          return;
        }

        // STRING
        if (typeof value === 'string' && removeHTML(value).length === 0) {
          isValid.value = false;
          errorMessage.value = $gettext('Is required.');
          ctx.emit('change-valid', errorMessage.value, value);
          return;
        }
      }

      ///-------------------------------------------------------------------
      /// DIRTY
      ///-------------------------------------------------------------------

      if (!isDirty.value) {
        ctx.emit('change-valid', '', value);
        return;
      }

      ///-------------------------------------------------------------------
      /// VALIDITY
      ///-------------------------------------------------------------------

      isValid.value = true;

      if (wasValid !== isValid.value || oldError !== errorMessage.value) {
        ctx.emit('change-valid', isValid.value ? null : errorMessage.value, value);
      }
    }

    watch(
      () => [props.modelValue, currentLanguage.value, props.required, props.disabled],
      () => setTimeout(checkValidity, 0),
      { immediate: true, deep: true }
    );

    function updateError(event: Event) {
      const message = (event.target as HTMLInputElement)?.validationMessage;
      errorMessage.value = props.error || message;
      ctx.emit('change-valid', errorMessage.value, props.modelValue);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      autocompleteReference,
      availableLanguages,
      checkValidity,
      editor,
      editorContent,
      errorMessage,
      extendLinkSelection,
      getTranslatedKey,
      getTranslatedName,
      hasError,
      html,
      isDirty,
      isTranslated,
      labelProps,
      labelSlots,
      language,
      onChange,
      setLanguage,
      unique,
      updateError,
    };
  },
});
