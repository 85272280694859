
import '@vuepic/vue-datepicker/dist/main.css';
import { $gettext, $pgettext } from 'vue-gettext';
import { availableLanguages, currentLanguage, currentLanguageISOString, getTranslatedKey, getTranslatedName, isTranslated, Translated, TranslatedKey } from '@/translation';
import { computed, CSSProperties, defineComponent, PropType, ref, watch } from 'vue';
import { cssColor, Modifier } from '@/utils/color';
import { de, enUS } from 'date-fns/locale';
import { humanizedJoin, uuid } from '@/utils/string';
import { Icon, IconProp } from './icon/BpIcon';
import { localizeDate, localizeDatetime, localizeTime } from '@/utils/date';
import Datepicker from '@vuepic/vue-datepicker';
import getCssVariable from '@/utils/css';
import useCompactMode from '@/compositions/use-compact-mode';
import useDarkMode from '@/compositions/use-dark-mode';

export default defineComponent({
  name: 'BpInput',
  components: {
    Datepicker,
  },
  props: {
    // GENERAL
    modelValue: {
      type: [String, Number, Boolean, Date, Object] as PropType<string | number | boolean | Date | Translated<string>>,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    chunks: Number,
    readonly: Boolean,
    type: {
      type: String as PropType<
        | 'button' | 'checkbox' | 'color' | 'date' | 'datetime' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number'
        | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'textarea' | 'time' | 'toggle' | 'url' | 'week'
      >,
      default: 'text',
    },
    valid: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },
    pattern: [String, Object] as PropType<string | RegExp>,
    before: String,
    prefix: String,
    suffix: String,
    after: String,
    // CHECKBOX
    // indeterminate: Boolean,
    flush: Boolean,
    // RADIO
    name: String,
    value: String,
    // DATEPICKER
    maxDate: [Date, String],
    minDate: [Date, String],
    // TEXTAREA
    resizable: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 4,
    },
    maxlength: Number,
    desiredlength: Number,
    // LABEL
    disabled: Boolean,
    error: String,
    errorIcon: IconProp,
    label: String,
    labelPosition: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
    labelWidth: String,
    large: Boolean,
    indent: Number,
    required: Boolean,
    seamless: Boolean,
    text: String,
  },
  emits: [
    'update:model-value',
    // 'update:indeterminate',
    'change-dirty',
    'change-file',
    'change-valid',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// LABEL
    ///-------------------------------------------------------------------

    const labelProps = computed(() => Object.fromEntries(Object.entries(props)
      .filter(([key]) => [
        'disabled', 'error', 'errorIcon', 'innerGap', 'indent', 'label', 'labelPosition', 'labelWidth', 'required', 'seamless', 'text'
      ].includes(key))));
    const labelSlots = computed(() => {
      return Object.keys(ctx.slots).filter(slot => ![
        'default',
        'label',
        hasError() && 'error',
        (
          ['checkbox', 'radio', 'toggle'].includes(props.type) ||
          isTranslated(props.modelValue) ||
          (desiredLength.value > 0 || maxLength.value > 0 && typeof (isTranslated(props.modelValue) ? props.modelValue[language.value] : props.modelValue) === 'string')
        ) && 'text'
      ].includes(slot));
    });

    ///-------------------------------------------------------------------
    /// MAXLENGTH
    ///-------------------------------------------------------------------
    
    const desiredLength = computed(() => {
      if (props.desiredlength) return props.desiredlength;
      return 0;
    })
    const maxLength = computed(() => {
      if (props.maxlength) return props.maxlength;
      switch (props.type) {
        case 'tel': return 50;
        case 'email': return 254;
        default: return 0;
      }
    });

    ///-------------------------------------------------------------------
    /// INPUT
    ///-------------------------------------------------------------------

    const inputEl = ref<HTMLInputElement | HTMLTextAreaElement | typeof Datepicker>();
    const isDirty = ref(false);
    const unique = uuid();

    const input = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let component: any = 'input';
      let value = props.modelValue;
      if (props.type === 'number' && typeof props.modelValue === 'string') {
        const valueAsNumber = parseFloat(props.modelValue);
        value = isNaN(valueAsNumber) ? 0 : valueAsNumber;
      }
      let inputProps: Record<string, unknown> = {
        value,
        type: props.type,
        onChange: onChange,
        onInput: onInput,
      };
      if (isTranslated(props.modelValue)) {
        inputProps.value = props.modelValue[language.value]
      }
      switch (props.type) {
        case 'textarea': {
          component = 'textarea';
          inputProps.rows = props.rows;
          inputProps.maxlength = props.chunks || (desiredLength.value > 0 ? desiredLength.value : undefined) || (maxLength.value > 0 ? maxLength.value : undefined);
          break;
        }
        case 'file': {
          inputProps = {
            ...inputProps,
            id: 'file-' + unique,
            onChange: onChangeFile,
            // onDrop: onDrop,
            // onDragover: onDragover,
          }
          break;
        }
        case 'checkbox':
        case 'toggle': {
          inputProps = {
            ...inputProps,
            type: 'checkbox',
            checked: inputProps.value,
            value: undefined,
          }
          break;
        }
        case 'radio': {
          inputProps = {
            ...inputProps,
            checked: inputProps.value === props.value,
            name: props.name,
            value: props.value,
          }
          break;
        }
        case 'date':
        case 'month':
        case 'week':
        case 'datetime':
        case 'datetime-local':
        case 'time': {
          component = Datepicker;
          inputProps = {
            // TODO: Correct v-model handling for different picker, e.g. month & week picker
            modelValue: inputProps.value instanceof Date ? inputProps.value : typeof inputProps.value === 'string' ? new Date(inputProps.value) : '',
            'onUpdate:modelValue': onUpdate,
            // Mode specific settings
            enableTimePicker: !['date', 'month', 'week'].includes(props.type),
            monthPicker: props.type === 'month',
            timePicker: props.type === 'time',
            weekPicker: props.type === 'week',
            // General settings
            autoApply: true,
            calendarClassName: 'bp-input__datepicker-calendar',
            calendarCellClassName: 'bp-input__datepicker-calendar-cell',
            clearable: props.clearable && !props.required,
            closeOnAutoApply: !['datetime', 'datetime-local'].includes(props.type),
            disabled: props.disabled,
            format: ['date', 'month', 'week'].includes(props.type) ? localizeDate : ['datetime', 'datetime-local'].includes(props.type) ? localizeDatetime : localizeTime,
            formatLocale: currentLanguage.value === 'de' ? de : enUS,
            hideInputIcon: true,
            hideOffsetDates: true,
            locale: currentLanguageISOString(),
            maxDate: props.maxDate,
            menuClassName: 'bp-input__datepicker-menu',
            minDate: props.minDate,
            monthChangeOnScroll: false,
            preventMinMaxNavigation: !!props.maxDate || !!props.minDate,
            teleport: '#floating-elements',
            utc: true,
            textInput: true,
            textInputOptions: {
              format: props.type === 'month'
                ? $pgettext('Date & time format', 'MM/yyyy')
                : props.type === 'time'
                  ? $pgettext('Date & time format', 'HH:mm')
                  : $pgettext('Date & time format', 'MM/dd/yyyy HH:mm'),
            },
            transitions: false,
            weekNumbers: true,
            // TODO: Reposition menu when scrolling in container which is not the document body
            // altPosition: (el: HTMLElement | undefined) => { left: '50%', top: '50%', transform: 'translate(-50%, -50%)' },
          };
          break;
        }
      }
      return {
        component,
        props: {
          ...ctx.attrs,
          disabled: props.disabled,
          maxlength: props.chunks || (desiredLength.value > 0 ? desiredLength.value : undefined) || (maxLength.value > 0 ? maxLength.value : undefined),
          ...inputProps,
        }
      }
    });

    ///-------------------------------------------------------------------
    /// DATEPICKER
    ///-------------------------------------------------------------------

    const datePickerEl = ref<HTMLInputElement>();

    function openDatepicker() {
      if (inputEl.value) {
        (inputEl.value as typeof Datepicker).openMenu()
      }
    }

    ///-------------------------------------------------------------------
    /// CHECKBOX
    ///-------------------------------------------------------------------

    function toggle() {
      if (['checkbox', 'toggle'].includes(props.type) && !props.disabled) {
        ctx.emit('update:model-value', /* props.indeterminate ? true : */ !props.modelValue);
        // ctx.emit('update:indeterminate', false);
      }
    }

    function select() {
      if (props.type === 'radio' && !props.disabled) {
        ctx.emit('update:model-value', props.value);
      }
    }

    function icon(type: 'checkbox' | 'radio') {
      return [
        ...((type === 'checkbox' && props.modelValue) || (type === 'radio' && props.modelValue === props.value)
          ? [{
              icon: type === 'checkbox' ? ['fad', 'square-check'] : ['fad', 'circle-dot'],
              primaryColor: props.disabled ? 'var(--theme-text-disabled)' : 'var(--theme-text)',
              secondaryColor: props.disabled ? 'var(--theme-disabled)' : 'var(--theme-background)',
              secondaryOpacity: 1,
            }]
          : [{
              icon: type === 'checkbox' ? 'square' : 'circle',
              color: props.disabled ? 'var(--theme-disabled)' : 'var(--theme-background)',
            }]
        ),
        {
          icon: type === 'checkbox' ? ['far', 'square'] : ['far', 'circle'],
          color: props.disabled ? 'var(--theme-text-disabled)' : 'var(--theme-intense-border)',
        },
      ] as Icon;
    }

    ///-------------------------------------------------------------------
    /// FILE
    ///-------------------------------------------------------------------

    async function onChangeFile(event: Event) {
      onChange();
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (!files) {
        return;
      }
      const file = Array.from(files)[0];
      if (!file) {
        ctx.emit('change-file', '', null);
        return;
      }
      const fileData = await readFileAsync(file);
      ctx.emit('change-file', file.name, fileData);
    }

    function readFileAsync(file: File) {
      return new Promise<string>((res, rej) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (typeof reader.result === 'string') {
            res(reader.result);
          } else {
            rej(new Error('Invalid file contents'));
          }
        };
        reader.onerror = () => rej(reader.error);
        reader.onabort = () => rej(reader.error);
      });
    }

    ///-------------------------------------------------------------------
    /// PASSWORD
    ///-------------------------------------------------------------------

    const showPassword = ref(false);

    function password(event: MouseEvent | TouchEvent) {
      event.preventDefault();
      event.stopImmediatePropagation();
      showPassword.value = ['mousedown', 'touchstart'].includes(event.type) ? true : false;
    }

    ///-------------------------------------------------------------------
    /// TEXT
    ///-------------------------------------------------------------------

    const hasText = () => !!((['checkbox', 'radio', 'toggle'].includes(props.type) || isTranslated(props.modelValue)) || props.maxlength || props.text || ctx.slots.text);

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const language = ref<TranslatedKey>(currentLanguageISOString());

    function setLanguage(lang: string) {
      language.value = getTranslatedKey(lang);
    }

    ///-------------------------------------------------------------------
    /// OTHER
    ///-------------------------------------------------------------------

    function onInput(event: InputEvent) {
      const target = event.target as HTMLInputElement;
      const modelValue = props.modelValue;
      if (isTranslated(modelValue)) {
        if (props.type === "number") {
          ctx.emit('update:model-value', { ...modelValue, [language.value]: isNaN(target.valueAsNumber) ? 0 : target.valueAsNumber });
        } else if (['checkbox', 'toggle'].includes(props.type)){
          ctx.emit('update:model-value', { ...modelValue, [language.value]: target.checked });
        } else {
          ctx.emit('update:model-value', { ...modelValue, [language.value]: target.value });
        }
      } else {
        if (props.type === "number") {
          ctx.emit('update:model-value', isNaN(target.valueAsNumber) ? 0 : target.valueAsNumber);
        } else if (['checkbox', 'toggle'].includes(props.type)){
          ctx.emit('update:model-value', target.checked);
        } else {
          ctx.emit('update:model-value', target.value);
        }
      }
    }

    function onUpdate(date: string | Date | null) {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!date || !(date instanceof Date) || isNaN(date as any)) {
        ctx.emit('update:model-value', '');
        return;
      }
      if (['date', 'month', 'week'].includes(props.type)) {
        date.setUTCHours(0, 0, 0);
      }
      switch (props.type) {
        case 'date':
          ctx.emit('update:model-value', date.toISOString().split('T')[0]);
          return;
        case 'time':
          ctx.emit('update:model-value', date.toISOString().split('T')[1].slice(0, -1));
          return;
        default:
          ctx.emit('update:model-value', date.toISOString());
          return;
      }
    }

    function onChange() {
      if (!isDirty.value) {
        isDirty.value = true;
        ctx.emit('change-dirty', true);
      }
    }

    ///-------------------------------------------------------------------
    /// VALIDITY
    ///-------------------------------------------------------------------

    const hasError = () => !!((isDirty.value && (props.valid === false || !isValid.value || errorMessage.value)) || props.error || ctx.slots.error);

    const isValid = ref<boolean>(props.valid !== undefined ? props.valid : true);
    const errorMessage = ref('');

    function checkValidity() {
      const wasValid = isValid.value;
      const oldError = errorMessage.value;
      const value = props.modelValue as string;

      errorMessage.value = '';

      ///-------------------------------------------------------------------
      /// DISABLED -- TODO: Check if this logic is correct!
      ///-------------------------------------------------------------------

      if (props.disabled) {
        ctx.emit('change-valid', '', value);
        return;
      }

      ///-------------------------------------------------------------------
      /// REQUIRED
      ///-------------------------------------------------------------------

      if (props.required) {
        // TRANSLATED OBJECT
        if (isTranslated(value) && Object.values(value).filter(val => !val).length > 0) {
          isValid.value = false;
          const errorLanguages = Object.entries(value)
            .filter(([, val]) => !val)
            .map(([lang]) => getTranslatedName(lang))
            .sort((a, b) => a < b ? -1 : 1);
          errorMessage.value = humanizedJoin(errorLanguages) + ' ' + (errorLanguages.length > 1 ? $gettext('are required.') : $gettext('is required.'));
          ctx.emit('change-valid', errorMessage.value, value);
          // console.log('... translated object is required', {errorMessage: errorMessage.value, value});
          return;
        }

        // STRING
        if (typeof value === 'string' && value.length === 0) {
          isValid.value = false;
          errorMessage.value = $gettext('Is required.');
          ctx.emit('change-valid', errorMessage.value, value);
          // console.log('... string is required', {errorMessage: errorMessage.value, value});
          return;
        }

        // BOOLEAN
        if (typeof value === 'boolean' && value === false) {
          isValid.value = false;
          errorMessage.value = $gettext('Is required.');
          ctx.emit('change-valid', errorMessage.value, value);
          // console.log('... boolean is required', {errorMessage: errorMessage.value, value});
          return;
        }
      }

      ///-------------------------------------------------------------------
      /// VALIDITY
      ///-------------------------------------------------------------------

      if (inputEl.value instanceof HTMLInputElement) {
        setTimeout(() => {
          isValid.value = inputEl.value?.checkValidity();
          if (!isValid.value) {
            // console.log('... native validity check', {errorMessage: errorMessage.value, value});
            return;
          }
        }, 0);
      }

      ///-------------------------------------------------------------------
      /// DIRTY
      ///-------------------------------------------------------------------

      if (!isDirty.value) {
        ctx.emit('change-valid', '', value);
        // console.log('... is dirty', {errorMessage: errorMessage.value, value});
        return;
      }

      // Custom validity handling
      switch (props.type) {
        case 'email': {
          isValid.value = value.length === 0 || /^.+@.+\..{2,}$/.test(value);
          if (!isValid.value) {
            errorMessage.value = $gettext('Invalid email address.');
          }
          break;
        }
        case 'tel': {
          isValid.value = value.length === 0 || /^\+?[0-9\s/\-().]+$/.test(value);
          if (!isValid.value) {
            errorMessage.value = $gettext('Invalid phone number.');
          }
          break;
        }
      }

      // Error prop/slot
      if (props.error || ctx.slots.error) {
        isValid.value = false;
        errorMessage.value = props.error || 'TODO';
      }

      if (wasValid !== isValid.value || oldError !== errorMessage.value) {
        ctx.emit('change-valid', isValid.value ? null : errorMessage.value, value);
        // console.log('... validity changed', {errorMessage: errorMessage.value, value});
      }
      // console.log('... eof', {errorMessage: errorMessage.value, value});
    }

    watch(
      () => [props.modelValue, currentLanguage.value, props.required, props.disabled],
      () => setTimeout(checkValidity, 0),
      { immediate: true, deep: true }
    );

    function updateError(event: Event) {
      const message = (event.target as HTMLInputElement)?.validationMessage;
      errorMessage.value = props.error || message;
      ctx.emit('change-valid', errorMessage.value, props.modelValue);
    }

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();
    const { current: darkMode } = useDarkMode();

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    function getComputedVariable(variable: string) {
      return `${eval(getCssVariable(variable)
        .replaceAll('calc', '')
        .replace(/([\d.]+)rem/g, match => (parseFloat(match) * 16).toString())
        .replace(/([\d.]+)px/g, '$1'))}px`;
    }

    function chunksBackgroundImage(fillVariable: string, borderVariable: string) {
      const chunkWidth = compactMode?.value ? '24px' : '40px';
      const chunkHeight = compactMode?.value ? '32.4px' : '48.4px';
      return `<svg xmlns="http://www.w3.org/2000/svg">
        <rect
          x="${getComputedVariable('border-width-xs')}"
          y="${getComputedVariable('border-width-xs')}"
          width="${chunkWidth}"
          height="${chunkHeight}"
          stroke="${getCssVariable(borderVariable)}"
          stroke-width="${getComputedVariable('border-width-sm')}"
          rx="${getComputedVariable('border-radius-sm')}"
          stroke-linejoin="round"
          fill="${getCssVariable(fillVariable)}"
        ></rect>
      </svg>`.replace(/\n\s*/g, ' ').replaceAll('> <', '><')
    }

    const style = ref<CSSProperties>({});
    function computeStyle() {
      const colorVariable = hasError()
        ? 'color-red-600'
        : isDirty.value && props.valid !== undefined && props.valid
          ? 'color-green-600'
          : 'theme-intense-border';
      style.value = {
        '--input-border-color': cssColor('light-blue'),
        '--input-focus-background-color': cssColor('light-blue'),
        '--input-focus-text-color': cssColor('light-blue', Modifier.TEXT),
        // CHUNKS
        ...(props.chunks ? {
          '--input-chunks': props.chunks,
          '--input-chunks-background-image': `url('data:image/svg+xml;utf8,${chunksBackgroundImage('theme-background', colorVariable)}')`,
          '--input-chunks-background-image-focus': `url('data:image/svg+xml;utf8,${chunksBackgroundImage(colorVariable, colorVariable)}')`,
        } : {}),
      }
    }
    watch(() => [darkMode?.value, hasError()], computeStyle, { immediate: true })

    ///-------------------------------------------------------------------
    /// VALIDATE PATTERN
    ///-------------------------------------------------------------------

    function patternBeforeinput(event: Event) {
      const pattern = props.pattern ? props.pattern : /*props.type === 'email' ? new RegExp('.+@.+\\..{2,}', 'i') :*/ undefined;
      if (!pattern) {
        return;
      }

      const target = (event as InputEvent).target as HTMLInputElement;
      const char = (event as InputEvent).data;
      const text = (target.value + ((event as InputEvent).inputType === 'insertLineBreak' ? '\n' : char || '')) as string;
      const regExp = typeof pattern === 'string' ? new RegExp(pattern) : pattern;
      const hasSelection =
        typeof target.selectionStart === 'number' && typeof target.selectionEnd === 'number'
          ? Math.abs(target.selectionEnd - target.selectionStart) !== 0
          : false;

      const preventChar = !!(char && !regExp.test(char));
      const preventText = !!(text && !regExp.test(text));

      if (!(event as InputEvent).inputType.startsWith('deleteContent') && (preventChar || preventText) && !hasSelection) {
        event.preventDefault();
      }
    }

    function patternPaste(event: ClipboardEvent) {
      const pattern = props.pattern ? props.pattern : /*props.type === 'email' ? new RegExp('.+@.+\\..{2,}', 'i') :*/ undefined;
      if (!pattern) {
        return;
      }
      try {
        let paste = event.clipboardData?.getData('Text') || '';
        const regExp = typeof pattern === 'string' ? new RegExp(pattern) : pattern;

        const value = [];
        for (let match of paste.matchAll(regExp)) {
          value.push(match);
        }
        paste = value.join('');

        const target = event.target as HTMLInputElement;
        target.setRangeText(paste);
        target.selectionStart = (target.selectionStart || 0) + paste.length;
        target.selectionEnd = target.selectionStart;

        event.preventDefault();
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        console.debug('[INPUT] paste event triggered while using the "pattern" property. Caught error:', (error as Error).message);
      }
    }

    ///-------------------------------------------------------------------
    /// SLOTS
    ///-------------------------------------------------------------------

    const hasBefore = () => !!(ctx.slots.before || props.before)
    const hasPrefix = () => !!(ctx.slots.prefix || props.prefix)
    const hasSuffix = () => !!(ctx.slots.suffix || ['date', 'datetime', 'datetime-local', 'month', 'time', 'week', 'checkbox', 'toggle', 'radio', 'file', 'password'].includes(props.type))
    const hasAfter = () => !!(ctx.slots.after || props.after)

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      availableLanguages,
      checkValidity,
      compactMode,
      datePickerEl,
      de,
      desiredLength,
      enUS,
      errorMessage,
      getTranslatedKey,
      getTranslatedName,
      hasAfter,
      hasBefore,
      hasError,
      hasPrefix,
      hasSuffix,
      hasText,
      icon,
      input,
      inputEl,
      isDirty,
      isTranslated,
      language,
      maxLength,
      onChange,
      onInput,
      onUpdate,
      openDatepicker,
      password,
      select,
      setLanguage,
      showPassword,
      style,
      toggle,
      unique,
      updateError,
      // LABEL
      labelProps,
      labelSlots,
      patternBeforeinput,
      patternPaste,
    }
  }
});
