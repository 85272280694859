import { ref } from 'vue';
import { KeyOf, LiteralUnion } from './object';

export type FormError = { name: string, message: string }

export function useFormErrors<T>() {
  const formErrors = ref(new Map<string, FormError>());

  function setFormError(id: LiteralUnion<KeyOf<T>>, name: FormError['name'], message: FormError['message'], errors: Map<string, FormError> = formErrors.value) {
    if (!message && errors.has(id)) {
      errors.delete(id);
    } else if (message) {
      errors.set(id, { name, message })
    }
  }

  function formatFormErrors(errors: Map<string, FormError> = formErrors.value) {
    const formattedErrors: string[] = [];
    for (const error of errors.values()) {
      formattedErrors.push(`<p style="white-space: nowrap;"><strong>${error.name}:</strong> ${error.message}</p>`);
    }
    return formattedErrors.join('\n');
  }

  return {
    errors: formErrors,
    formatErrors: formatFormErrors,
    setError: setFormError,
  }
}